var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpText",
        {
          attrs: {
            muted: _vm.payload.inactive,
            strikethrough: _vm.payload.inactive
          }
        },
        [
          _c("ItpIcon", {
            attrs: {
              name:
                _vm.payload.account_type === "cash_book"
                  ? _vm.$config.account.cashBook.icon
                  : _vm.$config.account.bankAccount.icon
            }
          }),
          _vm._v(" "),
          _c("span", [
            _c("strong", [_vm._v(_vm._s(_vm.payload.name))]),
            _vm._v(
              " (" +
                _vm._s(_vm.payload.currency) +
                ") " +
                _vm._s(_vm.payload.bank || "") +
                "\n    "
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }