var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "name",
            label: _vm._f("pgettext")("Name", "Employee"),
            labelColsMd: "4",
            contentColsMd: "8",
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "name", required: "", autofocus: "" },
            model: {
              value: _vm.employee.name,
              callback: function($$v) {
                _vm.$set(_vm.employee, "name", $$v)
              },
              expression: "employee.name"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "job_title",
            label: _vm._f("pgettext")("Job Title", "Employee"),
            labelColsMd: "4",
            contentColsMd: "8",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "job_title" },
            model: {
              value: _vm.employee.job_title,
              callback: function($$v) {
                _vm.$set(_vm.employee, "job_title", $$v)
              },
              expression: "employee.job_title"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "phone",
            label: _vm._f("pgettext")("Phone Number", "Employee"),
            labelColsMd: "4",
            contentColsMd: "8",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "phone" },
            model: {
              value: _vm.employee.phone,
              callback: function($$v) {
                _vm.$set(_vm.employee, "phone", $$v)
              },
              expression: "employee.phone"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "mobile",
            label: _vm._f("pgettext")("Mobile Number", "Employee"),
            labelColsMd: "4",
            contentColsMd: "8",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "mobile" },
            model: {
              value: _vm.employee.mobile,
              callback: function($$v) {
                _vm.$set(_vm.employee, "mobile", $$v)
              },
              expression: "employee.mobile"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "email",
            label: _vm._f("pgettext")("Email", "Employee"),
            labelColsMd: "4",
            contentColsMd: "8",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "email" },
            model: {
              value: _vm.employee.email,
              callback: function($$v) {
                _vm.$set(_vm.employee, "email", $$v)
              },
              expression: "employee.email"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "fax",
            label: _vm._f("pgettext")("Fax", "Employee"),
            labelColsMd: "4",
            contentColsMd: "8",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "fax" },
            model: {
              value: _vm.employee.fax,
              callback: function($$v) {
                _vm.$set(_vm.employee, "fax", $$v)
              },
              expression: "employee.fax"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "notes",
            label: _vm._f("pgettext")("Notes", "Employee"),
            labelColsMd: "4",
            contentColsMd: "8",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormTextarea", {
            attrs: { name: "notes" },
            model: {
              value: _vm.employee.notes,
              callback: function($$v) {
                _vm.$set(_vm.employee, "notes", $$v)
              },
              expression: "employee.notes"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }