var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "InflationIndexedPriceChange",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "ItpForm",
                {
                  attrs: { name: "payload", id: "hnvqxzl56c" },
                  on: {
                    submit: function($event) {
                      return _vm.evh_1040167445267876_submit($event, {})
                    }
                  }
                },
                [
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelFor: "contract_number",
                        label: _vm._f("gettext")("Contract number"),
                        labelColsMd: "4",
                        contentColsMd: "8",
                        labelAlign: _vm.labelAlign
                      }
                    },
                    [
                      _c("ItpFormPlaintext", { attrs: { strong: "" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.item.data.contract_data.contract_number
                            ) +
                            "\n        "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelFor: "tenant",
                        label: _vm._f("gettext")("Tenant"),
                        labelColsMd: "4",
                        contentColsMd: "8",
                        labelAlign: _vm.labelAlign
                      }
                    },
                    [
                      _c("ItpFormPlaintext", { attrs: { strong: "" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.item.data.contract_data.counterparty.name
                            ) +
                            "\n        "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.item.data.contract_data.rental
                    ? _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            labelFor: "rental",
                            label: _vm._f("gettext")("Rental"),
                            labelColsMd: "4",
                            contentColsMd: "8",
                            labelAlign: _vm.labelAlign
                          }
                        },
                        [
                          _c("ItpFormPlaintext", { attrs: { strong: "" } }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.item.data.contract_data.rental.name
                                ) +
                                "\n        "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "name",
                        label: _vm._f("pgettext")("Name", "ContractService"),
                        labelColsMd: "4",
                        contentColsMd: "8",
                        labelAlign: _vm.labelAlign
                      }
                    },
                    [
                      _c("ItpFormInput", {
                        attrs: { name: "name", readonly: "" },
                        model: {
                          value: _vm.item.data.service_data.name,
                          callback: function($$v) {
                            _vm.$set(_vm.item.data.service_data, "name", $$v)
                          },
                          expression: "item.data.service_data.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "current_price",
                        label: _vm._f("pgettext")(
                          "Current price",
                          "ContractServiceFee"
                        ),
                        labelColsMd: "4",
                        contentColsMd: "8",
                        labelAlign: _vm.labelAlign,
                        contentClass: "input-group"
                      }
                    },
                    [
                      _c("ItpFormInput", {
                        staticClass: "col-4",
                        attrs: {
                          type: "number",
                          name: "fee_amount",
                          readonly: ""
                        },
                        model: {
                          value: _vm.item.data.source_object.amount,
                          callback: function($$v) {
                            _vm.$set(_vm.item.data.source_object, "amount", $$v)
                          },
                          expression: "item.data.source_object.amount"
                        }
                      }),
                      _vm._v(" "),
                      _c("DIV", { staticClass: "input-group-append" }, [
                        _c("span", { staticClass: "input-group-text" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.item.data.source_object.currency) +
                              "\n          "
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "amount",
                        label: _vm._f("pgettext")(
                          "New price",
                          "ContractServiceFee"
                        ),
                        labelColsMd: "4",
                        contentColsMd: "8",
                        labelAlign: _vm.labelAlign,
                        contentClass: "input-group"
                      }
                    },
                    [
                      _c("ItpFormInput", {
                        staticClass: "col-4",
                        attrs: {
                          type: "number",
                          name: "fee_amount",
                          required: ""
                        },
                        model: {
                          value: _vm.payload.price,
                          callback: function($$v) {
                            _vm.$set(_vm.payload, "price", $$v)
                          },
                          expression: "payload.price"
                        }
                      }),
                      _vm._v(" "),
                      _c("DIV", { staticClass: "input-group-append" }, [
                        _c("span", { staticClass: "input-group-text" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.item.data.source_object.currency) +
                              "\n          "
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "valid_from",
                        label: _vm._f("pgettext")(
                          "Valid from",
                          "ContractServiceFee"
                        ),
                        labelColsMd: "4",
                        contentColsMd: "4",
                        labelAlign: _vm.labelAlign
                      }
                    },
                    [
                      _c("ItpFormDatetime", {
                        attrs: {
                          name: "valid_from",
                          required: "",
                          readonly: _vm.isServiceReadonly
                        },
                        model: {
                          value: _vm.payload.valid_from,
                          callback: function($$v) {
                            _vm.$set(_vm.payload, "valid_from", $$v)
                          },
                          expression: "payload.valid_from"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "valid_to",
                        label: _vm._f("pgettext")(
                          "Valid to",
                          "ContractServiceFee"
                        ),
                        labelColsMd: "4",
                        contentColsMd: "4",
                        labelAlign: _vm.labelAlign
                      }
                    },
                    [
                      _c("ItpFormDatetime", {
                        attrs: {
                          name: "valid_to",
                          readonly: _vm.isServiceReadonly
                        },
                        model: {
                          value: _vm.payload.valid_to,
                          callback: function($$v) {
                            _vm.$set(_vm.payload, "valid_to", $$v)
                          },
                          expression: "payload.valid_to"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "fee_notes",
                        label: _vm._f("pgettext")(
                          "Notes",
                          "ContractServiceFee"
                        ),
                        labelColsMd: "4",
                        contentColsMd: "8",
                        labelAlign: _vm.labelAlign
                      }
                    },
                    [
                      _c("ItpFormTextarea", {
                        attrs: { name: "fee_notes" },
                        model: {
                          value: _vm.payload.notes,
                          callback: function($$v) {
                            _vm.$set(_vm.payload, "notes", $$v)
                          },
                          expression: "payload.notes"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _c("ItpButton", {
                attrs: {
                  form: "hnvqxzl56c",
                  text: _vm._f("gettext")("Save"),
                  type: "submit",
                  variant: "primary",
                  spinning: _vm.actions.update.isRunning
                }
              }),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("gettext")("Cancel"),
                  variant: "default",
                  disabled: _vm.actions.update.isRunning
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1419464017721962_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }