var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DIV", [
    _vm._v("\n  " + _vm._s(_vm.option.number)),
    _c("br"),
    _vm._v("\n" + _vm._s(_vm.option.description) + "\n\n")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }