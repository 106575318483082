var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("VueSlickgrid", {
    ref: "grid",
    attrs: {
      name: "contractInvoices",
      headerTitle: _vm._f("pgettext")("ContractInvoices", "ContractInvoices"),
      items: _vm.$fn.slickDataSource("browse_invoice_lines", {
        parameters: _vm.getQueryParameters
      }),
      "checkbox-row-selection": "",
      "footer-totals": "",
      "export-to-csv": "",
      exportToCsvFilename: _vm._f("pgettext")("contract-invoices", "filename"),
      columnDefaults: _vm.b_7315092382398562_columnDefaults,
      columns: _vm.b_7315092382398562_columns
    },
    on: {
      selectedRowsChanged: function($event) {
        return _vm.evh_7315092382398562_selectedRowsChanged($event, {})
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }