var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpWidgetCard",
        [
          _c(
            "ItpBox",
            { attrs: { slot: "header", alignItems: "center" }, slot: "header" },
            [
              _c("strong", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("pgettext")("Units", "Property")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              !_vm.parent.readonly
                ? _c("ItpButton", {
                    staticClass: "ml-auto",
                    attrs: {
                      text: _vm._f("gettext")("Add unit..."),
                      variant: "light",
                      size: "sm"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_8320016629450276_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "BListGroup",
            [
              !_vm.parent.units || !_vm.parent.units.length
                ? _c(
                    "BListGroupItem",
                    [
                      _c("ItpText", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("pgettext")(
                                "No units have been added yet.",
                                "Property"
                              )
                            ) +
                            "\n        "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(
                _vm._.orderBy(_vm.parent.units, ["sort_order", "name"]),
                function(item) {
                  return _c(
                    "BListGroupItem",
                    { key: item.id },
                    [
                      _c(
                        "ItpBox",
                        { attrs: { alignItems: "center" } },
                        [
                          _c("ItpText", {
                            staticClass: "font-weight-bold",
                            attrs: { text: item.name }
                          }),
                          _vm._v(" "),
                          item.locked
                            ? _c("ItpIcon", {
                                staticClass: "px-3",
                                attrs: {
                                  title: _vm._f("gettext")("Locked"),
                                  name: "fa-lock"
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.parent.readonly
                            ? _c("ItpButton", {
                                staticClass: "ml-auto",
                                attrs: {
                                  icon: "fa-pen",
                                  tooltip: _vm._f("gettext")("Edit"),
                                  variant: "light",
                                  size: "sm"
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.evh_2585509888553580_clickPreventStop(
                                      $event,
                                      { item: item }
                                    )
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "ItpBox",
                        [
                          _c("ItpText", {
                            attrs: { text: item.description, pre: "" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                }
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }