import './styles.scss';
import * as _ from 'lodash';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Application, ApplicationSpec } from '@integro/core';
import { GetTextTranslations } from '@integro/gettext';
import { connections } from './connections';
import * as ui from '@integro/ui';
import * as components from './components';
import * as app from './app.yaml';
import * as msg_hu from './messages/hu.po';
import * as config from './config.yaml?plugin';
import * as scripts from './scripts';
import * as utils from './utils';

const template = `
<div class="app d-flex flex-column flex-nowrap overflow-hidden h-100">
  <MainMenu class="app__navbar"></MainMenu>
  <div class="app__content flex-grow-1 flex-shrink-1 overflow-hidden bg-light pt-2">
    <router-tab :keep-last-tab="false" restore>
      <template #default="tab">
        <ItpIcon v-if="tab.icon" :name="tab.icon" class="router-tab__item-icon" />
        <span class="router-tab__item-title" :title="tab.tips">{{
          tab.title
        }}</span>
        <i
          v-if="tab.closable"
          class="router-tab__item-close"
          @click.prevent="tab.close"
        />
      </template>
    </router-tab>
  </div>
</div>`;

@Component({ template, components })
export class Home extends Vue {
  data() {
    return {
      mode: 'development'
    };
  }
}

Vue.component('Home', Home);

const application = new Application(({
  ...app,
  connections,
  config,
  translations: [(msg_hu as unknown) as GetTextTranslations],
  home: 'Home'
} as unknown) as ApplicationSpec);

ui.initialize(application);
scripts.initialize(application);


_.set(application, 'utils', utils);

application.mount();
