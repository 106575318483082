var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpWidgetCard",
    [
      _c(
        "ItpBox",
        { attrs: { slot: "header", alignItems: "center" }, slot: "header" },
        [
          _c("strong", [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm._f("pgettext")("Rental Services", "MeterRentalServices")
                ) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c("ItpButton", {
            staticClass: "ml-auto",
            attrs: {
              text: _vm._f("pgettext")(
                "Add rental service...",
                "UcMeterRentalServices"
              ),
              variant: "light",
              size: "sm"
            },
            on: {
              click: function($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.evh_3343864469950044_clickPreventStop($event, {})
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "BListGroup",
            [
              !_vm.items.length
                ? _c("BListGroupItem", [
                    _c("span", [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm._f("pgettext")(
                              "No rental services.",
                              "MeterRentalServices"
                            )
                          ) +
                          "\n      "
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.sortItems(_vm.items), function(item) {
                return _c(
                  "BListGroupItem",
                  { key: item.id, staticClass: "d-flex flex-column" },
                  [
                    _c(
                      "ItpBox",
                      { attrs: { alignItems: "center" } },
                      [
                        _c(
                          "ItpBox",
                          { attrs: { alignItems: "center", "wrap-items": "" } },
                          [
                            _c(
                              "ItpLink",
                              {
                                attrs: {
                                  to: {
                                    name: "app.rental",
                                    params: { id: item.rental_id }
                                  }
                                }
                              },
                              [
                                _c("ItpText", {
                                  attrs: { strong: "", text: item.rental.name }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("ItpText", {
                              staticClass: "px-3 font-weight-bold",
                              attrs: {
                                text: item.service.name,
                                strikethrough: _vm.isServiceStale(item)
                              }
                            }),
                            _vm._v(" "),
                            _c("ItpLink", {
                              attrs: {
                                icon: _vm.$config.externalLinkIcon,
                                to: {
                                  name: "app.product",
                                  params: { id: item.service_id }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("UcValidityPeriod", {
                              staticClass: "px-3",
                              attrs: {
                                from: item.valid_from,
                                to: item.valid_to
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "ItpBadge",
                              {
                                staticClass: "px-3",
                                attrs: {
                                  title: _vm._f("gettext")(
                                    "Billing multiplier"
                                  ),
                                  "no-box-shadow": "",
                                  variant: "light"
                                }
                              },
                              [
                                _c("ItpIcon", { attrs: { name: "fa-times" } }),
                                _vm._v(" "),
                                _c("ItpText", {
                                  staticClass: "pl-2",
                                  attrs: { text: item.meter_factor }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("UcInactive", {
                              staticClass: "px-3",
                              attrs: { value: item.inactive }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("ItpButton", {
                          staticClass: "ml-auto",
                          attrs: {
                            icon: "fa-pen",
                            tooltip: _vm._f("gettext")("Edit"),
                            variant: "light",
                            size: "sm"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.evh_7989141091456081_clickPreventStop(
                                $event,
                                { item: item }
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "ItpBox",
                      [
                        item.notes
                          ? _c("ItpText", {
                              attrs: { text: item.notes, pre: "" }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("UcRentalServiceCostAllocators", {
                      attrs: { service: item }
                    })
                  ],
                  1
                )
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }