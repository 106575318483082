var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpBadge",
    { attrs: { value: _vm.status, variantMap: _vm.$config.statusVariants } },
    [
      _vm._v(
        "\n  " +
          _vm._s(_vm._f("pgettext")(_vm.status, "Status", "Sentence")) +
          "\n"
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }