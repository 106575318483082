var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "Register",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "ItpForm",
                {
                  attrs: {
                    name: "register",
                    readonly: _vm.item.locked,
                    labelAlign: _vm.$config.forms.defaultLabelAlign,
                    id: _vm.formId
                  },
                  on: {
                    submit: function($event) {
                      return _vm.evh_1040167445267876_submit($event, {})
                    }
                  }
                },
                [
                  _c(
                    "ItpRow",
                    [
                      _c(
                        "ItpCol",
                        [
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                labelColsMd: "2",
                                contentColsMd: "5",
                                name: "register_type",
                                label: _vm._f("pgettext")(
                                  "Register type",
                                  "Register"
                                ),
                                required: ""
                              }
                            },
                            [
                              _c("ItpFormSelect", {
                                attrs: {
                                  name: "register_type",
                                  options: _vm.registerTypes,
                                  required: "",
                                  placeholder: _vm._f("pgettext")(
                                    "The type of the register.",
                                    "Register"
                                  )
                                },
                                model: {
                                  value: _vm.item.register_type,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "register_type", $$v)
                                  },
                                  expression: "item.register_type"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                labelColsMd: "2",
                                contentColsMd: "5",
                                name: "name",
                                label: _vm._f("pgettext")("Name", "Register"),
                                required: ""
                              }
                            },
                            [
                              _c("ItpFormInput", {
                                attrs: {
                                  name: "name",
                                  required: "",
                                  placeholder: _vm._f("pgettext")(
                                    "The name of the register.",
                                    "Register"
                                  )
                                },
                                model: {
                                  value: _vm.item.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "name", $$v)
                                  },
                                  expression: "item.name"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                labelColsMd: "2",
                                contentColsMd: "5",
                                name: "numbering_id",
                                label: _vm._f("pgettext")(
                                  "Numbering",
                                  "Register"
                                ),
                                required: ""
                              }
                            },
                            [
                              _c("NumberingSelector", {
                                attrs: {
                                  name: "numbering_id",
                                  required: "",
                                  objectType: "register:*"
                                },
                                model: {
                                  value: _vm.item.numbering_id,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "numbering_id", $$v)
                                  },
                                  expression: "item.numbering_id"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                labelColsMd: "2",
                                contentColsMd: "5",
                                name: "company_id",
                                label: _vm._f("pgettext")(
                                  "Company",
                                  "Register"
                                ),
                                required: ""
                              }
                            },
                            [
                              _c("ItpFormSelect2", {
                                attrs: {
                                  name: "company_id",
                                  dataSource: _vm.b_7748094403243621_dataSource,
                                  valueField: "id",
                                  textField: "name",
                                  required: ""
                                },
                                model: {
                                  value: _vm.item.company_id,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "company_id", $$v)
                                  },
                                  expression: "item.company_id"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _c("ItpButton", {
                attrs: {
                  form: _vm.forms.register && _vm.forms.register.id,
                  text: _vm._f("pgettext")("Save", "Register"),
                  type: "submit",
                  variant: "primary",
                  default: "",
                  disabled:
                    !_vm.forms.register || !_vm.forms.register.submittable,
                  spinning: _vm.actions.submit.isRunning
                }
              }),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("pgettext")("Close", "Register"),
                  variant: "default",
                  disabled: _vm.actions.submit.isRunning
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1419464017721962_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }