var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpBox",
        { attrs: { direction: "column" } },
        [
          _c("ItpText", {
            attrs: { strong: "", text: _vm.item.name, spacing: "mb-2" }
          }),
          _vm._v(" "),
          _vm.item.job_title
            ? _c("ItpText", {
                attrs: { spacing: "mb-2", text: _vm.item.job_title }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.item.phone
            ? _c(
                "ItpBox",
                { attrs: { spacing: "mb-2", alignItems: "center" } },
                [
                  _c("Icon", {
                    staticClass: "mr-2",
                    attrs: {
                      icon: "ic:baseline-phone",
                      title: _vm._f("pgettext")("Phone Number", "Contact")
                    }
                  }),
                  _vm._v(" "),
                  _c("ItpText", { attrs: { text: _vm.item.phone } })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.item.mobile
            ? _c(
                "ItpBox",
                { attrs: { spacing: "mb-2", alignItems: "center" } },
                [
                  _c("Icon", {
                    staticClass: "mr-2",
                    attrs: {
                      icon: "mdi:cellphone-basic",
                      title: _vm._f("pgettext")("Mobile", "Contact")
                    }
                  }),
                  _vm._v(" "),
                  _c("ItpText", { attrs: { text: _vm.item.mobile } })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.item.fax
            ? _c(
                "ItpBox",
                { attrs: { spacing: "mb-2", alignItems: "center" } },
                [
                  _c("Icon", {
                    staticClass: "mr-2",
                    attrs: {
                      icon: "mdi:fax",
                      title: _vm._f("pgettext")("Fax", "Contact")
                    }
                  }),
                  _vm._v(" "),
                  _c("ItpText", { attrs: { text: _vm.item.fax } })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.item.email
            ? _c(
                "ItpBox",
                { attrs: { spacing: "mb-2", alignItems: "center" } },
                [
                  _c("Icon", {
                    staticClass: "mr-2",
                    attrs: {
                      icon: "mdi:at",
                      title: _vm._f("pgettext")("Email", "Contact")
                    }
                  }),
                  _vm._v(" "),
                  _c("ItpText", { attrs: { text: _vm.item.email } })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.item.notes
            ? _c(
                "ItpBox",
                [_c("ItpText", { attrs: { text: _vm.item.notes, pre: "" } })],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }