import AddFixedAssetToRentalModal from './AddFixedAssetToRentalModal.yaml?component';
import CreateWorksheetsFromWasteBinReadingsModal from './CreateWorksheetsFromWasteBinReadingsModal.yaml?component';
import FixedAsset from './FixedAsset.yaml?component';
import FixedAssetBrowser from './FixedAssetBrowser.yaml?component';
import MCreateFixedAsset from './MCreateFixedAsset.yaml?component';
import MFixedAssetEditor from './MFixedAssetEditor.yaml?component';
import MFixedAssetEditorFieldset from './MFixedAssetEditorFieldset.yaml?component';
import WasteBinReadingModal from './WasteBinReadingModal.yaml?component';
import WasteBinReadingsList from './WasteBinReadingsList.yaml?component';
import WasteBinsBrowser from './WasteBinsBrowser.yaml?component';

export * from './form';
export * from './primitives';

export default [
  AddFixedAssetToRentalModal,
  CreateWorksheetsFromWasteBinReadingsModal,
  FixedAsset,
  FixedAssetBrowser,
  MCreateFixedAsset,
  MFixedAssetEditor,
  MFixedAssetEditorFieldset,
  WasteBinReadingModal,
  WasteBinReadingsList,
  WasteBinsBrowser
];
