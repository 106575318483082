var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        noHeader: _vm.b_7315092382398562_noHeader,
        "no-primary-button": "",
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "EntityEvents",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _c("UcHeader", {
        attrs: {
          slot: "header",
          card: !_vm.modal,
          modal: _vm.modal,
          title: _vm.documentTitle
        },
        on: {
          close: function($event) {
            return _vm.evh_2248226175642056_close($event, {})
          }
        },
        slot: "header"
      }),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            {
              staticClass: "h-100",
              attrs: { card: !_vm.modal, modal: _vm.modal }
            },
            [
              _c(
                "DIV",
                { staticClass: "row h-100" },
                [
                  _c(
                    "DIV",
                    { staticClass: "col-6 h-100" },
                    [
                      _c("VueSlickgrid", {
                        attrs: {
                          name: "events",
                          headerTitle: _vm._f("pgettext")(
                            "Events",
                            "EntityEvents"
                          ),
                          items: _vm.$fn.slickDataSource("list_entity_events", {
                            parameters: { id: _vm.entityId }
                          }),
                          "checkbox-row-selection": "",
                          headerShowColumnSelectorButton:
                            _vm.b_7789867021953041_headerShowColumnSelectorButton,
                          headerShowReloadButton:
                            _vm.b_7789867021953041_headerShowReloadButton,
                          "export-to-csv": "",
                          exportToCsvFilename: _vm._f("pgettext")(
                            "payments",
                            "ExportFilename"
                          ),
                          columns: _vm.b_7789867021953041_columns
                        },
                        on: {
                          "command:event_name": function($event) {
                            return _vm.evh_7789867021953041_commandEventName(
                              $event,
                              {}
                            )
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "DIV",
                    { staticClass: "col-6 h-100 overflow-hidden" },
                    [
                      _c("CodeMirror", {
                        staticClass: "h-100 v-100",
                        attrs: {
                          mode: "json",
                          options: _vm.b_2070266589801014_options
                        },
                        model: {
                          value: _vm.eventData,
                          callback: function($$v) {
                            _vm.eventData = $$v
                          },
                          expression: "eventData"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }