var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "Job",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c(
            "UcHeader",
            {
              attrs: { slot: "header", card: !_vm.modal, modal: _vm.modal },
              on: {
                close: function($event) {
                  return _vm.evh_2248226175642056_close($event, {})
                }
              },
              slot: "header"
            },
            [
              _c("JobHeader", {
                attrs: { item: _vm.resource },
                on: {
                  reload: function($event) {
                    return _vm.evh_8564662037462133_reload($event, {})
                  },
                  deleted: function($event) {
                    return _vm.evh_8564662037462133_deleted($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "DIV",
        { staticClass: "px-4 py-3" },
        [
          _vm.resource
            ? _c("JobView", {
                attrs: { item: _vm.resource },
                on: {
                  reload: function($event) {
                    return _vm.evh_1040167445267876_reload($event, {})
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _c("ItpButton", {
                attrs: { text: _vm._f("gettext")("Close"), variant: "default" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1548630417156826_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }