var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.badge ? "ItpBadge" : "DIV",
    {
      tag: "component",
      class: _vm.badge
        ? "bg-" + _vm.colorCode + "-100 border border-" + _vm.colorCode + "-500"
        : null,
      attrs: { noBoxShadow: !!_vm.badge }
    },
    [
      _c(
        "ItpBox",
        { attrs: { alignItems: "center" } },
        [
          !_vm.noIcon
            ? _c("ItpIcon", {
                staticClass: "mr-2",
                attrs: { name: _vm.icon, title: _vm.title }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("ItpText", {
            staticClass: "mr-2",
            attrs: { strong: "", text: _vm.text }
          }),
          _vm._v(" "),
          !_vm.noLink
            ? _c("ItpLink", {
                attrs: { icon: _vm.$config.externalLinkIcon, to: _vm.linkTo }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }