var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DIV", [
    _vm._v("\n  " + _vm._s(_vm.option.name) + "\n"),
    _vm.option.client_name
      ? _c("span", [_vm._v("· " + _vm._s(_vm.option.client_name))])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }