import FieldsetPropertyGeneralData from './FieldsetPropertyGeneralData.yaml?component';
import UcPropertyGeneralData from './UcPropertyGeneralData.yaml?component';
import UcPropertyUnits from './UcPropertyUnits.yaml?component';
import UcPropertyValidityCard from './UcPropertyValidityCard.yaml?component';

export {
  FieldsetPropertyGeneralData,
  UcPropertyGeneralData,
  UcPropertyUnits,
  UcPropertyValidityCard
};
