var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpWidgetCard",
        [
          _c(
            "ItpBox",
            { attrs: { slot: "header", alignItems: "center" }, slot: "header" },
            [
              _c("strong", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("pgettext")("General", "Meter")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("ItpButton", {
                staticClass: "ml-auto",
                attrs: {
                  text: _vm._f("gettext")("Edit..."),
                  variant: "light",
                  size: "sm"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_8320016629450276_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "BListGroup",
            [
              _c(
                "ItpLabeledListGroupItem",
                { attrs: { label: _vm._f("pgettext")("Type", "Meter") } },
                [
                  _c(
                    "ItpBox",
                    { attrs: { alignItems: "center" } },
                    [
                      _c(
                        "ItpBadge",
                        {
                          staticClass: "mr-2",
                          attrs: { "no-box-shadow": "", variant: "light" }
                        },
                        [
                          _c("UcMeterTypeIcon", {
                            attrs: { type: _vm.meter.meter_type }
                          }),
                          _vm._v(" "),
                          _c("ItpText", {
                            staticClass: "mx-2",
                            attrs: {
                              text: _vm._f("pgettext")(
                                _vm.meter.meter_type,
                                "MeterTypes",
                                "Sentence"
                              )
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "ItpBadge",
                        {
                          staticClass: "mx-2",
                          attrs: {
                            title: _vm._f("pgettext")("Unit", "Meter"),
                            "no-box-shadow": "",
                            variant: "light"
                          }
                        },
                        [
                          _c("ItpText", {
                            staticClass: "pl-2",
                            attrs: { text: _vm.meter.unit }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "ItpBadge",
                        {
                          staticClass: "mx-2",
                          attrs: {
                            title: _vm._f("pgettext")("Multiplier", "Meter"),
                            "no-box-shadow": "",
                            variant: "light"
                          }
                        },
                        [
                          _c("ItpIcon", { attrs: { name: "fa-times" } }),
                          _vm._v(" "),
                          _c("ItpText", {
                            staticClass: "pl-2",
                            attrs: {
                              text: _vm._f("number")(_vm.meter.multiplier)
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.meter.main_meter
                ? _c(
                    "ItpLabeledListGroupItem",
                    {
                      attrs: {
                        label: _vm._f("pgettext")("Main meter", "Meter")
                      }
                    },
                    [
                      _c("UcCompactMeterDisplay", {
                        attrs: { payload: _vm.meter.main_meter }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("ItpLabeledListGroupItem", {
                attrs: {
                  label: _vm._f("pgettext")("Serial number", "Meter"),
                  content: _vm.meter.serial_number,
                  helpTooltip: _vm._f("gettext")(
                    "The device's unique serial number."
                  )
                }
              }),
              _vm._v(" "),
              _vm.meter.smart_device
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Smart device", "Meter"),
                      content: _vm._f("gettext")("Yes")
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.meter.groups && _vm.meter.groups.length
                ? _c(
                    "ItpLabeledListGroupItem",
                    { attrs: { label: _vm._f("pgettext")("Groups", "Meter") } },
                    _vm._l(_vm.meter.groups, function(name) {
                      return _c("ItpBadge", {
                        key: name,
                        staticClass: "border border-gray-dark-900 mr-2",
                        attrs: { text: name }
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "ItpLabeledListGroupItem",
                { attrs: { label: _vm._f("pgettext")("Status", "Meter") } },
                [
                  _c("UcResourceStatusCard", {
                    attrs: { resource: _vm.meter },
                    on: {
                      reload: function($event) {
                        return _vm.evh_3818132362335583_reload($event, {})
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.meter.model_name
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Model name", "Meter"),
                      content: _vm.meter.model_name
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.meter.deveui
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("DevEUI", "Meter"),
                      content: _vm.meter.deveui,
                      helpTooltip: _vm._f("gettext")(
                        "64-bit globally-unique Extended Unique Identifier (EUI-64) assigned by the manufacturer, or the owner, of the end-device."
                      )
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.meter.tag_id
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Location Code", "Meter"),
                      content: _vm.meter.tag_id,
                      helpTooltip: _vm._f("gettext")(
                        "Locally-unique tag ID to be used within the company."
                      )
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "ItpLabeledListGroupItem",
                { attrs: { label: _vm._f("pgettext")("Validity", "Meter") } },
                [_c("UcMeterValidityCard", { attrs: { item: _vm.meter } })],
                1
              ),
              _vm._v(" "),
              _vm.meter.site_id
                ? _c(
                    "ItpLabeledListGroupItem",
                    { attrs: { label: _vm._f("pgettext")("Site", "Meter") } },
                    [
                      _c(
                        "ItpLink",
                        {
                          attrs: {
                            to: {
                              name: "app.property",
                              params: { id: _vm.meter.site_id }
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(_vm.meter.site.name) + "\n"
                          ),
                          _c(
                            "span",
                            { attrs: { if: _vm.meter.site.description } },
                            [_vm._v("- " + _vm._s(_vm.meter.site.description))]
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.meter.description
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Description", "Meter"),
                      content: _vm.meter.description
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }