var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-tabs",
    {
      staticClass: "h-100 d-flex flex-column tabs-material overflow-hidden",
      attrs: {
        "nav-class": "material px-3",
        "content-class": "flex-fill overflow-auto px-3 py-4 h-100 bg-page"
      },
      on: {
        input: function($event) {
          return _vm.evh_7315092382398562_input($event, {})
        }
      }
    },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c("template", { slot: "tabs-start" }, [_vm._t("tabs-start")], 2),
      _vm._v(" "),
      _vm.closeButton
        ? _c("template", { slot: "tabs-end" }, [
            _c(
              "button",
              {
                staticClass: "close ml-auto mr-2",
                attrs: { "data-dismiss": "modal", "aria-label": "Close" },
                on: {
                  click: function($event) {
                    return _vm.evh_1548630417156826_click($event, {})
                  }
                }
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            )
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }