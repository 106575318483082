var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _vm.item.runs && _vm.item.runs.length === 1
        ? _c("JobRunView", { attrs: { item: _vm.item.runs[0] } })
        : _vm._e(),
      _vm._v(" "),
      _vm.item.runs && _vm.item.runs.length > 1
        ? _c(
            "BCard",
            { attrs: { "no-body": "" } },
            [
              _vm.item.runs && _vm.item.runs.length
                ? _c(
                    "BTabs",
                    { attrs: { card: "" } },
                    _vm._l(_vm.item.runs, function(run) {
                      return _c(
                        "BTab",
                        { key: run.id },
                        [
                          _c(
                            "template",
                            { slot: "title" },
                            [
                              _c("ItpText", {
                                attrs: { text: "#" + run.run_number }
                              }),
                              _vm._v(" "),
                              _c("UcJobStatusBadge", {
                                attrs: { value: run.status }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("JobRunView", { attrs: { item: run } })
                        ],
                        2
                      )
                    }),
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }