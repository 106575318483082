var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "FixedAsset",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c(
            "DIV",
            { staticClass: "page--v2 d-flex flex-column" },
            [
              _c(
                "MaterialTabs",
                [
                  _c(
                    "ItpBox",
                    {
                      staticClass: "mr-3",
                      attrs: { slot: "tabs-start", alignItems: "center" },
                      slot: "tabs-start"
                    },
                    [
                      _c(
                        "b-breadcrumb",
                        { staticClass: "m-0 bg-white" },
                        [
                          _c("b-breadcrumb-item", {
                            attrs: {
                              text: _vm._f("gettext")("Fixed Assets"),
                              to: _vm.b_2285343489561581_to
                            }
                          }),
                          _vm._v(" "),
                          _c("b-breadcrumb-item", {
                            attrs: {
                              text: _vm.item.snapshot("name"),
                              active: ""
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("ItpButton", {
                        staticClass: "px-3",
                        attrs: {
                          variant: "default",
                          icon: "fa-sync-alt",
                          text: _vm._f("gettext")("Reload"),
                          spinning: _vm.actions.reload.isRunning
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.evh_2095089031469157_clickPreventStop(
                              $event,
                              {}
                            )
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      staticClass: "container float-left",
                      attrs: { title: _vm._f("gettext")("General") }
                    },
                    [
                      _c(
                        "ItpRow",
                        [
                          _c(
                            "ItpCol",
                            { attrs: { w: "6" } },
                            [
                              _c("UcFixedAssetGeneral", {
                                staticClass: "pb-4",
                                attrs: {
                                  fixedAsset: _vm.item,
                                  reload: _vm.lastLoaded
                                },
                                on: {
                                  reload: function($event) {
                                    return _vm.evh_2679161326024595_reload(
                                      $event,
                                      {}
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ItpCol",
                            { attrs: { w: "6" } },
                            [
                              _vm.item && _vm.item.asset_type === "waste_bin"
                                ? _c("UcFixedAssetWasteBin", {
                                    staticClass: "pb-4",
                                    attrs: {
                                      fixedAsset: _vm.item,
                                      reload: _vm.lastLoaded
                                    },
                                    on: {
                                      reload: function($event) {
                                        return _vm.evh_2789477278097085_reload(
                                          $event,
                                          {}
                                        )
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("UcCommentsList", {
                                staticClass: "pb-4",
                                attrs: {
                                  parent: _vm.item,
                                  reload: _vm.lastLoaded
                                }
                              }),
                              _vm._v(" "),
                              _c("UcAttachmentsList", {
                                staticClass: "pb-4",
                                attrs: {
                                  parent: _vm.item,
                                  reload: _vm.lastLoaded
                                }
                              }),
                              _vm._v(" "),
                              _c("UcFixedAssetWorkItemsPanel", {
                                staticClass: "pb-4",
                                attrs: {
                                  oid: _vm.item.id,
                                  reload: _vm.lastLoaded
                                },
                                on: {
                                  reload: function($event) {
                                    return _vm.evh_8856043737157701_reload(
                                      $event,
                                      {}
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }