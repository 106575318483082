var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "Groups",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _c(
        "DIV",
        { staticClass: "overflow-auto h-100 p-3" },
        [
          _vm.loaded
            ? _c("ItpDataTable", {
                ref: "table",
                staticClass: "h-100",
                attrs: {
                  tableId: "Settings.Groups",
                  editable: "",
                  isRowEditable: function(p) {
                    return !p.locked
                  },
                  value: _vm.items,
                  columns: _vm.b_8564662037462133_columns
                },
                on: {
                  save: function($event) {
                    return _vm.evh_8564662037462133_save($event, {})
                  },
                  delete: function($event) {
                    return _vm.evh_8564662037462133_delete($event, {})
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }