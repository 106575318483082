var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpBox",
    {
      class: [
        {
          "modal-footer": _vm.modal,
          "card-footer": _vm.card,
          "bg-gray-100": _vm.modal || _vm.card
        }
      ],
      attrs: { alignItems: "center" }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }