var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        "no-primary-button": "",
        closeButtonText: _vm._f("gettext")("Close"),
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "ContractServiceEditor",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "ItpForm",
                {
                  ref: "mainForm",
                  staticClass: "row",
                  attrs: { id: _vm.formId, name: "contract" },
                  on: {
                    submit: function($event) {
                      return _vm.evh_1040167445267876_submit($event, {})
                    }
                  }
                },
                [
                  _c(
                    "ItpCol",
                    [
                      _c("ItpText", {
                        attrs: {
                          strong: "",
                          text: _vm._f("pgettext")(
                            "Service",
                            "ContractServiceEditor"
                          )
                        }
                      }),
                      _vm._v(" "),
                      !_vm.isServiceReadonly
                        ? _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                name: "service",
                                label: _vm._f("pgettext")(
                                  "Service",
                                  "ContractService"
                                ),
                                labelColsMd: "4",
                                contentColsMd: "8",
                                labelAlign: _vm.labelAlign
                              }
                            },
                            [
                              _c("ItpFormSelect2", {
                                attrs: {
                                  name: "client",
                                  "bind-object": "",
                                  options: _vm.services,
                                  textField: "name"
                                },
                                on: {
                                  optionSelected: function($event) {
                                    return _vm.evh_3054425420812800_optionSelected(
                                      $event,
                                      {}
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.item.service,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "service", $$v)
                                  },
                                  expression: "item.service"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            name: "name",
                            label: _vm._f("pgettext")(
                              "Name",
                              "ContractService"
                            ),
                            labelColsMd: "4",
                            contentColsMd: "8",
                            labelAlign: _vm.labelAlign
                          }
                        },
                        [
                          _c("ItpFormInput", {
                            attrs: {
                              name: "name",
                              required: "",
                              readonly: _vm.isServiceReadonly
                            },
                            model: {
                              value: _vm.item.name,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "name", $$v)
                              },
                              expression: "item.name"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            name: "valid_from",
                            label: _vm._f("pgettext")(
                              "Valid from",
                              "ContractService"
                            ),
                            labelColsMd: "4",
                            contentColsMd: "4",
                            labelAlign: _vm.labelAlign
                          }
                        },
                        [
                          _c("ItpFormDatetime", {
                            attrs: {
                              name: "valid_from",
                              required: "",
                              readonly: _vm.isServiceReadonly
                            },
                            model: {
                              value: _vm.item.valid_from,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "valid_from", $$v)
                              },
                              expression: "item.valid_from"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            name: "valid_to",
                            label: _vm._f("pgettext")(
                              "Valid to",
                              "ContractService"
                            ),
                            labelColsMd: "4",
                            contentColsMd: "4",
                            labelAlign: _vm.labelAlign
                          }
                        },
                        [
                          _c("ItpFormDatetime", {
                            attrs: {
                              name: "valid_to",
                              readonly: _vm.isServiceReadonly
                            },
                            model: {
                              value: _vm.item.valid_to,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "valid_to", $$v)
                              },
                              expression: "item.valid_to"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            name: "valid_to",
                            label: _vm._f("pgettext")(
                              "Description",
                              "ContractService"
                            ),
                            labelColsMd: "4",
                            contentColsMd: "8",
                            labelAlign: _vm.labelAlign
                          }
                        },
                        [
                          _c("ItpFormTextarea", {
                            attrs: {
                              name: "description",
                              readonly: _vm.isServiceReadonly,
                              rows: "5"
                            },
                            model: {
                              value: _vm.item.description,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "description", $$v)
                              },
                              expression: "item.description"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.mode === "editService" && !_vm.service.locked
                        ? _c("ItpButton", {
                            attrs: {
                              text: _vm._f("pgettext")(
                                "Lock Service",
                                "Button"
                              ),
                              variant: "light",
                              icon: "fa-lock",
                              spinning: _vm.actions.lockService.isRunning
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.evh_1478395556975758_clickPreventStop(
                                  $event,
                                  {}
                                )
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayFee ? _c("hr") : _vm._e(),
                      _vm._v(" "),
                      _vm.displayFee
                        ? _c("ItpText", {
                            attrs: {
                              strong: "",
                              text: _vm._f("pgettext")(
                                "Fee",
                                "ContractServiceEditor"
                              )
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayFeeDates
                        ? _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                name: "fee_valid_from",
                                label: _vm._f("pgettext")(
                                  "Valid from",
                                  "ContractServiceFee"
                                ),
                                labelColsMd: "4",
                                contentColsMd: "4",
                                labelAlign: _vm.labelAlign
                              }
                            },
                            [
                              _c("ItpFormDatetime", {
                                attrs: { name: "valid_from", required: "" },
                                model: {
                                  value: _vm.feeItem.valid_from,
                                  callback: function($$v) {
                                    _vm.$set(_vm.feeItem, "valid_from", $$v)
                                  },
                                  expression: "feeItem.valid_from"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayFeeDates
                        ? _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                name: "fee_valid_to",
                                label: _vm._f("pgettext")(
                                  "Valid to",
                                  "ContractServiceFee"
                                ),
                                labelColsMd: "4",
                                contentColsMd: "4",
                                labelAlign: _vm.labelAlign
                              }
                            },
                            [
                              _c("ItpFormDatetime", {
                                attrs: { name: "valid_to" },
                                model: {
                                  value: _vm.feeItem.valid_to,
                                  callback: function($$v) {
                                    _vm.$set(_vm.feeItem, "valid_to", $$v)
                                  },
                                  expression: "feeItem.valid_to"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayFee
                        ? _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                name: "fee_valid_to",
                                label: _vm._f("pgettext")(
                                  "Amount",
                                  "ContractServiceFee"
                                ),
                                labelColsMd: "4",
                                contentColsMd: "8",
                                labelAlign: _vm.labelAlign,
                                contentClass: "input-group"
                              }
                            },
                            [
                              _c("ItpFormInput", {
                                staticClass: "col-8",
                                attrs: {
                                  type: "number",
                                  name: "fee_amount",
                                  required: ""
                                },
                                model: {
                                  value: _vm.feeItem.amount,
                                  callback: function($$v) {
                                    _vm.$set(_vm.feeItem, "amount", $$v)
                                  },
                                  expression: "feeItem.amount"
                                }
                              }),
                              _vm._v(" "),
                              _c("ItpFormSelect", {
                                staticClass: "col-4",
                                attrs: {
                                  name: "currency",
                                  options: _vm.currencies,
                                  textField: "code",
                                  valueField: "code",
                                  required: ""
                                },
                                model: {
                                  value: _vm.feeItem.currency,
                                  callback: function($$v) {
                                    _vm.$set(_vm.feeItem, "currency", $$v)
                                  },
                                  expression: "feeItem.currency"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayFee
                        ? _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                name: "fee_unit",
                                label: _vm._f("pgettext")(
                                  "Invoice unit",
                                  "ContractServiceFee"
                                ),
                                labelColsMd: "4",
                                contentColsMd: "4",
                                labelAlign: _vm.labelAlign,
                                contentClass: "input-group"
                              }
                            },
                            [
                              _c("ItpFormInput", {
                                attrs: { name: "fee_unit", required: "" },
                                model: {
                                  value: _vm.feeItem.unit,
                                  callback: function($$v) {
                                    _vm.$set(_vm.feeItem, "unit", $$v)
                                  },
                                  expression: "feeItem.unit"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayFee
                        ? _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                name: "fee_quantity",
                                label: _vm._f("pgettext")(
                                  "Quantity",
                                  "ContractServiceFee"
                                ),
                                labelColsMd: "4",
                                contentColsMd: "4",
                                labelAlign: _vm.labelAlign,
                                contentClass: "input-group"
                              }
                            },
                            [
                              _c("ItpFormInput", {
                                attrs: { type: "number", name: "fee_quantity" },
                                model: {
                                  value: _vm.feeItem.quantity,
                                  callback: function($$v) {
                                    _vm.$set(_vm.feeItem, "quantity", $$v)
                                  },
                                  expression: "feeItem.quantity"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayFee
                        ? _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                name: "fee_inflation_indexed",
                                label: _vm._f("pgettext")(
                                  "Inflation indexed",
                                  "ContractServiceFee"
                                ),
                                labelColsMd: "4",
                                contentColsMd: "8",
                                labelAlign: _vm.labelAlign
                              }
                            },
                            [
                              _c("ItpFormCheckbox", {
                                attrs: { name: "fee_inflation_indexed" },
                                model: {
                                  value: _vm.feeItem.inflation_indexed,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.feeItem,
                                      "inflation_indexed",
                                      $$v
                                    )
                                  },
                                  expression: "feeItem.inflation_indexed"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayFee
                        ? _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                name: "fee_notes",
                                label: _vm._f("pgettext")(
                                  "Notes",
                                  "ContractServiceFee"
                                ),
                                labelColsMd: "4",
                                contentColsMd: "8",
                                labelAlign: _vm.labelAlign
                              }
                            },
                            [
                              _c("ItpFormTextarea", {
                                attrs: { name: "fee_notes" },
                                model: {
                                  value: _vm.feeItem.notes,
                                  callback: function($$v) {
                                    _vm.$set(_vm.feeItem, "notes", $$v)
                                  },
                                  expression: "feeItem.notes"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.mode === "editServiceFee" && !_vm.feeItem.locked
                        ? _c("ItpButton", {
                            attrs: {
                              text: _vm._f("pgettext")(
                                "Lock Service Fee",
                                "Button"
                              ),
                              variant: "light",
                              icon: "fa-lock",
                              spinning: _vm.actions.lockServiceFee.isRunning
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.evh_2712422394771578_clickPreventStop(
                                  $event,
                                  {}
                                )
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _vm.mode === "editService" && _vm.isDeletable
                ? _c("ItpButton", {
                    staticClass: "mr-auto",
                    attrs: {
                      text: _vm._f("pgettext")("Delete Service", "Button"),
                      variant: "danger",
                      icon: "fa-times",
                      spinning: _vm.actions.deleteService.isRunning
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_1548630417156826_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.mode === "editServiceFee" && _vm.isDeletable
                ? _c("ItpButton", {
                    staticClass: "mr-auto",
                    attrs: {
                      text: _vm._f("pgettext")("Delete Fee", "Button"),
                      variant: "danger",
                      icon: "fa-times",
                      spinning: _vm.actions.deleteServiceFee.isRunning
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_1419464017721962_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isEditable
                ? _c("ItpButton", {
                    attrs: {
                      text: _vm._f("pgettext")("Save", "Button"),
                      disabled:
                        !_vm.forms.contract || !_vm.forms.contract.submittable,
                      type: "submit",
                      form: _vm.formId,
                      variant: "primary",
                      icon: "fa-save",
                      spinning: _vm.actions.save.isRunning
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: { text: _vm._f("gettext")("Close"), variant: "default" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_5566617329548203_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }