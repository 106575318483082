var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "Rental",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c(
            "DIV",
            { staticClass: "page--v2 d-flex flex-column" },
            [
              _c(
                "MaterialTabs",
                { attrs: { tabManager: _vm.tabManager } },
                [
                  _c(
                    "ItpBox",
                    {
                      staticClass: "mr-3",
                      attrs: { slot: "tabs-start", alignItems: "center" },
                      slot: "tabs-start"
                    },
                    [
                      _c(
                        "b-breadcrumb",
                        { staticClass: "m-0 bg-white" },
                        [
                          _c("b-breadcrumb-item", {
                            attrs: {
                              text: _vm._f("gettext")("Rentals"),
                              to: _vm.b_2285343489561581_to
                            }
                          }),
                          _vm._v(" "),
                          _c("b-breadcrumb-item", {
                            attrs: {
                              text: _vm.item.snapshot("name"),
                              active: ""
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("ItpButton", {
                        staticClass: "px-3",
                        attrs: {
                          variant: "default",
                          icon: "fa-sync-alt",
                          text: _vm._f("gettext")("Reload"),
                          spinning: _vm.actions.reload.isRunning
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.evh_2095089031469157_clickPreventStop(
                              $event,
                              {}
                            )
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      staticClass: "container float-left",
                      attrs: { title: _vm._f("gettext")("General") }
                    },
                    [
                      _vm.tabManager.visited(0)
                        ? _c(
                            "ItpRow",
                            [
                              _c(
                                "ItpCol",
                                { attrs: { w: "6" } },
                                [
                                  _c("UcRentalGeneralPanel", {
                                    staticClass: "pb-5",
                                    attrs: {
                                      rental: _vm.item,
                                      reload: _vm.lastLoaded
                                    },
                                    on: {
                                      reload: function($event) {
                                        return _vm.evh_2679161326024595_reload(
                                          $event,
                                          {}
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.item
                                    ? _c("UcRentalFormContracts", {
                                        staticClass: "pb-5",
                                        attrs: {
                                          rental: _vm.item,
                                          reload: _vm.lastLoaded
                                        },
                                        on: {
                                          reload: function($event) {
                                            return _vm.evh_7987652045662005_reload(
                                              $event,
                                              {}
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.item
                                    ? _c("UcRentalFormProperties", {
                                        staticClass: "pb-5",
                                        attrs: {
                                          rentalId: _vm.item.id,
                                          reload: _vm.lastLoaded
                                        },
                                        on: {
                                          reload: function($event) {
                                            return _vm.evh_6490121676898844_reload(
                                              $event,
                                              {}
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "ItpCol",
                                { attrs: { w: "6" } },
                                [
                                  _c("UcCommentsList", {
                                    staticClass: "pb-5",
                                    attrs: {
                                      parent: _vm.item,
                                      reload: _vm.lastLoaded
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("UcAttachmentsList", {
                                    staticClass: "pb-5",
                                    attrs: {
                                      parent: _vm.item,
                                      reload: _vm.lastLoaded
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("UcRentalInvoicesPanel", {
                                    staticClass: "pb-5",
                                    attrs: {
                                      rentalId: _vm.item.id,
                                      reload: _vm.lastLoaded
                                    },
                                    on: {
                                      reload: function($event) {
                                        return _vm.evh_5361955607147334_reload(
                                          $event,
                                          {}
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      staticClass: "container-fluid h-100",
                      attrs: { title: _vm._f("gettext")("Services") }
                    },
                    [
                      _vm.tabManager.visited(1)
                        ? _c("UcRentalServices", {
                            attrs: { rental: _vm.item, reload: _vm.lastLoaded },
                            on: {
                              reload: function($event) {
                                return _vm.evh_8697745105058001_reload(
                                  $event,
                                  {}
                                )
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      staticClass: "container-fluid h-100",
                      attrs: { title: _vm._f("gettext")("Fixed Assets") }
                    },
                    [
                      _vm.tabManager.visited(2)
                        ? _c("UcRentalFormFixedAssets", {
                            attrs: {
                              rentalId: _vm.item.id,
                              editable: !_vm.item.locked,
                              reload: _vm.lastLoaded
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }