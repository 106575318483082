var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpModal",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "WComment"
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _vm.loaded
                ? _c(
                    "ItpForm",
                    {
                      attrs: { name: "comment" },
                      on: {
                        submit: function($event) {
                          return _vm.evh_1040167445267876_submit($event, {})
                        }
                      }
                    },
                    [
                      _c(
                        "ItpFormGroup",
                        { attrs: { name: "message" } },
                        [
                          _c("ItpFormTextarea", {
                            attrs: {
                              name: "message",
                              required: "",
                              autofocus: "",
                              rows: "8",
                              placeholder: _vm._f("gettext")("Comment message")
                            },
                            model: {
                              value: _vm.editor.message,
                              callback: function($$v) {
                                _vm.$set(_vm.editor, "message", $$v)
                              },
                              expression: "editor.message"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _c("ItpButton", {
                attrs: {
                  form: _vm.forms.comment,
                  text: _vm._f("gettext")("Save"),
                  type: "submit",
                  spinning: _vm.actions.submit.isRunning
                }
              }),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("gettext")("Close"),
                  variant: "default",
                  disabled: _vm.actions.submit.isRunning
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1419464017721962_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }