<template>
<DIV
  :class="b_7315092382398562_class"
>
  <slot>
  </slot>
</DIV>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcBody extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    type: Boolean,
  })
  modal!: boolean;

  @Watch('modal')
  onModal(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("modal")
  }

  @Prop({
    type: Boolean,
  })
  card!: boolean;

  @Watch('card')
  onCard(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("card")
  }

  @Prop({
    type: Boolean,
  })
  stretch!: boolean;

  @Watch('stretch')
  onStretch(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("stretch")
  }

  @Prop({
    type: Boolean,
  })
  fullHeight!: boolean;

  @Watch('fullHeight')
  onFull_height(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("fullHeight")
  }

  ux!: any;
  b_7315092382398562_class!: any;

  async $$load_b_7315092382398562_class() {
    return {
      "uc-body": true,
      stretch: this.stretch,
      col: true,
      "py-4": this.modal,
      "px-5": this.modal,
      "h-100": this.stretch || this.fullHeight,
      "flex-grow-1": this.card,
      "card-body": this.card,
      "overflow-auto": !this.fullHeight,
      "overflow-hidden": this.modal && this.fullHeight,
    }
  }

  dataMembers = ['ux', 'b_7315092382398562_class'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
        b_7315092382398562_class: null,
      },
    }
  }

  actions!: {
  }

  getActions() {
    return {
    }
  }
}

Vue.component("UcBody", UcBody);

</script>