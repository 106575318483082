var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "ItpBox",
        { staticClass: "items-spacing w-100", attrs: { alignItems: "center" } },
        [
          _c(
            "ItpBox",
            { attrs: { direction: "column" } },
            [
              _c("ItpText", { staticClass: "mb-0", attrs: { muted: "" } }, [
                _vm._v("\n      " + _vm._s(_vm.resourceTitle) + "\n    ")
              ]),
              _vm._v(" "),
              _c("ItpText", {
                staticClass: "mb-0",
                attrs: { tag: "h4", text: _vm.resourceKey }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("UcInvoiceTypeBadge", {
            attrs: { value: _vm.invoice.invoice_type }
          }),
          _vm._v(" "),
          _c("UcInvoiceStatusBadge", { attrs: { value: _vm.invoice.status } }),
          _vm._v(" "),
          _vm.invoice.tags && _vm.invoice.tags.length
            ? _c(
                "DIV",
                _vm._l(_vm.invoice.tags, function(tag) {
                  return _c(
                    "span",
                    { staticClass: "badge badge-secondary mx-1 shadow-none" },
                    [_vm._v("\n      " + _vm._s(tag) + "\n    ")]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.invoice.isReadOnly
            ? _c(
                "DIV",
                { attrs: { title: _vm._f("gettext")("Closed") } },
                [_c("ItpIcon", { attrs: { icon: _vm.$config.lockIcon } })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.invoice.reference_invoice
            ? _c(
                "ItpBox",
                { attrs: { direction: "column" } },
                [
                  _c("ItpText", { staticClass: "mb-0", attrs: { muted: "" } }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm._f("pgettext")("Reference invoice", "Invoice")
                        ) +
                        "\n    "
                    )
                  ]),
                  _vm._v(" "),
                  _c("UcInvoiceMiniCard", {
                    attrs: {
                      item: _vm.invoice.reference_invoice,
                      "no-icon": ""
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.invoice.referencing_invoices &&
          _vm.invoice.referencing_invoices.length
            ? _c(
                "ItpBox",
                { attrs: { direction: "column" } },
                [
                  _c("ItpText", { staticClass: "mb-0", attrs: { muted: "" } }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm._f("pgettext")("Referencing invoices", "Invoice")
                        ) +
                        "\n    "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "ItpBox",
                    _vm._l(_vm.invoice.referencing_invoices, function(obj) {
                      return _c("UcInvoiceMiniCard", {
                        key: obj.id,
                        staticClass: "mr-2",
                        attrs: { item: obj, "no-icon": "" }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.invoice.isNew
            ? _c("Icon", {
                attrs: {
                  icon: "flagpack:" + _vm.invoice.language,
                  title: _vm._f("pgettext")("Invoice Language", "Invoice")
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "DIV",
            { staticClass: "btn-toolbar ml-auto" },
            [
              !_vm.invoice.isNew
                ? _c("BtnSyncInvoice", {
                    staticClass: "ml-auto mr-2",
                    attrs: { invoice: _vm.invoice },
                    on: {
                      success: function($event) {
                        return _vm.evh_8680084583475136_success($event, {})
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.invoice.isNew
                ? _c("BtnSyncResource", {
                    staticClass: "ml-auto mr-2",
                    attrs: {
                      resource: _vm.invoice,
                      target: "remote",
                      confirmMessage:
                        "A számlát csak egyszer lehet felölteni a régi rendszerbe,\nfeltöltés után a számlát már nem lehet módosítani."
                    },
                    on: {
                      success: function($event) {
                        return _vm.evh_3282321863127091_success($event, {})
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.invoice.isNew && !_vm.invoice.isReadOnly
                ? _c("ItpButton", {
                    staticClass: "ml-auto mr-2",
                    attrs: {
                      icon: _vm.$config.editIcon,
                      tooltip: _vm._f("gettext")("Edit"),
                      variant: "light"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_2386378572842701_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.invoice.isNew
                ? _c("ItpButton", {
                    staticClass: "ml-auto mr-2",
                    attrs: {
                      icon: "fa-sync-alt",
                      tooltip: _vm._f("gettext")("Reload"),
                      spinning: _vm.actions.reload.isRunning,
                      variant: "light"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_6305342467955186_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.invoice.print_url
                ? _c("ItpButton", {
                    staticClass: "ml-auto mr-2",
                    attrs: {
                      variant: "light",
                      icon: "icon-park-outline:printer-one",
                      href: _vm.invoice.print_url,
                      tooltip: _vm._f("pgettext")(
                        "Open print document",
                        "Invoice"
                      ),
                      target: "_blank",
                      "use-auth": "",
                      "show-spinner": ""
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.invoice.preview_url
                ? _c("ItpButton", {
                    staticClass: "ml-auto mr-2",
                    attrs: {
                      variant: "light",
                      icon: "icon-park-outline:preview-open",
                      href: _vm.invoice.preview_url,
                      tooltip: _vm._f("pgettext")("Open preview", "Invoice"),
                      target: "_blank",
                      "use-auth": "",
                      "show-spinner": ""
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.invoice.log_messages
                ? _c("ItpButton", {
                    staticClass: "ml-auto mr-2",
                    attrs: {
                      variant: "light",
                      icon: "icon-park-outline:log",
                      tooltip: _vm._f("pgettext")(
                        "Show log messages",
                        "Invoice"
                      )
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_6493473528837603_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.invoice.isNew
                ? _c(
                    "b-dropdown",
                    {
                      staticClass: "border",
                      attrs: { right: "", variant: "light", "no-caret": "" }
                    },
                    [
                      _c("ItpIcon", {
                        staticClass: "mx-1",
                        attrs: { slot: "button-content", fa: "bars" },
                        slot: "button-content"
                      }),
                      _vm._v(" "),
                      _c("ItpButton", {
                        attrs: {
                          "dropdown-item": "",
                          text: _vm._f("pgettext")("Copy", "BtnCopyResource"),
                          tooltip: _vm._f("pgettext")(
                            "Create new invoice from this one",
                            "Invoice"
                          ),
                          variant: "default"
                        },
                        on: {
                          click: function($event) {
                            return _vm.evh_5796712150329752_click($event, {})
                          }
                        }
                      }),
                      _vm._v(" "),
                      !_vm.invoice.isNew
                        ? _c("ItpButton", {
                            attrs: {
                              "dropdown-item": "",
                              text: _vm._f("pgettext")(
                                "Create storno",
                                "Invoice"
                              ),
                              tooltip: _vm.createStornoTooltip,
                              variant: "default",
                              disabled: !_vm.invoiceIsReversible
                            },
                            on: {
                              click: function($event) {
                                return _vm.evh_4125470329773403_click(
                                  $event,
                                  {}
                                )
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.invoice.isNew
                        ? _c("ItpButton", {
                            attrs: {
                              "dropdown-item": "",
                              variant: "default",
                              text: _vm._f("pgettext")(
                                "Create corrector",
                                "Invoice"
                              ),
                              tooltip: _vm.createCorrectorTooltip,
                              disabled: !_vm.invoiceIsModifiable
                            },
                            on: {
                              click: function($event) {
                                return _vm.evh_3785918277944093_click(
                                  $event,
                                  {}
                                )
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.invoice.isNew && !_vm.invoice.isReadOnly
                        ? _c("BtnLockResource", {
                            attrs: {
                              "no-icon": "",
                              "dropdown-item": "",
                              resource: _vm.invoice,
                              tooltip: _vm._f("pgettext")(
                                "Lock invoice",
                                "Invoice"
                              )
                            },
                            on: {
                              success: function($event) {
                                return _vm.evh_2191919750581405_success(
                                  $event,
                                  {}
                                )
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.invoice.isNew && !_vm.invoice.isReadOnly
                        ? _c("BtnDeleteResource", {
                            attrs: {
                              "no-icon": "",
                              "dropdown-item": "",
                              resource: _vm.invoice,
                              tooltip: _vm._f("pgettext")(
                                "Delete invoice",
                                "Invoice"
                              )
                            },
                            on: {
                              success: function($event) {
                                return _vm.evh_3699289115122175_success(
                                  $event,
                                  {}
                                )
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c("ItpButton", {
                        attrs: {
                          "dropdown-item": "",
                          text: _vm._f("pgettext")(
                            "History",
                            "BtnResourceHistory"
                          ),
                          tooltip: _vm._f("pgettext")(
                            "Invoice history",
                            "Invoice"
                          ),
                          variant: "default"
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.evh_1247093655110851_clickPreventStop(
                              $event,
                              {}
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }