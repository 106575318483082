var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "contract_number",
            label: _vm._f("pgettext")("Contract number", "Contract"),
            labelColsMd: "4",
            contentColsMd: "8",
            labelAlign: _vm.labelAlign,
            required: "",
            description:
              "Bármilyen formátum megengedett, de a szerződésszámnak egyedinek kell kell lennie."
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "contract_number", required: "" },
            model: {
              value: _vm.contract.contract_number,
              callback: function($$v) {
                _vm.$set(_vm.contract, "contract_number", $$v)
              },
              expression: "contract.contract_number"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "description",
            label: _vm._f("pgettext")("Description", "Contract"),
            labelColsMd: "4",
            contentColsMd: "8",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "description" },
            model: {
              value: _vm.contract.extras.description,
              callback: function($$v) {
                _vm.$set(_vm.contract.extras, "description", $$v)
              },
              expression: "contract.extras.description"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "reference_number",
            label: _vm._f("pgettext")("Reference number", "Contract"),
            labelColsMd: "4",
            contentColsMd: "5",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "reference_number" },
            model: {
              value: _vm.contract.extras.reference_number,
              callback: function($$v) {
                _vm.$set(_vm.contract.extras, "reference_number", $$v)
              },
              expression: "contract.extras.reference_number"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "folder_number",
            label: _vm._f("pgettext")("Folder number", "Contract"),
            labelColsMd: "4",
            contentColsMd: "3",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "folder_number" },
            model: {
              value: _vm.contract.extras.folder_number,
              callback: function($$v) {
                _vm.$set(_vm.contract.extras, "folder_number", $$v)
              },
              expression: "contract.extras.folder_number"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }