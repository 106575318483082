<template>
<DIV>
  <ItpIcon icon="icon-park-outline:dot" :class="dotClass"></ItpIcon> {{ option.name }}

</DIV>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcSuggestProductOption extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    type: Object,
  })
  option!: object;

  @Watch('option')
  onOption(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("option")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  get colorCode() {
    if (this.loaded) {

      return this.$config.product.colorCodes[this.option.product_type]
    }
    return null;
  }

  get dotClass() {
    if (this.loaded) {

      return `text-${this.colorCode}-500`
    }
    return null;
  }

  actions!: {
  }

  getActions() {
    return {
    }
  }
}

Vue.component("UcSuggestProductOption", UcSuggestProductOption);

</script>