import ClientBankAccount from './ClientBankAccount.yaml?component';
import UcClientBankAccountCard from './UcClientBankAccountCard.yaml?component';
import UcClientBankAccountFieldset from './UcClientBankAccountFieldset.yaml?component';
import UcClientBankAccounts from './UcClientBankAccounts.yaml?component';

export {
  ClientBankAccount,
  UcClientBankAccountCard,
  UcClientBankAccountFieldset,
  UcClientBankAccounts
};
