var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "ItpBox",
        { staticClass: "items-spacing w-100", attrs: { alignItems: "center" } },
        [
          _c(
            "ItpBox",
            { attrs: { direction: "column" } },
            [
              _c(
                "ItpBox",
                [
                  _c("ItpText", { staticClass: "mb-0", attrs: { muted: "" } }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.resourceTitle) + "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _vm.payment.payment_type
                    ? _c("UcPaymentTransactionTypeSymbol", {
                        staticClass: "mx-2",
                        attrs: { paymentType: _vm.payment.payment_type }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("ItpText", {
                staticClass: "mb-0",
                attrs: { tag: "h4", text: _vm.resourceKey }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "DIV",
            { staticClass: "btn-toolbar ml-auto" },
            [
              !_vm.isNew
                ? _c("ItpButton", {
                    staticClass: "ml-auto mr-2",
                    attrs: {
                      icon: "fa-sync-alt",
                      tooltip: _vm._f("gettext")("Reload"),
                      spinning: _vm.actions.reload.isRunning,
                      variant: "light"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_1040167445267876_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.payment.preview_url
                ? _c("ItpButton", {
                    staticClass: "ml-auto mr-2",
                    attrs: {
                      variant: "light",
                      icon: "icon-park-outline:preview-open",
                      href: _vm.payment.preview_url,
                      tooltip: _vm._f("pgettext")("Open preview", "Payment"),
                      target: "_blank",
                      "use-auth": "",
                      "show-spinner": ""
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isNew
                ? _c(
                    "b-dropdown",
                    {
                      staticClass: "border",
                      attrs: { right: "", variant: "light" }
                    },
                    [
                      _c("ItpIcon", {
                        staticClass: "mx-1",
                        attrs: { slot: "button-content", fa: "bars" },
                        slot: "button-content"
                      }),
                      _vm._v(" "),
                      !_vm.isNew
                        ? _c("BtnDeleteResource", {
                            attrs: {
                              "no-icon": "",
                              "dropdown-item": "",
                              resource: _vm.payment,
                              tooltip: _vm._f("pgettext")(
                                "Delete payment",
                                "Payment"
                              ),
                              disabled: _vm.payment.isReadonly
                            },
                            on: {
                              success: function($event) {
                                return _vm.evh_4942415580156499_success(
                                  $event,
                                  {}
                                )
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }