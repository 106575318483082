var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "RentalServiceCostAllocator",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              dialogName: _vm.resourceTitle,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "ItpForm",
                {
                  ref: "mainForm",
                  attrs: { name: _vm.formName, id: _vm.formId },
                  on: {
                    submit: function($event) {
                      return _vm.evh_1040167445267876_submit($event, {})
                    }
                  }
                },
                [
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "meter_id",
                        label: _vm._f("pgettext")(
                          "Meter",
                          "RentalServiceCostAllocator"
                        ),
                        labelColsMd: "4",
                        contentColsMd: "8",
                        labelAlign: _vm.labelAlign,
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormSelect2", {
                        attrs: {
                          name: "meter_id",
                          dataSource: _vm.b_7789867021953041_dataSource,
                          initialOptions: _vm.editor.meter
                            ? [_vm.editor.meter]
                            : [],
                          valueField: "id",
                          textField: "serial_number",
                          "use-option-template-for-selected-option": "",
                          optionTemplate:
                            '<UcSuggestMeterOption :option="option"></UcSuggestMeterOption>',
                          required: "",
                          readonly: _vm.item.isReadOnly
                        },
                        model: {
                          value: _vm.editor.meter_id,
                          callback: function($$v) {
                            _vm.$set(_vm.editor, "meter_id", $$v)
                          },
                          expression: "editor.meter_id"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "factor",
                        label: _vm._f("pgettext")(
                          "Factor",
                          "RentalServiceCostAllocator"
                        ),
                        labelColsMd: "4",
                        contentColsMd: "4",
                        labelAlign: _vm.labelAlign,
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormInput", {
                        attrs: {
                          type: "number",
                          name: "factor",
                          readonly: _vm.item.isReadOnly,
                          required: ""
                        },
                        model: {
                          value: _vm.editor.factor,
                          callback: function($$v) {
                            _vm.$set(_vm.editor, "factor", $$v)
                          },
                          expression: "editor.factor"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "notes",
                        label: _vm._f("pgettext")(
                          "Notes",
                          "RentalServiceCostAllocator"
                        ),
                        labelColsMd: "4",
                        contentColsMd: "8",
                        labelAlign: _vm.labelAlign
                      }
                    },
                    [
                      _c("ItpFormTextarea", {
                        attrs: { name: "note", readonly: _vm.item.isReadOnly },
                        model: {
                          value: _vm.item.notes,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "notes", $$v)
                          },
                          expression: "item.notes"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("pgettext")("Save", "Button"),
                  type: "submit",
                  form: _vm.forms[_vm.formName],
                  spinning: _vm.actions.create.isRunning
                }
              }),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("gettext")("Close"),
                  variant: "default",
                  disabled: _vm.actions.create.isRunning
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1419464017721962_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }