var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c(
            "DIV",
            { staticClass: "card" },
            [
              _c(
                "DIV",
                { staticClass: "card-header d-flex align-items-center" },
                [
                  _c(
                    "DIV",
                    [
                      _c("SPAN", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm._f("gettext")("Notifications")) +
                            "\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _vm.items.length
                        ? _c(
                            "SPAN",
                            { staticClass: "badge badge-secondary ml-2" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.items.length) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("DIV", { staticClass: "ml-auto" }),
                  _vm._v(" "),
                  _c(
                    "DIV",
                    [
                      _c("ItpButton", {
                        attrs: {
                          icon: "fa-sync",
                          tooltip: _vm._f("gettext")("Reload"),
                          spinning:
                            _vm.actions.reload && _vm.actions.reload.isRunning,
                          size: "sm",
                          variant: "link"
                        },
                        on: {
                          click: function($event) {
                            return _vm.evh_8697745105058001_click($event, {})
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.items.length
                ? _c(
                    "UL",
                    {
                      staticClass: "list-group list-group-flush",
                      style: _vm.b_3343864469950044_style
                    },
                    _vm._l(_vm.items, function(item) {
                      return _c(
                        "LI",
                        { key: item.id, staticClass: "list-group-item" },
                        [
                          _c(
                            "ItpBox",
                            [
                              item.type == "expiring_contract"
                                ? _c("UcExpiringContractNotificationCard", {
                                    attrs: { notification: item }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              item.type == "inflation_indexed_price_change"
                                ? _c(
                                    "UcInflationIndexedPriceChangeNotificationCard",
                                    { attrs: { notification: item } }
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "close ml-auto mt-2 mr-2 align-self-start",
                                  attrs: {
                                    "data-dismiss": "modal",
                                    "aria-label": _vm._f("gettext")("Remove"),
                                    title: _vm._f("gettext")("Remove")
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.evh_2406129194387753_click(
                                        $event,
                                        { item: item }
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { "aria-hidden": "true" } },
                                    [_vm._v("×")]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.items.length
                ? _c("DIV", { staticClass: "card-body" }, [
                    _c("p", { staticClass: "card-text" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm._f("gettext")("No new notifications.")) +
                          "\n      "
                      )
                    ])
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }