var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _vm.rental.address
        ? _c(
            "DIV",
            [
              _c("ItpText", { attrs: { muted: "" } }, [
                _vm._v(_vm._s(_vm._f("pgettext")("Address", "Rental")) + ":")
              ]),
              _vm._v(" " + _vm._s(_vm.rental.address) + "\n\n  ")
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.rental.area
        ? _c(
            "DIV",
            [
              _c("ItpText", { attrs: { muted: "" } }, [
                _vm._v(_vm._s(_vm._f("pgettext")("Area", "Rental")) + ":")
              ]),
              _vm._v(" " + _vm._s(_vm.rental.area) + "\n\n  ")
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.rental.lot_number
        ? _c(
            "DIV",
            [
              _c("ItpText", { attrs: { muted: "" } }, [
                _vm._v(_vm._s(_vm._f("pgettext")("LOT number", "Rental")) + ":")
              ]),
              _vm._v(" " + _vm._s(_vm.rental.lot_number) + "\n\n  ")
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.rental.house_number
        ? _c(
            "DIV",
            [
              _c("ItpText", { attrs: { muted: "" } }, [
                _vm._v(
                  _vm._s(_vm._f("pgettext")("House number", "Rental")) + ":"
                )
              ]),
              _vm._v(" " + _vm._s(_vm.rental.house_number) + "\n\n  ")
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.rental.description
        ? _c(
            "DIV",
            [
              _c("ItpText", { attrs: { muted: "" } }, [
                _vm._v(
                  _vm._s(_vm._f("pgettext")("Description", "Rental")) + ":"
                )
              ]),
              _vm._v(" " + _vm._s(_vm.rental.description) + "\n\n  ")
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }