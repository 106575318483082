var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.item.status === "running" && _vm.item.progress
    ? _c("BProgress", {
        attrs: {
          max: _vm.item.progress.total,
          value: _vm.item.progress.completed,
          "show-progress": "",
          animated: ""
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }