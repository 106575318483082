var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.items || !_vm.items.length
        ? _c(
            "DIV",
            [
              _c("ItpAlert", {
                attrs: {
                  show: "",
                  message: _vm._f("pgettext")(
                    "This meter has no related invoices.",
                    "Meter"
                  )
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.items && _vm.items.length
        ? _c("ItpDataTable", {
            attrs: {
              tableId: "MeterInvoices",
              editable: _vm.b_6804155476809633_editable,
              paging: "",
              value: _vm._.orderBy(
                _vm.items,
                ["invoice_date", "reading_time", "created_at"],
                ["desc", "desc", "desc"]
              ),
              columns: _vm.b_6804155476809633_columns
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }