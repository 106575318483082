var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "ContractStatusTransition",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "ItpForm",
                {
                  ref: "mainForm",
                  staticClass: "row",
                  attrs: {
                    id: _vm.formId,
                    name: "contract",
                    autofocus: "first"
                  },
                  on: {
                    submit: function($event) {
                      return _vm.evh_1040167445267876_submit($event, {})
                    }
                  }
                },
                [
                  _c(
                    "ItpCol",
                    [
                      _vm.options.displayDate
                        ? _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                name: "date",
                                label: _vm.options.date,
                                labelColsMd: "4",
                                contentColsMd: "4",
                                labelAlign: _vm.labelAlign,
                                required: ""
                              }
                            },
                            [
                              _c("ItpFormDatetime", {
                                attrs: { name: "date", required: "" },
                                model: {
                                  value: _vm.item.date,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "date", $$v)
                                  },
                                  expression: "item.date"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.options.effective_date
                        ? _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                name: "effective_date",
                                label: _vm.options.effective_date,
                                labelColsMd: "4",
                                contentColsMd: "4",
                                labelAlign: _vm.labelAlign,
                                required: ""
                              }
                            },
                            [
                              _c("ItpFormDatetime", {
                                attrs: { name: "effective_date", required: "" },
                                model: {
                                  value: _vm.item.effective_date,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "effective_date", $$v)
                                  },
                                  expression: "item.effective_date"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            name: "notes",
                            label: _vm._f("pgettext")(
                              "Notes",
                              "ContractStatusTransition"
                            ),
                            labelColsMd: "4",
                            contentColsMd: "8",
                            labelAlign: _vm.labelAlign,
                            required: _vm.options.notesRequired,
                            description: _vm.options.notesHelpText
                          }
                        },
                        [
                          _c("ItpFormTextarea", {
                            attrs: {
                              name: "notes",
                              required: _vm.options.notesRequired
                            },
                            model: {
                              value: _vm.item.notes,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "notes", $$v)
                              },
                              expression: "item.notes"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("pgettext")("Save", "Button"),
                  disabled:
                    !_vm.forms.contract || !_vm.forms.contract.submittable,
                  type: "submit",
                  form: _vm.formId,
                  variant: "primary",
                  icon: "fa-save",
                  spinning: _vm.actions.submit.isRunning
                }
              }),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: { text: _vm._f("gettext")("Close"), variant: "default" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1419464017721962_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }