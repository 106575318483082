var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpWidgetCard",
        [
          _c(
            "ItpBox",
            { attrs: { slot: "header", alignItems: "center" }, slot: "header" },
            [
              _c("strong", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("gettext")("Attachments")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              !_vm.isReadOnly
                ? _c("ItpButton", {
                    staticClass: "ml-auto",
                    attrs: {
                      text: _vm._f("pgettext")("Upload file...", "Attachments"),
                      variant: "light",
                      size: "sm"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_8320016629450276_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "BListGroup",
            [
              !_vm.items || !_vm.items.length
                ? _c("b-list-group-item", [
                    _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("gettext")(
                              "No attachments have been added yet."
                            )
                          ) +
                          "\n        "
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(
                _vm._.orderBy(_vm.items, ["created_at"], ["desc"]),
                function(item) {
                  return _c(
                    "b-list-group-item",
                    { key: item.id, staticClass: "d-flex flex-column" },
                    [
                      _c(
                        "ItpBox",
                        { attrs: { direction: "row", spacing: "pb-1" } },
                        [
                          _c(
                            "div",
                            [
                              _c("UcFileIcon", {
                                attrs: { filename: item.file_name }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ItpBox",
                            {
                              attrs: {
                                spacing: "pl-3 w-100",
                                direction: "column"
                              }
                            },
                            [
                              _c(
                                "ItpBox",
                                { attrs: { spacing: "pb-1" } },
                                [
                                  _c("ItpLink", {
                                    attrs: {
                                      text: item.file_name,
                                      href: item.download_url,
                                      tooltip: item.file_name,
                                      downloadFileName: item.file_name,
                                      "download-link-only": "",
                                      "use-auth": ""
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("ItpText", { staticClass: "px-3" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm._f("filesize")(item.file_size)
                                        ) +
                                        "\n              "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  !item.locked
                                    ? _c(
                                        "b-dropdown",
                                        {
                                          staticClass: "ml-auto mb-auto",
                                          attrs: {
                                            size: "sm",
                                            "toggle-class":
                                              "btn-default-borderless",
                                            "no-caret": ""
                                          }
                                        },
                                        [
                                          _c("ItpIcon", {
                                            staticClass: "mx-1",
                                            attrs: {
                                              slot: "button-content",
                                              fa: "ellipsis-v"
                                            },
                                            slot: "button-content"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "b-dropdown-item-button",
                                            {
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  $event.stopPropagation()
                                                  return _vm.evh_8513850661379123_clickPreventStop(
                                                    $event,
                                                    { item: item }
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("ItpText", {
                                                attrs: {
                                                  text: _vm._f("pgettext")(
                                                    "Edit",
                                                    "Command"
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "b-dropdown-item-button",
                                            {
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  $event.stopPropagation()
                                                  return _vm.evh_3861238174616472_clickPreventStop(
                                                    $event,
                                                    { item: item }
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("ItpText", {
                                                attrs: {
                                                  text: _vm._f("pgettext")(
                                                    "Delete",
                                                    "Command"
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              item.description
                                ? _c(
                                    "ItpBox",
                                    { attrs: { spacing: "pb-2" } },
                                    [
                                      _c("ItpText", {
                                        attrs: {
                                          pre: "",
                                          text: item.description
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "ItpBox",
                                { attrs: { alignItems: "center" } },
                                [
                                  _c(
                                    "small",
                                    { staticClass: "text-muted text-nowrap" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm._f("datetime")(
                                              item.created_at || item.created_at
                                            )
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }