<template>
<ItpBox
  direction="column"
>
  <ItpBox
    alignItems="center"
  >
    <ItpIcon
      :title="$config.meterTypes[payload.meter_type].description"
      :name="$config.meterTypes[payload.meter_type].icon"
      class="pr-2"
    >
    </ItpIcon>
    <ItpLink
      :to="{
  name: 'app.meter',
  params: { id: payload.id }
}"
      class="d-inline-block"
    >
      {{ payload.serial_number }}
<span v-if="payload.tag_id">
({{ payload.tag_id }})
</span>

    </ItpLink>
  </ItpBox>
  <ItpBox
    alignItems="center"
  >
    <ItpText
      :title="'DevEUI'|gettext"
      :text="payload.deveui"
      class="pl-3"
      v-if="payload.deveui"
    >
    </ItpText>
  </ItpBox>
</ItpBox>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcCompactMeterDisplay extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  payload!: object;

  @Watch('payload')
  onPayload(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("payload")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  actions!: {
  }

  getActions() {
    return {
    }
  }
}

Vue.component("UcCompactMeterDisplay", UcCompactMeterDisplay);

</script>