var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "InvoiceGeneration",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _c("UcHeader", {
        attrs: {
          slot: "header",
          card: !_vm.modal,
          modal: _vm.modal,
          title: _vm.documentTitle
        },
        on: {
          close: function($event) {
            return _vm.evh_2248226175642056_close($event, {})
          }
        },
        slot: "header"
      }),
      _vm._v(" "),
      _c(
        "UcBody",
        { attrs: { card: !_vm.modal, modal: _vm.modal } },
        [
          _c("FieldsetInvoiceGeneration", {
            attrs: { contract: _vm.contract },
            on: {
              close: function($event) {
                return _vm.evh_1040167445267876_close($event, {})
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }