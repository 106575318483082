var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _vm.option.object === "rental"
        ? _c("UcSuggestRentalOption", { attrs: { option: _vm.option } })
        : _vm._e(),
      _vm._v(" "),
      _vm.option.object === "contract"
        ? _c("UcSuggestContractOption", { attrs: { option: _vm.option } })
        : _vm._e(),
      _vm._v(" "),
      _vm.option.object === "client"
        ? _c("UcSuggestClientOption", { attrs: { option: _vm.option } })
        : _vm._e(),
      _vm._v(" "),
      _vm.option.object === "property" || _vm.option.object === "property_unit"
        ? _c("UcSuggestPropertyOption", { attrs: { option: _vm.option } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }