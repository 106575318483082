var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "AccountStatementReport",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              color: _vm.$config.payment.color,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal, stretch: "" } },
            [
              _c(
                "ItpBox",
                { staticClass: "h-100 w-100", attrs: { direction: "column" } },
                [
                  _c(
                    "ItpForm",
                    {
                      ref: "mainForm",
                      staticClass: "pb-3",
                      attrs: { id: _vm.formId, name: "payment" }
                    },
                    [
                      _c(
                        "ItpBox",
                        [
                          _c(
                            "DIV",
                            {
                              staticClass: "mr-2",
                              style: _vm.b_5731695935601903_style
                            },
                            [
                              _c("ItpFormSelect2", {
                                attrs: {
                                  name: "accounts",
                                  "bind-object": "",
                                  textField: "name,currency,account_number",
                                  options: _vm.accounts,
                                  "use-option-template-for-selected-option": "",
                                  optionTemplate:
                                    "<UcAccountSelectOption :payload=option></UcAccountSelectOption>"
                                },
                                model: {
                                  value: _vm.item.account,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "account", $$v)
                                  },
                                  expression: "item.account"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("DateRangeSelector", {
                            staticClass: "mr-2",
                            attrs: {
                              name: "paymentFilters",
                              from: _vm.item.date_from,
                              to: _vm.item.date_to
                            },
                            on: {
                              changed: function($event) {
                                return _vm.evh_1747314907724257_changed(
                                  $event,
                                  {}
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "DIV",
                            [
                              _c("ItpButton", {
                                attrs: {
                                  text: _vm._f("pgettext")(
                                    "Display...",
                                    "Button"
                                  ),
                                  size: "sm",
                                  icon: "ri:barcode-box-line",
                                  disabled: _vm.connector.loading,
                                  spinning: _vm.connector.loading
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.evh_2421697723438155_click(
                                      $event,
                                      {}
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("ItpPdfViewer", {
                    staticClass: "flex-fill",
                    attrs: { file: _vm.file && _vm.file.file }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c("UcFooter", {
            attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
            slot: "footer"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }