var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpWidgetCard",
        [
          _c(
            "ItpBox",
            { attrs: { slot: "header", alignItems: "center" }, slot: "header" },
            [
              _c("strong", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("pgettext")("General", "Contract")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              !_vm.readonly
                ? _c("ItpButton", {
                    staticClass: "ml-auto",
                    attrs: {
                      text: _vm._f("gettext")("Edit..."),
                      variant: "light",
                      size: "sm"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_8320016629450276_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "BListGroup",
            [
              _c("ItpLabeledListGroupItem", {
                attrs: {
                  label: _vm._f("pgettext")("Contract number", "Contract"),
                  content: _vm.contract.contract_number
                }
              }),
              _vm._v(" "),
              _c(
                "ItpLabeledListGroupItem",
                {
                  attrs: {
                    label: _vm._f("pgettext")("Contract type", "Contract")
                  }
                },
                [
                  _c("UcContractTypeBadge", {
                    attrs: { value: _vm.contract.contract_type }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpLabeledListGroupItem",
                { attrs: { label: _vm._f("pgettext")("Status", "Contract") } },
                [
                  _c("UcContractStatusCard", {
                    attrs: { contract: _vm.contract, reload: _vm.lastLoaded },
                    on: {
                      reload: function($event) {
                        return _vm.evh_7349226261686150_reload($event, {})
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.contract.extras.description
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Description", "Contract"),
                      content: _vm.contract.extras.description
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.contract.extras.folder_number
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Folder number", "Contract"),
                      content: _vm.contract.extras.folder_number
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.contract.extras.reference_number
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Reference number", "Contract"),
                      content: _vm.contract.extras.reference_number
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "ItpLabeledListGroupItem",
                {
                  attrs: {
                    label: _vm._f("pgettext")("Lessor", "Contract"),
                    expandable: ""
                  }
                },
                [
                  _c(
                    "ItpBox",
                    [
                      _c("ItpLink", {
                        attrs: {
                          text: _vm.contract.company.name,
                          to: _vm.b_6379803495938895_to
                        }
                      }),
                      _vm._v(" "),
                      !_vm.readonly
                        ? _c("ItpButton", {
                            staticClass: "ml-auto",
                            attrs: {
                              text: _vm._f("gettext")("Edit..."),
                              variant: "light",
                              size: "sm"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.evh_5937240531526321_clickPreventStop(
                                  $event,
                                  {}
                                )
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "expander" },
                    [
                      _c("UcContractCompanyCard", {
                        attrs: { item: _vm.contract.company }
                      })
                    ],
                    1
                  )
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "ItpLabeledListGroupItem",
                {
                  attrs: {
                    label: _vm._f("pgettext")("Tenant", "Contract"),
                    expandable: ""
                  }
                },
                [
                  _c(
                    "ItpBox",
                    [
                      _c("ItpLink", {
                        attrs: {
                          text: _vm.contract.counterparty.name,
                          to: _vm.b_8906220998092919_to
                        }
                      }),
                      _vm._v(" "),
                      !_vm.readonly
                        ? _c("ItpButton", {
                            staticClass: "ml-auto",
                            attrs: {
                              text: _vm._f("gettext")("Edit..."),
                              variant: "light",
                              size: "sm"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.evh_7527485200259320_clickPreventStop(
                                  $event,
                                  {}
                                )
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "expander" },
                    [
                      _c("UcContractClientCard", {
                        attrs: { item: _vm.contract.counterparty }
                      })
                    ],
                    1
                  )
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "ItpLabeledListGroupItem",
                {
                  attrs: {
                    label: _vm._f("pgettext")("Rental", "Contract"),
                    expandable: "",
                    expanderCols: "11"
                  }
                },
                [
                  _c(
                    "ItpBox",
                    [
                      _vm.contract.rental
                        ? _c("ItpLink", {
                            attrs: {
                              text: _vm.contract.rental.name,
                              to: {
                                name: "app.rental",
                                params: {
                                  id: _vm.contract.rental.id
                                }
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.readonly
                        ? _c("ItpButton", {
                            staticClass: "ml-auto",
                            attrs: {
                              text: _vm._f("gettext")("Edit..."),
                              variant: "light",
                              size: "sm"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.evh_7127218473441393_clickPreventStop(
                                  $event,
                                  {}
                                )
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "expander" },
                    [
                      _c("UcContractRentalCard", {
                        attrs: { rental: _vm.contract.rental }
                      }),
                      _vm._v(" "),
                      _c("UcContractRentalServices", {
                        attrs: {
                          rental: _vm.contract.rental,
                          reload: _vm.lastLoaded
                        },
                        on: {
                          reload: function($event) {
                            return _vm.evh_4198689235226718_reload($event, {})
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }