var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _vm.contract.revision_number == 0
        ? _c(
            "ItpFormGroup",
            {
              attrs: {
                name: "revision_date",
                label: _vm._f("pgettext")("Contract date", "Contract"),
                labelColsMd: "4",
                contentColsMd: "4",
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormDatetime", {
                attrs: { name: "revision_date" },
                model: {
                  value: _vm.contract.lifecycle.revision_date,
                  callback: function($$v) {
                    _vm.$set(_vm.contract.lifecycle, "revision_date", $$v)
                  },
                  expression: "contract.lifecycle.revision_date"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.contract.revision_number > 0
        ? _c(
            "ItpFormGroup",
            {
              attrs: {
                name: "revision_date",
                label: _vm._f("pgettext")("Revision date", "Contract"),
                labelColsMd: "4",
                contentColsMd: "4",
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormDatetime", {
                attrs: { name: "revision_date" },
                model: {
                  value: _vm.contract.lifecycle.revision_date,
                  callback: function($$v) {
                    _vm.$set(_vm.contract.lifecycle, "revision_date", $$v)
                  },
                  expression: "contract.lifecycle.revision_date"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "valid_from",
            label: _vm._f("pgettext")("Validity", "Contract"),
            labelColsMd: "4",
            contentColsMd: "8",
            labelAlign: _vm.labelAlign,
            contentClass: "form-row"
          }
        },
        [
          _c(
            "DIV",
            { staticClass: "col" },
            [
              _c("ItpFormDatetime", {
                attrs: { name: "valid_from" },
                model: {
                  value: _vm.contract.lifecycle.valid_from,
                  callback: function($$v) {
                    _vm.$set(_vm.contract.lifecycle, "valid_from", $$v)
                  },
                  expression: "contract.lifecycle.valid_from"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "DIV",
            { staticClass: "col" },
            [
              _c("ItpFormDatetime", {
                attrs: { name: "valid_to" },
                model: {
                  value: _vm.contract.lifecycle.valid_to,
                  callback: function($$v) {
                    _vm.$set(_vm.contract.lifecycle, "valid_to", $$v)
                  },
                  expression: "contract.lifecycle.valid_to"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "date_signed",
            label: _vm._f("pgettext")("Date signed", "Contract"),
            labelColsMd: "4",
            contentColsMd: "4",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormDatetime", {
            attrs: { name: "date_signed" },
            model: {
              value: _vm.contract.lifecycle.date_signed,
              callback: function($$v) {
                _vm.$set(_vm.contract.lifecycle, "date_signed", $$v)
              },
              expression: "contract.lifecycle.date_signed"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "effective_date",
            label: _vm._f("pgettext")("Effective date", "Contract"),
            labelColsMd: "4",
            contentColsMd: "4",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormDatetime", {
            attrs: { name: "effective_date" },
            model: {
              value: _vm.contract.lifecycle.effective_date,
              callback: function($$v) {
                _vm.$set(_vm.contract.lifecycle, "effective_date", $$v)
              },
              expression: "contract.lifecycle.effective_date"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "date_of_occupancy",
            label: _vm._f("pgettext")("Date of occupancy", "Contract"),
            labelColsMd: "4",
            contentColsMd: "4",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormDatetime", {
            attrs: { name: "date_of_occupancy" },
            model: {
              value: _vm.contract.lifecycle.date_of_occupancy,
              callback: function($$v) {
                _vm.$set(_vm.contract.lifecycle, "date_of_occupancy", $$v)
              },
              expression: "contract.lifecycle.date_of_occupancy"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "auto_renewal",
            label: _vm._f("pgettext")("Auto renewal", "Contract"),
            labelColsMd: "4",
            contentColsMd: "4",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormCheckbox", {
            attrs: { name: "auto_renewal" },
            model: {
              value: _vm.contract.lifecycle.auto_renewal,
              callback: function($$v) {
                _vm.$set(_vm.contract.lifecycle, "auto_renewal", $$v)
              },
              expression: "contract.lifecycle.auto_renewal"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "notice_period",
            label: _vm._f("pgettext")("Notice period", "Contract"),
            labelColsMd: "4",
            contentColsMd: "4",
            labelAlign: _vm.labelAlign,
            contentClass: "input-group"
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { type: "number", name: "notice_period" },
            model: {
              value: _vm.contract.lifecycle.notice_period,
              callback: function($$v) {
                _vm.$set(_vm.contract.lifecycle, "notice_period", $$v)
              },
              expression: "contract.lifecycle.notice_period"
            }
          }),
          _vm._v(" "),
          _c("DIV", { staticClass: "input-group-append" }, [
            _c("span", { staticClass: "input-group-text" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm._f("pgettext")("days", "InputGroupAddon")) +
                  "\n      "
              )
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "next_indexation_date",
            label: _vm._f("pgettext")("Next indexation date", "Contract"),
            labelColsMd: "4",
            contentColsMd: "4",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormDatetime", {
            attrs: { name: "next_indexation_date" },
            model: {
              value: _vm.contract.lifecycle.next_indexation_date,
              callback: function($$v) {
                _vm.$set(_vm.contract.lifecycle, "next_indexation_date", $$v)
              },
              expression: "contract.lifecycle.next_indexation_date"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "termination_date",
            label: _vm._f("pgettext")("Terminaton date", "Contract"),
            labelColsMd: "4",
            contentColsMd: "4",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormDatetime", {
            attrs: { name: "termination_date" },
            model: {
              value: _vm.contract.lifecycle.termination_date,
              callback: function($$v) {
                _vm.$set(_vm.contract.lifecycle, "termination_date", $$v)
              },
              expression: "contract.lifecycle.termination_date"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "termination_effective_date",
            label: _vm._f("pgettext")("Terminaton effective date", "Contract"),
            labelColsMd: "4",
            contentColsMd: "4",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormDatetime", {
            attrs: { name: "termination_effective_date" },
            model: {
              value: _vm.contract.lifecycle.termination_effective_date,
              callback: function($$v) {
                _vm.$set(
                  _vm.contract.lifecycle,
                  "termination_effective_date",
                  $$v
                )
              },
              expression: "contract.lifecycle.termination_effective_date"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }