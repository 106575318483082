var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpWidgetCard",
    [
      _c(
        "ItpBox",
        { attrs: { slot: "header", alignItems: "center" }, slot: "header" },
        [
          _c("strong", [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm._f("pgettext")("Rental Services", "MeterRentalServices")
                ) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c("ItpButton", {
            staticClass: "ml-auto",
            attrs: {
              text: _vm._f("pgettext")(
                "Add rental service...",
                "UcRentalServices"
              ),
              variant: "light",
              size: "sm"
            },
            on: {
              click: function($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.evh_3343864469950044_clickPreventStop($event, {})
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "BListGroup",
            [
              !_vm.items.length
                ? _c("BListGroupItem", [
                    _c("span", [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm._f("pgettext")(
                              "No rental services.",
                              "MeterRentalServices"
                            )
                          ) +
                          "\n      "
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.sortItems(_vm.items), function(item) {
                return _c(
                  "BListGroupItem",
                  { key: item.id, staticClass: "d-flex flex-column" },
                  [
                    _c(
                      "ItpBox",
                      { attrs: { alignItems: "start" } },
                      [
                        _c("UcInactive", { attrs: { value: item.inactive } }),
                        _vm._v(" "),
                        _c(
                          "ItpBox",
                          {
                            staticClass: "w-100",
                            attrs: { direction: "column" }
                          },
                          [
                            _c(
                              "ItpBox",
                              { staticClass: "W-100" },
                              [
                                _c(
                                  "ItpBox",
                                  { staticClass: "col-md-3" },
                                  [
                                    _c(
                                      "ItpText",
                                      {
                                        staticClass: "font-weight-bold mr-2",
                                        attrs: {
                                          strikethrough: _vm.isServiceStale(
                                            item
                                          )
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(item.service.name) +
                                            " (" +
                                            _vm._s(item.billing_unit) +
                                            ")\n              "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("ItpLink", {
                                      attrs: {
                                        icon: _vm.$config.externalLinkIcon,
                                        to: {
                                          name: "app.product",
                                          params: { id: item.service.id }
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "ItpBox",
                                  { staticClass: "col-md-3" },
                                  [
                                    _c("UcValidityPeriod", {
                                      staticClass: "pl-3",
                                      attrs: {
                                        from: item.valid_from,
                                        to: item.valid_to
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "ItpBox",
                                  {
                                    staticClass: "col-md-1",
                                    attrs: {
                                      alignItems: "center",
                                      direction: "column"
                                    }
                                  },
                                  [
                                    _c(
                                      "ItpBadge",
                                      {
                                        staticClass: "mx-3",
                                        attrs: {
                                          title: _vm._f("gettext")(
                                            "Billing multiplier"
                                          ),
                                          "no-box-shadow": "",
                                          variant: "light"
                                        }
                                      },
                                      [
                                        _c("ItpIcon", {
                                          attrs: { name: "fa-times" }
                                        }),
                                        _vm._v(" "),
                                        _c("ItpText", {
                                          attrs: { text: item.meter_factor }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    item.non_billable
                                      ? _c("ItpBadge", {
                                          staticClass: "mx-3 mt-2",
                                          attrs: {
                                            variant: "warning",
                                            text: _vm._f("pgettext")(
                                              "Non billable",
                                              "RentalService"
                                            ),
                                            "no-box-shadow": ""
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "ItpBox",
                                  {
                                    staticClass: "col-md-5",
                                    attrs: {
                                      alignItems: "start",
                                      "wrap-items": ""
                                    }
                                  },
                                  [
                                    _c("UcCompactMeterDisplay", {
                                      staticClass: "pl-3",
                                      attrs: { payload: item.meter }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "ItpBox",
                              [
                                _c(
                                  "div",
                                  { staticClass: "col-md-7" },
                                  [
                                    item.notes
                                      ? _c("ItpText", {
                                          attrs: { text: item.notes, pre: "" }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("UcRentalServiceCostAllocators", {
                                  staticClass: "col-md-5",
                                  attrs: { service: item }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("ItpButton", {
                          staticClass: "ml-auto",
                          attrs: {
                            icon: "fa-pen",
                            tooltip: _vm._f("gettext")("Edit"),
                            variant: "light",
                            size: "sm"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.evh_8855792642156263_clickPreventStop(
                                $event,
                                { item: item }
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }