var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "InvoiceReferenceSearch",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "ItpForm",
                { attrs: { name: "search", id: _vm.formId } },
                [
                  _c(
                    "DIV",
                    { staticClass: "form-row" },
                    [
                      _c("ItpSelect2", {
                        staticClass: "col-4",
                        attrs: {
                          name: "selectedReference",
                          options: _vm.referenceTypes,
                          valueField: "target",
                          textField: "text",
                          clearable: _vm.b_7789867021953041_clearable,
                          "bind-object": ""
                        },
                        on: {
                          selected: function($event) {
                            return _vm.evh_7789867021953041_selected($event, {})
                          }
                        },
                        model: {
                          value: _vm.selectedReference,
                          callback: function($$v) {
                            _vm.selectedReference = $$v
                          },
                          expression: "selectedReference"
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpFormSelect2", {
                        staticClass: "col-8",
                        attrs: {
                          name: "search",
                          placeholder: _vm._f("pgettext")(
                            "Search...",
                            "Search Placeholder"
                          ),
                          dataSource:
                            _vm.selectedReference &&
                            _vm.selectedReference.dataSource,
                          options: _vm.items,
                          optionTemplate:
                            "<InvoiceReferenceSearchOption :option=option>\n</InvoiceReferenceSearchOption>",
                          "use-option-template-for-selected-option": ""
                        },
                        on: {
                          optionSelected: function($event) {
                            return _vm.evh_3468003412020058_optionSelected(
                              $event,
                              {}
                            )
                          }
                        },
                        model: {
                          value: _vm.searchTerm,
                          callback: function($$v) {
                            _vm.searchTerm = $$v
                          },
                          expression: "searchTerm"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-list-group",
                    { staticClass: "mt-3" },
                    _vm._l(_vm.selectedItems, function(item) {
                      return _c(
                        "b-list-group-item",
                        { key: item.id },
                        [
                          _c(
                            "ItpBox",
                            { attrs: { alignItems: "start" } },
                            [
                              _c("InvoiceReferenceItem", {
                                attrs: { item: item, "no-link": "" }
                              }),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "close ml-auto mr-1",
                                  attrs: {
                                    "data-dismiss": "modal",
                                    "aria-label": "Close"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.evh_6049458597007392_click(
                                        $event,
                                        { item: item }
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { "aria-hidden": "true" } },
                                    [_vm._v("×")]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("pgettext")(
                    "Add references",
                    "InvoiceReferenceSearch/Submit"
                  ),
                  variant: "primary",
                  icon: "fa-save",
                  spinning:
                    _vm.actions.submitReferences &&
                    _vm.actions.submitReferences.isRunning,
                  disabled: !_vm.selectedItems.length
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1548630417156826_clickPreventStop($event, {})
                  }
                }
              }),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("gettext")("Cancel"),
                  variant: "default",
                  disabled:
                    (_vm.actions.search && _vm.actions.search.isRunning) ||
                    (_vm.actions.submitReferences &&
                      _vm.actions.submitReferences.isRunning)
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1419464017721962_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }