var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "SensorDataBrowser",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c(
            "VueSlickgrid",
            {
              ref: "grid",
              staticClass: "page--grid",
              attrs: {
                name: "autoReadings",
                headerTitle: _vm._f("pgettext")(
                  "Auto Readings",
                  "MeterSensorDataBrowser"
                ),
                items: _vm.$fn.slickDataSource(
                  "integro_api/list_auto_meter_readings",
                  { parameters: _vm.getQueryParameters }
                ),
                "export-to-csv": "",
                exportToCsvFilename: _vm._f("pgettext")(
                  "meter-sensor-data",
                  "ExportFilename"
                ),
                "checkbox-row-selection": "",
                columnDefaults: _vm.b_2248226175642056_columnDefaults,
                columns: _vm.b_2248226175642056_columns
              },
              on: {
                selectedRowsChanged: function($event) {
                  return _vm.evh_2248226175642056_selectedRowsChanged(
                    $event,
                    {}
                  )
                }
              }
            },
            [
              _c(
                "DIV",
                {
                  staticClass: "flex-grow-1 d-flex",
                  attrs: { slot: "toolbar" },
                  slot: "toolbar"
                },
                [
                  _c("ItpFormDatetime", {
                    staticClass: "w-auto mx-2",
                    attrs: { name: "time_to", size: "xs" },
                    model: {
                      value: _vm.timeTo,
                      callback: function($$v) {
                        _vm.timeTo = $$v
                      },
                      expression: "timeTo"
                    }
                  }),
                  _vm._v(" "),
                  _c("JobHandlerControl", {
                    attrs: {
                      size: "md",
                      jobType: "create_meter_readings",
                      "load-current": "",
                      payload: function() {
                        return _vm.onCreateMeterReadingsPayload()
                      },
                      icon: "material-symbols:cloud-sync-outline-rounded",
                      text: _vm._f("pgettext")(
                        "Create readings",
                        "SensorDataBrowser|Button"
                      ),
                      disabled: !_vm.selectedItems || !_vm.selectedItems.length
                    },
                    on: {
                      completed: function($event) {
                        return _vm.evh_8320016629450276_completed($event, {})
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }