var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ItpWidgetCard",
        { staticClass: "mb-3" },
        [
          _c(
            "ItpBox",
            { attrs: { slot: "header", alignItems: "center" }, slot: "header" },
            [
              _c("strong", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm._f("pgettext")("Selling prices", "ProductPrices")
                    ) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("ItpButton", {
                staticClass: "ml-auto",
                attrs: {
                  text: _vm._f("pgettext")("Add price...", "ProductPrices"),
                  variant: "light",
                  size: "sm"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_3343864469950044_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.loaded
            ? _c(
                "BListGroup",
                [
                  !_vm.sellingPrices.length
                    ? _c("BListGroupItem", [
                        _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm._f("pgettext")(
                                  "No prices.",
                                  "ProductPrices"
                                )
                              ) +
                              "\n        "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.sellingPrices, function(item) {
                    return _c(
                      "BListGroupItem",
                      { key: item.id, staticClass: "d-flex flex-column" },
                      [
                        _c(
                          "ItpBox",
                          { attrs: { alignItems: "start" } },
                          [
                            _c("ItpIcon", {
                              attrs: {
                                icon: item.is_purchase_price
                                  ? "icons8:buy"
                                  : "material-symbols:sell-outline"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "ItpBox",
                              {
                                staticClass: "w-100",
                                attrs: { direction: "column" }
                              },
                              [
                                _c(
                                  "ItpBox",
                                  { staticClass: "W-100" },
                                  [
                                    _c(
                                      "ItpBox",
                                      { staticClass: "col-md-3" },
                                      [
                                        _c(
                                          "ItpText",
                                          {
                                            staticClass: "font-weight-bold",
                                            attrs: {
                                              strikethrough: item.inactive
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(item.price) +
                                                " " +
                                                _vm._s(item.currency) +
                                                "\n                "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        item.quantity_from
                                          ? _c(
                                              "ItpBadge",
                                              {
                                                staticClass: "mx-3 badge-icon",
                                                attrs: {
                                                  title: _vm._f("pgettext")(
                                                    "From quantity",
                                                    "ProductPrice"
                                                  ),
                                                  "no-box-shadow": "",
                                                  variant: "light"
                                                }
                                              },
                                              [
                                                _c("ItpIcon", {
                                                  attrs: {
                                                    name:
                                                      "fa-solid:greater-than-equal"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("ItpText", {
                                                  attrs: {
                                                    text: item.quantity_from
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item.is_discount_price
                                          ? _c(
                                              "ItpBadge",
                                              {
                                                staticClass: "mx-3 badge-icon",
                                                attrs: {
                                                  title: _vm._f("pgettext")(
                                                    "Discount price",
                                                    "ProductPrice"
                                                  ),
                                                  "no-box-shadow": "",
                                                  variant: "warning"
                                                }
                                              },
                                              [
                                                _c("ItpIcon", {
                                                  attrs: {
                                                    name: "mdi:discount-outline"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "ItpBox",
                                      { staticClass: "col-md-3" },
                                      [
                                        _c("UcValidityPeriod", {
                                          staticClass: "pl-3",
                                          attrs: {
                                            from: item.valid_from,
                                            to: item.valid_to
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "ItpBox",
                                      {
                                        staticClass: "col-md-6",
                                        attrs: {
                                          alignItems: "start",
                                          "wrap-items": ""
                                        }
                                      },
                                      [
                                        item.partner
                                          ? _c("UcCompactClientDisplay", {
                                              staticClass: "pl-3",
                                              attrs: { payload: item.partner }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("ItpBox", { staticClass: "W-100" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-3" },
                                    [
                                      item.note
                                        ? _c("ItpText", {
                                            attrs: { text: item.note, pre: "" }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("ItpButton", {
                              staticClass: "ml-auto",
                              attrs: {
                                icon: "fa-pen",
                                tooltip: _vm._f("gettext")("Edit"),
                                variant: "light",
                                size: "sm"
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.evh_8855792642156263_clickPreventStop(
                                    $event,
                                    { item: item }
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpWidgetCard",
        [
          _c(
            "ItpBox",
            { attrs: { slot: "header", alignItems: "center" }, slot: "header" },
            [
              _c("strong", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm._f("pgettext")("Purchase prices", "ProductPrices")
                    ) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("ItpButton", {
                staticClass: "ml-auto",
                attrs: {
                  text: _vm._f("pgettext")("Add price...", "ProductPrices"),
                  variant: "light",
                  size: "sm"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_302930856768608_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.loaded
            ? _c(
                "BListGroup",
                [
                  !_vm.purchasePrices.length
                    ? _c("BListGroupItem", [
                        _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm._f("pgettext")(
                                  "No prices.",
                                  "ProductPrices"
                                )
                              ) +
                              "\n        "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.purchasePrices, function(item) {
                    return _c(
                      "BListGroupItem",
                      { key: item.id, staticClass: "d-flex flex-column" },
                      [
                        _c(
                          "ItpBox",
                          { attrs: { alignItems: "start" } },
                          [
                            _c("ItpIcon", {
                              attrs: {
                                icon: item.is_purchase_price
                                  ? "icons8:buy"
                                  : "material-symbols:sell-outline"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "ItpBox",
                              {
                                staticClass: "w-100",
                                attrs: { direction: "column" }
                              },
                              [
                                _c(
                                  "ItpBox",
                                  { staticClass: "W-100" },
                                  [
                                    _c(
                                      "ItpBox",
                                      { staticClass: "col-md-3" },
                                      [
                                        _c(
                                          "ItpText",
                                          {
                                            staticClass: "font-weight-bold",
                                            attrs: {
                                              strikethrough: item.inactive
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(item.price) +
                                                " " +
                                                _vm._s(item.currency) +
                                                "\n                "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        item.quantity_from
                                          ? _c(
                                              "ItpBadge",
                                              {
                                                staticClass: "mx-3 badge-icon",
                                                attrs: {
                                                  title: _vm._f("pgettext")(
                                                    "From quantity",
                                                    "ProductPrice"
                                                  ),
                                                  "no-box-shadow": "",
                                                  variant: "light"
                                                }
                                              },
                                              [
                                                _c("ItpIcon", {
                                                  attrs: {
                                                    name:
                                                      "fa-solid:greater-than-equal"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("ItpText", {
                                                  attrs: {
                                                    text: item.quantity_from
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item.is_discount_price
                                          ? _c(
                                              "ItpBadge",
                                              {
                                                staticClass: "mx-3 badge-icon",
                                                attrs: {
                                                  title: _vm._f("pgettext")(
                                                    "Discount price",
                                                    "ProductPrice"
                                                  ),
                                                  "no-box-shadow": "",
                                                  variant: "warning"
                                                }
                                              },
                                              [
                                                _c("ItpIcon", {
                                                  attrs: {
                                                    name: "mdi:discount-outline"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "ItpBox",
                                      { staticClass: "col-md-3" },
                                      [
                                        _c("UcValidityPeriod", {
                                          staticClass: "pl-3",
                                          attrs: {
                                            from: item.valid_from,
                                            to: item.valid_to
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "ItpBox",
                                      {
                                        staticClass: "col-md-6",
                                        attrs: {
                                          alignItems: "start",
                                          "wrap-items": ""
                                        }
                                      },
                                      [
                                        item.partner
                                          ? _c("UcCompactClientDisplay", {
                                              staticClass: "pl-3",
                                              attrs: { payload: item.partner }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("ItpBox", { staticClass: "W-100" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-3" },
                                    [
                                      item.note
                                        ? _c("ItpText", {
                                            attrs: { text: item.note, pre: "" }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("ItpButton", {
                              staticClass: "ml-auto",
                              attrs: {
                                icon: "fa-pen",
                                tooltip: _vm._f("gettext")("Edit"),
                                variant: "light",
                                size: "sm"
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.evh_7972369138378646_clickPreventStop(
                                    $event,
                                    { item: item }
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }