var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpWidgetCard",
        [
          _c(
            "ItpBox",
            { attrs: { slot: "header", alignItems: "center" }, slot: "header" },
            [
              _c("strong", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("pgettext")("Waste Bin", "FixedAsset")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              !_vm.readonly
                ? _c("ItpButton", {
                    staticClass: "ml-auto",
                    attrs: {
                      text: _vm._f("gettext")("Edit..."),
                      variant: "light",
                      size: "sm"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_8320016629450276_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "BListGroup",
            [
              _vm.fixedAsset.waste_bin.bin_type
                ? _c(
                    "ItpLabeledListGroupItem",
                    {
                      attrs: {
                        label: _vm._f("pgettext")("Type", "FixedAssetWasteBin")
                      }
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("pgettext")(
                                _vm.fixedAsset.waste_bin.bin_type,
                                "BinTypes",
                                "StartCase"
                              )
                            ) +
                            "\n        "
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.fixedAsset.waste_bin.bin_number != null
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Number", "FixedAssetWasteBin"),
                      content: _vm._f("number")(
                        _vm.fixedAsset.waste_bin.bin_number
                      )
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.fixedAsset.waste_bin.capacity != null
                ? _c(
                    "ItpLabeledListGroupItem",
                    {
                      attrs: {
                        label: _vm._f("pgettext")(
                          "Capacity",
                          "FixedAssetWasteBin"
                        )
                      }
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.fixedAsset.waste_bin.capacity.amount) +
                            " " +
                            _vm._s(_vm.fixedAsset.waste_bin.capacity.unit) +
                            "\n\n        "
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "ItpLabeledListGroupItem",
                {
                  attrs: {
                    label: _vm._f("pgettext")("Label", "FixedAssetWasteBin")
                  }
                },
                [
                  _c("ItpButton", {
                    attrs: {
                      text: _vm._f("pgettext")("Display label...", "Button"),
                      href: _vm.$fn.urlFor("render_label", {
                        id: _vm.fixedAsset.id
                      }),
                      "use-auth": "",
                      size: "sm",
                      icon: "ri:barcode-box-line"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }