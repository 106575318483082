var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      !!_vm.noLegend
        ? _c("legend", [
            _vm._v(
              "\n    " +
                _vm._s(_vm._f("pgettext")("Lessor", "Contract")) +
                "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "company",
            label: _vm._f("pgettext")("Company", "Contract"),
            labelColsMd: "4",
            contentColsMd: "8",
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormSelect2", {
            attrs: {
              name: "company",
              "bind-object": "",
              dataSource: _vm.b_1040167445267876_dataSource,
              textField: "name",
              required: "",
              optionTemplate:
                "<UcSuggestClientOption :option=option></UcSuggestClientOption>",
              "use-option-template-for-selected-option": ""
            },
            model: {
              value: _vm.contract.company,
              callback: function($$v) {
                _vm.$set(_vm.contract, "company", $$v)
              },
              expression: "contract.company"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.contract.company
        ? _c(
            "ItpFormGroup",
            {
              attrs: {
                name: "head_office",
                label: _vm._f("pgettext")("Head office", "Company"),
                labelColsMd: "4",
                contentColsMd: "8",
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { name: "head_office" },
                model: {
                  value: _vm.contract.company.head_office,
                  callback: function($$v) {
                    _vm.$set(_vm.contract.company, "head_office", $$v)
                  },
                  expression: "contract.company.head_office"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.contract.company
        ? _c(
            "ItpFormGroup",
            {
              attrs: {
                name: "branch_office",
                label: _vm._f("pgettext")("Branch office", "Company"),
                labelColsMd: "4",
                contentColsMd: "8",
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { name: "branch_office" },
                model: {
                  value: _vm.contract.company.branch_office,
                  callback: function($$v) {
                    _vm.$set(_vm.contract.company, "branch_office", $$v)
                  },
                  expression: "contract.company.branch_office"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.contract.company
        ? _c(
            "ItpFormGroup",
            {
              attrs: {
                name: "statistical_code",
                label: _vm._f("pgettext")("Statistical code", "Company"),
                labelColsMd: "4",
                contentColsMd: "4",
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { name: "statistical_code" },
                model: {
                  value: _vm.contract.company.statistical_code,
                  callback: function($$v) {
                    _vm.$set(_vm.contract.company, "statistical_code", $$v)
                  },
                  expression: "contract.company.statistical_code"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.contract.company
        ? _c(
            "ItpFormGroup",
            {
              attrs: {
                name: "registration_number",
                label: _vm._f("pgettext")("Registration number", "Company"),
                labelColsMd: "4",
                contentColsMd: "4",
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { name: "registration_number" },
                model: {
                  value: _vm.contract.company.registration_number,
                  callback: function($$v) {
                    _vm.$set(_vm.contract.company, "registration_number", $$v)
                  },
                  expression: "contract.company.registration_number"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.contract.company
        ? _c(
            "ItpFormGroup",
            {
              attrs: {
                name: "account_number",
                label: _vm._f("pgettext")("Account number", "Company"),
                labelColsMd: "4",
                contentColsMd: "8",
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { name: "account_number" },
                model: {
                  value: _vm.contract.company.account_number,
                  callback: function($$v) {
                    _vm.$set(_vm.contract.company, "account_number", $$v)
                  },
                  expression: "contract.company.account_number"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.contract.company
        ? _c(
            "ItpFormGroup",
            {
              attrs: {
                name: "representative",
                label: _vm._f("pgettext")("Representative", "Company"),
                labelColsMd: "4",
                contentColsMd: "8",
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormTextarea", {
                attrs: { name: "representative" },
                model: {
                  value: _vm.contract.company.representative,
                  callback: function($$v) {
                    _vm.$set(_vm.contract.company, "representative", $$v)
                  },
                  expression: "contract.company.representative"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }