var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpModal",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "WAttachmentModal"
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "ItpForm",
                {
                  ref: "mainForm",
                  attrs: { id: _vm.formId, name: "attachment" },
                  on: {
                    submit: function($event) {
                      return _vm.evh_1040167445267876_submit($event, {})
                    }
                  }
                },
                [
                  !_vm.attachment
                    ? _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            name: "file",
                            label: _vm._f("pgettext")("File", "Attachment"),
                            labelFor: "file",
                            required: ""
                          }
                        },
                        [
                          _c("ItpFormFile", {
                            attrs: {
                              autofocus: !_vm.attachment,
                              name: "file",
                              required: "",
                              placeholder: _vm._f("gettext")(
                                "Choose a file or drop it here"
                              ),
                              "drop-placeholder": _vm._f("gettext")(
                                "Drop file here"
                              ),
                              lang: "hu",
                              validation: _vm.b_7789867021953041_validation
                            },
                            model: {
                              value: _vm.file,
                              callback: function($$v) {
                                _vm.file = $$v
                              },
                              expression: "file"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "description",
                        label: _vm._f("gettext")("Description")
                      }
                    },
                    [
                      _c("ItpFormTextarea", {
                        attrs: {
                          name: "description",
                          autofocus: !!_vm.attachment
                        },
                        model: {
                          value: _vm.item.description,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "description", $$v)
                          },
                          expression: "item.description"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("pgettext")("Save", "Button"),
                  disabled:
                    !_vm.forms.attachment || !_vm.forms.attachment.submittable,
                  type: "submit",
                  form: _vm.formId,
                  variant: "primary",
                  icon: "fa-save",
                  spinning: _vm.actions.save.isRunning
                }
              }),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: { text: _vm._f("gettext")("Close"), variant: "default" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1419464017721962_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }