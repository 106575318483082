var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpModal",
    {
      attrs: {
        compact: "",
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "FileSelectorModal"
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "b-tabs",
            { attrs: { card: "" } },
            [
              _c(
                "b-tab",
                { attrs: { title: _vm._f("gettext")("Upload") } },
                [
                  _c(
                    "b-card-body",
                    [
                      _c(
                        "DIV",
                        { staticClass: "row mb-3" },
                        [
                          _c("b-form-file", {
                            staticClass: "col mr-3",
                            attrs: {
                              multiple: _vm.multiple,
                              placeholder: _vm._f("gettext")(
                                "Choose a file or drop it here"
                              ),
                              "drop-placeholder": _vm._f("gettext")(
                                "Drop file here"
                              ),
                              lang: "hu"
                            },
                            model: {
                              value: _vm.selectedFiles,
                              callback: function($$v) {
                                _vm.selectedFiles = $$v
                              },
                              expression: "selectedFiles"
                            }
                          }),
                          _vm._v(" "),
                          _c("ItpButton", {
                            staticClass: "col-auto",
                            attrs: {
                              disabled: !_vm.selectedFiles,
                              text: _vm._f("gettext")("Upload"),
                              icon: "fa-upload",
                              spinning: _vm.actions.submit.isRunning
                            },
                            on: {
                              click: function($event) {
                                return _vm.evh_7786837829063213_click(
                                  $event,
                                  {}
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-tab",
                { attrs: { title: _vm._f("gettext")("Scanned Documents") } },
                [
                  _c(
                    "b-card-body",
                    [
                      _c("DIV", { staticClass: "row mb-3" }, [
                        _c("p", { staticClass: "text-center w-100" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm._f("gettext")(
                                  "There are no documents in the scan folder."
                                )
                              ) +
                              "\n            "
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }