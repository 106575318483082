var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ItpDataTable", {
    attrs: {
      tableId: "ProductPrices",
      editable: "",
      paging: "",
      value: _vm._.orderBy(
        _vm.items,
        ["valid_from", "valid_to", "created_at"],
        ["desc", "desc", "desc"]
      ),
      rowTemplate: _vm.schemaDefaults("CreateClientAddress", {
        part_id: _vm.product.id,
        valid_from: new Date().toLocaleDateString()
      }),
      columns: _vm.b_7315092382398562_columns
    },
    on: {
      save: function($event) {
        return _vm.evh_7315092382398562_save($event, {})
      },
      delete: function($event) {
        return _vm.evh_7315092382398562_delete($event, {})
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }