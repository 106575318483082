var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DIV",
    [
      _vm.option.meter_type
        ? _c("UcMeterTypeIcon", {
            attrs: {
              type: _vm.option.meter_type,
              color: _vm.option.inactive
                ? _vm.$config.colors.inactiveRed
                : _vm.$config.colors.activeGreen
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("ItpText", {
        attrs: { text: _vm.option.serial_number, spacing: "mx-1" }
      }),
      _vm._v(" "),
      _c("ItpText", { attrs: { text: _vm.option.deveui, spacing: "mx-1" } }),
      _vm._v(" "),
      _c("ItpText", { attrs: { text: _vm.option.tag_id, spacing: "mx-1" } }),
      _vm._v(" "),
      _c("ItpText", {
        attrs: {
          text: _vm.option.model_name,
          small: "",
          muted: "",
          spacing: "mx-1"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }