var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpSelect2",
    {
      attrs: {
        name: _vm.name,
        textField: "description",
        filterable: _vm.b_7315092382398562_filterable,
        searchable: _vm.b_7315092382398562_searchable,
        options: _vm.items,
        readonly: _vm.readonly
      },
      on: {
        input: function($event) {
          return _vm.evh_7315092382398562_input($event, {})
        }
      },
      model: {
        value: _vm.internalValue,
        callback: function($$v) {
          _vm.internalValue = $$v
        },
        expression: "internalValue"
      }
    },
    [
      _c(
        "template",
        { slot: "list-footer" },
        [
          _c("HR"),
          _vm._v(" "),
          _c("ItpLink", {
            staticClass: "dropdown-item",
            attrs: { text: _vm._f("gettext")("Add new...") },
            on: {
              click: function($event) {
                return _vm.evh_3343864469950044_click($event, {})
              }
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }