var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "InvoiceRegister",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c(
            "VueSlickgrid",
            {
              ref: "grid",
              staticClass: "page--grid",
              attrs: {
                name: "invoiceRegister",
                headerTitle: _vm._f("pgettext")(
                  "Invoice register",
                  "Invoice register"
                ),
                items: _vm.$fn.slickDataSource(
                  "list_invoice_register_entries",
                  { parameters: _vm.getQueryParameters }
                ),
                "export-to-csv": "",
                exportToCsvFilename: _vm._f("pgettext")(
                  "invoice-register",
                  "filename"
                ),
                "footer-totals": "",
                "checkbox-row-selection": "",
                columnDefaults: _vm.b_2248226175642056_columnDefaults,
                columns: _vm.b_2248226175642056_columns
              },
              on: {
                command: function($event) {
                  return _vm.evh_2248226175642056_command($event, {})
                },
                selectedRowsChanged: function($event) {
                  return _vm.evh_2248226175642056_selectedRowsChanged(
                    $event,
                    {}
                  )
                }
              }
            },
            [
              _c(
                "DIV",
                {
                  staticClass: "flex-grow-1 d-flex",
                  attrs: { slot: "toolbar" },
                  slot: "toolbar"
                },
                [
                  _c("ItpFormSelect2", {
                    staticClass: "w-25 mx-3",
                    attrs: {
                      name: "register_name",
                      "bind-object": "",
                      valueField: "id",
                      textField: "name",
                      options: _vm.registers
                    },
                    on: {
                      input: function($event) {
                        return _vm.evh_6466705138206980_input($event, {})
                      }
                    },
                    model: {
                      value: _vm.selectedRegister,
                      callback: function($$v) {
                        _vm.selectedRegister = $$v
                      },
                      expression: "selectedRegister"
                    }
                  }),
                  _vm._v(" "),
                  _c("ItpButton", {
                    staticClass: "mx-2",
                    attrs: {
                      icon: _vm.$config.invoiceRegister.createEntry,
                      text: _vm._f("pgettext")(
                        "Add entry...",
                        "InvoiceRegister"
                      ),
                      size: "sm",
                      variant: "light"
                    },
                    on: {
                      click: function($event) {
                        return _vm.evh_8320016629450276_click($event, {})
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.statuses && _vm.statuses.length
                    ? _c(
                        "b-dropdown",
                        {
                          staticClass: "mx-2",
                          attrs: {
                            size: "sm",
                            variant: "light",
                            "toggle-class": "itp-button border",
                            tooltip: _vm._f("pgettext")(
                              "Set status to selected items",
                              "InvoiceRegister"
                            ),
                            disabled:
                              !_vm.selectedItems ||
                              !_vm.selectedItems.length ||
                              (_vm.actions.setStatus &&
                                _vm.actions.setStatus.isRunning)
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "button-content" },
                              slot: "button-content"
                            },
                            [
                              _vm.actions.setStatus.isRunning
                                ? _c("b-spinner", {
                                    staticClass: "mr-1",
                                    attrs: { small: "", type: "grow" }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("ItpText", {
                                staticClass: "mx-1",
                                attrs: {
                                  text: _vm._f("pgettext")(
                                    "Set status",
                                    "InvoiceRegister"
                                  )
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.statuses, function(status) {
                            return _c(
                              "b-dropdown-item-button",
                              {
                                key: status,
                                attrs: {
                                  disabled:
                                    !_vm.selectedItems ||
                                    !_vm.selectedItems.length
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.evh_5382094220310827_clickPreventStop(
                                      $event,
                                      { status: status }
                                    )
                                  }
                                }
                              },
                              [_c("ItpText", { attrs: { text: status } })],
                              1
                            )
                          }),
                          _vm._v(" "),
                          _c("hr"),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item-button",
                            {
                              attrs: {
                                disabled:
                                  !_vm.selectedItems ||
                                  !_vm.selectedItems.length
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.evh_8704926098283402_clickPreventStop(
                                    $event,
                                    {}
                                  )
                                }
                              }
                            },
                            [
                              _c("ItpText", {
                                attrs: {
                                  text: _vm._f("pgettext")(
                                    "Clear status",
                                    "InvoiceRegister"
                                  )
                                }
                              })
                            ],
                            1
                          )
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }