var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "ProductPrice",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "ItpForm",
                {
                  attrs: {
                    name: _vm.formName,
                    schema:
                      _vm.oid == null
                        ? "CreateProductPrice"
                        : "UpdateProductPrice",
                    id: _vm.formId
                  },
                  on: {
                    submit: function($event) {
                      return _vm.evh_1040167445267876_submit($event, {})
                    }
                  }
                },
                [
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "price",
                        label: _vm._f("pgettext")("Price", "ProductPrice"),
                        labelColsMd: "3",
                        contentColsMd: "9",
                        labelAlign: _vm.labelAlign,
                        contentClass: "input-group"
                      }
                    },
                    [
                      _c("ItpFormInput", {
                        staticClass: "col-4",
                        attrs: { type: "number", name: "price", required: "" },
                        model: {
                          value: _vm.item.price,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "price", $$v)
                          },
                          expression: "item.price"
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpFormSelect", {
                        staticClass: "col-4",
                        attrs: {
                          name: "currency",
                          options: _vm.currencies,
                          textField: "code",
                          valueField: "code",
                          required: ""
                        },
                        model: {
                          value: _vm.item.currency,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "currency", $$v)
                          },
                          expression: "item.currency"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "quantity_from",
                        label: _vm._f("pgettext")(
                          "From quantity",
                          "ProductPrice"
                        ),
                        labelColsMd: "3",
                        contentColsMd: "9",
                        labelAlign: _vm.labelAlign,
                        contentClass: "input-group"
                      }
                    },
                    [
                      _c("ItpFormInput", {
                        staticClass: "col-4",
                        attrs: { type: "number", name: "quantity_from" },
                        model: {
                          value: _vm.item.quantity,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "quantity", $$v)
                          },
                          expression: "item.quantity"
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpFormInput", {
                        staticClass: "col-4",
                        attrs: {
                          name: "quantity_from_unit",
                          required: "",
                          readonly: ""
                        },
                        model: {
                          value: _vm.product.unit,
                          callback: function($$v) {
                            _vm.$set(_vm.product, "unit", $$v)
                          },
                          expression: "product.unit"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "valid_from",
                        label: _vm._f("pgettext")("Validity", "ProductPrice"),
                        labelColsMd: "3",
                        contentColsMd: "9",
                        labelAlign: _vm.labelAlign,
                        contentClass: "form-row"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c("ItpFormDatetime", {
                            attrs: {
                              type: "date",
                              name: "valid_from",
                              placeholder: _vm._f("pgettext")(
                                "Valid from",
                                "InputPlaceholder"
                              ),
                              readonly: _vm.isReadOnly,
                              required: ""
                            },
                            model: {
                              value: _vm.item.valid_from,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "valid_from", $$v)
                              },
                              expression: "item.valid_from"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c("ItpFormDatetime", {
                            attrs: {
                              type: "date",
                              name: "valid_to",
                              placeholder: _vm._f("pgettext")(
                                "Valid to",
                                "InputPlaceholder"
                              ),
                              readonly: _vm.isReadOnly
                            },
                            model: {
                              value: _vm.item.valid_to,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "valid_to", $$v)
                              },
                              expression: "item.valid_to"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "meter_id",
                        label: _vm._f("pgettext")("Client", "ProductPrice"),
                        labelColsMd: "3",
                        contentColsMd: "9",
                        labelAlign: _vm.labelAlign
                      }
                    },
                    [
                      _c("ItpFormSelect2", {
                        attrs: {
                          name: "client",
                          dataSource: _vm.b_5074589820235598_dataSource,
                          valueField: "id",
                          textField: "name",
                          required: "",
                          optionTemplate:
                            "<UcSuggestClientOption :option=option></UcSuggestClientOption>",
                          "use-option-template-for-selected-option": ""
                        },
                        model: {
                          value: _vm.item.partner_id,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "partner_id", $$v)
                          },
                          expression: "item.partner_id"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "is_discount_price",
                        label: _vm._f("pgettext")(
                          "Discount price",
                          "ProductPrice"
                        ),
                        labelColsMd: "3",
                        contentColsMd: "9",
                        labelAlign: _vm.labelAlign
                      }
                    },
                    [
                      _c("ItpFormCheckbox", {
                        attrs: {
                          name: "is_discount_price",
                          inline: "",
                          readonly: _vm.isReadOnly
                        },
                        model: {
                          value: _vm.item.is_discount_price,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "is_discount_price", $$v)
                          },
                          expression: "item.is_discount_price"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "inactive",
                        label: _vm._f("pgettext")("Inactive", "ProductPrice"),
                        labelColsMd: "3",
                        contentColsMd: "9",
                        labelAlign: _vm.labelAlign
                      }
                    },
                    [
                      _c("ItpFormCheckbox", {
                        attrs: {
                          name: "inactive",
                          inline: "",
                          readonly: _vm.isReadOnly
                        },
                        model: {
                          value: _vm.item.inactive,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "inactive", $$v)
                          },
                          expression: "item.inactive"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "note",
                        label: _vm._f("pgettext")("Notes", "ProductPrice"),
                        labelColsMd: "3",
                        contentColsMd: "9",
                        labelAlign: _vm.labelAlign
                      }
                    },
                    [
                      _c("ItpFormTextarea", {
                        attrs: { name: "note", readonly: _vm.isReadOnly },
                        model: {
                          value: _vm.item.note,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "note", $$v)
                          },
                          expression: "item.note"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _vm.oid && !_vm.item.locked
                ? _c(
                    "ItpBox",
                    { staticClass: "mr-auto" },
                    [
                      _c("BtnDeleteResource", {
                        attrs: {
                          resource: _vm.item,
                          disabled: _vm.actions.submit.isRunning
                        },
                        on: {
                          success: function($event) {
                            return _vm.evh_2140198529698299_success($event, {})
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("BtnLockResource", {
                        staticClass: "ml-3",
                        attrs: {
                          resource: _vm.item,
                          disabled: _vm.actions.submit.isRunning
                        },
                        on: {
                          success: function($event) {
                            return _vm.evh_2434514588044777_success($event, {})
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.item.locked
                ? _c("ItpButton", {
                    attrs: {
                      text: _vm._f("pgettext")("Save", "ModalButton"),
                      type: "submit",
                      form: _vm.formId,
                      variant: "primary",
                      icon: "fa-save",
                      spinning: _vm.actions.submit.isRunning,
                      disabled:
                        !_vm.forms[_vm.formName] ||
                        !_vm.forms[_vm.formName].submittable
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("pgettext")("Close", "ModalButton"),
                  variant: "default",
                  disabled: _vm.actions.submit.isRunning
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_2186679009640457_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }