var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "RentalProperty",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "ItpForm",
                {
                  staticClass: "container",
                  attrs: {
                    name: _vm.formName,
                    schema: "RentalProperty",
                    id: _vm.formId
                  },
                  on: {
                    submit: function($event) {
                      return _vm.evh_1040167445267876_submit($event, {})
                    }
                  }
                },
                [
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "property_id",
                        label: _vm._f("gettext")("Real Property")
                      }
                    },
                    [
                      _c("ItpFormSelect2", {
                        attrs: {
                          name: "property_id",
                          dataSource: _vm.b_7789867021953041_dataSource,
                          initialOptions: [_vm.item.property],
                          valueField: "id",
                          textField: "name",
                          readonly: _vm.item.locked
                        },
                        model: {
                          value: _vm.item.property_unit_id,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "property_unit_id", $$v)
                          },
                          expression: "item.property_unit_id"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      staticClass: "w-auto",
                      attrs: {
                        name: "valid_from",
                        label: _vm._f("gettext")("Valid from")
                      }
                    },
                    [
                      _c("ItpFormDatetime", {
                        attrs: {
                          type: "date",
                          name: "valid_from",
                          readonly: _vm.item.locked,
                          defaultValue: _vm.$fn.today()
                        },
                        model: {
                          value: _vm.item.valid_from,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "valid_from", $$v)
                          },
                          expression: "item.valid_from"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      staticClass: "w-auto",
                      attrs: {
                        name: "valid_to",
                        label: _vm._f("gettext")("Valid to")
                      }
                    },
                    [
                      _c("ItpFormDatetime", {
                        attrs: {
                          type: "date",
                          name: "valid_to",
                          readonly: _vm.item.locked
                        },
                        model: {
                          value: _vm.item.valid_to,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "valid_to", $$v)
                          },
                          expression: "item.valid_to"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    { attrs: { name: "inactive" } },
                    [
                      _c("ItpFormCheckbox", {
                        attrs: {
                          name: "inactive",
                          label: _vm._f("gettext")("Inactive"),
                          inline: "",
                          readonly: _vm.item.locked
                        },
                        model: {
                          value: _vm.item.inactive,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "inactive", $$v)
                          },
                          expression: "item.inactive"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "notes",
                        label: _vm._f("gettext")("Notes")
                      }
                    },
                    [
                      _c("ItpFormTextarea", {
                        attrs: { name: "notes", readonly: _vm.item.locked },
                        model: {
                          value: _vm.item.notes,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "notes", $$v)
                          },
                          expression: "item.notes"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _vm.resourceId && !_vm.item.locked
                ? _c("ItpButton", {
                    staticClass: "mr-auto",
                    attrs: {
                      text: _vm._f("gettext")("Lock"),
                      variant: "default",
                      spinning: _vm.actions.lock.isRunning
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_1548630417156826_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.item.locked
                ? _c("ItpButton", {
                    attrs: {
                      form: _vm.formId,
                      text: _vm._f("gettext")("Save"),
                      type: "submit",
                      variant: "primary",
                      spinning: _vm.actions.submit.isRunning,
                      disabled:
                        !_vm.forms[_vm.formName] ||
                        !_vm.forms[_vm.formName].submittable
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("gettext")("Close"),
                  variant: "default",
                  disabled: _vm.actions.submit.isRunning
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_2186679009640457_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }