import FieldsetLetterRegisterEntry from './FieldsetLetterRegisterEntry.yaml?component';
import LetterRegister from './LetterRegister.yaml?component';
import LetterRegisterEntry from './LetterRegisterEntry.yaml?component';
import LetterRegisterEntryHeader from './LetterRegisterEntryHeader.yaml?component';

export {
  FieldsetLetterRegisterEntry,
  LetterRegister,
  LetterRegisterEntry,
  LetterRegisterEntryHeader
};
