var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        "loading-spinner": "",
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpWidgetCard",
        [
          _c(
            "ItpBox",
            { attrs: { slot: "header", alignItems: "center" }, slot: "header" },
            [
              _c("strong", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm._f("pgettext")("Properties", "RentalFormProperties")
                    ) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("ItpButton", {
                staticClass: "ml-auto",
                attrs: {
                  text: _vm._f("gettext")("Add property..."),
                  size: "sm",
                  variant: "light"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_8320016629450276_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.loaded
            ? _c(
                "BListGroup",
                [
                  !_vm.items.length
                    ? _c(
                        "BListGroupItem",
                        { staticClass: "card-body text-center" },
                        [
                          _c("span", [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm._f("pgettext")(
                                    "No properties.",
                                    "RentalFormProperties"
                                  )
                                ) +
                                "\n        "
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.items, function(item) {
                    return _c(
                      "BListGroupItem",
                      { key: item.id, staticClass: "d-flex flex-column" },
                      [
                        _c(
                          "DIV",
                          { staticClass: "w-100" },
                          [
                            _c(
                              "DIV",
                              { staticClass: "d-flex flex-row" },
                              [
                                _c("DIV", { staticClass: "px-2" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.property_unit.full_name) +
                                      "\n            "
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "DIV",
                                  {
                                    staticClass: "px-2",
                                    attrs: { id: item.property_unit.unit_type }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.property_unit.unit_type) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "DIV",
                              { staticClass: "d-flex flex-row my-1" },
                              [
                                _c("DIV", { staticClass: "px-2" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.valid_from) +
                                      "\n            "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("DIV", { staticClass: "px-2" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.valid_to) +
                                      "\n            "
                                  )
                                ])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "DIV",
                              { staticClass: "d-flex flex-row" },
                              [
                                item.locked
                                  ? _c(
                                      "ItpBadge",
                                      {
                                        staticClass: "mx-2 shadow-none",
                                        attrs: { variant: "secondary" }
                                      },
                                      [
                                        _c("ItpIcon", {
                                          staticClass: "px-2",
                                          attrs: { fa: "lock" }
                                        }),
                                        _vm._v(" "),
                                        _c("SPAN", [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm._f("gettext")("Locked")
                                              ) +
                                              "\n              "
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                item.inactive
                                  ? _c("ItpBadge", {
                                      staticClass: "mx-2 shadow-none",
                                      attrs: {
                                        text: _vm._f("gettext")("Inactive"),
                                        variant: "warning"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "DIV",
                          { staticClass: "ml-2 d-flex flex-row" },
                          [
                            _vm.editable
                              ? _c("ItpButton", {
                                  attrs: {
                                    variant: "default",
                                    size: "sm",
                                    icon: "fa-edit"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.evh_6853569216922577_clickPreventStop(
                                        $event,
                                        { item: item }
                                      )
                                    }
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.editable && !item.locked
                              ? _c("ItpButton", {
                                  attrs: {
                                    variant: "default",
                                    size: "sm",
                                    icon: "fa-trash"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.evh_6492569393195423_clickPreventStop(
                                        $event,
                                        { item: item }
                                      )
                                    }
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }