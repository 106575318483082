var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "JobModal",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "DIV",
                { staticClass: "row" },
                [
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        "cols-md": "3",
                        name: "status",
                        label: _vm._f("pgettext")("Status", "Job")
                      }
                    },
                    [
                      _c("ItpFormInput", {
                        attrs: { name: "status", size: "md", readonly: "" },
                        model: {
                          value: _vm.item.status,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "status", $$v)
                          },
                          expression: "item.status"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        "cols-md": "3",
                        name: "job_type",
                        label: _vm._f("pgettext")("Job type", "Job")
                      }
                    },
                    [
                      _c("ItpFormInput", {
                        attrs: { name: "job_type", size: "md", readonly: "" },
                        model: {
                          value: _vm.item.job_type,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "job_type", $$v)
                          },
                          expression: "item.job_type"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        "cols-md": "3",
                        name: "created_at",
                        label: _vm._f("gettext")("Created at")
                      }
                    },
                    [
                      _c("ItpFormInput", {
                        attrs: { name: "created_at", size: "md", readonly: "" },
                        model: {
                          value: _vm.item.created_at,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "created_at", $$v)
                          },
                          expression: "item.created_at"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        "cols-md": "3",
                        name: "created_by",
                        label: _vm._f("gettext")("Created by")
                      }
                    },
                    [
                      _c("ItpFormInput", {
                        attrs: {
                          name: "created_by",
                          size: "md",
                          model: _vm.b_5074589820235598_model,
                          readonly: ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("HR"),
              _vm._v(" "),
              _c(
                "DIV",
                [
                  _c("label", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("gettext")("Job data")) +
                        "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c("ItpJSONToTable", {
                    attrs: { truncateText: "200", json: _vm.item.job_data }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("HR"),
              _vm._v(" "),
              _c(
                "DIV",
                [
                  _c("label", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("gettext")("Job result")) +
                        "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c("ItpJSONToTable", {
                    attrs: { truncateText: "200", json: _vm.item.job_result }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "DIV",
                { staticClass: "modal-footer" },
                [
                  _vm.item.status == "Errored"
                    ? _c("ItpButton", {
                        staticClass: "mr-auto",
                        attrs: {
                          text: _vm._f("gettext")("Restart"),
                          variant: "danger",
                          spinning: _vm.actions.restart.isRunning
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.evh_2140198529698299_clickPreventStop(
                              $event,
                              {}
                            )
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }