var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "JobsV2",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _c(
        "VueSlickgrid",
        {
          ref: "grid",
          staticClass: "page--grid",
          attrs: {
            name: "jobs-v2",
            headerTitle: _vm._f("pgettext")("Jobs", "Jobs"),
            items: _vm.$fn.slickDataSource("browse_a_job"),
            "checkbox-row-selection": "",
            columnDefaults: _vm.b_2248226175642056_columnDefaults,
            columns: _vm.b_2248226175642056_columns
          },
          on: {
            selectedRowsChanged: function($event) {
              return _vm.evh_2248226175642056_selectedRowsChanged($event, {})
            },
            command: function($event) {
              return _vm.evh_2248226175642056_command($event, {})
            }
          }
        },
        [
          _c(
            "DIV",
            {
              staticClass: "flex-grow-1 d-flex",
              attrs: { slot: "toolbar" },
              slot: "toolbar"
            },
            [_c("PageHelp", { attrs: { path: "/general/jobs.html" } })],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }