var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _vm.loaded && !_vm.loading && !_vm.stats
        ? _c(
            "DIV",
            [
              _c(
                "ItpAlert",
                { staticClass: "col", attrs: { show: "" } },
                [
                  _c("ItpIcon", {
                    staticClass: "mr-1",
                    attrs: { name: "fa-info-circle" }
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("pgettext")(
                            "No data is available for the selected device.",
                            "MeterChartsAlert"
                          )
                        ) +
                        "\n      "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded && _vm.stats
        ? _c(
            "DIV",
            { staticClass: "container float-left" },
            [
              _c(
                "ItpRow",
                { staticClass: "mb-4" },
                [
                  _c(
                    "ItpWidgetCard",
                    { staticClass: "col" },
                    [
                      _c("ItpUplot", {
                        attrs: {
                          slot: "content",
                          chartData: _vm.stats.readings,
                          options: _vm.b_7789867021953041_options
                        },
                        slot: "content"
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpWidgetCard",
                    { staticClass: "col" },
                    [
                      _c("ItpUplot", {
                        attrs: {
                          slot: "content",
                          chartData: _vm.stats.consumptions,
                          options: _vm.b_2070266589801014_options
                        },
                        slot: "content"
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("ItpDataTable", {
                staticClass: "mb-4 shadow-sm",
                attrs: {
                  value: _vm.stats.tableData,
                  editable: _vm.b_4186377094414664_editable,
                  paging: "",
                  tableId: "meterAutoReadingStats",
                  columns: _vm.b_4186377094414664_columns
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }