var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c("legend", { staticClass: "text-muted" }, [
        _vm._v(
          "\n    " + _vm._s(_vm._f("pgettext")("General", "Rental")) + "\n  "
        )
      ]),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "4",
            name: "name",
            label: _vm._f("pgettext")("Name", "Rental"),
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "name", required: "", autofocus: "" },
            model: {
              value: _vm.rental.name,
              callback: function($$v) {
                _vm.$set(_vm.rental, "name", $$v)
              },
              expression: "rental.name"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "description",
            label: _vm._f("pgettext")("Description", "Rental"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormTextarea", {
            attrs: { name: "description" },
            model: {
              value: _vm.rental.description,
              callback: function($$v) {
                _vm.$set(_vm.rental, "description", $$v)
              },
              expression: "rental.description"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "description",
            label: _vm._f("pgettext")("Company", "Rental"),
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormSelect2", {
            attrs: {
              name: "company_id",
              dataSource: _vm.b_6917646200545441_dataSource,
              valueField: "id",
              textField: "name",
              required: ""
            },
            model: {
              value: _vm.rental.company_id,
              callback: function($$v) {
                _vm.$set(_vm.rental, "company_id", $$v)
              },
              expression: "rental.company_id"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "description",
            label: _vm._f("pgettext")("Site", "Rental"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormSelect2", {
            attrs: {
              name: "site_id",
              dataSource: _vm.b_6424600534600827_dataSource,
              initialOptions: [_vm.rental.site],
              valueField: "id",
              optionTemplate: _vm.b_6424600534600827_optionTemplate,
              "use-option-template-for-selected-option": ""
            },
            model: {
              value: _vm.rental.site_id,
              callback: function($$v) {
                _vm.$set(_vm.rental, "site_id", $$v)
              },
              expression: "rental.site_id"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "location_code",
            label: _vm._f("pgettext")("Location", "Rental"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "location_code" },
            model: {
              value: _vm.rental.location_code,
              callback: function($$v) {
                _vm.$set(_vm.rental, "location_code", $$v)
              },
              expression: "rental.location_code"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            name: "lot_number",
            label: _vm._f("pgettext")("Lot number", "Rental"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "lot_number" },
            model: {
              value: _vm.rental.lot_number,
              callback: function($$v) {
                _vm.$set(_vm.rental, "lot_number", $$v)
              },
              expression: "rental.lot_number"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            name: "house_number",
            label: _vm._f("pgettext")("Building number", "Rental"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "house_number" },
            model: {
              value: _vm.rental.house_number,
              callback: function($$v) {
                _vm.$set(_vm.rental, "house_number", $$v)
              },
              expression: "rental.house_number"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            label: _vm._f("pgettext")("Area", "Rental"),
            labelAlign: _vm.labelAlign,
            contentClass: "input-group"
          }
        },
        [
          _c("ItpFormInput", {
            staticClass: "col-8",
            attrs: { type: "number", name: "area" },
            model: {
              value: _vm.rental.area,
              callback: function($$v) {
                _vm.$set(_vm.rental, "area", $$v)
              },
              expression: "rental.area"
            }
          }),
          _vm._v(" "),
          _c("ItpFormSelect", {
            staticClass: "col-4",
            attrs: { name: "unit_of_area", options: _vm.units },
            model: {
              value: _vm.rental.unit_of_area,
              callback: function($$v) {
                _vm.$set(_vm.rental, "unit_of_area", $$v)
              },
              expression: "rental.unit_of_area"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("FieldsetRentalValidityData", {
        attrs: { rental: _vm.rental, labelAlign: _vm.labelAlign }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }