var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ItpButton", {
    attrs: {
      dropdownItem: _vm.dropdownItem,
      text: _vm.noText ? null : _vm.messages.text,
      icon: _vm.noIcon ? null : "fa-lock",
      variant: _vm.dropdownItem ? "default" : "light",
      spinning: _vm.actions.lock.isRunning,
      disabled: _vm.disabled,
      size: _vm.size,
      tooltip: _vm.tooltip || _vm.messages.tooltip
    },
    on: {
      click: function($event) {
        $event.preventDefault()
        $event.stopPropagation()
        return _vm.evh_7315092382398562_clickPreventStop($event, {})
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }