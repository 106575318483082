<template>
<address
  v-if="client"
>
  <div v-if="client.address">{{ client.address.postal_code }} {{ client.address.city }}</div>
<div v-if="client.address">{{ client.address.address }}</div>
{{ 'Tax Number'|gettext }}: {{ client.tax_number }}<br/>
{{ 'Account Number'|gettext }}: {{ client.account_number }}

</address>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcInvoiceClientPanel extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  client!: object;

  @Watch('client')
  onClient(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("client")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  actions!: {
  }

  getActions() {
    return {
    }
  }
}

Vue.component("UcInvoiceClientPanel", UcInvoiceClientPanel);

</script>