var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "Templates",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _c(
        "DIV",
        { staticClass: "overflow-hidden h-100 d-flex" },
        [
          _c(
            "ItpSidebar",
            { staticClass: "mr-3", attrs: { "default-width": "400" } },
            [
              _c(
                "DIV",
                { staticClass: "mb-2 w-100 d-flex align-items-center" },
                [
                  _c(
                    "DIV",
                    { staticClass: "flex-grow-1" },
                    [
                      _c("strong", { staticClass: "text-muted mr-2" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm._f("pgettext")("Template", "Templates")
                            ) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("PageHelp", {
                        attrs: { path: "/general/templates.html" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("ItpButton", {
                    attrs: {
                      icon: "fa-plus",
                      text: _vm._f("pgettext")(
                        "Create template...",
                        "Templates"
                      ),
                      variant: "secondary",
                      size: "sm"
                    },
                    on: {
                      click: function($event) {
                        return _vm.evh_2095089031469157_click($event, {})
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-list-group",
                _vm._l(_vm.items, function(item) {
                  return _c("router-link", {
                    key: item.id,
                    staticClass: "py-2",
                    attrs: {
                      to: {
                        name: "app.templates.template",
                        params: {
                          id: item.id
                        }
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var href = ref.href
                            var route = ref.route
                            var navigate = ref.navigate
                            var isActive = ref.isActive
                            var isExactActive = ref.isExactActive
                            return [
                              _c(
                                "b-list-group-item",
                                {
                                  class: {
                                    "font-weight-bold": isActive,
                                    "overflow-hidden": true
                                  }
                                },
                                [
                                  _c(
                                    "ItpBox",
                                    [
                                      _c(
                                        "DIV",
                                        { staticClass: "w-100 mr-2" },
                                        [
                                          _c(
                                            "ItpBox",
                                            { staticClass: "w-100" },
                                            [
                                              _c(
                                                "b-link",
                                                {
                                                  staticClass: "text-truncate",
                                                  attrs: { href: href },
                                                  on: { click: navigate }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(item.name) +
                                                      "\n                  "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "DIV",
                                            [
                                              _c("ItpText", {
                                                attrs: {
                                                  text: item.object_type
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-badge",
                                        {
                                          class: {
                                            "badge-alt": true,
                                            "badge-primary":
                                              item.template_type === "html",
                                            "badge-mjml":
                                              item.template_type === "mjml",
                                            "ml-auto": true,
                                            "align-self-center": true,
                                            "mx-3": true
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.template_type) +
                                              "\n              "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "close ml-auto",
                                          attrs: {
                                            type: "button",
                                            "aria-label": _vm._f("pgettext")(
                                              "Delete",
                                              "Templates"
                                            )
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.evh_6545969026712024_click(
                                                $event,
                                                {
                                                  href: href,
                                                  route: route,
                                                  navigate: navigate,
                                                  isActive: isActive,
                                                  isExactActive: isExactActive
                                                }
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { "aria-hidden": "true" }
                                            },
                                            [_vm._v("×")]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("router-view", { staticClass: "h-100 w-100 overflow-hidden" })
        ],
        1
      ),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }