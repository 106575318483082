var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "Claims",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c(
            "VueSlickgrid",
            {
              ref: "grid",
              staticClass: "page--grid",
              attrs: {
                name: "claims",
                headerTitle: _vm.documentTitle,
                items: _vm.$fn.slickDataSource("list_claims"),
                "checkbox-row-selection": "",
                "export-to-csv": "",
                exportToCsvFilename: _vm._f("pgettext")(
                  "claims",
                  "ExportFilename"
                ),
                "footer-totals": "",
                columnDefaults: _vm.b_2248226175642056_columnDefaults,
                columns: _vm.b_2248226175642056_columns
              },
              on: {
                selectedRowsChanged: function($event) {
                  return _vm.evh_2248226175642056_selectedRowsChanged(
                    $event,
                    {}
                  )
                },
                command: function($event) {
                  return _vm.evh_2248226175642056_command($event, {})
                }
              }
            },
            [
              _c(
                "DIV",
                {
                  staticClass: "flex-grow-1 d-flex",
                  attrs: { slot: "toolbar" },
                  slot: "toolbar"
                },
                [
                  _c("ItpButton", {
                    staticClass: "mx-2",
                    attrs: {
                      icon: "fa-cog",
                      text: _vm._f("gettext")("Create dunning letters..."),
                      size: "sm",
                      disabled: !_vm.selectedItems || !_vm.selectedItems.length,
                      variant: "primary"
                    },
                    on: {
                      click: function($event) {
                        return _vm.evh_6466705138206980_click($event, {})
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("PageHelp", { attrs: { path: "/claims/claims.html" } })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }