var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "WorksheetList",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _c(
        "VueSlickgrid",
        {
          ref: "grid",
          staticClass: "page--grid",
          attrs: {
            name: "worksheets",
            headerTitle: _vm._f("gettext")("Worksheets"),
            items: _vm.$fn.slickDataSource("integro_api/list_worksheet"),
            "checkbox-row-selection": "",
            columnDefaults: _vm.b_2248226175642056_columnDefaults,
            columns: _vm.b_2248226175642056_columns
          },
          on: {
            selectedRowsChanged: function($event) {
              return _vm.evh_2248226175642056_selectedRowsChanged($event, {})
            }
          }
        },
        [
          _c(
            "DIV",
            {
              staticClass: "flex-grow-1 d-flex",
              attrs: { slot: "toolbar" },
              slot: "toolbar"
            },
            [
              _c("ItpButton", {
                staticClass: "mx-2",
                attrs: {
                  icon: _vm.$config.worksheet.createWorksheetIcon,
                  text: _vm._f("gettext")("New worksheet..."),
                  size: "sm",
                  variant: "light"
                },
                on: {
                  click: function($event) {
                    return _vm.evh_6466705138206980_click($event, {})
                  }
                }
              }),
              _vm._v(" "),
              _c("ItpButton", {
                staticClass: "mx-2",
                attrs: {
                  icon: "fa-trash",
                  text: _vm._f("gettext")("Delete"),
                  size: "sm",
                  variant: "light",
                  disabled: !_vm.selectedItems || !_vm.selectedItems.length,
                  spinning: _vm.actions.deleteItems.isRunning
                },
                on: {
                  click: function($event) {
                    return _vm.evh_8320016629450276_click($event, {})
                  }
                }
              }),
              _vm._v(" "),
              _c("PageHelp", { attrs: { path: "/worksheets/worksheets.html" } })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }