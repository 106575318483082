<template>
<span
  :title="bindings.tooltip"
  v-if="bindings"
>
  <ItpIcon
    :name="bindings.icon"
    :color="bindings.color"
  >
  </ItpIcon>
</span>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcPaymentTransactionTypeSymbol extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: String,
  })
  paymentType!: string;

  @Watch('paymentType')
  onPayment_type(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("paymentType")
  }

  transactionTypes!: any;
  ux!: any;
  dataMembers = ['transactionTypes', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        transactionTypes: {
          credit: {
            icon: "zondicons:arrow-outline-down",
            tooltip: this.$fn.pgettext("PaymentType", "credit"),
            color: "green",
          }
          ,
          debit: {
            icon: "zondicons:arrow-outline-up",
            tooltip: this.$fn.pgettext("PaymentType", "debit"),
            color: "red",
          }
          ,
          deposit: {
            icon: "zondicons:arrow-outline-down",
            tooltip: this.$fn.pgettext("PaymentType", "deposit"),
            color: "green",
          }
          ,
          payment: {
            icon: "zondicons:arrow-outline-up",
            tooltip: this.$fn.pgettext("PaymentType", "payment"),
            color: "red",
          }
          ,
        }
        ,
        ux: null,
      },
    }
  }

  get bindings() {
    if (this.loaded) {

      return this.transactionTypes[this.paymentType]
    }
    return null;
  }

  actions!: {
  }

  getActions() {
    return {
    }
  }
}

Vue.component("UcPaymentTransactionTypeSymbol", UcPaymentTransactionTypeSymbol);

</script>