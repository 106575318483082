var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "ItpBox",
        { staticClass: "w-100", attrs: { alignItems: "center" } },
        [
          _c("ItpText", {
            staticClass: "mb-0 mx-2",
            attrs: { muted: "", text: _vm.resourceTitle }
          }),
          _vm._v(" "),
          _c("ItpText", {
            staticClass: "mb-0 mx-2",
            attrs: { tag: "h5", text: _vm.resourceKey }
          }),
          _vm._v(" "),
          _c("UcJobNameBadge", {
            staticClass: "mx-2",
            attrs: { value: _vm.item.name }
          }),
          _vm._v(" "),
          _c("UcJobStatusBadge", {
            staticClass: "mx-2",
            attrs: { value: _vm.item.status }
          }),
          _vm._v(" "),
          _c("ItpText", { staticClass: "mx-2", attrs: { strong: "" } }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm._f("datetime")(_vm.item.submit_time)) +
                "\n  "
            )
          ]),
          _vm._v(" "),
          _c(
            "ItpText",
            {
              staticClass: "mx-2 text-truncate",
              style: _vm.b_1382594685840889_style,
              attrs: { strong: "", "no-wrap": "" }
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(_vm._f("username")(_vm.item.created_by)) +
                  "\n  "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "b-button-group",
            { staticClass: "ml-auto" },
            [
              _c("ItpButton", {
                staticClass: "ml-auto",
                attrs: {
                  icon: "fa-sync-alt",
                  tooltip: _vm._f("gettext")("Reload"),
                  spinning: _vm.actions.reload.isRunning,
                  variant: "light"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_2754826507016131_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-dropdown",
            { staticClass: "border", attrs: { right: "", variant: "light" } },
            [
              _c("ItpIcon", {
                staticClass: "mx-1",
                attrs: { slot: "button-content", fa: "bars" },
                slot: "button-content"
              }),
              _vm._v(" "),
              _vm.item.links.retry
                ? _c("ItpButton", {
                    staticClass: "mr-2",
                    attrs: {
                      "dropdown-item": "",
                      text: _vm._f("pgettext")("Retry", "Job"),
                      size: "sm"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_7463771596263925_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.item.links.cancel
                ? _c("ItpButton", {
                    staticClass: "mr-2",
                    attrs: {
                      "dropdown-item": "",
                      text: _vm._f("pgettext")("Cancel", "Job"),
                      size: "sm"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_7763705103899431_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }