import FieldsetFixedAssetGeneral from './FieldsetFixedAssetGeneral.yaml?component';
import FieldsetFixedAssetWasteBin from './FieldsetFixedAssetWasteBin.yaml?component';
import UcFixedAssetGeneral from './UcFixedAssetGeneral.yaml?component';
import UcFixedAssetRentalCard from './UcFixedAssetRentalCard.yaml?component';
import UcFixedAssetWasteBin from './UcFixedAssetWasteBin.yaml?component';
import UcFixedAssetWorkItemsPanel from './UcFixedAssetWorkItemsPanel.yaml?component';

export {
  FieldsetFixedAssetGeneral,
  FieldsetFixedAssetWasteBin,
  UcFixedAssetGeneral,
  UcFixedAssetRentalCard,
  UcFixedAssetWasteBin,
  UcFixedAssetWorkItemsPanel
};
