var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpWidgetCard",
        [
          _c(
            "ItpBox",
            { attrs: { slot: "header", alignItems: "center" }, slot: "header" },
            [
              _c("strong", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("pgettext")("General", "FixedAsset")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              !_vm.readonly
                ? _c("ItpButton", {
                    staticClass: "ml-auto",
                    attrs: {
                      text: _vm._f("gettext")("Edit..."),
                      variant: "light",
                      size: "sm"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_8320016629450276_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "BListGroup",
            [
              _c(
                "ItpLabeledListGroupItem",
                {
                  attrs: {
                    label: _vm._f("pgettext")("Asset Type", "FixedAsset")
                  }
                },
                [
                  _c("UcFixedAssetTypeBadge", {
                    attrs: { value: _vm.fixedAsset.asset_type }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.fixedAsset.name
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Name", "FixedAsset"),
                      content: _vm.fixedAsset.name
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.fixedAsset.description
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Description", "FixedAsset"),
                      content: _vm.fixedAsset.description
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.fixedAsset.inventory_number
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")(
                        "Inventory Number",
                        "FixedAsset"
                      ),
                      content: _vm.fixedAsset.inventory_number
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "ItpLabeledListGroupItem",
                {
                  attrs: { label: _vm._f("pgettext")("Rental", "FixedAsset") }
                },
                [
                  _c("UcFixedAssetRentalCard", {
                    attrs: { fixedAsset: _vm.fixedAsset },
                    on: {
                      reload: function($event) {
                        return _vm.evh_8352907821006950_reload($event, {})
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.fixedAsset.sku
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("SKU", "FixedAsset"),
                      content: _vm.fixedAsset.sku
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.fixedAsset.type_no
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Type Number", "FixedAsset"),
                      content: _vm.fixedAsset.type_no
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.fixedAsset.quantity
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Quantity", "FixedAsset"),
                      content: _vm._f("number")(_vm.fixedAsset.quantity)
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.fixedAsset.cost
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Cost", "FixedAsset"),
                      content: _vm._f("number")(_vm.fixedAsset.cost)
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.fixedAsset.gl_code
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")(
                        "General Ledger Code",
                        "FixedAsset"
                      ),
                      content: _vm.fixedAsset.gl_code
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.fixedAsset.year_of_production
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")(
                        "Year of Production",
                        "FixedAsset"
                      ),
                      content: _vm.fixedAsset.year_of_production
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.fixedAsset.manufacturer
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Manufacturer", "FixedAsset"),
                      content: _vm.fixedAsset.manufacturer
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.fixedAsset.serial_number
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Serial Number", "FixedAsset"),
                      content: _vm.fixedAsset.serial_number
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.fixedAsset.category
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Category", "FixedAsset"),
                      content: _vm.fixedAsset.category
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.fixedAsset.groups
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Groups", "FixedAsset"),
                      content: _vm.fixedAsset.groups
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }