var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c(
            "ItpBox",
            { attrs: { alignItems: "center" } },
            [
              _c("ItpFormSelect", {
                staticClass: "mx-3 w-auto",
                attrs: {
                  name: "date_type",
                  placeholder: _vm._f("pgettext")(
                    "date filter type",
                    "Invoices"
                  ),
                  options: _vm.b_8564662037462133_options
                },
                model: {
                  value: _vm.item.date_type,
                  callback: function($$v) {
                    _vm.$set(_vm.item, "date_type", $$v)
                  },
                  expression: "item.date_type"
                }
              }),
              _vm._v(" "),
              _c(
                "DateRangeSelector",
                {
                  attrs: {
                    name: "invoiceFilters",
                    from: _vm.item.date_from,
                    to: _vm.item.date_to
                  },
                  on: {
                    changed: function($event) {
                      return _vm.evh_3343864469950044_changed($event, {})
                    }
                  }
                },
                [
                  _c("ItpText", {
                    attrs: { text: _vm._f("pgettext")("Unpaid", "Invoices") }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormCheckbox",
                {
                  staticClass: "mx-3",
                  attrs: { name: "unpaid", inline: "" },
                  model: {
                    value: _vm.item.unpaid,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "unpaid", $$v)
                    },
                    expression: "item.unpaid"
                  }
                },
                [
                  _c("ItpText", {
                    attrs: { text: _vm._f("pgettext")("Unpaid", "Invoices") }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("ItpButton", {
                staticClass: "mx-2",
                attrs: {
                  icon: _vm.$config.reloadIcon,
                  text: _vm._f("gettext")("Load"),
                  variant: "light"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_4892266742011185_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }