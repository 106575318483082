var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c(
            "ItpBox",
            [
              _c("DateRangeSelector", {
                staticClass: "mr-2",
                style: _vm.b_8564662037462133_style,
                attrs: {
                  name: "paymentFilters",
                  from: _vm.item.date_from,
                  to: _vm.item.date_to
                },
                on: {
                  changed: function($event) {
                    return _vm.evh_8564662037462133_changed($event, {})
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "DIV",
                [
                  _c("ItpButton", {
                    attrs: {
                      variant: "light",
                      icon: _vm.$config.reloadIcon,
                      title: _vm._f("pgettext")(
                        "Load data",
                        "WorkSummaryViewFilters"
                      ),
                      spinning:
                        _vm.actions &&
                        _vm.actions.load &&
                        _vm.actions.load.isRunning
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_3943151158818434_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }