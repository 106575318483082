var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "b-dropdown",
        { attrs: { size: "sm", "toggle-class": "btn-light border" } },
        [
          _c(
            "template",
            { slot: "button-content" },
            [
              _c("UcContractRevisionDropdownItem", {
                attrs: { revisionInfo: _vm.selectedRevision }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.contract.revisions.length > 1 ? _c("hr") : _vm._e(),
          _vm._v(" "),
          _vm._l(
            _vm._.orderBy(_vm.contract.revisions, ["revision_number"]),
            function(revision) {
              return _c(
                "b-dropdown-item-button",
                {
                  key: revision.revision_number,
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.evh_8358196963355853_clickPreventStop($event, {
                        revision: revision
                      })
                    }
                  }
                },
                [
                  _c("UcContractRevisionDropdownItem", {
                    attrs: {
                      revisionInfo: revision,
                      isSelected:
                        _vm.contract.revision_number == revision.revision_number
                    }
                  })
                ],
                1
              )
            }
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c(
            "b-dropdown-item-button",
            {
              on: {
                click: function($event) {
                  return _vm.evh_8356612450665535_click($event, {})
                }
              }
            },
            [
              _c("ItpText", {
                attrs: {
                  text: _vm._f("pgettext")("Create revision...", "Contract")
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.contract.metadata.can_delete_revision
            ? _c(
                "b-dropdown-item-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.evh_1382594685840889_click($event, {})
                    }
                  }
                },
                [
                  _c("ItpText", {
                    attrs: {
                      text: _vm._f("pgettext")("Delete revision", "Contract")
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }