var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "ItpBox",
        { staticClass: "items-spacing w-100", attrs: { alignItems: "center" } },
        [
          _c(
            "ItpText",
            {
              staticClass: "mb-0",
              attrs: { tag: "h5", capitalize: "", muted: "" }
            },
            [_vm._v("\n    " + _vm._s(_vm.resourceTitle) + "\n  ")]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "m-0 p-0" }, [_vm._v("\n    ·\n  ")]),
          _vm._v(" "),
          _c("ItpText", {
            staticClass: "mb-0",
            attrs: { tag: "h4", text: _vm.resourceKey }
          }),
          _vm._v(" "),
          _vm.readonly
            ? _c(
                "DIV",
                { attrs: { title: _vm._f("gettext")("Closed") } },
                [_c("ItpIcon", { attrs: { icon: _vm.$config.lockIcon } })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "DIV",
            { staticClass: "btn-toolbar ml-auto" },
            [
              !_vm.isNew && !_vm.worksheet.isReadOnly
                ? _c("ItpButton", {
                    staticClass: "ml-auto mr-2",
                    attrs: {
                      icon: "material-symbols:edit-outline",
                      tooltip: _vm._f("gettext")("Edit"),
                      variant: "light"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_6424600534600827_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isNew
                ? _c("ItpButton", {
                    staticClass: "ml-auto mr-2",
                    attrs: {
                      icon: "fa-sync-alt",
                      tooltip: _vm._f("gettext")("Reload"),
                      spinning: _vm.actions.reload.isRunning,
                      variant: "light"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_8249915319319009_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.worksheet.preview_url
                ? _c("ItpLink", {
                    attrs: {
                      icon: "bxs:file-pdf",
                      href: _vm.worksheet.preview_url,
                      tooltip: "Open PDF",
                      target: "_blank",
                      "use-auth": "",
                      button: "",
                      variant: "light",
                      "show-spinner": "",
                      "no-download-link": "",
                      text: _vm._f("gettext")("Preview")
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isNew
                ? _c(
                    "b-dropdown",
                    {
                      staticClass: "border",
                      attrs: { right: "", variant: "light", "no-caret": "" }
                    },
                    [
                      _c("ItpIcon", {
                        staticClass: "mx-1",
                        attrs: { slot: "button-content", fa: "bars" },
                        slot: "button-content"
                      }),
                      _vm._v(" "),
                      _c("ItpButton", {
                        attrs: {
                          "dropdown-item": "",
                          text: _vm._f("pgettext")("Copy", "BtnCopyResource"),
                          tooltip: _vm._f("pgettext")(
                            "Copy worksheet",
                            "Worksheet"
                          ),
                          variant: "default"
                        }
                      }),
                      _vm._v(" "),
                      !_vm.isNew
                        ? _c("BtnDeleteResource", {
                            attrs: {
                              "no-icon": "",
                              "dropdown-item": "",
                              resource: _vm.worksheet,
                              tooltip: _vm._f("pgettext")(
                                "Delete worksheet",
                                "Worksheet"
                              )
                            },
                            on: {
                              success: function($event) {
                                return _vm.evh_1890060294221449_success(
                                  $event,
                                  {}
                                )
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c("ItpButton", {
                        attrs: {
                          "dropdown-item": "",
                          text: _vm._f("pgettext")(
                            "History",
                            "BtnResourceHistory"
                          ),
                          tooltip: _vm._f("pgettext")(
                            "Worksheet history",
                            "Worksheet"
                          ),
                          variant: "default"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }