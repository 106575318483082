var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "h-100", attrs: { "no-body": "" } },
    [
      _c(
        "b-tabs",
        {
          staticClass: "h-100 d-flex flex-column",
          attrs: { card: "", "content-class": "flex-fill overflow-hidden" }
        },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }