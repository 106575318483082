var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpWidgetCard",
        [
          _c(
            "ItpBox",
            { attrs: { slot: "header", alignItems: "center" }, slot: "header" },
            [
              _c("strong", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("pgettext")("General", "Product")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("ItpButton", {
                staticClass: "ml-auto",
                attrs: {
                  text: _vm._f("gettext")("Edit..."),
                  variant: "light",
                  size: "sm"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_8320016629450276_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "BListGroup",
            { attrs: { flush: "" } },
            [
              _vm.product.product_type
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Type", "Product"),
                      content: _vm._f("pgettext")(
                        _vm.product.product_type,
                        "ProductTypes",
                        "Sentence"
                      )
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("ItpLabeledListGroupItem", {
                attrs: {
                  label: _vm._f("pgettext")("Number", "Product"),
                  content: _vm.product.number
                }
              }),
              _vm._v(" "),
              _c("ItpLabeledListGroupItem", {
                attrs: {
                  label: _vm._f("pgettext")("Name", "Product"),
                  content: _vm.product.name
                }
              }),
              _vm._v(" "),
              _vm.product.description
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Description", "Product"),
                      content: _vm.product.description
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.product.unit
                ? _c(
                    "ItpLabeledListGroupItem",
                    { attrs: { label: _vm._f("pgettext")("Unit", "Product") } },
                    [
                      _c("ItpText", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.product.unit) +
                            "\n        "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "ItpLabeledListGroupItem",
                {
                  attrs: { label: _vm._f("pgettext")("VAT codes", "Product") }
                },
                [
                  _c(
                    "DIV",
                    [
                      _c("ItpText", { attrs: { muted: "" } }, [
                        _vm._v(
                          _vm._s(_vm._f("pgettext")("Sales VAT", "Product")) +
                            ":"
                        )
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.product.sales_vat_code) +
                          "\n\n        "
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "DIV",
                    [
                      _c("ItpText", { attrs: { muted: "" } }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("pgettext")("Purchase VAT", "Product")
                          ) + ":"
                        )
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.product.purchase_vat_code) +
                          "\n\n        "
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.product.invoice_group_id
                ? _c(
                    "ItpLabeledListGroupItem",
                    {
                      attrs: {
                        label: _vm._f("pgettext")("Invoice group", "Product")
                      }
                    },
                    [
                      _c("UcInvoiceGroup", {
                        attrs: { groupId: _vm.product.invoice_group_id }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.product.intermediated_service
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")(
                        "Intermediated service",
                        "Product"
                      ),
                      content: _vm._f("gettext")("Yes")
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.product.customs_tariff_no
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")(
                        "Customs tariff no.",
                        "Product"
                      ),
                      content: _vm.product.customs_tariff_no
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.product.hu_teszor_code
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("TESZOR code (HU)", "Product"),
                      content: _vm.product.hu_teszor_code
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.product.eu_ewc_code
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("EWC code (EU)", "Product"),
                      content: _vm.product.eu_ewc_code
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.product.external_key
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("External key", "Product"),
                      content: _vm.product.external_key
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }