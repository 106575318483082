<template>
<table
  class="ml-auto"
  v-if="invoice.totals"
>
  <tbody>
    <tr>
      <td
        class="pr-4"
      >
        {{ 'Taxable subtotal'|pgettext('Invoice') }}
      </td>
      <td
        class="text-right"
      >
        <span>
          {{ invoice.totals.subtotal | money(invoice.currency, currency.digits) }}
        </span>
      </td>
      <td
        class="text-right pl-4"
        v-if="invoice.exchanged_totals && exchangedCurrency"
      >
        <ItpText
          small
        >
          {{ invoice.exchanged_totals.subtotal | money(invoice.exchanged_currency, exchangedCurrency.digits) }}
        </ItpText>
      </td>
    </tr>
    <tr
      v-for="(tax, index) in invoice.totals.taxes"
    >
      <td
        class="pr-4"
      >
        <ItpText
          :text="'Tax'|gettext"
          small
          muted
        >
        </ItpText>
        <ItpText
          :text="tax.tax_code"
          small
          muted
        >
        </ItpText>
      </td>
      <td
        class="text-right"
      >
        <span>
          {{ tax.amount | money(invoice.currency, currency.digits) }}
        </span>
      </td>
      <td
        class="text-right pl-4"
        v-if="invoice.exchanged_totals && exchangedCurrency"
      >
        <ItpText
          small
        >
          {{ invoice.exchanged_totals.taxes[index].amount | money(invoice.exchanged_currency, exchangedCurrency.digits) }}
        </ItpText>
      </td>
    </tr>
    <tr>
      <td
        class="pr-4"
      >
        <ItpText
          :text="'Total'|gettext"
          strong
        >
        </ItpText>
      </td>
      <td
        class="text-right"
      >
        <strong>
          {{ invoice.totals.total | money(invoice.currency, currency.digits) }}
        </strong>
      </td>
      <td
        class="text-right pl-4"
        v-if="invoice.exchanged_totals && exchangedCurrency"
      >
        <ItpText
          small
        >
          {{ invoice.exchanged_totals.total | money(invoice.exchanged_currency, exchangedCurrency.digits) }}
        </ItpText>
      </td>
    </tr>
  </tbody>
</table>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcInvoiceFooter extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  invoice!: object;

  @Watch('invoice')
  onInvoice(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoice")
  }

  @Prop({
    required: true,
    type: Object,
  })
  currency!: object;

  @Watch('currency')
  onCurrency(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("currency")
  }

  @Prop({
    type: Object,
  })
  exchangedCurrency!: object;

  @Watch('exchangedCurrency')
  onExchanged_currency(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("exchangedCurrency")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  actions!: {
  }

  getActions() {
    return {
    }
  }
}

Vue.component("UcInvoiceFooter", UcInvoiceFooter);

</script>