var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "RentalService",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "ItpForm",
                {
                  attrs: {
                    name: _vm.formName,
                    schema:
                      _vm.oid == null
                        ? "CreateRentalService"
                        : "UpdateRentalService",
                    id: _vm.formId
                  },
                  on: {
                    submit: function($event) {
                      return _vm.evh_1040167445267876_submit($event, {})
                    }
                  }
                },
                [
                  _vm.assignToRental
                    ? _c(
                        "DIV",
                        [
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                labelColsMd: "4",
                                contentColsMd: "8",
                                name: "rental_id",
                                label: _vm._f("pgettext")(
                                  "Rental",
                                  "RentalService"
                                ),
                                required: ""
                              }
                            },
                            [
                              _c("ItpFormSelect2", {
                                attrs: {
                                  name: "rental_id",
                                  initialOptions: _vm.item.rental
                                    ? [_vm.item.rental]
                                    : [],
                                  dataSource: _vm.b_5731695935601903_dataSource,
                                  valueField: "id",
                                  textField: "name",
                                  "use-option-template-for-selected-option": "",
                                  optionTemplate:
                                    '<UcSuggestRentalOption :option="option"></UcSuggestRentalOption>',
                                  required: "",
                                  readonly:
                                    _vm.isReadOnly || _vm.preventEditRental
                                },
                                model: {
                                  value: _vm.item.rental_id,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "rental_id", $$v)
                                  },
                                  expression: "item.rental_id"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("hr")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "meter_id",
                        label: _vm._f("pgettext")("Meter", "RentalService"),
                        labelColsMd: "4",
                        contentColsMd: "8",
                        labelAlign: _vm.labelAlign,
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormSelect2", {
                        attrs: {
                          name: "meter_id",
                          dataSource: _vm.b_2070266589801014_dataSource,
                          initialOptions: _vm.item.meter
                            ? [_vm.item.meter]
                            : [],
                          valueField: "id",
                          textField: "serial_number",
                          "use-option-template-for-selected-option": "",
                          optionTemplate:
                            '<UcSuggestMeterOption :option="option"></UcSuggestMeterOption>',
                          required: "",
                          readonly: _vm.preventEditMeter || _vm.isReadOnly
                        },
                        on: {
                          optionSelected: function($event) {
                            return _vm.evh_2070266589801014_optionSelected(
                              $event,
                              {}
                            )
                          }
                        },
                        model: {
                          value: _vm.item.meter_id,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "meter_id", $$v)
                          },
                          expression: "item.meter_id"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "meter_factor",
                        label: _vm._f("pgettext")(
                          "Meter factor",
                          "RentalService"
                        ),
                        labelColsMd: "4",
                        contentColsMd: "4",
                        labelAlign: _vm.labelAlign,
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormInput", {
                        attrs: {
                          type: "number",
                          name: "meter_factor",
                          readonly: _vm.isReadOnly,
                          required: ""
                        },
                        model: {
                          value: _vm.item.meter_factor,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "meter_factor", $$v)
                          },
                          expression: "item.meter_factor"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "service_id",
                        label: _vm._f("pgettext")("Service", "RentalService"),
                        labelColsMd: "4",
                        contentColsMd: "8",
                        labelAlign: _vm.labelAlign,
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormSelect2", {
                        attrs: {
                          name: "service",
                          dataSource: _vm.b_5074589820235598_dataSource,
                          "bind-object": "",
                          valueField: "id",
                          textField: "name",
                          readonly: _vm.isReadOnly,
                          required: ""
                        },
                        on: {
                          optionSelected: function($event) {
                            return _vm.evh_5074589820235598_optionSelected(
                              $event,
                              {}
                            )
                          }
                        },
                        model: {
                          value: _vm.item.service,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "service", $$v)
                          },
                          expression: "item.service"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "billing_unit",
                        label: _vm._f("pgettext")(
                          "Billing unit",
                          "RentalService"
                        ),
                        labelColsMd: "4",
                        contentColsMd: "3",
                        labelAlign: _vm.labelAlign,
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormSelect2", {
                        attrs: {
                          name: "billing_unit",
                          options: _vm.units,
                          valueField: "symbol",
                          textField: "symbol",
                          readonly: _vm.isReadOnly,
                          required: ""
                        },
                        model: {
                          value: _vm.item.billing_unit,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "billing_unit", $$v)
                          },
                          expression: "item.billing_unit"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "non_billable",
                        label: _vm._f("pgettext")(
                          "Non billable",
                          "RentalService"
                        ),
                        labelColsMd: "4",
                        contentColsMd: "8",
                        labelAlign: _vm.labelAlign
                      }
                    },
                    [
                      _c("ItpFormCheckbox", {
                        attrs: {
                          name: "non_billable",
                          inline: "",
                          readonly: _vm.isReadOnly
                        },
                        model: {
                          value: _vm.item.non_billable,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "non_billable", $$v)
                          },
                          expression: "item.non_billable"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "valid_from",
                        label: _vm._f("pgettext")("Validity", "RentalService"),
                        labelColsMd: "4",
                        contentColsMd: "8",
                        labelAlign: _vm.labelAlign,
                        contentClass: "form-row"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c("ItpFormDatetime", {
                            attrs: {
                              type: "date",
                              name: "valid_from",
                              placeholder: _vm._f("pgettext")(
                                "Valid from",
                                "InputPlaceholder"
                              ),
                              readonly: _vm.isReadOnly
                            },
                            model: {
                              value: _vm.item.valid_from,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "valid_from", $$v)
                              },
                              expression: "item.valid_from"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c("ItpFormDatetime", {
                            attrs: {
                              type: "date",
                              name: "valid_to",
                              placeholder: _vm._f("pgettext")(
                                "Valid to",
                                "InputPlaceholder"
                              ),
                              readonly: _vm.isReadOnly
                            },
                            model: {
                              value: _vm.item.valid_to,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "valid_to", $$v)
                              },
                              expression: "item.valid_to"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "inactive",
                        label: _vm._f("pgettext")("Inactive", "RentalService"),
                        labelColsMd: "4",
                        contentColsMd: "8",
                        labelAlign: _vm.labelAlign
                      }
                    },
                    [
                      _c("ItpFormCheckbox", {
                        attrs: {
                          name: "inactive",
                          inline: "",
                          readonly: _vm.isReadOnly
                        },
                        model: {
                          value: _vm.item.inactive,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "inactive", $$v)
                          },
                          expression: "item.inactive"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "notes",
                        label: _vm._f("pgettext")("Notes", "RentalService"),
                        labelColsMd: "4",
                        contentColsMd: "8",
                        labelAlign: _vm.labelAlign
                      }
                    },
                    [
                      _c("ItpFormTextarea", {
                        attrs: { name: "note", readonly: _vm.isReadOnly },
                        model: {
                          value: _vm.item.notes,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "notes", $$v)
                          },
                          expression: "item.notes"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _vm.oid && !_vm.item.locked
                ? _c(
                    "ItpBox",
                    { staticClass: "mr-auto" },
                    [
                      _c("BtnDeleteResource", {
                        attrs: {
                          resource: _vm.item,
                          disabled: _vm.actions.submit.isRunning
                        },
                        on: {
                          success: function($event) {
                            return _vm.evh_2140198529698299_success($event, {})
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("BtnLockResource", {
                        staticClass: "ml-3",
                        attrs: {
                          resource: _vm.item,
                          disabled: _vm.actions.submit.isRunning
                        },
                        on: {
                          success: function($event) {
                            return _vm.evh_2434514588044777_success($event, {})
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.item.locked
                ? _c("ItpButton", {
                    attrs: {
                      text: _vm._f("pgettext")("Save", "ModalButton"),
                      type: "submit",
                      form: _vm.formId,
                      variant: "primary",
                      icon: "fa-save",
                      spinning: _vm.actions.submit.isRunning,
                      disabled:
                        !_vm.forms[_vm.formName] ||
                        !_vm.forms[_vm.formName].submittable
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("pgettext")("Close", "ModalButton"),
                  variant: "default",
                  disabled: _vm.actions.submit.isRunning
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_2186679009640457_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }