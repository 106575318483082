var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "bin_type",
            label: _vm._f("pgettext")("Type", "FixedAssetWasteBin"),
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormSelect2", {
            attrs: {
              name: "groups",
              size: "md",
              options: _vm.binTypes,
              valueField: "id",
              textField: "name",
              required: ""
            },
            model: {
              value: _vm.fixedAsset.waste_bin.bin_type,
              callback: function($$v) {
                _vm.$set(_vm.fixedAsset.waste_bin, "bin_type", $$v)
              },
              expression: "fixedAsset.waste_bin.bin_type"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "4",
            name: "bin_number",
            label: _vm._f("pgettext")("Number", "FixedAssetWasteBin"),
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { type: "number", name: "bin_number", required: "" },
            model: {
              value: _vm.fixedAsset.waste_bin.bin_number,
              callback: function($$v) {
                _vm.$set(_vm.fixedAsset.waste_bin, "bin_number", $$v)
              },
              expression: "fixedAsset.waste_bin.bin_number"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "4",
            name: "capacity",
            label: _vm._f("pgettext")("Capacity", "FixedAssetWasteBin"),
            labelAlign: _vm.labelAlign,
            appendText: _vm._f("pgettext")("liter", "InputAddon"),
            required: ""
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { type: "number", name: "capacity", required: "" },
            model: {
              value: _vm.fixedAsset.waste_bin.capacity.amount,
              callback: function($$v) {
                _vm.$set(_vm.fixedAsset.waste_bin.capacity, "amount", $$v)
              },
              expression: "fixedAsset.waste_bin.capacity.amount"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }