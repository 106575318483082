var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "HandoverProtocol",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c(
            "UcHeader",
            {
              attrs: { slot: "header", card: !_vm.modal, modal: _vm.modal },
              on: {
                close: function($event) {
                  return _vm.evh_2248226175642056_close($event, {})
                }
              },
              slot: "header"
            },
            [
              _c("UcContractProtocolHeader", {
                attrs: { protocol: _vm.item },
                on: {
                  reload: function($event) {
                    return _vm.evh_8564662037462133_reload($event, {})
                  },
                  deleted: function($event) {
                    return _vm.evh_8564662037462133_deleted($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "ItpForm",
                {
                  attrs: { id: _vm.formId, name: _vm.formName },
                  on: {
                    submit: function($event) {
                      return _vm.evh_1040167445267876_submit($event, {})
                    }
                  }
                },
                [
                  _c("FieldsetHandoverProtocol", {
                    attrs: { item: _vm.item, form: _vm.forms[_vm.formName] },
                    on: {
                      reload: function($event) {
                        return _vm.evh_5534025912102772_reload($event, {})
                      },
                      close: function($event) {
                        return _vm.evh_5534025912102772_close($event, {})
                      },
                      changed: function($event) {
                        return _vm.evh_5534025912102772_changed($event, {})
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _vm.loaded &&
              !_vm.item.isNew &&
              _vm.item.protocol_status === "draft"
                ? _c("ItpButton", {
                    attrs: {
                      form: _vm.formId,
                      variant: "warning",
                      icon: "fa-times",
                      text: _vm._f("pgettext")(
                        "Cancel protocol",
                        "HandoverProtocol"
                      ),
                      spinning: _vm.actions.cancel.isRunning
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_1548630417156826_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.loaded &&
              !_vm.item.isNew &&
              _vm.item.protocol_status === "draft"
                ? _c("ItpButton", {
                    staticClass: "ml-auto",
                    attrs: {
                      form: _vm.formId,
                      variant: "success",
                      icon: "fa-save",
                      text: _vm._f("gettext")("Complete"),
                      spinning: _vm.actions.complete.isRunning,
                      disabled:
                        !_vm.forms[_vm.formName] ||
                        !_vm.forms[_vm.formName].submittable
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_1419464017721962_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.loaded && _vm.item.protocol_status === "draft"
                ? _c("ItpButton", {
                    attrs: {
                      type: "submit",
                      form: _vm.formId,
                      variant: "primary",
                      icon: "fa-save",
                      text: _vm._f("gettext")("Save"),
                      spinning: _vm.actions.save.isRunning,
                      disabled:
                        !_vm.forms[_vm.formName] ||
                        !_vm.forms[_vm.formName].submittable
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_2186679009640457_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("ItpButton", {
                staticClass: "ml-3",
                attrs: {
                  variant: "default",
                  text: _vm._f("gettext")("Close"),
                  disabled: _vm.actions.save.isRunning
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_5566617329548203_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }