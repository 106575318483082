import FieldsetContractClientData from './FieldsetContractClientData.yaml?component';
import FieldsetContractCompanyData from './FieldsetContractCompanyData.yaml?component';
import FieldsetContractGeneralData from './FieldsetContractGeneralData.yaml?component';
import FieldsetContractNumbersData from './FieldsetContractNumbersData.yaml?component';
import FieldsetContractRentalData from './FieldsetContractRentalData.yaml?component';
import FieldsetContractValidityData from './FieldsetContractValidityData.yaml?component';
import UcContractClientCard from './UcContractClientCard.yaml?component';
import UcContractCompanyCard from './UcContractCompanyCard.yaml?component';
import UcContractGeneralInfo from './UcContractGeneralInfo.yaml?component';
import UcContractRentalCard from './UcContractRentalCard.yaml?component';
import UcContractRentalServices from './UcContractRentalServices.yaml?component';
import UcContractValidityCard from './UcContractValidityCard.yaml?component';

export {
  FieldsetContractClientData,
  FieldsetContractCompanyData,
  FieldsetContractGeneralData,
  FieldsetContractNumbersData,
  FieldsetContractRentalData,
  FieldsetContractValidityData,
  UcContractClientCard,
  UcContractCompanyCard,
  UcContractGeneralInfo,
  UcContractRentalCard,
  UcContractRentalServices,
  UcContractValidityCard
};
