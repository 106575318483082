var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.bindings
    ? _c(
        "span",
        { attrs: { title: _vm.bindings.tooltip } },
        [
          _c("ItpIcon", {
            attrs: { name: _vm.bindings.icon, color: _vm.bindings.color }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }