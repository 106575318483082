<template>
<ItpBox
  direction="column"
>
  <ItpText
    strong
    :text="item.name"
    spacing="mb-2"
  >
  </ItpText>
  <ItpText
    spacing="mb-2"
    :text="item.job_title"
    v-if="item.job_title"
  >
  </ItpText>
  <ItpBox
    spacing="mb-2"
    alignItems="center"
    v-if="item.phone"
  >
    <Icon
      icon="ic:baseline-phone"
      :title="'Phone Number'|pgettext('Contact')"
      class="mr-2"
    >
    </Icon>
    <ItpText
      :text="item.phone"
    >
    </ItpText>
  </ItpBox>
  <ItpBox
    spacing="mb-2"
    alignItems="center"
    v-if="item.mobile"
  >
    <Icon
      icon="mdi:cellphone-basic"
      :title="'Mobile'|pgettext('Contact')"
      class="mr-2"
    >
    </Icon>
    <ItpText
      :text="item.mobile"
    >
    </ItpText>
  </ItpBox>
  <ItpBox
    spacing="mb-2"
    alignItems="center"
    v-if="item.fax"
  >
    <Icon
      icon="mdi:fax"
      :title="'Fax'|pgettext('Contact')"
      class="mr-2"
    >
    </Icon>
    <ItpText
      :text="item.fax"
    >
    </ItpText>
  </ItpBox>
  <ItpBox
    spacing="mb-2"
    alignItems="center"
    v-if="item.email"
  >
    <Icon
      icon="mdi:at"
      :title="'Email'|pgettext('Contact')"
      class="mr-2"
    >
    </Icon>
    <ItpText
      :text="item.email"
    >
    </ItpText>
  </ItpBox>
  <ItpBox
    v-if="item.notes"
  >
    <ItpText
      :text="item.notes"
      pre
    >
    </ItpText>
  </ItpBox>
</ItpBox>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcEmployeeCard extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  item!: object;

  @Watch('item')
  onItem(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("item")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  actions!: {
  }

  getActions() {
    return {
    }
  }
}

Vue.component("UcEmployeeCard", UcEmployeeCard);

</script>