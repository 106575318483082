var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "CreateTemplate",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "ItpForm",
                {
                  attrs: {
                    name: "template",
                    schema: "CreateTemplate",
                    id: "jrgztyjdyk"
                  },
                  on: {
                    submit: function($event) {
                      return _vm.evh_1040167445267876_submit($event, {})
                    }
                  }
                },
                [
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "name",
                        label: _vm._f("pgettext")("Name", "Templates")
                      }
                    },
                    [
                      _c("ItpFormInput", {
                        attrs: {
                          name: "name",
                          size: "md",
                          required: "",
                          autofocus: "",
                          placeholder: _vm._f("pgettext")(
                            "Name of template",
                            "Templates"
                          )
                        },
                        model: {
                          value: _vm.item.name,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "name", $$v)
                          },
                          expression: "item.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "name",
                        label: _vm._f("pgettext")("Object type", "Templates")
                      }
                    },
                    [
                      _c("ItpFormSelect", {
                        attrs: {
                          name: "name",
                          size: "md",
                          options: _vm.objectTypes,
                          required: ""
                        },
                        model: {
                          value: _vm.item.object_type,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "object_type", $$v)
                          },
                          expression: "item.object_type"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "template_type",
                        label: _vm._f("pgettext")("Template type", "Templates"),
                        description: _vm._f("pgettext")(
                          "Emails are generated from MJML templates, PDF documents are generated from HTML templates.",
                          "Templates"
                        )
                      }
                    },
                    [
                      _c("ItpFormSelect", {
                        attrs: {
                          name: "template_type",
                          size: "md",
                          required: "",
                          options: _vm.b_7527331142178322_options
                        },
                        model: {
                          value: _vm.item.template_type,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "template_type", $$v)
                          },
                          expression: "item.template_type"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _c("ItpButton", {
                attrs: {
                  form: "jrgztyjdyk",
                  text: _vm._f("pgettext")("Save", "Templates"),
                  type: "submit",
                  variant: "primary",
                  default: "",
                  spinning: _vm.actions.submit.isRunning
                }
              }),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("pgettext")("Close", "Templates"),
                  variant: "default"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1419464017721962_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }