var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "Settings",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _c(
        "DIV",
        { staticClass: "overflow-hidden h-100 d-flex" },
        [
          _c(
            "ItpSidebar",
            { staticClass: "mr-3", attrs: { "default-width": "400" } },
            [
              _c(
                "b-list-group",
                _vm._l(_vm.sidebarItems, function(item) {
                  return _c("router-link", {
                    key: item.id,
                    staticClass: "py-2",
                    attrs: { to: item.to },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var href = ref.href
                            var route = ref.route
                            var navigate = ref.navigate
                            var isActive = ref.isActive
                            var isExactActive = ref.isExactActive
                            return [
                              _c(
                                "b-list-group-item",
                                {
                                  staticClass:
                                    "[\n  {'font-weight-bold': isActive},\n  'd-flex justify-content-between',\n  'align-items-center'\n]\n"
                                },
                                [
                                  _c(
                                    "b-link",
                                    {
                                      staticClass: "text-truncate",
                                      attrs: { href: href },
                                      on: { click: navigate }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.name) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("router-view", { staticClass: "h-100 w-100 overflow-hidden" })
        ],
        1
      ),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }