var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpModal",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "ReplaceMeter"
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "ItpForm",
                {
                  ref: "form",
                  attrs: { name: "replaceMeter", id: _vm.formId },
                  on: {
                    submit: function($event) {
                      return _vm.evh_1040167445267876_submit($event, {})
                    }
                  }
                },
                [
                  _c(
                    "ItpAlert",
                    { attrs: { show: "" } },
                    [
                      _c("DIV", [
                        _vm._v(
                          "\n          Az óracsere művelet a következő lépéseket végzi el automatikusan:\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        _vm._l(_vm.workflowStepsInfo.steps, function(step) {
                          return _c("li", [
                            _vm._v(
                              "\n            " + _vm._s(step) + "\n          "
                            )
                          ])
                        }),
                        0
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("HR"),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelAlign: _vm.labelAlign,
                        "label-cols-md": "3",
                        "content-cols-md": "3",
                        name: "replacement_time",
                        label: _vm._f("pgettext")(
                          "Replacement time",
                          "ReplaceMeterModal"
                        ),
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormDatetime", {
                        attrs: {
                          type: "datetime",
                          name: "replacement_time",
                          required: "",
                          readonly: _vm.isReadOnly
                        },
                        model: {
                          value: _vm.item.replacement_time,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "replacement_time", $$v)
                          },
                          expression: "item.replacement_time"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("HR"),
                  _vm._v(" "),
                  _c(
                    "DIV",
                    { staticClass: "form-group" },
                    [
                      _c("ItpText", {
                        attrs: {
                          strong: "",
                          muted: "",
                          text: _vm._f("pgettext")(
                            "Old device",
                            "ReplaceMeterModal"
                          )
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelAlign: _vm.labelAlign,
                        "label-cols-md": "3",
                        "content-cols-md": "9",
                        name: "format",
                        label: _vm._f("pgettext")("Meter", "ReplaceMeterModal"),
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormSelect2", {
                        attrs: {
                          name: "old_meter",
                          required: "",
                          readonly: _vm.isReadOnly,
                          dataSource: _vm.b_4963030162390099_dataSource,
                          valueField: "id",
                          textField: "serial_number",
                          "use-option-template-for-selected-option": "",
                          optionTemplate:
                            "<UcSuggestMeterOption :option=option></UcSuggestMeterOption>"
                        },
                        on: {
                          optionSelected: function($event) {
                            return _vm.evh_4963030162390099_optionSelected(
                              $event,
                              {}
                            )
                          }
                        },
                        model: {
                          value: _vm.item.old_meter.id,
                          callback: function($$v) {
                            _vm.$set(_vm.item.old_meter, "id", $$v)
                          },
                          expression: "item.old_meter.id"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelAlign: _vm.labelAlign,
                        "label-cols-md": "3",
                        "content-cols-md": "9",
                        name: "old_reading_value",
                        label: _vm._f("pgettext")(
                          "Reading value",
                          "ReplaceMeterModal"
                        ),
                        required: ""
                      }
                    },
                    [
                      _c(
                        "ItpBox",
                        [
                          _c("ItpFormInput", {
                            staticClass: "col-3",
                            attrs: {
                              type: "number",
                              name: "old_reading_value",
                              required: "",
                              readonly: _vm.isReadOnly
                            },
                            model: {
                              value: _vm.item.old_meter.reading_value,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.item.old_meter,
                                  "reading_value",
                                  $$v
                                )
                              },
                              expression: "item.old_meter.reading_value"
                            }
                          }),
                          _vm._v(" "),
                          _c("ItpFormSelect2", {
                            staticClass: "col-4",
                            attrs: {
                              name: "old_reading_unit",
                              options: _vm.oldMeterReadingUnitOptions,
                              required: "",
                              readonly: _vm.isReadOnly
                            },
                            model: {
                              value: _vm.item.old_meter.unit,
                              callback: function($$v) {
                                _vm.$set(_vm.item.old_meter, "unit", $$v)
                              },
                              expression: "item.old_meter.unit"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelAlign: _vm.labelAlign,
                        name: "old_reading_image",
                        label: _vm._f("pgettext")("Photo", "ReplaceMeter"),
                        "label-cols-md": "3",
                        "content-cols-md": "9"
                      }
                    },
                    [
                      _c("ItpFormFile", {
                        attrs: {
                          name: "old_reading_image",
                          placeholder: _vm._f("pgettext")(
                            "The photo of the gauge if available.",
                            "ReplaceMeter"
                          ),
                          lang: "hu"
                        },
                        on: {
                          changed: function($event) {
                            return _vm.evh_3855552110905499_changed($event, {})
                          }
                        },
                        model: {
                          value: _vm.old_reading_image,
                          callback: function($$v) {
                            _vm.old_reading_image = $$v
                          },
                          expression: "old_reading_image"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelAlign: _vm.labelAlign,
                        name: "old_notes",
                        label: _vm._f("pgettext")("Notes", "ReplaceMeterModal"),
                        "label-cols-md": "3",
                        "content-cols-md": "9"
                      }
                    },
                    [
                      _c("ItpFormTextarea", {
                        attrs: { name: "old_notes", readonly: _vm.isReadOnly },
                        model: {
                          value: _vm.item.old_meter.notes,
                          callback: function($$v) {
                            _vm.$set(_vm.item.old_meter, "notes", $$v)
                          },
                          expression: "item.old_meter.notes"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("HR"),
                  _vm._v(" "),
                  _c(
                    "DIV",
                    { staticClass: "form-group" },
                    [
                      _c("ItpText", {
                        attrs: {
                          strong: "",
                          muted: "",
                          text: _vm._f("pgettext")(
                            "New device",
                            "ReplaceMeterModal"
                          )
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelAlign: _vm.labelAlign,
                        "label-cols-md": "3",
                        "content-cols-md": "9",
                        name: "new_serial",
                        label: _vm._f("pgettext")("Device", "ReplaceMeterModal")
                      }
                    },
                    [
                      _c("ItpFormSelect2", {
                        attrs: {
                          name: "new_meter",
                          required: "",
                          readonly: _vm.isReadOnly,
                          dataSource: _vm.b_4246589045129675_dataSource,
                          valueField: "serial_number",
                          textField: "serial_number",
                          taggable: "",
                          clearable: "",
                          optionTemplate:
                            "<UcSuggestMeterOption :option=option></UcSuggestMeterOption>"
                        },
                        on: {
                          optionSelected: function($event) {
                            return _vm.evh_4246589045129675_optionSelected(
                              $event,
                              {}
                            )
                          }
                        },
                        model: {
                          value: _vm.item.new_meter.serial,
                          callback: function($$v) {
                            _vm.$set(_vm.item.new_meter, "serial", $$v)
                          },
                          expression: "item.new_meter.serial"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelAlign: _vm.labelAlign,
                        "label-cols-md": "3",
                        "content-cols-md": "9",
                        name: "new_serial",
                        label: _vm._f("pgettext")(
                          "Serial number",
                          "ReplaceMeterModal"
                        ),
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormInput", {
                        attrs: {
                          name: "new_serial",
                          required: "",
                          readonly: _vm.isReadOnly
                        },
                        model: {
                          value: _vm.item.new_meter.serial,
                          callback: function($$v) {
                            _vm.$set(_vm.item.new_meter, "serial", $$v)
                          },
                          expression: "item.new_meter.serial"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelAlign: _vm.labelAlign,
                        "label-cols-md": "3",
                        "content-cols-md": "9",
                        name: "new_deveui",
                        label: _vm._f("pgettext")("DevEUI", "ReplaceMeterModal")
                      }
                    },
                    [
                      _c("ItpFormInput", {
                        attrs: { name: "new_deveui", readonly: _vm.isReadOnly },
                        model: {
                          value: _vm.item.new_meter.deveui,
                          callback: function($$v) {
                            _vm.$set(_vm.item.new_meter, "deveui", $$v)
                          },
                          expression: "item.new_meter.deveui"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelAlign: _vm.labelAlign,
                        "label-cols-md": "3",
                        "content-cols-md": "9",
                        name: "new_tag_id",
                        label: _vm._f("pgettext")(
                          "Location Code",
                          "ReplaceMeterModal"
                        )
                      }
                    },
                    [
                      _c("ItpFormInput", {
                        attrs: { name: "new_tag_id", readonly: _vm.isReadOnly },
                        model: {
                          value: _vm.item.new_meter.tag_id,
                          callback: function($$v) {
                            _vm.$set(_vm.item.new_meter, "tag_id", $$v)
                          },
                          expression: "item.new_meter.tag_id"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelAlign: _vm.labelAlign,
                        "label-cols-md": "3",
                        "content-cols-md": "9",
                        name: "new_reading_value",
                        label: _vm._f("pgettext")(
                          "Reading value",
                          "ReplaceMeterModal"
                        ),
                        required: ""
                      }
                    },
                    [
                      _c(
                        "ItpBox",
                        [
                          _c("ItpFormInput", {
                            staticClass: "col-3",
                            attrs: {
                              type: "number",
                              name: "new_reading_value",
                              required: "",
                              readonly: _vm.isReadOnly
                            },
                            model: {
                              value: _vm.item.new_meter.reading_value,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.item.new_meter,
                                  "reading_value",
                                  $$v
                                )
                              },
                              expression: "item.new_meter.reading_value"
                            }
                          }),
                          _vm._v(" "),
                          _c("ItpFormSelect2", {
                            staticClass: "col-4",
                            attrs: {
                              name: "new_reading_unit",
                              options: _vm.oldMeterReadingUnitOptions,
                              required: "",
                              readonly: _vm.isReadOnly
                            },
                            model: {
                              value: _vm.item.new_meter.unit,
                              callback: function($$v) {
                                _vm.$set(_vm.item.new_meter, "unit", $$v)
                              },
                              expression: "item.new_meter.unit"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelAlign: _vm.labelAlign,
                        name: "new_reading_image",
                        label: _vm._f("pgettext")("Photo", "ReplaceMeter"),
                        "label-cols-md": "3",
                        "content-cols-md": "9"
                      }
                    },
                    [
                      _c("ItpFormFile", {
                        attrs: {
                          name: "new_reading_image",
                          placeholder: _vm._f("pgettext")(
                            "The photo of the gauge if available.",
                            "ReplaceMeter"
                          ),
                          lang: "hu"
                        },
                        model: {
                          value: _vm.new_reading_image,
                          callback: function($$v) {
                            _vm.new_reading_image = $$v
                          },
                          expression: "new_reading_image"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelAlign: _vm.labelAlign,
                        name: "new_notes",
                        label: _vm._f("pgettext")("Notes", "ReplaceMeterModal"),
                        "label-cols-md": "3",
                        "content-cols-md": "9"
                      }
                    },
                    [
                      _c("ItpFormTextarea", {
                        attrs: { name: "new_notes", readonly: _vm.isReadOnly },
                        model: {
                          value: _vm.item.new_meter.notes,
                          callback: function($$v) {
                            _vm.$set(_vm.item.new_meter, "notes", $$v)
                          },
                          expression: "item.new_meter.notes"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("HR"),
                  _vm._v(" "),
                  _c(
                    "DIV",
                    { staticClass: "row mb-3" },
                    [
                      _c(
                        "DIV",
                        { staticClass: "col-md-9 offset-md-3" },
                        [
                          _c("ItpButton", {
                            attrs: {
                              form: _vm.formId,
                              text: _vm._f("pgettext")(
                                "Replace Meter",
                                "ReplaceMeterModal"
                              ),
                              type: "submit",
                              variant: "primary",
                              icon: _vm.$config.meterReplacement.icon,
                              spinning: _vm.actions.submit.isRunning,
                              disabled: !!(
                                !_vm.forms.replaceMeter ||
                                !_vm.forms.replaceMeter.submittable ||
                                _vm.actions.submit.isRunning ||
                                _vm.responseData
                              )
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.responseData
                    ? _c(
                        "ItpAlert",
                        { attrs: { type: "success", show: "" } },
                        [
                          _c("H4", { staticClass: "alert-heading" }, [
                            _vm._v("\n          Sikeres óracsere\n        ")
                          ]),
                          _vm._v(" "),
                          _c(
                            "ul",
                            _vm._l(_vm.responseData.value.result, function(
                              step
                            ) {
                              return _c("li", [
                                _vm._v("\n  " + _vm._s(step.message) + "\n")
                              ])
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c(
                            "DIV",
                            { staticClass: "text-center" },
                            [
                              _c("ItpButton", {
                                attrs: {
                                  text: _vm._f("gettext")("Close"),
                                  variant: "primary",
                                  disabled: _vm.actions.submit.isRunning
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.evh_353388685162594_clickPreventStop(
                                      $event,
                                      {}
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("gettext")("Close"),
                  variant: "default",
                  disabled: _vm.actions.submit.isRunning
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1548630417156826_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }