var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "CreateWorksheetsFromWasteBinReadingsModal",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.documenTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _vm.loaded
                ? _c(
                    "ItpForm",
                    {
                      attrs: {
                        name: "CreateWorksheetsFromWasteBinReadingsModal",
                        id: _vm.formId
                      },
                      on: {
                        submit: function($event) {
                          return _vm.evh_1040167445267876_submit($event, {})
                        }
                      }
                    },
                    [
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            name: "service_id",
                            label: _vm._f("pgettext")(
                              "Service",
                              "CreateWorksheetsFromWasteBinReadingsModal"
                            ),
                            helpTooltip: _vm._f("pgettext")(
                              "Create line items with the selected service.",
                              "CreateWorksheetsFromWasteBinReadingsModal"
                            ),
                            labelAlign: _vm.labelAlign,
                            labelColsMd: "5",
                            contentColsMd: "7"
                          }
                        },
                        [
                          _c("ItpFormSelect2", {
                            attrs: {
                              name: "service_id",
                              dataSource: _vm.b_7789867021953041_dataSource,
                              valueField: "id",
                              textField: "name",
                              required: ""
                            },
                            model: {
                              value: _vm.item.service_id,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "service_id", $$v)
                              },
                              expression: "item.service_id"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            name: "worksheet_description",
                            label: _vm._f("pgettext")(
                              "Worksheet description",
                              "CreateWorksheetsFromWasteBinReadingsModal"
                            ),
                            helpTooltip: _vm._f("pgettext")(
                              "The given value will be the description of the created worksheet. Otherwise the selected service name.",
                              "CreateWorksheetsFromWasteBinReadingsModal"
                            ),
                            labelAlign: _vm.labelAlign,
                            labelColsMd: "5",
                            contentColsMd: "7"
                          }
                        },
                        [
                          _c("ItpFormInput", {
                            attrs: { name: "worksheet_description" },
                            model: {
                              value: _vm.item.worksheet_description,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "worksheet_description", $$v)
                              },
                              expression: "item.worksheet_description"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            name: "contractor_id",
                            label: _vm._f("pgettext")(
                              "Contractor",
                              "Worksheet"
                            ),
                            labelAlign: _vm.labelAlign,
                            labelColsMd: "5",
                            contentColsMd: "7"
                          }
                        },
                        [
                          _c("ItpFormSelect2", {
                            attrs: {
                              name: "contractor_id",
                              dataSource: _vm.b_7527331142178322_dataSource,
                              valueField: "id",
                              textField: "name",
                              optionTemplate:
                                "<UcSuggestClientOption :option=option></UcSuggestClientOption>",
                              "use-option-template-for-selected-option": ""
                            },
                            model: {
                              value: _vm.item.contractor_id,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "contractor_id", $$v)
                              },
                              expression: "item.contractor_id"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            name: "worker_id",
                            label: _vm._f("pgettext")("Worker", "Worksheet"),
                            labelAlign: _vm.labelAlign,
                            labelColsMd: "5",
                            contentColsMd: "7"
                          }
                        },
                        [
                          _c("ItpFormSelect2", {
                            attrs: {
                              name: "worker_id",
                              dataSource: _vm.b_5074589820235598_dataSource,
                              valueField: "id",
                              textField: "name"
                            },
                            model: {
                              value: _vm.item.worker_id,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "worker_id", $$v)
                              },
                              expression: "item.worker_id"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            name: "gl_account_number",
                            label: _vm._f("pgettext")(
                              "GL Account Number",
                              "CreateWorksheetsFromWasteBinReadingsModal"
                            ),
                            labelAlign: _vm.labelAlign,
                            labelColsMd: "5",
                            contentColsMd: "7"
                          }
                        },
                        [
                          _c("ItpFormInput", {
                            attrs: { name: "gl_account_number" },
                            model: {
                              value: _vm.item.gl_account_number,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "gl_account_number", $$v)
                              },
                              expression: "item.gl_account_number"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("pgettext")(
                    "Create worksheets",
                    "CreateWorksheetsFromWasteBinReadingsModal"
                  ),
                  type: "submit",
                  form: _vm.formId,
                  variant: "primary",
                  icon: "fa-save",
                  spinning: _vm.actions.submit.isRunning
                }
              }),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("gettext")("Close"),
                  variant: "default",
                  disabled: _vm.actions.submit.isRunning
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1419464017721962_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }