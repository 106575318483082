var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "4",
            name: "entry_date",
            label: _vm._f("pgettext")("Entry date", "LetterRegisterEntry"),
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormDatetime", {
            attrs: { name: "entry_date", required: "" },
            model: {
              value: _vm.item.entry_date,
              callback: function($$v) {
                _vm.$set(_vm.item, "entry_date", $$v)
              },
              expression: "item.entry_date"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "delivery_modes",
            label: _vm._f("pgettext")("Delivery modes", "LetterRegisterEntry"),
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormSelect2", {
            attrs: {
              name: "delivery_modes",
              options: _vm.deliveryModes,
              valueField: "name",
              textField: "name",
              multiple: "",
              required: ""
            },
            model: {
              value: _vm.item.delivery_modes,
              callback: function($$v) {
                _vm.$set(_vm.item, "delivery_modes", $$v)
              },
              expression: "item.delivery_modes"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "categories",
            label: _vm._f("pgettext")("Categories", "LetterRegisterEntry"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormSelect2", {
            attrs: {
              name: "categories",
              multiple: "",
              taggable: "",
              options: _vm.categories
            },
            model: {
              value: _vm.item.categories,
              callback: function($$v) {
                _vm.$set(_vm.item, "categories", $$v)
              },
              expression: "item.categories"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "client",
            labelAlign: _vm.labelAlign,
            label: _vm._f("pgettext")("Client", "LetterRegisterEntry"),
            required: ""
          }
        },
        [
          _c("ItpFormSelect2", {
            attrs: {
              name: "contractor",
              dataSource: _vm.b_6917646200545441_dataSource,
              valueField: "name",
              textField: "name",
              taggable: "",
              required: ""
            },
            on: {
              optionSelected: function($event) {
                return _vm.evh_6917646200545441_optionSelected($event, {})
              }
            },
            model: {
              value: _vm.item.client_name,
              callback: function($$v) {
                _vm.$set(_vm.item, "client_name", $$v)
              },
              expression: "item.client_name"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "subject",
            label: _vm._f("pgettext")("Subject", "LetterRegisterEntry"),
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "subject", required: "" },
            model: {
              value: _vm.item.subject,
              callback: function($$v) {
                _vm.$set(_vm.item, "subject", $$v)
              },
              expression: "item.subject"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "notes",
            label: _vm._f("pgettext")("Notes", "LetterRegisterEntry"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormTextarea", {
            attrs: { name: "notes" },
            model: {
              value: _vm.item.notes,
              callback: function($$v) {
                _vm.$set(_vm.item, "notes", $$v)
              },
              expression: "item.notes"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "4",
            name: "delivery_date",
            label: _vm._f("pgettext")("Delivery date", "LetterRegisterEntry"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormDatetime", {
            attrs: { name: "delivery_date" },
            model: {
              value: _vm.item.delivery_date,
              callback: function($$v) {
                _vm.$set(_vm.item, "delivery_date", $$v)
              },
              expression: "item.delivery_date"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "undelivered",
            label: _vm._f("pgettext")("Undelivered", "LetterRegisterEntry"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormCheckbox", {
            attrs: { name: "undelivered" },
            model: {
              value: _vm.item.undelivered,
              callback: function($$v) {
                _vm.$set(_vm.item, "undelivered", $$v)
              },
              expression: "item.undelivered"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.item.isNew
        ? _c(
            "ItpFormGroup",
            { attrs: { labelColsMd: "4", contentColsMd: "8" } },
            [
              _c("ItpButton", {
                staticClass: "mr-2",
                attrs: {
                  type: "submit",
                  form: _vm.form,
                  text: _vm._f("pgettext")(
                    "Create entry",
                    "LetterRegisterEntry/Button"
                  )
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.item.isNew && !_vm.item.isReadOnly
        ? _c(
            "ItpFormGroup",
            { attrs: { labelColsMd: "4", contentColsMd: "8" } },
            [
              _c("ItpButton", {
                staticClass: "mr-2",
                attrs: {
                  type: "submit",
                  form: _vm.form,
                  text: _vm._f("pgettext")("Save changes", "Button")
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.item.id ? _c("hr") : _vm._e(),
      _vm._v(" "),
      _vm.item.id
        ? _c("UcAttachmentsList", { attrs: { parent: _vm.item } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }