var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpBox",
        { staticClass: "pb-3", attrs: { alignItems: "center" } },
        [
          _c("ItpIcon", {
            staticClass: "mr-2",
            attrs: {
              name: _vm.$config.contract.icon,
              spacing: "mr-3",
              title: _vm._f("pgettext")("Contract", "Contract")
            }
          }),
          _vm._v(" "),
          _c(
            "ItpLink",
            {
              attrs: {
                to: {
                  name: "app.contract",
                  params: { id: _vm.item.id }
                }
              }
            },
            [
              _c("ItpText", {
                attrs: { strong: "", text: _vm.item.contract_number }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("UcContractTypeBadge", {
            staticClass: "mx-3",
            attrs: { value: _vm.item.contract_type }
          }),
          _vm._v(" "),
          _c(
            "ItpLink",
            {
              attrs: {
                to: {
                  name: "app.client",
                  params: { id: _vm.item.counterparty_id }
                }
              }
            },
            [
              _c("ItpText", {
                attrs: { strong: "", text: _vm.item.counterparty_name }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.displayRental
            ? _c(
                "ItpLink",
                {
                  staticClass: "px-3",
                  attrs: {
                    to: {
                      name: "app.rental",
                      params: { id: _vm.item.rental_id }
                    }
                  }
                },
                [
                  _c("ItpText", {
                    attrs: { strong: "", text: _vm.item.rental_name }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpBox",
        { attrs: { alignItems: "center" } },
        [
          _c("UcValidityPeriod", {
            staticClass: "pr-3",
            attrs: { from: _vm.item.valid_from, to: _vm.item.valid_to }
          }),
          _vm._v(" "),
          _c("UcContractStatusBadge", {
            staticClass: "mr-3",
            attrs: { value: _vm.item.status }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }