var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "ml-auto",
      attrs: { href: _vm.baseUrl + _vm.path, target: "_blank" }
    },
    [
      _c("ItpIcon", { attrs: { fa: "question-circle" } }),
      _vm._v(" "),
      _c("span", { staticClass: "mx-1" }, [
        _vm._v("\n    " + _vm._s(_vm._f("gettext")("Help")) + "\n  ")
      ]),
      _vm._v(" "),
      _c("ItpIcon", {
        staticClass: "text-muted",
        style: _vm.b_8358196963355853_style,
        attrs: { fa: "external-link-alt" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }