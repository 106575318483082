var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpModal",
    {
      attrs: {
        "no-primary-button": "",
        closeButtonText: _vm._f("gettext")("Close"),
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "TaskViewModal"
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c(
            "DIV",
            { staticClass: "page d-flex flex-column" },
            [
              _c(
                "DIV",
                { staticClass: "page__content container-fluid pt-3 h-100" },
                [
                  _c(
                    "ItpBox",
                    { attrs: { direction: "column" } },
                    [
                      _c("ItpBadge", {
                        attrs: {
                          text: _vm.task.status,
                          value: _vm.task.status,
                          variantMap: _vm.b_4615685607825208_variantMap
                        }
                      }),
                      _vm._v(" "),
                      _vm.task.result_text
                        ? _c("CodeMirror", {
                            attrs: { options: _vm.b_2095089031469157_options },
                            model: {
                              value: _vm.task.result_text,
                              callback: function($$v) {
                                _vm.$set(_vm.task, "result_text", $$v)
                              },
                              expression: "task.result_text"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }