var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpBox",
        { attrs: { alignItems: "center" } },
        [
          !_vm.noIcon
            ? _c("ItpIcon", {
                staticClass: "mr-2",
                attrs: {
                  name: _vm.$config.contract.icon,
                  title: _vm._f("pgettext")("Contract", "Contract")
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("ItpText", {
            staticClass: "mr-2",
            attrs: { strong: "", text: _vm.item.contract_number }
          }),
          _vm._v(" "),
          !_vm.noLink
            ? _c("ItpLink", {
                attrs: {
                  icon: _vm.$config.externalLinkIcon,
                  to: {
                    name: "app.contract",
                    params: { id: _vm.item.id }
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.item.contract_type
            ? _c("UcContractTypeBadge", {
                staticClass: "mx-3",
                attrs: { value: _vm.item.contract_type }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.item.status
            ? _c("UcContractStatusBadge", { attrs: { value: _vm.item.status } })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }