var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c("JobProgress", { attrs: { item: _vm.item } }),
      _vm._v(" "),
      _c(
        "ItpBox",
        { attrs: { direction: "row" } },
        [
          _vm.item.start_time
            ? _c("ItpText", {
                staticClass: "mr-2",
                attrs: {
                  muted: "",
                  text: _vm._f("pgettext")("Start time", "Job")
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.item.start_time
            ? _c("ItpText", { staticClass: "mr-4", attrs: { strong: "" } }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("datetime")(_vm.item.start_time)) +
                    "\n    "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.item.end_time
            ? _c("ItpText", {
                staticClass: "mr-2",
                attrs: {
                  muted: "",
                  text: _vm._f("pgettext")("End time", "Job")
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.item.end_time
            ? _c("ItpText", { staticClass: "mr-2", attrs: { strong: "" } }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("datetime")(_vm.item.end_time)) +
                    "\n    "
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("DIV", { staticClass: "border mt-2" }, [
        _c("pre", { staticClass: "m-0 p-3" }, [_vm._v(_vm._s(_vm.item.log))])
      ]),
      _vm._v(" "),
      _vm.item.files && _vm.item.files.length
        ? _c("JobFiles", { attrs: { item: _vm.item } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }