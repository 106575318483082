var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        headerClass: "bg-light",
        "no-footer": "",
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "AboutModal",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c("h4", [
                _vm._v(
                  "\n      Integro v" +
                    _vm._s(_vm.$app.PRODUCT_VERSION) +
                    "\n\n    "
                )
              ]),
              _vm._v(" "),
              _c("DIV", { staticClass: "mt-3" }, [
                _vm._v(
                  "\n      Copyright © 2006-2021 EuroSand Computer Kft.\n    "
                )
              ]),
              _vm._v(" "),
              _c("DIV", [_vm._v("\n      License type: Commercial\n    ")]),
              _vm._v(" "),
              _c(
                "table",
                {
                  staticClass:
                    "table table-sm table-striped table-borderless mt-3"
                },
                [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [
                        _vm._v(_vm._s(_vm._f("gettext")("Component")))
                      ]),
                      _vm._v(" "),
                      _c("th", [_vm._v(_vm._s(_vm._f("gettext")("Version")))])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.versions, function(v) {
                      return _c("tr", { key: v.name }, [
                        _c("td", [_vm._v(_vm._s(v.name))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(v.version))])
                      ])
                    }),
                    0
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }