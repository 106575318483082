var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      !!_vm.noLegend
        ? _c("legend", [
            _vm._v(
              "\n    " +
                _vm._s(_vm._f("pgettext")("Rental", "Contract")) +
                "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "rental",
            label: _vm._f("pgettext")("Rental", "Rental"),
            labelColsMd: "4",
            contentColsMd: "8",
            labelAlign: _vm.labelAlign,
            required: _vm.rentalRequired
          }
        },
        [
          _c("ItpFormSelect2", {
            attrs: {
              name: "rental",
              required: _vm.rentalRequired,
              "bind-object": "",
              dataSource: _vm.b_1040167445267876_dataSource,
              textField: "number,name",
              clearable: "",
              template: _vm.b_1040167445267876_template
            },
            model: {
              value: _vm.contract.rental,
              callback: function($$v) {
                _vm.$set(_vm.contract, "rental", $$v)
              },
              expression: "contract.rental"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.contract.rental
        ? _c(
            "ItpFormGroup",
            {
              attrs: {
                name: "name",
                label: _vm._f("pgettext")("Name", "Rental"),
                labelColsMd: "4",
                contentColsMd: "8",
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { name: "name" },
                model: {
                  value: _vm.contract.rental.name,
                  callback: function($$v) {
                    _vm.$set(_vm.contract.rental, "name", $$v)
                  },
                  expression: "contract.rental.name"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.contract.rental
        ? _c(
            "ItpFormGroup",
            {
              attrs: {
                name: "name",
                label: _vm._f("pgettext")("Address", "Rental"),
                labelColsMd: "4",
                contentColsMd: "8",
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { name: "name" },
                model: {
                  value: _vm.contract.rental.address,
                  callback: function($$v) {
                    _vm.$set(_vm.contract.rental, "address", $$v)
                  },
                  expression: "contract.rental.address"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.contract.rental
        ? _c(
            "ItpFormGroup",
            {
              attrs: {
                name: "area",
                label: _vm._f("pgettext")("Area", "Rental"),
                labelColsMd: "4",
                contentColsMd: "4",
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { name: "name" },
                model: {
                  value: _vm.contract.rental.area,
                  callback: function($$v) {
                    _vm.$set(_vm.contract.rental, "area", $$v)
                  },
                  expression: "contract.rental.area"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.contract.rental
        ? _c(
            "ItpFormGroup",
            {
              attrs: {
                name: "lot_number",
                label: _vm._f("pgettext")("LOT number", "Rental"),
                labelColsMd: "4",
                contentColsMd: "4",
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { name: "name" },
                model: {
                  value: _vm.contract.rental.lot_number,
                  callback: function($$v) {
                    _vm.$set(_vm.contract.rental, "lot_number", $$v)
                  },
                  expression: "contract.rental.lot_number"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.contract.rental
        ? _c(
            "ItpFormGroup",
            {
              attrs: {
                name: "house_number",
                label: _vm._f("pgettext")("House number", "Rental"),
                labelColsMd: "4",
                contentColsMd: "4",
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { name: "name" },
                model: {
                  value: _vm.contract.rental.house_number,
                  callback: function($$v) {
                    _vm.$set(_vm.contract.rental, "house_number", $$v)
                  },
                  expression: "contract.rental.house_number"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.contract.rental
        ? _c(
            "ItpFormGroup",
            {
              attrs: {
                name: "description",
                label: _vm._f("pgettext")("Description", "Rental"),
                labelColsMd: "4",
                contentColsMd: "8",
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormTextarea", {
                attrs: { name: "name" },
                model: {
                  value: _vm.contract.rental.description,
                  callback: function($$v) {
                    _vm.$set(_vm.contract.rental, "description", $$v)
                  },
                  expression: "contract.rental.description"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }