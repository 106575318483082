import UcEmployeeCard from './UcEmployeeCard.yaml?component';
import UcEmployeeFieldset from './UcEmployeeFieldset.yaml?component';
import WEmployeeModal from './WEmployeeModal.yaml?component';
import UcEmployeeList from './UcEmployeeList.yaml?component';

export {
  UcEmployeeCard,
  UcEmployeeFieldset,
  WEmployeeModal,
  UcEmployeeList
};
