var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.invoice.totals
    ? _c("table", { staticClass: "ml-auto" }, [
        _c(
          "tbody",
          [
            _c("tr", [
              _c("td", { staticClass: "pr-4" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("pgettext")("Taxable subtotal", "Invoice")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "text-right" }, [
                _c("span", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm._f("money")(
                          _vm.invoice.totals.subtotal,
                          _vm.invoice.currency,
                          _vm.currency.digits
                        )
                      ) +
                      "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _vm.invoice.exchanged_totals && _vm.exchangedCurrency
                ? _c(
                    "td",
                    { staticClass: "text-right pl-4" },
                    [
                      _c("ItpText", { attrs: { small: "" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("money")(
                                _vm.invoice.exchanged_totals.subtotal,
                                _vm.invoice.exchanged_currency,
                                _vm.exchangedCurrency.digits
                              )
                            ) +
                            "\n        "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm._l(_vm.invoice.totals.taxes, function(tax, index) {
              return _c("tr", [
                _c(
                  "td",
                  { staticClass: "pr-4" },
                  [
                    _c("ItpText", {
                      attrs: {
                        text: _vm._f("gettext")("Tax"),
                        small: "",
                        muted: ""
                      }
                    }),
                    _vm._v(" "),
                    _c("ItpText", {
                      attrs: { text: tax.tax_code, small: "", muted: "" }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("td", { staticClass: "text-right" }, [
                  _c("span", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("money")(
                            tax.amount,
                            _vm.invoice.currency,
                            _vm.currency.digits
                          )
                        ) +
                        "\n        "
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm.invoice.exchanged_totals && _vm.exchangedCurrency
                  ? _c(
                      "td",
                      { staticClass: "text-right pl-4" },
                      [
                        _c("ItpText", { attrs: { small: "" } }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm._f("money")(
                                  _vm.invoice.exchanged_totals.taxes[index]
                                    .amount,
                                  _vm.invoice.exchanged_currency,
                                  _vm.exchangedCurrency.digits
                                )
                              ) +
                              "\n        "
                          )
                        ])
                      ],
                      1
                    )
                  : _vm._e()
              ])
            }),
            _vm._v(" "),
            _c("tr", [
              _c(
                "td",
                { staticClass: "pr-4" },
                [
                  _c("ItpText", {
                    attrs: { text: _vm._f("gettext")("Total"), strong: "" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("td", { staticClass: "text-right" }, [
                _c("strong", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm._f("money")(
                          _vm.invoice.totals.total,
                          _vm.invoice.currency,
                          _vm.currency.digits
                        )
                      ) +
                      "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _vm.invoice.exchanged_totals && _vm.exchangedCurrency
                ? _c(
                    "td",
                    { staticClass: "text-right pl-4" },
                    [
                      _c("ItpText", { attrs: { small: "" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("money")(
                                _vm.invoice.exchanged_totals.total,
                                _vm.invoice.exchanged_currency,
                                _vm.exchangedCurrency.digits
                              )
                            ) +
                            "\n        "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ])
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }