var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ItpButton", {
    class: _vm.synced ? "bg-green-200" : "bg-yellow-200",
    attrs: {
      icon: _vm.synced
        ? "fluent:cloud-sync-complete-16-filled"
        : "material-symbols:cloud-sync-outline-rounded",
      tooltip: _vm.tooltipMessage,
      spinning: _vm.actions.sync.isRunning,
      variant: "light",
      disabled: _vm.synced,
      size: _vm.size
    },
    on: {
      click: function($event) {
        $event.preventDefault()
        $event.stopPropagation()
        return _vm.evh_7315092382398562_clickPreventStop($event, {})
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }