var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c("ItpDataTable", {
        ref: "tableServices",
        staticClass: "h-100",
        attrs: {
          tableId: "RentalFormServices",
          editable: _vm.editable,
          isRowEditable: function(p) {
            return !p.locked
          },
          value: _vm._.orderBy(
            _vm.items,
            ["created_at", "valid_from", "valid_to"],
            ["desc", "desc", "desc"]
          ),
          rowTemplate: _vm.schemaDefaults("CreateRentalService", {
            rental_id: _vm.rentalId,
            valid_from: _vm.$fn.today()
          }),
          columns: _vm.b_7315092382398562_columns
        },
        on: {
          save: function($event) {
            return _vm.evh_7315092382398562_save($event, {})
          },
          delete: function($event) {
            return _vm.evh_7315092382398562_delete($event, {})
          },
          "meter:selected": function($event) {
            return _vm.evh_7315092382398562_meterSelected($event, {})
          }
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }