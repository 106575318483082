<template>
<DIV
  class="d-inline-block"
>
  <ItpBox
    alignItems="center"
    :class="b_2248226175642056_class"
  >
    <ItpText
      :text="revisionInfo.revision_number"
      class="px-2"
    >
    </ItpText>
    <div
      class="mx-2 text-center"
      :style="b_3343864469950044_style"
    >
      <UcContractStatusBadge
        :value="revisionInfo.status"
      >
      </UcContractStatusBadge>
    </div>
    <ItpText
      :text="revisionInfo.revision_date | date"
      class="mx-2"
    >
    </ItpText>
    <UcValidityPeriod
      :from="revisionInfo.valid_from"
      :to="revisionInfo.valid_to"
      class="px-2 border-left"
      v-if="revisionInfo.valid_from || revisionInfo.valid_to"
    >
    </UcValidityPeriod>
    <ItpText
      :text="revisionInfo.description"
      class="px-2 border-left"
      v-if="revisionInfo.description"
    >
    </ItpText>
  </ItpBox>
</DIV>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcContractRevisionDropdownItem extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  revisionInfo!: object;

  @Watch('revisionInfo')
  onRevision_info(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("revisionInfo")
  }

  @Prop({
    type: Boolean,
  })
  isSelected!: boolean;

  @Watch('isSelected')
  onIs_selected(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("isSelected")
  }

  ux!: any;
  b_2248226175642056_class!: any;

  async $$load_b_2248226175642056_class() {
    return {
      "font-wwight-bold": this.isSelected,
    }
  }

  b_3343864469950044_style!: any;
  dataMembers = ['ux', 'b_2248226175642056_class', 'b_3343864469950044_style'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
        b_2248226175642056_class: null,
        b_3343864469950044_style: {
          width: "80px",
        }
        ,
      },
    }
  }

  actions!: {
  }

  getActions() {
    return {
    }
  }
}

Vue.component("UcContractRevisionDropdownItem", UcContractRevisionDropdownItem);

</script>