var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "ItpBox",
        { staticClass: "items-spacing w-100", attrs: { alignItems: "center" } },
        [
          _c(
            "ItpText",
            {
              staticClass: "mb-0",
              attrs: { tag: "h5", capitalize: "", muted: "" }
            },
            [_vm._v("\n    " + _vm._s(_vm.resourceTitle) + "\n  ")]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "m-0 p-0" }, [_vm._v("\n    ·\n  ")]),
          _vm._v(" "),
          _c("ItpText", {
            staticClass: "mb-0",
            attrs: { tag: "h4", text: _vm.resourceKey }
          }),
          _vm._v(" "),
          _c(
            "DIV",
            { staticClass: "btn-toolbar ml-auto" },
            [
              !_vm.account.isNew
                ? _c("ItpButton", {
                    staticClass: "ml-auto mr-2",
                    attrs: {
                      icon: "fa-sync-alt",
                      tooltip: _vm._f("gettext")("Reload"),
                      spinning: _vm.actions.reload.isRunning,
                      variant: "light"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_6917646200545441_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.account.isNew
                ? _c(
                    "b-dropdown",
                    {
                      staticClass: "border",
                      attrs: { right: "", variant: "light" }
                    },
                    [
                      _c("ItpIcon", {
                        staticClass: "mx-1",
                        attrs: { slot: "button-content", fa: "bars" },
                        slot: "button-content"
                      }),
                      _vm._v(" "),
                      !_vm.account.isNew || !_vm.account.isReadOnly
                        ? _c("BtnDeleteResource", {
                            attrs: {
                              "no-icon": "",
                              "dropdown-item": "",
                              resource: _vm.account,
                              tooltip: _vm._f("pgettext")(
                                "Delete account",
                                "Account"
                              )
                            },
                            on: {
                              success: function($event) {
                                return _vm.evh_997556510679958_success(
                                  $event,
                                  {}
                                )
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }