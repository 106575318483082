var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "CreateInvoice",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c(
            "UcHeader",
            {
              attrs: {
                slot: "header",
                card: !_vm.modal,
                modal: _vm.modal,
                color: _vm.$config.invoice.color
              },
              on: {
                close: function($event) {
                  return _vm.evh_2248226175642056_close($event, {})
                },
                deleted: function($event) {
                  return _vm.evh_2248226175642056_deleted($event, {})
                }
              },
              slot: "header"
            },
            [
              _c("UcInvoiceHeader", {
                attrs: {
                  invoice: _vm._.clone(_vm.item),
                  formId: _vm.formId,
                  invoiceNumber: _vm.invoiceRegisterEntry
                    ? _vm.item.invoice_number
                    : null
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _vm.loaded
                ? _c(
                    "ItpForm",
                    {
                      attrs: {
                        id: _vm.formId,
                        name: "invoice",
                        autofocus: "first"
                      },
                      on: {
                        submit: function($event) {
                          return _vm.evh_1040167445267876_submit($event, {})
                        }
                      }
                    },
                    [
                      _c(
                        "ItpCol",
                        [
                          _c("UcInvoiceEditorFieldset", {
                            attrs: { invoice: _vm.item, formId: _vm.formId }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: {
                slot: "footer",
                card: !_vm.modal,
                modal: _vm.modal,
                color: _vm.$config.invoice.color
              },
              slot: "footer"
            },
            [
              _c("ItpButton", {
                attrs: {
                  form: _vm.forms.invoice && _vm.forms.invoice.id,
                  icon: "fa-save",
                  text: _vm._f("pgettext")(
                    "Create invoice",
                    "CreateInvoice|Submit"
                  ),
                  type: "submit",
                  variant: "primary",
                  spinning: _vm.actions.submit.isRunning,
                  disabled: !_vm.forms.invoice || !_vm.forms.invoice.submittable
                }
              }),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("gettext")("Close"),
                  variant: "default",
                  disabled: _vm.actions.submit.isRunning
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1419464017721962_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }