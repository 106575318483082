<template>
<Icon
  :icon="icon"
  width="32"
>
</Icon>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcFileIcon extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: String,
  })
  filename!: string;

  @Watch('filename')
  onFilename(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("filename")
  }

  assignments!: any;
  ux!: any;
  dataMembers = ['assignments', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        assignments: {
          default: "vscode-icons:file-type-binary",
          pdf: "vscode-icons:file-type-pdf2",
          doc: "vscode-icons:file-type-word",
          docx: "vscode-icons:file-type-word",
          xls: "vscode-icons:file-type-excel",
          xlsx: "vscode-icons:file-type-excel",
          txt: "vscode-icons:file-type-text",
          zip: "vscode-icons:file-type-zip2",
          ps: "vscode-icons:file-type-photoshop2",
          md: "vscode-icons:file-type-markdown",
          bmp: "vscode-icons:file-type-image",
          jpg: "vscode-icons:file-type-image",
          jpeg: "vscode-icons:file-type-image",
          avi: "vscode-icons:file-type-video",
        }
        ,
        ux: null,
      },
    }
  }

  get extension() {
    if (this.loaded) {

      return this.filename.indexOf('.')
        ? _.last(this.filename.split('.')).toLowerCase()
        : this.filename.slice(1, 3)
    }
    return null;
  }

  get icon() {
    if (this.loaded) {

      return _.get(this.assignments, this.extension, this.assignments.default)
    }
    return null;
  }

  actions!: {
  }

  getActions() {
    return {
    }
  }
}

Vue.component("UcFileIcon", UcFileIcon);

</script>