var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      !_vm.item.valid_from
        ? _c(
            "DIV",
            [
              _c("ItpText", { attrs: { muted: "" } }, [
                _vm._v(_vm._s(_vm._f("pgettext")("From", "Rental")) + ":")
              ]),
              _vm._v(
                " " + _vm._s(_vm._f("pgettext")("N/A", "Rental")) + "\n\n  "
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.item.valid_from
        ? _c(
            "DIV",
            [
              _c("ItpText", { attrs: { muted: "" } }, [
                _vm._v(_vm._s(_vm._f("pgettext")("From", "Rental")) + ":")
              ]),
              _vm._v(" " + _vm._s(_vm.item.valid_from) + "\n\n  ")
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.item.valid_to
        ? _c(
            "DIV",
            [
              _c("ItpText", { attrs: { muted: "" } }, [
                _vm._v(_vm._s(_vm._f("pgettext")("To", "Rental")) + ":")
              ]),
              _vm._v(" " + _vm._s(_vm.item.valid_to) + "\n\n  ")
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.item.valid_to
        ? _c(
            "DIV",
            [
              _c("ItpText", { attrs: { muted: "" } }, [
                _vm._v(_vm._s(_vm._f("pgettext")("To", "Rental")) + ":")
              ]),
              _vm._v(
                " " +
                  _vm._s(_vm._f("pgettext")("Indefinite", "Rental")) +
                  "\n\n  "
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }