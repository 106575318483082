var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c("MiniCard", {
        attrs: {
          colorCode: _vm.$config.worksheet.colorCode,
          badge: _vm.badge,
          noIcon: _vm.noIcon,
          title: _vm._f("pgettext")("Worksheet", "Worksheet"),
          icon: _vm.$config.worksheet.icon,
          text: _vm.item.name || _vm.item.number,
          linkTo: {
            name: "app.worksheet",
            params: { id: _vm.item.id }
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }