var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpModal",
    {
      attrs: {
        "no-primary-button": "",
        closeButtonText: _vm._f("gettext")("Close"),
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "WasteBinReadingModal"
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "ItpForm",
                {
                  ref: "mainForm",
                  staticClass: "row",
                  attrs: { name: "waste_bin_reading", id: _vm.formId }
                },
                [
                  _c(
                    "ItpCol",
                    { attrs: { auto: _vm.b_5534025912102772_auto } },
                    [
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            row: "",
                            "label-cols-md": "3",
                            "content-cols-md": "9",
                            name: "waste_bin_number",
                            label: _vm._f("pgettext")(
                              "Bin Number",
                              "WasteBinReadingModal"
                            )
                          }
                        },
                        [
                          _c("ItpFormInput", {
                            attrs: { name: "waste_bin_number", readonly: "" },
                            model: {
                              value: _vm.item.waste_bin_number,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "waste_bin_number", $$v)
                              },
                              expression: "item.waste_bin_number"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            row: "",
                            "label-cols-md": "3",
                            "content-cols-md": "9",
                            name: "timestamp",
                            label: _vm._f("pgettext")(
                              "Time",
                              "WasteBinReadingModal"
                            )
                          }
                        },
                        [
                          _c(
                            "ItpFormPlaintext",
                            { attrs: { id: "timestamp" } },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm._f("datetime")(_vm.item.timestamp)
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            row: "",
                            "label-cols-md": "3",
                            "content-cols-md": "9",
                            name: "identity",
                            label: _vm._f("pgettext")(
                              "Device",
                              "WasteBinReadingModal"
                            )
                          }
                        },
                        [
                          _c(
                            "ItpFormPlaintext",
                            { attrs: { id: "identity" } },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.item.identity) +
                                  "\n          "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            row: "",
                            "label-cols-md": "3",
                            "content-cols-md": "9",
                            name: "state",
                            label: _vm._f("pgettext")(
                              "State",
                              "WasteBinReadingModal"
                            )
                          }
                        },
                        [
                          _c(
                            "ItpBox",
                            { attrs: { alignItems: "center" } },
                            [
                              _c(
                                "ItpBadge",
                                {
                                  staticClass: "mr-3",
                                  attrs: {
                                    value: _vm.item.state,
                                    variantMap:
                                      _vm.b_5430362791568289_variantMap,
                                    "no-box-shadow": ""
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm._f("pgettext")(
                                          _vm.item.state,
                                          "WasteBinReadingStates",
                                          "StartCase"
                                        )
                                      ) +
                                      "\n\n            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("ItpButton", {
                                attrs: {
                                  text: _vm._f("pgettext")(
                                    "Toggle state",
                                    "WasteBinReadingModal"
                                  ),
                                  variant: "light",
                                  size: "sm"
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.evh_2286348834130966_clickPreventStop(
                                      $event,
                                      {}
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _c("ItpButton", {
                attrs: { text: _vm._f("gettext")("Close"), variant: "default" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1548630417156826_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }