var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpWidgetCard",
        [
          _c(
            "ItpBox",
            { attrs: { slot: "header", alignItems: "center" }, slot: "header" },
            [
              _c("strong", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("pgettext")("General", "Rental")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("ItpButton", {
                staticClass: "ml-auto",
                attrs: {
                  text: _vm._f("gettext")("Edit..."),
                  variant: "light",
                  size: "sm"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_8320016629450276_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "BListGroup",
            [
              _vm.rental.name
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Name", "Rental"),
                      content: _vm.rental.name
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.rental.description
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Description", "Rental"),
                      content: _vm.rental.description
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "ItpLabeledListGroupItem",
                {
                  attrs: {
                    label: _vm._f("pgettext")("Owner company", "Rental")
                  }
                },
                [
                  _c("ItpLink", {
                    attrs: {
                      text: _vm.rental.company.name,
                      to: {
                        name: "app.company",
                        params: { id: _vm.rental.company_id }
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.rental.site_id
                ? _c(
                    "ItpLabeledListGroupItem",
                    { attrs: { label: _vm._f("pgettext")("Site", "Rental") } },
                    [
                      _c(
                        "ItpLink",
                        {
                          attrs: {
                            to: {
                              name: "app.property",
                              params: { id: _vm.rental.site_id }
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(_vm.rental.site.name) + "\n"
                          ),
                          _c(
                            "span",
                            { attrs: { if: _vm.rental.site.description } },
                            [_vm._v("- " + _vm._s(_vm.rental.site.description))]
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.rental.location_code
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Location", "Rental"),
                      content: _vm.rental.location_code
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.rental.lot_number
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Lot number", "Rental"),
                      content: _vm.rental.lot_number
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.rental.house_number
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Building number", "Rental"),
                      content: _vm.rental.house_number
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.rental.area
                ? _c(
                    "ItpLabeledListGroupItem",
                    { attrs: { label: _vm._f("pgettext")("Area", "Rental") } },
                    [
                      _c("ItpText", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.rental.area) +
                            " " +
                            _vm._s(_vm.rental.unit_of_area) +
                            "\n        "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "ItpLabeledListGroupItem",
                { attrs: { label: _vm._f("pgettext")("Validity", "Rental") } },
                [_c("UcRentalValidityCard", { attrs: { item: _vm.rental } })],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }