var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "Contracts",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _c(
        "VueSlickgrid",
        {
          ref: "grid",
          staticClass: "page--grid",
          attrs: {
            name: "contracts",
            headerTitle: _vm._f("pgettext")("Contracts", "Contracts"),
            items: _vm.$fn.slickDataSource("integro_api/list_contract", {
              parameters: function() {
                return _vm.dsParams
              }
            }),
            "checkbox-row-selection": "",
            "export-to-csv": "",
            exportToCsvFilename: _vm._f("pgettext")("contracts", "filename"),
            columnDefaults: _vm.b_2248226175642056_columnDefaults,
            columns: _vm.b_2248226175642056_columns
          },
          on: {
            selectedRowsChanged: function($event) {
              return _vm.evh_2248226175642056_selectedRowsChanged($event, {})
            }
          }
        },
        [
          _c(
            "DIV",
            {
              staticClass: "flex-grow-1 d-flex",
              attrs: { slot: "toolbar" },
              slot: "toolbar"
            },
            [
              _c(
                "DIV",
                { staticClass: "mx-2" },
                [
                  _c("ItpFormCheckbox", {
                    attrs: {
                      name: "skip_archived",
                      label: _vm._f("pgettext")(
                        "Skip archived contracts",
                        "Contracts"
                      ),
                      inline: ""
                    },
                    model: {
                      value: _vm.dsParams.skip_archived,
                      callback: function($$v) {
                        _vm.$set(_vm.dsParams, "skip_archived", $$v)
                      },
                      expression: "dsParams.skip_archived"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "DIV",
                { staticClass: "border-left px-4" },
                [
                  _c("ItpButton", {
                    attrs: {
                      icon: _vm.$config.contract.createContractIcon,
                      text: _vm._f("pgettext")(
                        "Create contract...",
                        "Contracts"
                      ),
                      size: "sm",
                      variant: "light"
                    },
                    on: {
                      click: function($event) {
                        return _vm.evh_4440454779651433_click($event, {})
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("PageHelp", { attrs: { path: "/contracts/contracts.html" } })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }