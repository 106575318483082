var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpWidgetCard",
        [
          _c(
            "ItpBox",
            { attrs: { slot: "header", alignItems: "center" }, slot: "header" },
            [
              _c("strong", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("pgettext")("Lifecycle", "Contract")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              !_vm.readonly
                ? _c("ItpButton", {
                    staticClass: "ml-auto",
                    attrs: {
                      text: _vm._f("gettext")("Edit..."),
                      variant: "light",
                      size: "sm"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_8320016629450276_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.loaded
            ? _c(
                "BListGroup",
                [
                  _vm.contract.revision_number == 0 && _vm.item.revision_date
                    ? _c("ItpLabeledListGroupItem", {
                        attrs: {
                          label: _vm._f("pgettext")(
                            "Contract date",
                            "Contract"
                          ),
                          labelCols: _vm.labelCols,
                          content: _vm._f("date")(_vm.item.revision_date)
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.contract.revision_number > 0 && _vm.item.revision_date
                    ? _c("ItpLabeledListGroupItem", {
                        attrs: {
                          label: _vm._f("pgettext")(
                            "Revision date",
                            "Contract"
                          ),
                          labelCols: _vm.labelCols,
                          content: _vm._f("date")(_vm.item.revision_date)
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.valid_from || _vm.item.valid_to
                    ? _c(
                        "ItpLabeledListGroupItem",
                        {
                          attrs: {
                            label: _vm._f("pgettext")("Validity", "Contract"),
                            labelCols: _vm.labelCols
                          }
                        },
                        [
                          _c("UcValidityPeriod", {
                            attrs: {
                              from: _vm.item.valid_from,
                              to: _vm.item.valid_to,
                              missingToMessage: _vm._f("pgettext")(
                                "Indefinite",
                                "ContractValidity"
                              )
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.date_signed
                    ? _c(
                        "ItpLabeledListGroupItem",
                        {
                          attrs: {
                            label: _vm._f("pgettext")(
                              "Date signed",
                              "Contract"
                            ),
                            labelCols: _vm.labelCols
                          }
                        },
                        [
                          _c("ItpText", [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm._f("date")(_vm.item.date_signed)) +
                                "\n        "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.effective_date
                    ? _c(
                        "ItpLabeledListGroupItem",
                        {
                          attrs: {
                            label: _vm._f("pgettext")(
                              "Effective date",
                              "Contract"
                            ),
                            labelCols: _vm.labelCols
                          }
                        },
                        [
                          _c("ItpText", [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm._f("date")(_vm.item.effective_date)
                                ) +
                                "\n        "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.date_of_occupancy
                    ? _c(
                        "ItpLabeledListGroupItem",
                        {
                          attrs: {
                            label: _vm._f("pgettext")(
                              "Date of occupancy",
                              "Contract"
                            ),
                            labelCols: _vm.labelCols
                          }
                        },
                        [
                          _c("ItpText", [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm._f("date")(_vm.item.date_of_occupancy)
                                ) +
                                "\n        "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.auto_renewal != null
                    ? _c(
                        "ItpLabeledListGroupItem",
                        {
                          attrs: {
                            label: _vm._f("pgettext")(
                              "Auto renewal",
                              "Contract"
                            ),
                            labelCols: _vm.labelCols
                          }
                        },
                        [
                          _c("ItpText", [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.item.auto_renewal
                                    ? _vm.gettext("On")
                                    : _vm.gettext("Off")
                                ) +
                                "\n        "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.notice_period != null
                    ? _c(
                        "ItpLabeledListGroupItem",
                        {
                          attrs: {
                            label: _vm._f("pgettext")(
                              "Notice period",
                              "Contract"
                            ),
                            labelCols: _vm.labelCols
                          }
                        },
                        [
                          _c("ItpText", [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.item.notice_period) +
                                "\n        "
                            )
                          ]),
                          _vm._v(" "),
                          _c("ItpText", {
                            attrs: {
                              text: _vm._f("pgettext")(
                                "day(s)",
                                "Contract/NoticePeriod"
                              )
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.next_indexation_date
                    ? _c("ItpLabeledListGroupItem", {
                        attrs: {
                          label: _vm._f("pgettext")(
                            "Next indexation date",
                            "Contract"
                          ),
                          labelCols: _vm.labelCols,
                          content: _vm._f("date")(_vm.item.next_indexation_date)
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.termination_date
                    ? _c("ItpLabeledListGroupItem", {
                        attrs: {
                          label: _vm._f("pgettext")(
                            "Terminaton date",
                            "Contract"
                          ),
                          labelCols: _vm.labelCols,
                          content: _vm._f("date")(_vm.item.termination_date)
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.termination_effective_date
                    ? _c("ItpLabeledListGroupItem", {
                        attrs: {
                          label: _vm._f("pgettext")(
                            "Terminaton effective date",
                            "Contract"
                          ),
                          labelCols: _vm.labelCols,
                          content: _vm._f("date")(
                            _vm.item.termination_effective_date
                          )
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }