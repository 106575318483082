var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _vm.loaded && _vm.item
        ? _c(
            "ItpForm",
            {
              attrs: {
                name: "InvoiceGeneration",
                schema: "CreateInvoiceGenerationTask",
                readonly: _vm.item.locked,
                id: _vm.formId
              },
              on: {
                submit: function($event) {
                  return _vm.evh_2248226175642056_submit($event, {})
                }
              }
            },
            [
              _vm.descriptionRequired
                ? _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "description",
                        label: _vm._f("pgettext")(
                          "Description",
                          "InvoiceGenerator"
                        ),
                        labelColsMd: "6",
                        contentColsMd: "6",
                        labelAlign: _vm.labelAlign,
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormInput", {
                        attrs: { name: "description", required: "" },
                        model: {
                          value: _vm.item.description,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "description", $$v)
                          },
                          expression: "item.description"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.descriptionRequired ? _c("HR") : _vm._e(),
              _vm._v(" "),
              _c(
                "ItpBox",
                {
                  staticClass: "p-2 w-100",
                  attrs: { wrap: "", direction: "column" }
                },
                [
                  _c("ItpText", {
                    staticClass: "mb-2",
                    attrs: {
                      strong: "",
                      block: "",
                      text: _vm._f("pgettext")(
                        "Product Invoice Groups",
                        "InvoiceGenerator"
                      )
                    }
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.productInvoiceGroups, function(item) {
                    return _c("ItpFormCheckbox", {
                      key: item.id,
                      attrs: { name: item.id, label: item.name },
                      model: {
                        value: _vm.invoiceGroupFilters[item.id],
                        callback: function($$v) {
                          _vm.$set(_vm.invoiceGroupFilters, item.id, $$v)
                        },
                        expression: "invoiceGroupFilters[item.id]"
                      }
                    })
                  }),
                  _vm._v(" "),
                  _c("ItpFormCheckbox", {
                    attrs: {
                      name: "include_worksheet",
                      label: _vm._f("pgettext")(
                        "Include works from worksheets",
                        "InvoiceGenerator"
                      )
                    },
                    model: {
                      value: _vm.includeWorksheets,
                      callback: function($$v) {
                        _vm.includeWorksheets = $$v
                      },
                      expression: "includeWorksheets"
                    }
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c("HR"),
              _vm._v(" "),
              _c(
                "ItpPanel",
                { attrs: { stateKey: "InvoiceGenerator.panels.parameters" } },
                [
                  _c("ItpText", {
                    attrs: {
                      slot: "caption",
                      strong: "",
                      muted: "",
                      block: "",
                      text: _vm._f("pgettext")("Parameters", "InvoiceGenerator")
                    },
                    slot: "caption"
                  }),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "billing_period_start",
                        label: _vm._f("pgettext")(
                          "Billing Period Start",
                          "InvoiceGenerator"
                        ),
                        labelColsMd: "6",
                        contentColsMd: "6",
                        labelAlign: _vm.labelAlign,
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormDatetime", {
                        attrs: {
                          name: "billing_period_start",
                          placeholder: _vm._f("pgettext")(
                            "Start of Period",
                            "InvoiceGenerator"
                          ),
                          required: ""
                        },
                        model: {
                          value: _vm.item.billing_period_start,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "billing_period_start", $$v)
                          },
                          expression: "item.billing_period_start"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "billing_period_end",
                        label: _vm._f("pgettext")(
                          "Billing Period End",
                          "InvoiceGenerator"
                        ),
                        labelColsMd: "6",
                        contentColsMd: "6",
                        labelAlign: _vm.labelAlign,
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormDatetime", {
                        attrs: {
                          name: "billing_period_end",
                          placeholder: _vm._f("pgettext")(
                            "End of Period",
                            "InvoiceGenerator"
                          ),
                          required: ""
                        },
                        model: {
                          value: _vm.item.billing_period_end,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "billing_period_end", $$v)
                          },
                          expression: "item.billing_period_end"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "invoice_date",
                        label: _vm._f("pgettext")(
                          "Invoice Date",
                          "InvoiceGenerator"
                        ),
                        labelColsMd: "6",
                        contentColsMd: "6",
                        labelAlign: _vm.labelAlign,
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormDatetime", {
                        attrs: { name: "invoice_date", required: "" },
                        model: {
                          value: _vm.item.invoice_date,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "invoice_date", $$v)
                          },
                          expression: "item.invoice_date"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "fulfillment_date",
                        label: _vm._f("pgettext")(
                          "Fulfillment Date",
                          "InvoiceGenerator"
                        ),
                        labelColsMd: "6",
                        contentColsMd: "6",
                        labelAlign: _vm.labelAlign,
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormDatetime", {
                        attrs: { name: "fulfillment_date", required: "" },
                        model: {
                          value: _vm.item.fulfillment_date,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "fulfillment_date", $$v)
                          },
                          expression: "item.fulfillment_date"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "due_date",
                        label: _vm._f("pgettext")(
                          "Due Date",
                          "InvoiceGenerator"
                        ),
                        labelColsMd: "6",
                        contentColsMd: "6",
                        labelAlign: _vm.labelAlign,
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormDatetime", {
                        attrs: {
                          name: "due_date",
                          required: "",
                          placeholder: _vm._f("pgettext")(
                            "Due Date",
                            "InvoiceGenerator"
                          )
                        },
                        model: {
                          value: _vm.item.due_date,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "due_date", $$v)
                          },
                          expression: "item.due_date"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "meter_readings_start_date",
                        label: _vm._f("pgettext")(
                          "Meter Readings From",
                          "InvoiceGenerator"
                        ),
                        labelColsMd: "6",
                        contentColsMd: "6",
                        labelAlign: _vm.labelAlign
                      }
                    },
                    [
                      _c("ItpFormDatetime", {
                        attrs: {
                          name: "meter_readings_start_date",
                          placeholder: _vm._f("pgettext")(
                            "Meter Readings From",
                            "InvoiceGenerator"
                          )
                        },
                        model: {
                          value: _vm.item.meter_readings_start_date,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "meter_readings_start_date", $$v)
                          },
                          expression: "item.meter_readings_start_date"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "invoice_tags",
                        label: _vm._f("pgettext")(
                          "Invoice Tags",
                          "InvoiceGenerator"
                        ),
                        labelColsMd: "6",
                        contentColsMd: "6",
                        labelAlign: _vm.labelAlign
                      }
                    },
                    [
                      _c("ItpSelect2", {
                        attrs: {
                          name: "invoice_tags",
                          taggable: "",
                          multiple: ""
                        },
                        model: {
                          value: _vm.item.invoice_tags,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "invoice_tags", $$v)
                          },
                          expression: "item.invoice_tags"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("HR"),
              _vm._v(" "),
              _c(
                "ItpPanel",
                { attrs: { stateKey: "InvoiceGenerator.panels.options" } },
                [
                  _c("ItpText", {
                    attrs: {
                      slot: "caption",
                      strong: "",
                      muted: "",
                      block: "",
                      text: _vm._f("pgettext")("Options", "InvoiceGenerator")
                    },
                    slot: "caption"
                  }),
                  _vm._v(" "),
                  _c(
                    "fieldset",
                    [
                      _c(
                        "ItpFormGroup",
                        [
                          _c("ItpFormCheckbox", {
                            attrs: {
                              name: "include_zero_consumption",
                              label: _vm._f("pgettext")(
                                "Include zero consumptions",
                                "InvoiceGenerator"
                              ),
                              required: ""
                            },
                            model: {
                              value: _vm.item.include_zero_consumption,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.item,
                                  "include_zero_consumption",
                                  $$v
                                )
                              },
                              expression: "item.include_zero_consumption"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "fieldset",
                    [
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            help: _vm._f("gettext")(
                              "Charge all consumptions, even minimal ones."
                            )
                          }
                        },
                        [
                          _c("ItpFormCheckbox", {
                            attrs: {
                              name: "ignore_meter_billing_thresholds",
                              label: _vm._f("pgettext")(
                                "Ignore meter billing thresholds.",
                                "InvoiceGenerator"
                              ),
                              required: ""
                            },
                            model: {
                              value: _vm.item.ignore_meter_billing_thresholds,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.item,
                                  "ignore_meter_billing_thresholds",
                                  $$v
                                )
                              },
                              expression: "item.ignore_meter_billing_thresholds"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("HR"),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: {
                  type: "submit",
                  form:
                    _vm.forms.InvoiceGeneration &&
                    _vm.forms.InvoiceGeneration.id,
                  text: _vm._f("pgettext")(
                    "Generate Invoices",
                    "InvoiceGenerator"
                  ),
                  default: "",
                  disabled: !_vm.canSubmit,
                  spinning: _vm.actions.submit.isRunning
                }
              }),
              _vm._v(" "),
              _vm.currentGeneratorTask
                ? _c("TaskStatus", {
                    attrs: { taskId: _vm.currentGeneratorTask.id }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }