var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _vm.item.revision_date
        ? _c(
            "DIV",
            [
              _c("ItpText", { attrs: { muted: "" } }, [
                _vm._v(
                  _vm._s(_vm._f("pgettext")("Contract date", "Contract")) + ":"
                )
              ]),
              _vm._v(" " + _vm._s(_vm.item.revision_date) + "\n\n  ")
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.item.valid_from
        ? _c(
            "DIV",
            [
              _c("ItpText", { attrs: { muted: "" } }, [
                _vm._v(_vm._s(_vm._f("pgettext")("From", "Contract")) + ":")
              ]),
              _vm._v(" " + _vm._s(_vm.item.valid_from) + "\n\n  ")
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.item.valid_to
        ? _c(
            "DIV",
            [
              _c("ItpText", { attrs: { muted: "" } }, [
                _vm._v(_vm._s(_vm._f("pgettext")("To", "Contract")) + ":")
              ]),
              _vm._v(" " + _vm._s(_vm.item.valid_to) + "\n\n  ")
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.item.valid_to
        ? _c(
            "DIV",
            [
              _c("ItpText", { attrs: { muted: "" } }, [
                _vm._v(_vm._s(_vm._f("pgettext")("To", "Contract")) + ":")
              ]),
              _vm._v(
                " " +
                  _vm._s(_vm._f("pgettext")("Indefinite", "Contract")) +
                  "\n\n  "
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.item.notice_period
        ? _c(
            "DIV",
            [
              _c("ItpText", { attrs: { muted: "" } }, [
                _vm._v(
                  _vm._s(_vm._f("pgettext")("Notice Period", "Contract")) + ":"
                )
              ]),
              _vm._v(" " + _vm._s(_vm.item.notice_period) + "\n\n  ")
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "DIV",
        [
          _c("ItpText", { attrs: { muted: "" } }, [
            _vm._v(_vm._s(_vm._f("pgettext")("Auto renewal", "Contract")) + ":")
          ]),
          _vm._v(
            " " +
              _vm._s(
                _vm.item.auto_renewal ? _vm.gettext("On") : _vm.gettext("Off")
              ) +
              "\n\n  "
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }