var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "RentalBrowser",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _c(
        "VueSlickgrid",
        {
          ref: "grid",
          staticClass: "page--grid",
          attrs: {
            name: "rentals",
            headerTitle: _vm._f("gettext")("Rentals"),
            items: _vm.$fn.slickDataSource("list_rental", {
              parameters: _vm.getQueryParameters
            }),
            "checkbox-row-selection": "",
            columnDefaults: _vm.b_2248226175642056_columnDefaults,
            "export-to-csv": "",
            exportToCsvFilename: _vm._f("pgettext")("rentals", "filename"),
            columns: _vm.b_2248226175642056_columns
          },
          on: {
            selectedRowsChanged: function($event) {
              return _vm.evh_2248226175642056_selectedRowsChanged($event, {})
            },
            command: function($event) {
              return _vm.evh_2248226175642056_command($event, {})
            }
          }
        },
        [
          _c(
            "ItpBox",
            {
              staticClass: "flex-grow-1",
              attrs: { slot: "toolbar", alignItems: "center" },
              slot: "toolbar"
            },
            [
              _c(
                "DIV",
                { staticClass: "mx-2" },
                [
                  _c("ItpFormCheckbox", {
                    attrs: {
                      name: "actives_only",
                      label: _vm._f("pgettext")(
                        "Display active rentals only",
                        "RentalBrowser"
                      ),
                      inline: ""
                    },
                    model: {
                      value: _vm.dsParams.actives_only,
                      callback: function($$v) {
                        _vm.$set(_vm.dsParams, "actives_only", $$v)
                      },
                      expression: "dsParams.actives_only"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("ItpButton", {
                staticClass: "mx-2",
                attrs: {
                  icon: _vm.$config.rental.addRentalIcon,
                  text: _vm._f("pgettext")("Add rental...", "RentalBrowser"),
                  size: "sm",
                  variant: "light"
                },
                on: {
                  click: function($event) {
                    return _vm.evh_8320016629450276_click($event, {})
                  }
                }
              }),
              _vm._v(" "),
              _c("PageHelp", {
                staticClass: "ml-auto",
                attrs: { path: "/rentals/rentals.html" }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }