var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        card: "",
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "DunningLetter",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c(
            "UcHeader",
            {
              attrs: {
                slot: "header",
                card: !_vm.modal,
                modal: _vm.modal,
                title: _vm.documentTitle,
                color: _vm.$config.dunningLetter.color
              },
              on: {
                close: function($event) {
                  return _vm.evh_2248226175642056_close($event, {})
                }
              },
              slot: "header"
            },
            [
              !_vm.isModalComponent && _vm.item
                ? _c("strong", { staticClass: "text-muted mr-2" }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm._f("gettext")("Dunning letter")) +
                        " - " +
                        _vm._s(_vm.item.reference_number) +
                        "\n    "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btn-toolbar flex-nowrap" },
                [
                  _c("ItpButton", {
                    staticClass: "mr-2",
                    attrs: {
                      variant: "light",
                      icon: "fa-sync-alt",
                      text: _vm._f("gettext")("Reload"),
                      spinning: _vm.actions.reloadData.isRunning
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_3943151158818434_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("ItpButton", {
                    staticClass: "mr-2",
                    attrs: {
                      type: "submit",
                      form: "u1w012sjv6",
                      variant: "primary",
                      icon: "fa-save",
                      text: _vm._f("gettext")("Save"),
                      spinning: _vm.actions.save.isRunning,
                      disabled:
                        !_vm.forms.dunningLetter ||
                        !_vm.forms.dunningLetter.submittable
                    }
                  }),
                  _vm._v(" "),
                  _vm.item.is_deletable
                    ? _c("ItpButton", {
                        staticClass: "mr-2",
                        attrs: {
                          variant: "danger",
                          icon: "fa-trash",
                          text: _vm._f("gettext")("Delete"),
                          spinning: _vm.actions.delete.isRunning
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.evh_8518026866742051_clickPreventStop(
                              $event,
                              {}
                            )
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-dropdown",
                    {
                      ref: "sendMailDropdown",
                      staticClass: "mr-2",
                      attrs: { "no-caret": "" }
                    },
                    [
                      _c(
                        "DIV",
                        {
                          staticClass: "text-nowrap",
                          attrs: { slot: "button-content" },
                          slot: "button-content"
                        },
                        [
                          _c("ItpIcon", {
                            staticClass: "mr-2",
                            attrs: { name: "fa-envelope" }
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm._f("gettext")("Send email")) +
                                "\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c("ItpIcon", {
                            staticClass: "ml-2",
                            attrs: { name: "fa-caret-down" }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-form",
                        { style: _vm.b_7495010493811270_style },
                        [
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                name: "email",
                                label: _vm._f("gettext")("Recipient"),
                                description: _vm._f("gettext")(
                                  "Use semicolon to separate multiple addresses."
                                )
                              }
                            },
                            [
                              _c("ItpFormInput", {
                                attrs: {
                                  type: "text",
                                  name: "email",
                                  placeholder: _vm._f("gettext")(
                                    "Email address"
                                  ),
                                  size: "md"
                                },
                                model: {
                                  value: _vm.sendmail.email,
                                  callback: function($$v) {
                                    _vm.$set(_vm.sendmail, "email", $$v)
                                  },
                                  expression: "sendmail.email"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                name: "template",
                                label: _vm._f("gettext")("Template")
                              }
                            },
                            [
                              _c("ItpFormSelect", {
                                attrs: {
                                  name: "template",
                                  size: "md",
                                  required: "",
                                  options: _vm.emailTemplates,
                                  valueField: "id",
                                  textField: "name"
                                },
                                model: {
                                  value: _vm.sendmail.template,
                                  callback: function($$v) {
                                    _vm.$set(_vm.sendmail, "template", $$v)
                                  },
                                  expression: "sendmail.template"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("JobHandlerControl", {
                            attrs: {
                              jobType: "dunning_letters:email",
                              payload: function() {
                                return {
                                  id: _vm.item.id,
                                  template: _vm.sendmail.template,
                                  mail_to: _vm._.chain(
                                    _vm._.split(_vm.sendmail.email, ";")
                                  )
                                    .map(_vm._.trim)
                                    .filter(function(p) {
                                      return !!p
                                    })
                                    .value()
                                }
                              },
                              text: _vm._f("pgettext")(
                                "Send",
                                "DunningLetter|Submit"
                              ),
                              icon: "fa-envelope",
                              block: ""
                            },
                            on: {
                              completed: function($event) {
                                return _vm.evh_2492577004902623_completed(
                                  $event,
                                  {}
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-dropdown",
                    {
                      ref: "generatePdfDropdown",
                      staticClass: "mr-2",
                      attrs: { "no-caret": "" }
                    },
                    [
                      _c(
                        "DIV",
                        {
                          staticClass: "text-nowrap",
                          attrs: { slot: "button-content" },
                          slot: "button-content"
                        },
                        [
                          _c("ItpIcon", {
                            staticClass: "mr-2",
                            attrs: { name: "fa-file-pdf" }
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm._f("gettext")("Generate document")) +
                                "\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c("ItpIcon", {
                            staticClass: "ml-2",
                            attrs: { name: "fa-caret-down" }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-form",
                        { style: _vm.b_6865942025371558_style },
                        [
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                name: "template",
                                label: _vm._f("gettext")("Template")
                              }
                            },
                            [
                              _c("ItpFormSelect", {
                                attrs: {
                                  name: "template",
                                  placeholder: _vm._f("gettext")("Template"),
                                  size: "md",
                                  required: "",
                                  options: _vm.htmlTemplates,
                                  valueField: "id",
                                  textField: "name"
                                },
                                model: {
                                  value: _vm.printdoc.template,
                                  callback: function($$v) {
                                    _vm.$set(_vm.printdoc, "template", $$v)
                                  },
                                  expression: "printdoc.template"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("JobHandlerControl", {
                            attrs: {
                              jobType: "dunning_letters:pdf_generation",
                              payload: function() {
                                return {
                                  id: _vm.item.id,
                                  template: _vm.printdoc.template
                                }
                              },
                              text: _vm._f("pgettext")(
                                "Generate document",
                                "DunningLetter|Submit"
                              ),
                              icon: "fa-file-pdf",
                              block: ""
                            },
                            on: {
                              completed: function($event) {
                                return _vm.evh_4810867692521497_completed(
                                  $event,
                                  {}
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.item
                ? _c("ItpResourceMeta", {
                    staticClass: "ml-auto",
                    attrs: { item: _vm.item }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "DIV",
                { staticClass: "row" },
                [
                  _c(
                    "DIV",
                    { staticClass: "col-7" },
                    [
                      _vm.item && _vm.item.id
                        ? _c(
                            "ItpForm",
                            {
                              attrs: {
                                name: "dunningLetter",
                                id: "u1w012sjv6"
                              },
                              on: {
                                submit: function($event) {
                                  return _vm.evh_7789867021953041_submit(
                                    $event,
                                    {}
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "row" },
                                [
                                  _c(
                                    "ItpFormGroup",
                                    {
                                      staticClass: "col-md-4",
                                      attrs: {
                                        name: "status",
                                        label: _vm._f("gettext")("Status")
                                      }
                                    },
                                    [
                                      _c("ItpFormSelect", {
                                        attrs: {
                                          name: "status",
                                          size: "md",
                                          options:
                                            _vm.b_3273883411533724_options
                                        },
                                        model: {
                                          value: _vm.item.status,
                                          callback: function($$v) {
                                            _vm.$set(_vm.item, "status", $$v)
                                          },
                                          expression: "item.status"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ItpFormGroup",
                                    {
                                      staticClass: "col-md-2",
                                      attrs: {
                                        name: "severity",
                                        label: _vm._f("gettext")("Severity")
                                      }
                                    },
                                    [
                                      _c("ItpFormInput", {
                                        attrs: {
                                          name: "severity",
                                          size: "md",
                                          type: "number",
                                          min: "1",
                                          max: "3"
                                        },
                                        model: {
                                          value: _vm.item.severity,
                                          callback: function($$v) {
                                            _vm.$set(_vm.item, "severity", $$v)
                                          },
                                          expression: "item.severity"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "ItpFormGroup",
                                {
                                  attrs: {
                                    name: "notes",
                                    label: _vm._f("gettext")("Notes")
                                  }
                                },
                                [
                                  _c("ItpFormTextarea", {
                                    attrs: { name: "notes", size: "md" },
                                    model: {
                                      value: _vm.item.notes,
                                      callback: function($$v) {
                                        _vm.$set(_vm.item, "notes", $$v)
                                      },
                                      expression: "item.notes"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "ItpFormGroup",
                                {
                                  attrs: {
                                    name: "name",
                                    label: _vm._f("gettext")("Client")
                                  }
                                },
                                [
                                  _c("ItpFormInput", {
                                    attrs: {
                                      name: "name",
                                      size: "md",
                                      readonly: ""
                                    },
                                    model: {
                                      value: _vm.item.customer_name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.item, "customer_name", $$v)
                                      },
                                      expression: "item.customer_name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "ItpFormGroup",
                                {
                                  attrs: {
                                    name: "address",
                                    label: _vm._f("gettext")("Address")
                                  }
                                },
                                [
                                  _c("ItpFormInput", {
                                    attrs: {
                                      name: "address",
                                      size: "md",
                                      readonly: ""
                                    },
                                    model: {
                                      value: _vm.item.customer_data.address,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.item.customer_data,
                                          "address",
                                          $$v
                                        )
                                      },
                                      expression: "item.customer_data.address"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "DIV",
                                { staticClass: "row" },
                                [
                                  _c(
                                    "ItpFormGroup",
                                    { attrs: { "cols-md": "4" } },
                                    [
                                      _c("ItpFormInput", {
                                        attrs: {
                                          name: "zipcode",
                                          size: "md",
                                          readonly: ""
                                        },
                                        model: {
                                          value: _vm.item.customer_data.zipcode,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.item.customer_data,
                                              "zipcode",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.customer_data.zipcode"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ItpFormGroup",
                                    { attrs: { "cols-md": "8" } },
                                    [
                                      _c("ItpFormInput", {
                                        attrs: {
                                          name: "city",
                                          size: "md",
                                          readonly: ""
                                        },
                                        model: {
                                          value: _vm.item.customer_data.city,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.item.customer_data,
                                              "city",
                                              $$v
                                            )
                                          },
                                          expression: "item.customer_data.city"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "DIV",
                        { staticClass: "form-group" },
                        [
                          _c("label", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm._f("gettext")("Invoices")) +
                                "\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _vm.item
                            ? _c("ItpTable", {
                                attrs: {
                                  rows:
                                    _vm.item.items.length > 1
                                      ? _vm.item.items.concat([
                                          {
                                            $rowClass:
                                              "font-weight-bold text-muted",
                                            invoice_number: "Total",
                                            invoice_data: {
                                              amount: _vm.$fn._.sumBy(
                                                _vm.item.items,
                                                function(p) {
                                                  return p.invoice_data.amount
                                                }
                                              ),
                                              paid: _vm.$fn._.sumBy(
                                                _vm.item.items,
                                                function(p) {
                                                  return p.invoice_data.paid
                                                }
                                              ),
                                              debt: _vm.$fn._.sumBy(
                                                _vm.item.items,
                                                function(p) {
                                                  return p.invoice_data.debt
                                                }
                                              )
                                            }
                                          }
                                        ])
                                      : _vm.item.items,
                                  columns: _vm.b_2771751501651205_columns
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("DIV", { staticClass: "col-5" }, [
                    !_vm.item.jobs.length
                      ? _c("div", { staticClass: "container-fluid" }, [
                          _c(
                            "div",
                            {
                              staticClass: "text-center mt-2",
                              attrs: { role: "alert" }
                            },
                            [
                              _vm._v(
                                "\n  " +
                                  _vm._s(_vm._f("gettext")("No events")) +
                                  "\n"
                              )
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.item.jobs.length
                      ? _c(
                          "div",
                          { staticClass: "container-fluid" },
                          _vm._l(
                            _vm.$fn._.orderBy(
                              _vm.item.jobs,
                              ["number"],
                              ["desc"]
                            ),
                            function(job) {
                              return _c(
                                "div",
                                { key: job.id, staticClass: "row" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-4 text-right pb-5 text-nowrap text-truncate"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "text-nowrap" },
                                        [
                                          _vm._v(
                                            "\n      #" +
                                              _vm._s(job.number) +
                                              "\n      "
                                          ),
                                          job.job_type === "email"
                                            ? _c("ItpIcon", {
                                                attrs: { name: "fa-envelope" }
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          job.job_type === "document"
                                            ? _c("ItpIcon", {
                                                attrs: { name: "fa-file-pdf" }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "text-muted text-nowrap",
                                          attrs: {
                                            title: _vm._f("datetime")(
                                              job.created_at
                                            )
                                          }
                                        },
                                        [
                                          _c("ItpIcon", {
                                            attrs: { name: "fa-clock" }
                                          }),
                                          _vm._v(
                                            "\n      " +
                                              _vm._s(
                                                _vm._f(
                                                  "datetime"
                                                )(job.created_at, {
                                                  distanceToNow: true
                                                })
                                              ) +
                                              "\n    "
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "text-muted text-nowrap"
                                        },
                                        [
                                          _vm._v(
                                            "\n      " +
                                              _vm._s(
                                                _vm._f("username")(
                                                  job.created_by
                                                )
                                              ) +
                                              "\n    "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-8 border-left pb-5 text-nowrap text-truncate"
                                    },
                                    [
                                      _c("div", [
                                        job.updated_at
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "ml-1 text-muted text-nowrap",
                                                attrs: {
                                                  title: _vm._f("datetime")(
                                                    job.updated_at
                                                  )
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n        " +
                                                    _vm._s(
                                                      _vm._f(
                                                        "datetime"
                                                      )(job.updated_at, {
                                                        distanceToNow: true
                                                      })
                                                    ) +
                                                    "\n      "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]),
                                      _vm._v(" "),
                                      job.job_type === "email"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "text-truncate",
                                              attrs: { title: job.mail_to }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "text-muted mr-1"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n        " +
                                                      _vm._s(
                                                        _vm._f("pgettext")(
                                                          "To",
                                                          "email"
                                                        )
                                                      ) +
                                                      ":\n      "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                "\n      " +
                                                  _vm._s(
                                                    _vm.$fn._.isArray(
                                                      job.mail_to
                                                    )
                                                      ? job.mail_to.join("; ")
                                                      : job.mail_to
                                                  ) +
                                                  "\n    "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      job.job_type === "email" && job.subject
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "text-truncate",
                                              attrs: { title: job.subject }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "text-muted mr-1"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n        " +
                                                      _vm._s(
                                                        _vm._f("pgettext")(
                                                          "Subject",
                                                          "email"
                                                        )
                                                      ) +
                                                      ":\n      "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                "\n      " +
                                                  _vm._s(job.subject) +
                                                  "\n    "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      job.job_type === "email" && job.tracking
                                        ? _c(
                                            "div",
                                            {
                                              attrs: {
                                                title: _vm._f("datetime")(
                                                  job.tracking.timestamp
                                                )
                                              }
                                            },
                                            [
                                              _c("ItpIcon", {
                                                attrs: { name: "fa-eye" }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "ml-2" },
                                                [
                                                  _vm._v(
                                                    "\n        " +
                                                      _vm._s(
                                                        _vm._f("datetime")(
                                                          job.tracking
                                                            .timestamp,
                                                          {
                                                            distanceToNow: true
                                                          }
                                                        )
                                                      ) +
                                                      "\n      "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      job.log_url || job.pdf_url || job.html_url
                                        ? _c("hr", { staticClass: "my-2" })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      job.status === "failed"
                                        ? _c(
                                            "div",
                                            [
                                              _c("ItpLink", {
                                                staticClass: "d-block",
                                                attrs: {
                                                  href: job.log_url,
                                                  icon: "fa-file-alt",
                                                  text: _vm.$i18n._(
                                                    "Error log"
                                                  ),
                                                  tooltip: job.log_filename,
                                                  target: "_blank",
                                                  "use-auth": ""
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      job.status === "completed"
                                        ? _c(
                                            "div",
                                            [
                                              job.pdf_url
                                                ? _c("ItpLink", {
                                                    staticClass: "d-block",
                                                    attrs: {
                                                      href: job.pdf_url,
                                                      icon: "fa-file-pdf",
                                                      text: "PDF",
                                                      tooltip: job.pdf_filename,
                                                      target: "_blank",
                                                      "use-auth": ""
                                                    }
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              job.html_url
                                                ? _c("ItpLink", {
                                                    staticClass: "d-block",
                                                    attrs: {
                                                      href: job.html_url,
                                                      icon: "fa-file-code",
                                                      text: "HTML",
                                                      tooltip:
                                                        job.html_filename,
                                                      target: "_blank",
                                                      "use-auth": ""
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e()
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.modal && _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("pgettext")("Close", "DialogButton"),
                  variant: "default",
                  disabled: _vm.actions.save && _vm.actions.save.isRunning
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1548630417156826_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }