var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "ItpBox",
        { attrs: { direction: "column", alignItems: "stretch" } },
        [
          _c("ItpBox", { staticClass: "pb-2" }, [
            _c("strong", [
              _vm._v(
                "\n      " +
                  _vm._s(_vm._f("pgettext")("Works", "Worksheet")) +
                  "\n    "
              )
            ])
          ]),
          _vm._v(" "),
          _vm.loaded
            ? _c("ItpDataTable", {
                staticClass: "h-100",
                attrs: {
                  rowKey: "id",
                  tableId: "WorksheetWorkItems",
                  rowHeader: _vm.b_5962862420439144_rowHeader,
                  paging: _vm.b_5962862420439144_paging,
                  sortable: _vm.b_5962862420439144_sortable,
                  editable: !_vm.isReadonly,
                  value: _vm.worksheet.work_items,
                  variant: "details",
                  rowTemplate: {
                    worksheet_id: _vm.worksheet.id,
                    task: null,
                    service_id: null
                  },
                  columns: _vm.b_5962862420439144_columns
                },
                on: {
                  "save-completed": function($event) {
                    return _vm.evh_5962862420439144_saveCompleted($event, {})
                  },
                  save: function($event) {
                    return _vm.evh_5962862420439144_save($event, {})
                  },
                  delete: function($event) {
                    return _vm.evh_5962862420439144_delete($event, {})
                  },
                  "service:selected": function($event) {
                    return _vm.evh_5962862420439144_serviceSelected($event, {})
                  },
                  "service:change": function($event) {
                    return _vm.evh_5962862420439144_serviceChange($event, {})
                  },
                  "quantity:change": function($event) {
                    return _vm.evh_5962862420439144_quantityChange($event, {})
                  },
                  "unit_price:change": function($event) {
                    return _vm.evh_5962862420439144_unitPriceChange($event, {})
                  },
                  "discount:change": function($event) {
                    return _vm.evh_5962862420439144_discountChange($event, {})
                  },
                  "net_amount:change": function($event) {
                    return _vm.evh_5962862420439144_netAmountChange($event, {})
                  },
                  "amount:change": function($event) {
                    return _vm.evh_5962862420439144_amountChange($event, {})
                  },
                  "tax_code:change": function($event) {
                    return _vm.evh_5962862420439144_taxCodeChange($event, {})
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }