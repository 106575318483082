var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpBox",
        { attrs: { alignItems: "center" } },
        [
          _c(
            "ItpText",
            { attrs: { title: _vm.fromTooltip || _vm.defaultFromTooltip } },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.from
                      ? _vm.$options.filters.date(_vm.from)
                      : _vm.missingFromMessage || _vm.defaultMissingFromMessage
                  ) +
                  "\n    "
              )
            ]
          ),
          _vm._v(" "),
          _vm.to || _vm.missingToMessage || _vm.defaultMissingToMessage
            ? _c("span", [_vm._v("\n       - \n    ")])
            : _vm._e(),
          _vm._v(" "),
          _vm.to || _vm.missingToMessage || _vm.defaultMissingToMessage
            ? _c(
                "ItpText",
                { attrs: { title: _vm.toTooltip || _vm.defaultToTooltip } },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.to
                          ? _vm.$options.filters.date(_vm.to)
                          : _vm.missingToMessage || _vm.defaultMissingToMessage
                      ) +
                      "\n    "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }