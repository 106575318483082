var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "ConfirmSyncInvoice",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c("ItpAlert", { attrs: { variant: "info", show: "" } }, [
                _c(
                  "p",
                  [
                    _c("ItpIcon", { attrs: { fa: "info-circle" } }),
                    _vm._v(" "),
                    _c("ItpText", { attrs: { wrap: "" } }, [
                      _vm._v(
                        "\n          Szinkronizálás után a számla már nem módosítható.\n        "
                      )
                    ])
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "ItpForm",
                {
                  ref: "mainForm",
                  attrs: { id: _vm.formId, name: "confirmSyncInvoice" },
                  on: {
                    submit: function($event) {
                      return _vm.evh_4186377094414664_submit($event, {})
                    }
                  }
                },
                [
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "copies",
                        label: _vm._f("pgettext")("Print", "InvoiceSync"),
                        labelAlign: _vm.labelAlign,
                        "label-cols-md": "3",
                        "content-cols-md": "3"
                      }
                    },
                    [
                      _c("ItpFormCheckbox", {
                        attrs: { name: "copies", required: "", size: "md" },
                        model: {
                          value: _vm.payload.print,
                          callback: function($$v) {
                            _vm.$set(_vm.payload, "print", $$v)
                          },
                          expression: "payload.print"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "print_copies",
                        label: _vm._f("pgettext")("Copies", "InvoiceSync"),
                        labelAlign: _vm.labelAlign,
                        "label-cols-md": "3",
                        "content-cols-md": "3"
                      }
                    },
                    [
                      _c("ItpFormInput", {
                        attrs: {
                          type: "number",
                          name: "print_copies",
                          required: "",
                          disabled: !_vm.payload.print
                        },
                        model: {
                          value: _vm.payload.print_copies,
                          callback: function($$v) {
                            _vm.$set(_vm.payload, "print_copies", $$v)
                          },
                          expression: "payload.print_copies"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("pgettext")("Create invoice", "Button"),
                  disabled:
                    !_vm.forms.confirmSyncInvoice ||
                    !_vm.forms.confirmSyncInvoice.submittable,
                  type: "submit",
                  form: _vm.formId,
                  variant: "primary",
                  icon: "fa-save",
                  spinning: _vm.actions.submit.isRunning
                }
              }),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("gettext")("Cancel"),
                  variant: "default",
                  disabled: _vm.actions.submit.isRunning
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1419464017721962_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }