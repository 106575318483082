var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "Numbering",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "ItpForm",
                {
                  attrs: {
                    name: "numbering",
                    readonly: _vm.item.locked,
                    labelAlign: _vm.$config.forms.defaultLabelAlign,
                    id: _vm.formId
                  },
                  on: {
                    submit: function($event) {
                      return _vm.evh_1040167445267876_submit($event, {})
                    }
                  }
                },
                [
                  _c(
                    "ItpRow",
                    [
                      _c(
                        "ItpCol",
                        [
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                labelColsMd: "2",
                                contentColsMd: "5",
                                name: "resource_type",
                                label: _vm._f("pgettext")(
                                  "Resource type",
                                  "Numbering"
                                ),
                                required: ""
                              }
                            },
                            [
                              _c("ItpFormSelect", {
                                attrs: {
                                  name: "resource_type",
                                  options: _vm.resourceTypes,
                                  required: "",
                                  placeholder: _vm._f("pgettext")(
                                    "The type of the resource.",
                                    "Numbering"
                                  )
                                },
                                model: {
                                  value: _vm.item.resource_type,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "resource_type", $$v)
                                  },
                                  expression: "item.resource_type"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.oid
                            ? _c(
                                "ItpFormGroup",
                                {
                                  attrs: {
                                    labelColsMd: "2",
                                    contentColsMd: "5",
                                    name: "key",
                                    label: _vm._f("pgettext")(
                                      "Key",
                                      "Numbering"
                                    ),
                                    required: ""
                                  }
                                },
                                [
                                  _c("ItpFormInput", {
                                    attrs: {
                                      name: "key",
                                      required: "",
                                      placeholder: _vm._f("pgettext")(
                                        "The unique key of the sequence.",
                                        "Numbering"
                                      )
                                    },
                                    model: {
                                      value: _vm.item.key,
                                      callback: function($$v) {
                                        _vm.$set(_vm.item, "key", $$v)
                                      },
                                      expression: "item.key"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                name: "description",
                                label: _vm._f("pgettext")(
                                  "Description",
                                  "Numbering"
                                ),
                                labelColsMd: "2",
                                contentColsMd: "8",
                                required: ""
                              }
                            },
                            [
                              _c("ItpFormInput", {
                                attrs: {
                                  name: "description",
                                  required: "",
                                  autofocus: "",
                                  placeholder: _vm._f("pgettext")(
                                    "Short name/description.",
                                    "Numbering"
                                  )
                                },
                                model: {
                                  value: _vm.item.description,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "description", $$v)
                                  },
                                  expression: "item.description"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                name: "format",
                                label: _vm._f("pgettext")(
                                  "Format",
                                  "Numbering"
                                ),
                                labelColsMd: "2",
                                contentColsMd: "8",
                                required: ""
                              }
                            },
                            [
                              _c("ItpFormInput", {
                                attrs: { name: "format", required: "" },
                                model: {
                                  value: _vm.item.format,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "format", $$v)
                                  },
                                  expression: "item.format"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                name: "start",
                                label: _vm._f("pgettext")("Start", "Numbering"),
                                labelColsMd: "2",
                                contentColsMd: "2",
                                required: ""
                              }
                            },
                            [
                              _c("ItpFormInput", {
                                attrs: {
                                  type: "number",
                                  name: "start",
                                  required: ""
                                },
                                model: {
                                  value: _vm.item.start,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "start", $$v)
                                  },
                                  expression: "item.start"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                name: "increment",
                                label: _vm._f("pgettext")(
                                  "Increment",
                                  "Numbering"
                                ),
                                labelColsMd: "2",
                                contentColsMd: "2",
                                required: ""
                              }
                            },
                            [
                              _c("ItpFormInput", {
                                attrs: {
                                  type: "number",
                                  name: "increment",
                                  required: ""
                                },
                                model: {
                                  value: _vm.item.increment,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "increment", $$v)
                                  },
                                  expression: "item.increment"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("HR"),
              _vm._v(" "),
              _vm.loaded
                ? _c("ItpDataTable", {
                    attrs: {
                      paging: _vm.b_3083451129821185_paging,
                      value: _vm.item.variables,
                      columns: _vm.b_3083451129821185_columns
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _c("ItpButton", {
                attrs: {
                  form: _vm.forms.numbering && _vm.forms.numbering.id,
                  text: _vm._f("pgettext")("Save", "Numbering"),
                  type: "submit",
                  variant: "primary",
                  default: "",
                  disabled:
                    !_vm.forms.numbering || !_vm.forms.numbering.submittable,
                  spinning: _vm.actions.submit.isRunning
                }
              }),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("pgettext")("Close", "Numbering"),
                  variant: "default",
                  disabled: _vm.actions.submit.isRunning
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1419464017721962_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }