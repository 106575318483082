var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpRow",
        [
          _c(
            "ItpCol",
            { attrs: { w: "6" } },
            [
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    contentColsMd: "8",
                    name: "account_type",
                    label: _vm._f("gettext")("Account type"),
                    labelAlign: _vm.labelAlign,
                    required: ""
                  }
                },
                [
                  _c("ItpFormSelect", {
                    attrs: {
                      name: "account_type",
                      options: _vm.accountTypes,
                      readonly: _vm.isReadOnly,
                      required: ""
                    },
                    model: {
                      value: _vm.item.account_type,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "account_type", $$v)
                      },
                      expression: "item.account_type"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    contentColsMd: "8",
                    name: "name",
                    label: _vm._f("gettext")("Name"),
                    labelAlign: _vm.labelAlign,
                    required: ""
                  }
                },
                [
                  _c("ItpFormInput", {
                    attrs: {
                      name: "name",
                      readonly: _vm.isReadOnly,
                      required: ""
                    },
                    model: {
                      value: _vm.item.name,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "name", $$v)
                      },
                      expression: "item.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    contentColsMd: "5",
                    name: "account_type",
                    label: _vm._f("gettext")("Default"),
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormCheckbox", {
                    attrs: {
                      name: "is_default",
                      inline: "",
                      readonly: _vm.isReadOnly
                    },
                    model: {
                      value: _vm.item.is_default,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "is_default", $$v)
                      },
                      expression: "item.is_default"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    contentColsMd: "5",
                    name: "currency",
                    label: _vm._f("gettext")("Currency"),
                    labelAlign: _vm.labelAlign,
                    required: ""
                  }
                },
                [
                  _c("ItpFormSelect", {
                    attrs: {
                      name: "currency",
                      options: _vm.currencies,
                      valueField: "code",
                      textField: "code",
                      readonly: _vm.isReadOnly,
                      required: ""
                    },
                    model: {
                      value: _vm.item.currency,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "currency", $$v)
                      },
                      expression: "item.currency"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    contentColsMd: "5",
                    name: "currency",
                    label: _vm._f("pgettext")("Rounding", "Account"),
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormSelect", {
                    attrs: {
                      name: "round_to",
                      options: _vm.roundingTypes,
                      readonly: _vm.isReadOnly
                    },
                    model: {
                      value: _vm.item.round_to,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "round_to", $$v)
                      },
                      expression: "item.round_to"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    contentColsMd: "8",
                    name: "owner",
                    label: _vm._f("gettext")("Owner"),
                    labelAlign: _vm.labelAlign,
                    required: ""
                  }
                },
                [
                  _c("ItpFormSelect2", {
                    attrs: {
                      name: "owner_id",
                      dataSource: _vm.b_6041539876822251_dataSource,
                      initialOptions: [_vm.item.owner],
                      valueField: "id",
                      textField: "name",
                      readonly: _vm.isReadOnly,
                      required: ""
                    },
                    model: {
                      value: _vm.item.owner_id,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "owner_id", $$v)
                      },
                      expression: "item.owner_id"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.item.object
                ? _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelColsMd: "4",
                        contentColsMd: "8",
                        name: "payment_in_numbering_id",
                        label: _vm._f("pgettext")(
                          "Numbering (Pay in)",
                          "Account"
                        ),
                        labelAlign: _vm.labelAlign
                      }
                    },
                    [
                      _c("NumberingSelector", {
                        attrs: {
                          name: "payment_in_numbering_id",
                          readonly: _vm.isReadOnly,
                          objectType: _vm.item.object
                        },
                        model: {
                          value: _vm.item.payment_in_numbering_id,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "payment_in_numbering_id", $$v)
                          },
                          expression: "item.payment_in_numbering_id"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.item.object
                ? _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelColsMd: "4",
                        contentColsMd: "8",
                        name: "payment_out_numbering_id",
                        label: _vm._f("pgettext")(
                          "Numbering (Pay out)",
                          "Account"
                        ),
                        labelAlign: _vm.labelAlign
                      }
                    },
                    [
                      _c("NumberingSelector", {
                        attrs: {
                          name: "payment_out_numbering_id",
                          readonly: _vm.isReadOnly,
                          objectType: _vm.item.object
                        },
                        model: {
                          value: _vm.item.payment_out_numbering_id,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "payment_out_numbering_id", $$v)
                          },
                          expression: "item.payment_out_numbering_id"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    contentColsMd: "8",
                    name: "description",
                    label: _vm._f("gettext")("Description"),
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormTextarea", {
                    attrs: { name: "description", readonly: _vm.isReadOnly },
                    model: {
                      value: _vm.item.description,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "description", $$v)
                      },
                      expression: "item.description"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ItpCol",
            { attrs: { w: "6" } },
            [
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "3",
                    contentColsMd: "9",
                    name: "account_number",
                    label: _vm._f("gettext")("Account number"),
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormInput", {
                    attrs: { name: "account_number", readonly: _vm.isReadOnly },
                    model: {
                      value: _vm.item.account_number,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "account_number", $$v)
                      },
                      expression: "item.account_number"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "3",
                    contentColsMd: "9",
                    name: "iban",
                    label: _vm._f("gettext")("IBAN"),
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormInput", {
                    attrs: { name: "iban", readonly: _vm.isReadOnly },
                    model: {
                      value: _vm.item.iban,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "iban", $$v)
                      },
                      expression: "item.iban"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "3",
                    contentColsMd: "9",
                    name: "swift",
                    label: _vm._f("gettext")("SWIFT"),
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormInput", {
                    attrs: { name: "swift", readonly: _vm.isReadOnly },
                    model: {
                      value: _vm.item.swift,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "swift", $$v)
                      },
                      expression: "item.swift"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "3",
                    contentColsMd: "9",
                    name: "bank",
                    label: _vm._f("gettext")("Bank"),
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormInput", {
                    attrs: { name: "bank", readonly: _vm.isReadOnly },
                    model: {
                      value: _vm.item.bank,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "bank", $$v)
                      },
                      expression: "item.bank"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "3",
                    contentColsMd: "9",
                    name: "branch",
                    label: _vm._f("gettext")("Branch"),
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormInput", {
                    attrs: { name: "branch", readonly: _vm.isReadOnly },
                    model: {
                      value: _vm.item.branch,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "branch", $$v)
                      },
                      expression: "item.branch"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }