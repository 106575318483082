var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "name",
            label: _vm._f("pgettext")("Asset Type", "FixedAsset"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c(
            "ItpBadge",
            {
              staticClass: "px-2 shadow-none",
              attrs: { variant: "secondary" }
            },
            [
              _c("SPAN", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.fixedAssetTypes[_vm.fixedAsset.asset_type]) +
                    "\n      "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "name",
            label: _vm._f("pgettext")("Name", "FixedAsset"),
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "name", required: "", autofocus: "" },
            model: {
              value: _vm.fixedAsset.name,
              callback: function($$v) {
                _vm.$set(_vm.fixedAsset, "name", $$v)
              },
              expression: "fixedAsset.name"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "4",
            name: "quantity",
            label: _vm._f("pgettext")("Quantity", "FixedAsset"),
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { type: "number", name: "quantity", size: "md" },
            model: {
              value: _vm.fixedAsset.quantity,
              callback: function($$v) {
                _vm.$set(_vm.fixedAsset, "quantity", $$v)
              },
              expression: "fixedAsset.quantity"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "inventory_no",
            label: _vm._f("pgettext")("Inventory Number", "FixedAsset"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "inventory_no", size: "md" },
            model: {
              value: _vm.fixedAsset.inventory_no,
              callback: function($$v) {
                _vm.$set(_vm.fixedAsset, "inventory_no", $$v)
              },
              expression: "fixedAsset.inventory_no"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "4",
            name: "sku",
            label: _vm._f("pgettext")("SKU", "FixedAsset"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "sku", size: "md" },
            model: {
              value: _vm.fixedAsset.sku,
              callback: function($$v) {
                _vm.$set(_vm.fixedAsset, "sku", $$v)
              },
              expression: "fixedAsset.sku"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "description",
            label: _vm._f("pgettext")("Description", "FixedAsset"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "description", size: "md" },
            model: {
              value: _vm.fixedAsset.description,
              callback: function($$v) {
                _vm.$set(_vm.fixedAsset, "description", $$v)
              },
              expression: "fixedAsset.description"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "type_no",
            label: _vm._f("pgettext")("Type Number", "FixedAsset"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "type_no", size: "md" },
            model: {
              value: _vm.fixedAsset.type_no,
              callback: function($$v) {
                _vm.$set(_vm.fixedAsset, "type_no", $$v)
              },
              expression: "fixedAsset.type_no"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "4",
            name: "cost",
            label: _vm._f("pgettext")("Cost", "FixedAsset"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { type: "number", name: "cost", size: "md" },
            model: {
              value: _vm.fixedAsset.cost,
              callback: function($$v) {
                _vm.$set(_vm.fixedAsset, "cost", $$v)
              },
              expression: "fixedAsset.cost"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "4",
            name: "gl_code",
            label: _vm._f("pgettext")("General Ledger Code", "FixedAsset"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { type: "number", name: "gl_code", size: "md" },
            model: {
              value: _vm.fixedAsset.gl_code,
              callback: function($$v) {
                _vm.$set(_vm.fixedAsset, "gl_code", $$v)
              },
              expression: "fixedAsset.gl_code"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "4",
            name: "year_of_production",
            label: _vm._f("pgettext")("Year of Production", "FixedAsset"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { type: "number", name: "year_of_production", size: "md" },
            model: {
              value: _vm.fixedAsset.year_of_production,
              callback: function($$v) {
                _vm.$set(_vm.fixedAsset, "year_of_production", $$v)
              },
              expression: "fixedAsset.year_of_production"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "manufacturer",
            label: _vm._f("pgettext")("Manufacturer", "FixedAsset"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "manufacturer", size: "md" },
            model: {
              value: _vm.fixedAsset.manufacturer,
              callback: function($$v) {
                _vm.$set(_vm.fixedAsset, "manufacturer", $$v)
              },
              expression: "fixedAsset.manufacturer"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "serial_no",
            label: _vm._f("pgettext")("Serial Number", "FixedAsset"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "serial_no", size: "md" },
            model: {
              value: _vm.fixedAsset.serial_no,
              callback: function($$v) {
                _vm.$set(_vm.fixedAsset, "serial_no", $$v)
              },
              expression: "fixedAsset.serial_no"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "category",
            label: _vm._f("pgettext")("Category", "FixedAsset"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "category", size: "md" },
            model: {
              value: _vm.fixedAsset.category,
              callback: function($$v) {
                _vm.$set(_vm.fixedAsset, "category", $$v)
              },
              expression: "fixedAsset.category"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "groups",
            label: _vm._f("pgettext")("Groups", "FixedAsset"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormSelect2", {
            attrs: {
              name: "groups",
              size: "md",
              multiple: "",
              options: _vm.fixedAssetGroups,
              valueField: "id",
              textField: "name"
            },
            model: {
              value: _vm.fixedAsset.groups,
              callback: function($$v) {
                _vm.$set(_vm.fixedAsset, "groups", $$v)
              },
              expression: "fixedAsset.groups"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }