var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "LetterRegister",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c(
            "VueSlickgrid",
            {
              ref: "grid",
              staticClass: "page--grid",
              attrs: {
                name: "letterRegister",
                headerTitle: _vm._f("pgettext")(
                  "Letter register",
                  "Letter register"
                ),
                items: _vm.$fn.slickDataSource("list_letter_register_entries", {
                  parameters: _vm.getQueryParameters
                }),
                "export-to-csv": "",
                exportToCsvFilename: _vm._f("pgettext")(
                  "letter-register",
                  "filename"
                ),
                "checkbox-row-selection": "",
                headerShowColumnSelectorButton:
                  _vm.b_2248226175642056_headerShowColumnSelectorButton,
                columnDefaults: _vm.b_2248226175642056_columnDefaults,
                columns: _vm.b_2248226175642056_columns
              },
              on: {
                command: function($event) {
                  return _vm.evh_2248226175642056_command($event, {})
                },
                selectedRowsChanged: function($event) {
                  return _vm.evh_2248226175642056_selectedRowsChanged(
                    $event,
                    {}
                  )
                }
              }
            },
            [
              _c(
                "DIV",
                {
                  staticClass: "flex-grow-1 d-flex",
                  attrs: { slot: "toolbar" },
                  slot: "toolbar"
                },
                [
                  _c("ItpFormSelect2", {
                    staticClass: "w-25 mx-3",
                    attrs: {
                      name: "register_name",
                      "bind-object": "",
                      valueField: "id",
                      textField: "name",
                      options: _vm.registers
                    },
                    on: {
                      input: function($event) {
                        return _vm.evh_6466705138206980_input($event, {})
                      }
                    },
                    model: {
                      value: _vm.selectedRegister,
                      callback: function($$v) {
                        _vm.selectedRegister = $$v
                      },
                      expression: "selectedRegister"
                    }
                  }),
                  _vm._v(" "),
                  _c("ItpButton", {
                    staticClass: "mx-2",
                    attrs: {
                      icon: _vm.$config.letterRegister.createEntry,
                      text: _vm._f("pgettext")(
                        "Add entry...",
                        "LetterRegister"
                      ),
                      variant: "light"
                    },
                    on: {
                      click: function($event) {
                        return _vm.evh_8320016629450276_click($event, {})
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }