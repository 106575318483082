var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _vm.item.head_office
        ? _c(
            "DIV",
            [
              _c("ItpText", { attrs: { muted: "" } }, [
                _vm._v(
                  _vm._s(_vm._f("pgettext")("Head Office", "Client")) + ":"
                )
              ]),
              _vm._v(" " + _vm._s(_vm.item.head_office) + "\n\n  ")
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.item.branch_office
        ? _c(
            "DIV",
            [
              _c("ItpText", { attrs: { muted: "" } }, [
                _vm._v(
                  _vm._s(_vm._f("pgettext")("Branch Office", "Client")) + ":"
                )
              ]),
              _vm._v(" " + _vm._s(_vm.item.branch_office) + "\n\n  ")
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.item.statistical_code
        ? _c(
            "DIV",
            [
              _c("ItpText", { attrs: { muted: "" } }, [
                _vm._v(
                  _vm._s(_vm._f("pgettext")("Statistical Code", "Client")) + ":"
                )
              ]),
              _vm._v(" " + _vm._s(_vm.item.statistical_code) + "\n\n  ")
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.item.registration_number
        ? _c(
            "DIV",
            [
              _c("ItpText", { attrs: { muted: "" } }, [
                _vm._v(
                  _vm._s(_vm._f("pgettext")("Registration Number", "Client")) +
                    ":"
                )
              ]),
              _vm._v(" " + _vm._s(_vm.item.registration_number) + "\n\n  ")
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.item.account_number
        ? _c(
            "DIV",
            [
              _c("ItpText", { attrs: { muted: "" } }, [
                _vm._v(
                  _vm._s(_vm._f("pgettext")("Bank Account", "Client")) + ":"
                )
              ]),
              _vm._v(" " + _vm._s(_vm.item.account_number) + "\n\n  ")
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.item.representative
        ? _c(
            "DIV",
            [
              _c("ItpText", { attrs: { muted: "" } }, [
                _vm._v(
                  _vm._s(_vm._f("pgettext")("Representatives", "Contract")) +
                    ":"
                )
              ]),
              _vm._v(" " + _vm._s(_vm.item.representative) + "\n\n  ")
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }