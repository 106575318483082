var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpBox",
    { attrs: { alignItems: "center" } },
    [
      _c("DIV", [
        _vm._v("\n    " + _vm._s(_vm.option.contract_number) + "\n\n  ")
      ]),
      _vm._v(" "),
      _vm.option.contract_type
        ? _c("UcContractTypeBadge", {
            staticClass: "mx-3",
            attrs: { value: _vm.option.contract_type }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.option.status
        ? _c("UcContractStatusBadge", { attrs: { value: _vm.option.status } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }