var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _vm.loaded && _vm.items.length
        ? _c(
            "div",
            { staticClass: "container-fluid" },
            _vm._l(_vm.items, function(job) {
              return _c("div", { key: job.id, staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-4 text-right pb-5 text-nowrap text-truncate"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "text-nowrap" },
                      [
                        _vm._v("\n      #" + _vm._s(job.number) + "\n      "),
                        job.job_type === "email"
                          ? _c("ItpIcon", { attrs: { name: "fa-envelope" } })
                          : _vm._e(),
                        _vm._v(" "),
                        job.job_type === "document"
                          ? _c("ItpIcon", { attrs: { name: "fa-file-pdf" } })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "text-muted text-nowrap",
                        attrs: { title: _vm._f("datetime")(job.created_at) }
                      },
                      [
                        _c("ItpIcon", { attrs: { name: "fa-clock" } }),
                        _vm._v(
                          "\n      " +
                            _vm._s(
                              _vm._f("datetime")(job.created_at, {
                                distanceToNow: true
                              })
                            ) +
                            "\n    "
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-muted text-nowrap" }, [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm._f("username")(job.created_by)) +
                          "\n    "
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-8 border-left pb-5 text-nowrap text-truncate"
                  },
                  [
                    _c("div", [
                      job.updated_at
                        ? _c(
                            "span",
                            {
                              staticClass: "ml-1 text-muted text-nowrap",
                              attrs: {
                                title: _vm._f("datetime")(job.updated_at)
                              }
                            },
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(
                                    _vm._f("datetime")(job.updated_at, {
                                      distanceToNow: true
                                    })
                                  ) +
                                  "\n      "
                              )
                            ]
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    job.job_type === "email"
                      ? _c(
                          "div",
                          {
                            staticClass: "text-truncate",
                            attrs: { title: job.mail_to }
                          },
                          [
                            _c("span", { staticClass: "text-muted mr-1" }, [
                              _vm._v(
                                "\n        " +
                                  _vm._s(_vm._f("pgettext")("To", "email")) +
                                  ":\n      "
                              )
                            ]),
                            _vm._v(
                              "\n      " +
                                _vm._s(
                                  _vm.$fn._.isArray(job.mail_to)
                                    ? job.mail_to.join("; ")
                                    : job.mail_to
                                ) +
                                "\n    "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    job.job_type === "email" && job.subject
                      ? _c(
                          "div",
                          {
                            staticClass: "text-truncate",
                            attrs: { title: job.subject }
                          },
                          [
                            _c("span", { staticClass: "text-muted mr-1" }, [
                              _vm._v(
                                "\n        " +
                                  _vm._s(
                                    _vm._f("pgettext")("Subject", "email")
                                  ) +
                                  ":\n      "
                              )
                            ]),
                            _vm._v("\n      " + _vm._s(job.subject) + "\n    ")
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    job.job_type === "email" && job.tracking
                      ? _c(
                          "div",
                          {
                            attrs: {
                              title: _vm._f("datetime")(job.tracking.timestamp)
                            }
                          },
                          [
                            _c("ItpIcon", { attrs: { name: "fa-eye" } }),
                            _vm._v(" "),
                            _c("span", { staticClass: "ml-2" }, [
                              _vm._v(
                                "\n        " +
                                  _vm._s(
                                    _vm._f("datetime")(job.tracking.timestamp, {
                                      distanceToNow: true
                                    })
                                  ) +
                                  "\n      "
                              )
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    job.log_url || job.pdf_url || job.html_url
                      ? _c("hr", { staticClass: "my-2" })
                      : _vm._e(),
                    _vm._v(" "),
                    job.status === "Errored"
                      ? _c(
                          "div",
                          [
                            _c("ItpLink", {
                              staticClass: "d-block",
                              attrs: {
                                href: job.log_url,
                                icon: "fa-file-alt",
                                text: _vm.$i18n._("Error log"),
                                tooltip: job.log_filename,
                                target: "_blank",
                                "use-auth": ""
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    job.status === "Completed"
                      ? _c(
                          "div",
                          [
                            job.pdf_url
                              ? _c("ItpLink", {
                                  staticClass: "d-block",
                                  attrs: {
                                    href: job.pdf_url,
                                    icon: "fa-file-pdf",
                                    text: "PDF",
                                    tooltip: job.pdf_filename,
                                    target: "_blank",
                                    "use-auth": ""
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            job.html_url
                              ? _c("ItpLink", {
                                  staticClass: "d-block",
                                  attrs: {
                                    href: job.html_url,
                                    icon: "fa-file-code",
                                    text: "HTML",
                                    tooltip: job.html_filename,
                                    target: "_blank",
                                    "use-auth": ""
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ])
            }),
            0
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }