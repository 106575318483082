import Address from './Address.yaml?component';
import FieldsetAddress from './FieldsetAddress.yaml?component';
import UcAddressCard from './UcAddressCard.yaml?component';
import UcAddressFieldset from './UcAddressFieldset.yaml?component';
import UcAddressTypeBadge from './UcAddressTypeBadge.yaml?component';
import UcAddressList from './UcAddressList.yaml?component';

export {
  Address,
  FieldsetAddress,
  UcAddressCard,
  UcAddressTypeBadge,
  UcAddressList
};
