var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpBox",
    { attrs: { direction: "row" } },
    [
      _vm.invoice.meta.sync_enabled && _vm.context
        ? _c("ItpButton", {
            attrs: {
              icon: "material-symbols:cloud-sync-outline-rounded",
              text: _vm.context.text,
              tooltip: _vm.context.tooltip,
              spinning: _vm.actions.sync.isRunning || _vm.isJobRunning,
              disabled: _vm.actions.sync.isRunning || _vm.isJobRunning,
              variant: _vm.context.variant
            },
            on: {
              click: function($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.evh_2248226175642056_clickPreventStop($event, {})
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.invoice.sync_job && _vm.invoice.sync_job.id
        ? _c("ItpButton", {
            attrs: {
              icon: "icon-park-outline:log",
              tooltip: _vm.messages.showLogMessagesTooltip,
              variant: "light",
              size: "sm"
            },
            on: {
              click: function($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.evh_5962862420439144_clickPreventStop($event, {})
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }