var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DIV",
    {
      class: [
        {
          "modal-header": _vm.modal,
          "shadow-sm": _vm.modal,
          "card-header": _vm.card,
          "bg-gray-100": _vm.modal || _vm.card
        },
        _vm.borderClass
      ]
    },
    [
      _c(
        "ItpBox",
        {
          class: _vm.b_2248226175642056_class,
          attrs: { alignItems: "center" }
        },
        [
          _vm.dialogName
            ? _c(
                "ItpText",
                {
                  staticClass: "mb-0",
                  attrs: { tag: "h5", capitalize: "", muted: "" }
                },
                [_vm._v("\n      " + _vm._s(_vm.dialogName) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.dialogName && _vm.title
            ? _c("span", { staticClass: "m-0 p-0" }, [
                _vm._v("\n      ·\n    ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.title
            ? _c("ItpText", {
                staticClass: "mb-0",
                attrs: { tag: "h4", text: _vm.title }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._t("default")
        ],
        2
      ),
      _vm._v(" "),
      _vm.modal
        ? _c(
            "button",
            {
              staticClass: "close ml-auto mr-1",
              attrs: { "data-dismiss": "modal", "aria-label": "Close" },
              on: {
                click: function($event) {
                  return _vm.evh_5962862420439144_click($event, {})
                }
              }
            },
            [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }