var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpRow",
        [
          _c(
            "ItpCol",
            { attrs: { col: "7" } },
            [
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    contentColsMd: "8",
                    name: "name",
                    label: _vm._f("pgettext")("Name", "Client"),
                    labelAlign: _vm.labelAlign,
                    required: ""
                  }
                },
                [
                  _c("ItpFormInput", {
                    attrs: { name: "name", required: "", autofocus: "" },
                    model: {
                      value: _vm.client.name,
                      callback: function($$v) {
                        _vm.$set(_vm.client, "name", $$v)
                      },
                      expression: "client.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    name: "number",
                    label: _vm._f("pgettext")("Code", "Client"),
                    labelAlign: _vm.labelAlign,
                    required: ""
                  }
                },
                [
                  _c("ItpFormInput", {
                    attrs: { name: "number", required: "" },
                    model: {
                      value: _vm.client.code,
                      callback: function($$v) {
                        _vm.$set(_vm.client, "code", $$v)
                      },
                      expression: "client.code"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    contentColsMd: "8",
                    name: "client_relation",
                    label: _vm._f("pgettext")("Type", "Client"),
                    labelAlign: _vm.labelAlign,
                    required: ""
                  }
                },
                [
                  _c("ItpFormSelect", {
                    attrs: {
                      name: "client_relation",
                      required: "",
                      options: _vm.clientRelations
                    },
                    model: {
                      value: _vm.client.client_relation,
                      callback: function($$v) {
                        _vm.$set(_vm.client, "client_relation", $$v)
                      },
                      expression: "client.client_relation"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    contentColsMd: "8",
                    name: "groups",
                    label: _vm._f("pgettext")("Groups", "Client"),
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormSelect2", {
                    attrs: {
                      name: "groups",
                      multiple: "",
                      options: _vm.clientGroups
                    },
                    model: {
                      value: _vm.client.groups,
                      callback: function($$v) {
                        _vm.$set(_vm.client, "groups", $$v)
                      },
                      expression: "client.groups"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    contentColsMd: "8",
                    name: "emails",
                    label: _vm._f("pgettext")("Email", "Client"),
                    labelAlign: _vm.labelAlign,
                    description: _vm._f("pgettext")(
                      "Multiple email addresses should be separated by semicolon.",
                      "Client"
                    )
                  }
                },
                [
                  _c("ItpFormInput", {
                    attrs: { type: "text", name: "emails" },
                    model: {
                      value: _vm.client.emails,
                      callback: function($$v) {
                        _vm.$set(_vm.client, "emails", $$v)
                      },
                      expression: "client.emails"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    contentColsMd: "8",
                    name: "notes",
                    label: _vm._f("pgettext")("Notes", "Client"),
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormTextarea", {
                    attrs: { name: "notes" },
                    model: {
                      value: _vm.client.notes,
                      callback: function($$v) {
                        _vm.$set(_vm.client, "notes", $$v)
                      },
                      expression: "client.notes"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ItpCol",
            { attrs: { col: "5" } },
            [
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    name: "tax_number",
                    label: _vm._f("pgettext")("Tax Number", "Client"),
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormInput", {
                    attrs: { name: "tax_number" },
                    model: {
                      value: _vm.client.tax_number,
                      callback: function($$v) {
                        _vm.$set(_vm.client, "tax_number", $$v)
                      },
                      expression: "client.tax_number"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    name: "eu_tax_number",
                    label: _vm._f("pgettext")("EU Tax Number", "Client"),
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormInput", {
                    attrs: { name: "eu_tax_number" },
                    model: {
                      value: _vm.client.eu_tax_number,
                      callback: function($$v) {
                        _vm.$set(_vm.client, "eu_tax_number", $$v)
                      },
                      expression: "client.eu_tax_number"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    name: "registration_number",
                    label: _vm._f("pgettext")("Registration Number", "Client"),
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormInput", {
                    attrs: { name: "registration_number" },
                    model: {
                      value: _vm.client.registration_number,
                      callback: function($$v) {
                        _vm.$set(_vm.client, "registration_number", $$v)
                      },
                      expression: "client.registration_number"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    name: "payment_period_days",
                    label: _vm._f("pgettext")("Payment period", "Client"),
                    labelColsMd: "4",
                    contentColsMd: "5",
                    labelAlign: _vm.labelAlign,
                    contentClass: "input-group"
                  }
                },
                [
                  _c("ItpFormInput", {
                    attrs: { type: "number", name: "payment_period_days" },
                    model: {
                      value: _vm.client.payment_period_days,
                      callback: function($$v) {
                        _vm.$set(_vm.client, "payment_period_days", $$v)
                      },
                      expression: "client.payment_period_days"
                    }
                  }),
                  _vm._v(" "),
                  _c("DIV", { staticClass: "input-group-append" }, [
                    _c("span", { staticClass: "input-group-text" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm._f("pgettext")("days", "InputGroupAddon")
                          ) +
                          "\n          "
                      )
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    name: "discount",
                    label: _vm._f("pgettext")("Discount", "Client"),
                    labelColsMd: "4",
                    contentColsMd: "4",
                    labelAlign: _vm.labelAlign,
                    contentClass: "input-group"
                  }
                },
                [
                  _c("ItpFormInput", {
                    attrs: { type: "number", name: "discount" },
                    model: {
                      value: _vm.client.discount,
                      callback: function($$v) {
                        _vm.$set(_vm.client, "discount", $$v)
                      },
                      expression: "client.discount"
                    }
                  }),
                  _vm._v(" "),
                  _c("DIV", { staticClass: "input-group-append" }, [
                    _c("span", { staticClass: "input-group-text" }, [
                      _vm._v("\n            %\n          ")
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    name: "is_contractor",
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormCheckbox", {
                    attrs: {
                      name: "is_clientor",
                      label: _vm._f("pgettext")("Contractor", "Client")
                    },
                    model: {
                      value: _vm.client.is_contractor,
                      callback: function($$v) {
                        _vm.$set(_vm.client, "is_contractor", $$v)
                      },
                      expression: "client.is_contractor"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    name: "is_natural_person",
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormCheckbox", {
                    attrs: {
                      name: "is_natural_person",
                      label: _vm._f("pgettext")("Natural Person", "Client")
                    },
                    model: {
                      value: _vm.client.is_natural_person,
                      callback: function($$v) {
                        _vm.$set(_vm.client, "is_natural_person", $$v)
                      },
                      expression: "client.is_natural_person"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    name: "vat_exempt",
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormCheckbox", {
                    attrs: {
                      name: "vat_exempt",
                      label: _vm._f("pgettext")("VAT exempt", "Client")
                    },
                    model: {
                      value: _vm.client.vat_exempt,
                      callback: function($$v) {
                        _vm.$set(_vm.client, "vat_exempt", $$v)
                      },
                      expression: "client.vat_exempt"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    name: "excluded_from_claims",
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormCheckbox", {
                    attrs: {
                      name: "excluded_from_claims",
                      label: _vm._f("pgettext")("Exclude from claims", "Client")
                    },
                    model: {
                      value: _vm.client.excluded_from_claims,
                      callback: function($$v) {
                        _vm.$set(_vm.client, "excluded_from_claims", $$v)
                      },
                      expression: "client.excluded_from_claims"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }