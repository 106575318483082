var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpBox",
    { attrs: { direction: "column" } },
    [
      _c("ItpBox", [
        _c(
          "div",
          { staticClass: "col" },
          [
            _c("ItpText", {
              attrs: {
                strong: "",
                text: _vm._f("pgettext")(
                  "Cost allocators",
                  "RentalServiceCostAllocators"
                )
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.service.cost_allocators && _vm.service.cost_allocators.data.length
        ? _c(
            "ItpBox",
            { attrs: { direction: "column" } },
            _vm._l(_vm.service.cost_allocators.data, function(item) {
              return _c(
                "ItpBox",
                { key: item.id },
                [
                  _c("UcMeterMiniCard", {
                    staticClass: "col-6",
                    attrs: { item: item.meter }
                  }),
                  _vm._v(" "),
                  _c("ItpText", {
                    staticClass: "col-3",
                    attrs: { text: item.factor }
                  }),
                  _vm._v(" "),
                  _c("ItpText", {
                    staticClass: "col-3",
                    attrs: { text: item.notes }
                  }),
                  _vm._v(" "),
                  !item.isReadonly && !_vm.service.isReadonly
                    ? _c(
                        "b-dropdown",
                        {
                          staticClass: "ml-auto mb-auto",
                          attrs: {
                            size: "sm",
                            "toggle-class": "btn-default-borderless",
                            "no-caret": ""
                          }
                        },
                        [
                          _c("ItpIcon", {
                            staticClass: "mx-1",
                            attrs: { slot: "button-content", fa: "ellipsis-h" },
                            slot: "button-content"
                          }),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item-button",
                            {
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.evh_2576027262358435_clickPreventStop(
                                    $event,
                                    { item: item }
                                  )
                                }
                              }
                            },
                            [
                              _c("ItpText", {
                                attrs: {
                                  text: _vm._f("pgettext")("Edit", "Command")
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item-button",
                            {
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.evh_7593494570053535_clickPreventStop(
                                    $event,
                                    { item: item }
                                  )
                                }
                              }
                            },
                            [
                              _c("ItpText", {
                                attrs: {
                                  text: _vm._f("pgettext")("Delete", "Command")
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("ItpBox", [
        _c(
          "div",
          { staticClass: "col" },
          [
            !_vm.service.isReadonly
              ? _c("ItpLink", {
                  staticClass: "ml-auto",
                  attrs: {
                    text: _vm._f("pgettext")(
                      "Add cost allocator...",
                      "RentalServiceCostAllocators"
                    )
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.evh_2140198529698299_clickPreventStop(
                        $event,
                        {}
                      )
                    }
                  }
                })
              : _vm._e()
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }