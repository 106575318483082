import UcContactCard from './UcContactCard.yaml?component';
import UcContactFieldset from './UcContactFieldset.yaml?component';
import WContactModal from './WContactModal.yaml?component';
import UcContactList from './UcContactList.yaml?component';

export {
  UcContactCard,
  UcContactFieldset,
  WContactModal,
  UcContactList
};
