import DunningLetter from './DunningLetter.yaml?component';
import CreateDunningLetter from './CreateDunningLetter.yaml?component';
import DunningLetterJobs from './DunningLetterJobs.yaml?component';
import DunningLetters from './DunningLetters.yaml?component';

export {
  DunningLetter,
  CreateDunningLetter,
  DunningLetterJobs,
  DunningLetters
};
