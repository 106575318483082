var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "CreateContractRevision",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "ItpForm",
                { attrs: { id: _vm.formId, name: _vm.formName } },
                [
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "revision_number",
                        label: _vm._f("pgettext")(
                          "Revision Number",
                          "Contract"
                        ),
                        labelColsMd: "4",
                        contentColsMd: "2",
                        labelAlign: _vm.labelAlign,
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormInput", {
                        attrs: {
                          type: "number",
                          name: "revision_number",
                          required: ""
                        },
                        model: {
                          value: _vm.item.revision_number,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "revision_number", $$v)
                          },
                          expression: "item.revision_number"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "status",
                        label: _vm._f("pgettext")("Status", "Contract"),
                        labelColsMd: "4",
                        contentColsMd: "4",
                        labelAlign: _vm.labelAlign,
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormSelect2", {
                        attrs: {
                          name: "status",
                          valueField: "value",
                          textField: "text",
                          options: _vm.statuses,
                          required: ""
                        },
                        model: {
                          value: _vm.item.status,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "status", $$v)
                          },
                          expression: "item.status"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "revision_date",
                        label: _vm._f("pgettext")("Revision Date", "Contract"),
                        labelColsMd: "4",
                        contentColsMd: "4",
                        labelAlign: _vm.labelAlign,
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormDatetime", {
                        attrs: {
                          type: "date",
                          name: "revision_date",
                          required: ""
                        },
                        model: {
                          value: _vm.item.revision_date,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "revision_date", $$v)
                          },
                          expression: "item.revision_date"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "valid_from",
                        label: _vm._f("pgettext")("Validity", "Contract"),
                        labelColsMd: "4",
                        contentColsMd: "8",
                        labelAlign: _vm.labelAlign,
                        contentClass: "form-row",
                        required: ""
                      }
                    },
                    [
                      _c(
                        "DIV",
                        { staticClass: "col" },
                        [
                          _c("ItpFormDatetime", {
                            attrs: { name: "valid_from", required: "" },
                            model: {
                              value: _vm.item.valid_from,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "valid_from", $$v)
                              },
                              expression: "item.valid_from"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "DIV",
                        { staticClass: "col" },
                        [
                          _c("ItpFormDatetime", {
                            attrs: { name: "valid_to", required: "" },
                            model: {
                              value: _vm.item.valid_to,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "valid_to", $$v)
                              },
                              expression: "item.valid_to"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("HR"),
                  _vm._v(" "),
                  _c("ItpAlert", { attrs: { variant: "info", show: "" } }, [
                    _c(
                      "p",
                      [
                        _c("ItpIcon", { attrs: { fa: "info-circle" } }),
                        _vm._v(" "),
                        _c("ItpText", { attrs: { wrap: "" } }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("pgettext")(
                                  "The new revision will be created based on the opened revision. Select the following options to copy services and/or fees to the new revision.",
                                  "CreateContractRevision"
                                )
                              ) +
                              "\n          "
                          )
                        ])
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "options",
                        label: _vm._f("pgettext")(
                          "Options",
                          "CreateContractRevision"
                        ),
                        labelColsMd: "4",
                        contentColsMd: "8",
                        labelAlign: _vm.labelAlign
                      }
                    },
                    [
                      _c(
                        "ItpBox",
                        { staticClass: "pl-1", attrs: { direction: "column" } },
                        [
                          _c("ItpFormCheckbox", {
                            attrs: {
                              name: "copy_services",
                              label: _vm._f("pgettext")(
                                "Copy services",
                                "CreateContractRevision"
                              ),
                              inline: ""
                            },
                            model: {
                              value: _vm.options.copy_services,
                              callback: function($$v) {
                                _vm.$set(_vm.options, "copy_services", $$v)
                              },
                              expression: "options.copy_services"
                            }
                          }),
                          _vm._v(" "),
                          _c("ItpFormCheckbox", {
                            attrs: {
                              name: "copy_service_fees",
                              label: _vm._f("pgettext")(
                                "Copy service fees",
                                "CreateContractRevision"
                              ),
                              inline: ""
                            },
                            model: {
                              value: _vm.options.copy_service_fees,
                              callback: function($$v) {
                                _vm.$set(_vm.options, "copy_service_fees", $$v)
                              },
                              expression: "options.copy_service_fees"
                            }
                          }),
                          _vm._v(" "),
                          _c("ItpFormCheckbox", {
                            attrs: {
                              name: "skip_outdated_services",
                              label: _vm._f("pgettext")(
                                "Skip outdated services",
                                "CreateContractRevision"
                              ),
                              inline: ""
                            },
                            model: {
                              value: _vm.options.skip_outdated_services,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.options,
                                  "skip_outdated_services",
                                  $$v
                                )
                              },
                              expression: "options.skip_outdated_services"
                            }
                          }),
                          _vm._v(" "),
                          _c("ItpFormCheckbox", {
                            attrs: {
                              name: "skip_outdated_service_fees",
                              label: _vm._f("pgettext")(
                                "Skip outdated service fees",
                                "CreateContractRevision"
                              ),
                              inline: ""
                            },
                            model: {
                              value: _vm.options.skip_outdated_service_fees,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.options,
                                  "skip_outdated_service_fees",
                                  $$v
                                )
                              },
                              expression: "options.skip_outdated_service_fees"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _c("ItpButton", {
                staticClass: "ml-3",
                attrs: {
                  type: "submit",
                  form: _vm.formId,
                  variant: "primary",
                  icon: "fa-save",
                  text: _vm._f("pgettext")("Create revision", "Contract"),
                  spinning: _vm.actions.save.isRunning,
                  disabled:
                    !_vm.forms[_vm.formName] ||
                    !_vm.forms[_vm.formName].submittable
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1548630417156826_clickPreventStop($event, {})
                  }
                }
              }),
              _vm._v(" "),
              _c("ItpButton", {
                staticClass: "ml-3",
                attrs: {
                  variant: "default",
                  text: _vm._f("gettext")("Close"),
                  disabled: _vm.actions.save.isRunning
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1419464017721962_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }