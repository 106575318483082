var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ItpDataTable", {
    attrs: {
      tableId: "MeterReadings",
      editable: "",
      paging: _vm.b_7315092382398562_paging,
      value: _vm._.orderBy(
        _vm.items,
        ["reading_date", "reading_time", "created_at"],
        ["desc", "desc", "desc"]
      ),
      rowTemplate: _vm.schemaDefaults("CreateMeterReading", {
        meter_id: _vm.meter.id,
        unit: _vm.meter.unit,
        reading_date: new Date().toLocaleDateString(),
        reading_time: new Date().toLocaleTimeString()
      }),
      "prepend-new-row": "",
      columns: _vm.b_7315092382398562_columns
    },
    on: {
      save: function($event) {
        return _vm.evh_7315092382398562_save($event, {})
      },
      delete: function($event) {
        return _vm.evh_7315092382398562_delete($event, {})
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }