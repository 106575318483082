var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "ItpBox",
        { staticClass: "items-spacing w-100", attrs: { alignItems: "center" } },
        [
          _c(
            "ItpBox",
            { attrs: { direction: "column" } },
            [
              _c("ItpText", {
                staticClass: "mb-0",
                attrs: { muted: "", text: _vm.resourceTitle }
              }),
              _vm._v(" "),
              _c("ItpText", {
                staticClass: "mb-0",
                attrs: { tag: "h5", text: _vm.resourceKey }
              })
            ],
            1
          ),
          _vm._v(" "),
          !_vm.entry.isNew
            ? _c(
                "ItpBox",
                { attrs: { direction: "column" } },
                [
                  _c("ItpText", {
                    staticClass: "mb-0",
                    attrs: {
                      muted: "",
                      text: _vm._f("pgettext")(
                        "Invoice number",
                        "InvoiceRegisterEntryHeader"
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c("ItpText", {
                    staticClass: "mb-0",
                    attrs: { tag: "h4", text: _vm.entry.invoice_number }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-button-group",
            { staticClass: "ml-auto" },
            [
              !_vm.entry.isNew
                ? _c("ItpButton", {
                    staticClass: "ml-auto",
                    attrs: {
                      icon: "fa-sync-alt",
                      tooltip: _vm._f("gettext")("Reload"),
                      spinning: _vm.actions.reload.isRunning,
                      variant: "light"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_1548630417156826_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.entry.preview_url
                ? _c("ItpLink", {
                    attrs: {
                      icon: "bxs:file-pdf",
                      href: _vm.entry.preview_url,
                      tooltip: "Open PDF",
                      target: "_blank",
                      "use-auth": "",
                      button: "",
                      variant: "light",
                      "show-spinner": "",
                      "no-download-link": "",
                      text: _vm._f("gettext")("Preview")
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.entry.isNew
                ? _c(
                    "b-dropdown",
                    {
                      staticClass: "border",
                      attrs: { right: "", variant: "light" }
                    },
                    [
                      _c("ItpIcon", {
                        staticClass: "mx-1",
                        attrs: { slot: "button-content", fa: "bars" },
                        slot: "button-content"
                      }),
                      _vm._v(" "),
                      !_vm.entry.isNew && !_vm.entry.isReadOnly
                        ? _c("BtnLockResource", {
                            attrs: {
                              "no-icon": "",
                              "dropdown-item": "",
                              resource: _vm.entry,
                              tooltip: _vm._f("pgettext")(
                                "Lock entry",
                                "InvoiceRegister"
                              )
                            },
                            on: {
                              success: function($event) {
                                return _vm.evh_6793520326132864_success(
                                  $event,
                                  {}
                                )
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.entry.isNew && !_vm.entry.isReadOnly
                        ? _c("BtnDeleteResource", {
                            attrs: {
                              "no-icon": "",
                              "dropdown-item": "",
                              resource: _vm.entry,
                              tooltip: _vm._f("pgettext")(
                                "Delete entry",
                                "InvoiceRegister"
                              )
                            },
                            on: {
                              success: function($event) {
                                return _vm.evh_1269078620197197_success(
                                  $event,
                                  {}
                                )
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }