var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c(
            "ItpBox",
            { attrs: { spacing: "pr-3 py-1", alignItems: "center" } },
            [
              _c("ItpBadge", {
                attrs: {
                  text: _vm.resource.inactive
                    ? _vm.gettext("Inactive")
                    : _vm.gettext("Active"),
                  variant: _vm.resource.inactive ? "light" : "primary",
                  "no-box-shadow": ""
                }
              }),
              _vm._v(" "),
              !_vm.resource.locked && _vm.resource.inactive
                ? _c("ItpButton", {
                    staticClass: "mx-3 border",
                    attrs: {
                      size: "sm",
                      variant: "light",
                      text: _vm._f("pgettext")(
                        "Activate",
                        "UcResourceStatusCard"
                      )
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.evh_3343864469950044_clickStopPrevent(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.resource.locked && !_vm.resource.inactive
                ? _c("ItpButton", {
                    staticClass: "mx-3 border",
                    attrs: {
                      size: "sm",
                      variant: "light",
                      text: _vm._f("pgettext")(
                        "Inactivate",
                        "UcResourceStatusCard"
                      )
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.evh_1889980785424776_clickStopPrevent(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }