var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpBadge",
        {
          attrs: {
            value: _vm.value,
            variantMap: _vm.$config.invoice.invoiceStatusVariants
          }
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm._f("pgettext")(_vm.value, "InvoiceStatus", "StartCase")
              ) +
              "\n  "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }