var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpBox",
        { attrs: { direction: "column" } },
        [
          _vm.items && _vm.items.length
            ? _c(
                "ItpBox",
                { attrs: { direction: "column" } },
                _vm._l(_vm.items, function(item) {
                  return _c(
                    "ItpBox",
                    {
                      key: item.id,
                      staticClass: "border rounded rounded-lg p-2 mb-2 w-100",
                      style: _vm.b_6466705138206980_style,
                      attrs: { alignItems: "start" }
                    },
                    [
                      _c("ItpText", {
                        staticClass: "mr-3",
                        attrs: {
                          strong: "",
                          text: _vm._f("date")(item.payment_date)
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "ItpBox",
                        { staticClass: "mr-3", attrs: { direction: "column" } },
                        [
                          _c(
                            "DIV",
                            [
                              _c("ItpLink", {
                                attrs: {
                                  text: item.payment_number,
                                  strong: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.evh_4592790460410433_click(
                                      $event,
                                      { item: item }
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("UcAccountMiniCard", {
                            attrs: { item: item.account }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "ItpBox",
                        { staticClass: "ml-auto" },
                        [
                          _c("UcPaymentTransactionTypeSymbol", {
                            staticClass: "mx-2",
                            attrs: { paymentType: item.payment_type }
                          }),
                          _vm._v(" "),
                          _c(
                            "ItpText",
                            { staticClass: "ml-auto", attrs: { strong: "" } },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm._f("number")(item.amount)) +
                                  " " +
                                  _vm._s(item.currency) +
                                  "\n\n          "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.items && _vm.items.length > 1
            ? _c(
                "ItpBox",
                { staticClass: "px-2 mb-2 w-100" },
                [
                  _c("ItpText", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("pgettext")("Total", "InvoicePayments")) +
                        "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "ItpText",
                    { staticClass: "ml-auto", attrs: { strong: "" } },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm._f("number")(_vm.invoice.totals.paid)) +
                          " " +
                          _vm._s(_vm.invoice.currency) +
                          "\n\n      "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "ItpBox",
            { attrs: { direction: "row", alignItems: "center" } },
            [
              _c(
                "DIV",
                { staticClass: "form-check px-0" },
                [
                  _c(
                    "ItpFormCheckbox",
                    {
                      staticClass: "py-1",
                      attrs: { name: "no_payment_required", "is-switch": "" },
                      on: {
                        change: function($event) {
                          return _vm.evh_322074727583344_change($event, {})
                        }
                      },
                      model: {
                        value: _vm.invoice.no_payment_required,
                        callback: function($$v) {
                          _vm.$set(_vm.invoice, "no_payment_required", $$v)
                        },
                        expression: "invoice.no_payment_required"
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("pgettext")("No payment required", "Invoice")
                          ) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormCheckbox",
                    {
                      staticClass: "py-1",
                      attrs: { name: "uncollectible", "is-switch": "" },
                      on: {
                        change: function($event) {
                          return _vm.evh_5807883900791941_change($event, {})
                        }
                      },
                      model: {
                        value: _vm.invoice.uncollectible,
                        callback: function($$v) {
                          _vm.$set(_vm.invoice, "uncollectible", $$v)
                        },
                        expression: "invoice.uncollectible"
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("pgettext")("Uncollectible", "Invoice")
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-dropdown",
                {
                  staticClass: "border, ml-auto",
                  attrs: { right: "", variant: "light" }
                },
                [
                  _c(
                    "template",
                    { slot: "button-content" },
                    [
                      _c("ItpIcon", {
                        staticClass: "mx-1",
                        attrs: { fa: "bars" }
                      }),
                      _vm._v(" "),
                      _c("ItpText", {
                        attrs: {
                          text: _vm._f("pgettext")(
                            "New payment",
                            "InvoicePayments"
                          )
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item-button",
                    {
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.evh_1984986731969919_clickPrevent(
                            $event,
                            {}
                          )
                        }
                      }
                    },
                    [
                      _c("ItpText", {
                        attrs: {
                          text: _vm._f("pgettext")(
                            "New cash payment...",
                            "InvoicePayments"
                          )
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item-button",
                    {
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.evh_298505142589676_clickPrevent(
                            $event,
                            {}
                          )
                        }
                      }
                    },
                    [
                      _c("ItpText", {
                        attrs: {
                          text: _vm._f("pgettext")(
                            "New banking payment...",
                            "InvoicePayments"
                          )
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("ItpDropdownDivider"),
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item-button",
                    {
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.evh_5738488038990219_clickPrevent(
                            $event,
                            {}
                          )
                        }
                      }
                    },
                    [
                      _c("ItpText", {
                        attrs: {
                          text: _vm._f("pgettext")(
                            "New reconciliation...",
                            "InvoicePayments"
                          )
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item-button",
                    {
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.evh_7481595298497917_clickPrevent(
                            $event,
                            {}
                          )
                        }
                      }
                    },
                    [
                      _c("ItpText", {
                        attrs: {
                          text: _vm._f("pgettext")(
                            "Bad debt...",
                            "InvoicePayments"
                          )
                        }
                      })
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }