var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        card: "",
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "Worksheet",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c(
            "UcHeader",
            {
              attrs: {
                slot: "header",
                card: !_vm.modal,
                modal: _vm.modal,
                color: _vm.$config.worksheet.color
              },
              on: {
                close: function($event) {
                  return _vm.evh_2248226175642056_close($event, {})
                }
              },
              slot: "header"
            },
            [
              _c("UcWorksheetHeader", {
                attrs: { worksheet: _vm.item },
                on: {
                  reload: function($event) {
                    return _vm.evh_8564662037462133_reload($event, {})
                  },
                  deleted: function($event) {
                    return _vm.evh_8564662037462133_deleted($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _vm.isNew
                ? _c(
                    "ItpForm",
                    {
                      attrs: {
                        id: _vm.formId,
                        name: "worksheet",
                        autofocus: "first"
                      },
                      on: {
                        submit: function($event) {
                          return _vm.evh_1040167445267876_submit($event, {})
                        }
                      }
                    },
                    [
                      _c(
                        "ItpCol",
                        [
                          _c("UcWorksheetEditorFieldset", {
                            attrs: { worksheet: _vm.item }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isNew
                ? _c(
                    "ItpPanel",
                    {
                      attrs: {
                        collapsible: "",
                        caption: _vm._f("pgettext")(
                          "Worksheet data",
                          "Worksheet"
                        ),
                        carousel: ""
                      }
                    },
                    [
                      _c("UcWorksheetData", {
                        staticClass: "pt-3",
                        attrs: {
                          worksheet: _vm.item,
                          formId: _vm.formId,
                          reload: _vm.lastLoaded
                        },
                        on: {
                          reload: function($event) {
                            return _vm.evh_1468474219929533_reload($event, {})
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isNew ? _c("hr") : _vm._e(),
              _vm._v(" "),
              !_vm.isNew
                ? _c(
                    "DIV",
                    { staticClass: "overflow-hidden w-100" },
                    [
                      _c("UcWorksheetWorkItems", {
                        staticClass: "h-100 w-100",
                        attrs: {
                          worksheet: _vm.item,
                          isReadonly: _vm.isReadonly,
                          reload: _vm.lastLoaded
                        },
                        on: {
                          reload: function($event) {
                            return _vm.evh_1306227577716690_reload($event, {})
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isNew ? _c("hr") : _vm._e(),
              _vm._v(" "),
              !_vm.isNew
                ? _c(
                    "DIV",
                    { staticClass: "overflow-hidden w-100" },
                    [
                      _c("UcWorksheetInvoiceLineAccountings", {
                        staticClass: "h-100 w-100",
                        attrs: {
                          worksheet: _vm.item,
                          isReadonly: _vm.isReadonly,
                          reload: _vm.lastLoaded
                        },
                        on: {
                          reload: function($event) {
                            return _vm.evh_8723442106880637_reload($event, {})
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: {
                slot: "footer",
                card: !_vm.modal,
                modal: _vm.modal,
                color: _vm.$config.payment.color
              },
              slot: "footer"
            },
            [
              _vm.isNew
                ? _c("ItpButton", {
                    staticClass: "mr-2",
                    attrs: {
                      text: _vm._f("pgettext")("Create worksheet", "Button"),
                      type: "submit",
                      form: _vm.forms.worksheet,
                      variant: "primary",
                      spinning: _vm.actions.save.isRunning
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }