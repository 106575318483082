var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _vm.item.object === "client"
        ? _c("UcClientMiniCard", {
            attrs: { item: _vm.item, noLink: _vm.noLink }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.item.object === "contract"
        ? _c("UcContractMiniCard", {
            attrs: { item: _vm.item, noLink: _vm.noLink }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.item.object === "property" || _vm.item.object == "property_unit"
        ? _c("UcPropertyMiniCard", {
            attrs: { item: _vm.item, noLink: _vm.noLink }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.item.object === "rental"
        ? _c("UcRentalMiniCard", {
            attrs: { item: _vm.item, noLink: _vm.noLink }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.item.object === "worksheet"
        ? _c("UcWorksheetMiniCard", {
            attrs: { item: _vm.item, noLink: _vm.noLink }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }