export class LazyTabManager {
  uiSkipComponentReload: boolean = true;

  protected _visited: number[] = [];

  visited(index: number, setValue?: boolean) {
    const contains = this._visited.includes(index);

    if (!setValue) {
      return contains;
    }

    if (!contains) {
      this._visited.push(index);
    }
  }
}
