var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpWidgetCard",
    [
      _c(
        "ItpBox",
        { attrs: { slot: "header", alignItems: "center" }, slot: "header" },
        [
          _c("strong", [
            _vm._v(
              "\n      " +
                _vm._s(_vm._f("pgettext")("Contracts", "RentalFormContracts")) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c("ItpButton", {
            staticClass: "ml-auto",
            attrs: {
              text: _vm._f("gettext")("Create contract..."),
              size: "sm",
              variant: "light"
            },
            on: {
              click: function($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.evh_3343864469950044_clickPreventStop($event, {})
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "BListGroup",
            [
              !_vm.items.length
                ? _c(
                    "BListGroupItem",
                    { staticClass: "card-body text-center" },
                    [
                      _c("span", [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm._f("pgettext")(
                                "No rental contracts.",
                                "RentalFormContracts"
                              )
                            ) +
                            "\n      "
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.items, function(item) {
                return _c(
                  "BListGroupItem",
                  { key: item.id, staticClass: "d-flex flex-column" },
                  [
                    _c("UcContractCard", {
                      attrs: {
                        item: item,
                        displayRental: _vm.b_1468474219929533_displayRental
                      }
                    })
                  ],
                  1
                )
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }