var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c("legend", [
        _vm._v(
          "\n    " + _vm._s(_vm._f("pgettext")("Validity", "Rental")) + "\n  "
        )
      ]),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "valid_from",
            label: _vm._f("pgettext")("Valid from", "Rental"),
            labelColsMd: "4",
            contentColsMd: "4",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormDatetime", {
            attrs: { name: "valid_from" },
            model: {
              value: _vm.rental.valid_from,
              callback: function($$v) {
                _vm.$set(_vm.rental, "valid_from", $$v)
              },
              expression: "rental.valid_from"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "valid_to",
            label: _vm._f("pgettext")("Valid to", "Rental"),
            labelColsMd: "4",
            contentColsMd: "4",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormDatetime", {
            attrs: { name: "valid_to" },
            model: {
              value: _vm.rental.valid_to,
              callback: function($$v) {
                _vm.$set(_vm.rental, "valid_to", $$v)
              },
              expression: "rental.valid_to"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }