var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpBox",
        { attrs: { direction: "row" } },
        [
          _c(
            "div",
            { staticClass: "mr-3" },
            [
              _c("Icon", {
                attrs: {
                  icon: "ant-design:percentage-outlined",
                  tooltip: _vm.headerText
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ItpBox",
            { attrs: { direction: "column" } },
            [
              _c("ItpText", {
                attrs: { text: _vm.headerText, strong: "", tag: "h5" }
              }),
              _vm._v(" "),
              _c(
                "DIV",
                { staticClass: "pb-2" },
                [
                  _c("strong", { staticClass: "mr-2" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.notification.data.service_data.name) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("small", { staticClass: "mr-2" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm._f("gettext")("current price")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("strong", { staticClass: "mr-2" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("number")(
                            _vm.notification.data.source_object.amount
                          )
                        ) +
                        " " +
                        _vm._s(_vm.notification.data.source_object.currency) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("small", { staticClass: "mr-2" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm._f("gettext")("new price")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("strong", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("number")(_vm.notification.data.new_price)
                        ) +
                        " " +
                        _vm._s(_vm.notification.data.source_object.currency) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("ItpButton", {
                    staticClass: "ml-3",
                    attrs: {
                      text: _vm._f("gettext")("Add new price..."),
                      variant: "light",
                      size: "sm"
                    },
                    on: {
                      click: function($event) {
                        return _vm.evh_7751968922212722_click($event, {})
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpBox",
                { staticClass: "mb-2", attrs: { direction: "row" } },
                [
                  _c("UcContractMiniCard", {
                    attrs: {
                      item: _vm.notification.data.contract_data,
                      "no-icon": ""
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "mx-3" }, [
                    _vm._v("\n          ·\n        ")
                  ]),
                  _vm._v(" "),
                  _c("UcClientMiniCard", {
                    attrs: {
                      item: _vm.notification.data.contract_data.counterparty,
                      "no-icon": ""
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("ItpText", { attrs: { small: "", muted: "" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("datetime")(_vm.notification.created_at)) +
                    "  " +
                    _vm._s(_vm._f("username")(_vm.notification.created_by)) +
                    "\n\n      "
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }