var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpWidgetCard",
        [
          _c(
            "ItpBox",
            { attrs: { slot: "header", alignItems: "center" }, slot: "header" },
            [
              _c("strong", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("pgettext")("Invoices", "Contract")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _vm.invoices && _vm.invoices.length
                ? _c("strong", { staticClass: "mx-2" }, [
                    _vm._v(
                      "\n        (" + _vm._s(_vm.invoices.length) + ")\n      "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("ItpButton", {
                staticClass: "ml-2",
                attrs: {
                  variant: "light",
                  icon: "fa-sync-alt",
                  tooltip: _vm._f("gettext")("Reload"),
                  spinning: _vm.actions.reload.isRunning,
                  size: "sm"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_6705964765214521_clickPreventStop($event, {})
                  }
                }
              }),
              _vm._v(" "),
              _c("ItpButton", {
                staticClass: "ml-auto",
                attrs: {
                  text: _vm._f("pgettext")("Generate invoice...", "Contract"),
                  variant: "light",
                  size: "sm"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_6806515264385235_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.loaded
            ? _c(
                "BListGroup",
                [
                  !_vm.invoices.length
                    ? _c("BListGroupItem", [
                        _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm._f("pgettext")(
                                  "No related invoices.",
                                  "Contract"
                                )
                              ) +
                              "\n        "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(
                    _vm.filterItems(
                      _vm.invoices,
                      _vm.displayAll,
                      _vm.displayCount
                    ),
                    function(invoice) {
                      return _c(
                        "BListGroupItem",
                        { key: invoice.id, staticClass: "d-flex flex-column" },
                        [
                          _c(
                            "table",
                            {
                              staticClass: "table table-borderless table-sm m-0"
                            },
                            [
                              _c("tbody", [
                                _c("tr", [
                                  _c(
                                    "td",
                                    [
                                      _c("ItpLink", {
                                        attrs: {
                                          text: invoice.invoice_number,
                                          to: {
                                            name: "app.invoice",
                                            params: { id: invoice.id }
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      invoice.invoice_status === "draft"
                                        ? _c("UcInvoiceStatusBadge", {
                                            staticClass: "mt-2",
                                            attrs: {
                                              value: invoice.invoice_status
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "ItpText",
                                        {
                                          attrs: { muted: "", spacing: "mr-2" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm._f("pgettext")(
                                                  "Net",
                                                  "InvoiceContract"
                                                )
                                              ) +
                                              ":\n\n                "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "ItpText",
                                        {
                                          attrs: { strong: "", spacing: "mr-3" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm._f("number")(
                                                  invoice.net_total
                                                )
                                              ) +
                                              " " +
                                              _vm._s(invoice.currency) +
                                              "\n\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "ItpText",
                                        {
                                          attrs: { muted: "", spacing: "px-1" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm._f("pgettext")(
                                                  "Total",
                                                  "InvoiceContract"
                                                )
                                              ) +
                                              ":\n\n                "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "ItpText",
                                        {
                                          attrs: { strong: "", spacing: "px-2" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm._f("number")(invoice.total)
                                              ) +
                                              " " +
                                              _vm._s(invoice.currency) +
                                              "\n\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "b-dropdown",
                                        {
                                          staticClass: "ml-auto mb-auto",
                                          attrs: {
                                            size: "sm",
                                            "toggle-class":
                                              "btn-default-borderless",
                                            "no-caret": ""
                                          }
                                        },
                                        [
                                          _c("ItpIcon", {
                                            staticClass: "mx-1",
                                            attrs: {
                                              slot: "button-content",
                                              fa: "ellipsis-v"
                                            },
                                            slot: "button-content"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "b-dropdown-item-button",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.evh_5137197219681649_click(
                                                    $event,
                                                    { invoice: invoice }
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("ItpText", {
                                                attrs: {
                                                  text: _vm._f("pgettext")(
                                                    "Log messages",
                                                    "Invoice"
                                                  ),
                                                  tooltip: _vm._f("pgettext")(
                                                    "View log messages",
                                                    "Invoice"
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "ItpText",
                                        {
                                          attrs: {
                                            muted: "",
                                            strong: "",
                                            spacing: "mr-4"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm._f("date")(
                                                  invoice.invoice_date
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      invoice.billing_period_start
                                        ? _c(
                                            "ItpText",
                                            {
                                              attrs: {
                                                tooltip: _vm._f("pgettext")(
                                                  "Billing Period",
                                                  "InvoiceContract"
                                                )
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm._f("date")(
                                                      invoice.billing_period_start
                                                    )
                                                  ) +
                                                  " - " +
                                                  _vm._s(
                                                    _vm._f("date")(
                                                      invoice.billing_period_end
                                                    )
                                                  ) +
                                                  "\n\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ])
                              ])
                            ]
                          )
                        ]
                      )
                    }
                  ),
                  _vm._v(" "),
                  _vm.canHideItems || _vm.canDisplayMoreItems
                    ? _c(
                        "BListGroupItem",
                        [
                          _c(
                            "ItpBox",
                            { attrs: { justifyContent: "center" } },
                            [
                              _vm.canHideItems
                                ? _c("ItpLink", {
                                    attrs: {
                                      text: _vm._f("gettext")("Show less")
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.evh_7736732573189513_clickPreventStop(
                                          $event,
                                          {}
                                        )
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.canDisplayMoreItems
                                ? _c("ItpLink", {
                                    attrs: {
                                      text: _vm._f("gettext")("Show more")
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.evh_2912679705172878_clickPreventStop(
                                          $event,
                                          {}
                                        )
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }