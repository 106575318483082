<template>
<a
  :href="baseUrl + path"
  target="_blank"
  class="ml-auto"
>
  <ItpIcon
    fa="question-circle"
  >
  </ItpIcon>
  <span
    class="mx-1"
  >
    {{ 'Help'|gettext }}
  </span>
  <ItpIcon
    fa="external-link-alt"
    class="text-muted"
    :style="b_8358196963355853_style"
  >
  </ItpIcon>
</a>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class PageHelp extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: String,
  })
  path!: string;

  @Watch('path')
  onPath(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("path")
  }

  baseUrl!: any;

  async $$load_baseUrl() {
    return this.$app.vars.integro_docs_url
  }

  ux!: any;
  b_8358196963355853_style!: any;
  dataMembers = ['baseUrl', 'ux', 'b_8358196963355853_style'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        baseUrl: null,
        ux: null,
        b_8358196963355853_style: {
          "font-size": "0.75em",
        }
        ,
      },
    }
  }

  actions!: {
  }

  getActions() {
    return {
    }
  }
}

Vue.component("PageHelp", PageHelp);

</script>