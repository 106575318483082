var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "CreateDunningLetter",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "ItpForm",
                {
                  attrs: { name: "create-options", id: "om71edxw7f" },
                  on: {
                    submit: function($event) {
                      return _vm.evh_1040167445267876_submit($event, {})
                    }
                  }
                },
                [
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "lastNumber",
                        label: _vm._f("gettext")("Last reference number"),
                        "label-cols-md": "5",
                        "content-cols-md": "7"
                      }
                    },
                    [
                      _c("b-form-input", {
                        staticClass: "font-weight-bold no-focus-border",
                        attrs: { plaintext: "" },
                        model: {
                          value: _vm.numbering.info.last_number,
                          callback: function($$v) {
                            _vm.$set(_vm.numbering.info, "last_number", $$v)
                          },
                          expression: "numbering.info.last_number"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        row: "",
                        name: "startingReferenceNumber",
                        label: _vm._f("gettext")("Next reference number"),
                        "label-cols-md": "5",
                        "content-cols-md": "3"
                      }
                    },
                    [
                      _c("ItpFormInput", {
                        attrs: {
                          type: "number",
                          name: "startingReferenceNumber",
                          size: "md",
                          number: "",
                          min: _vm.numbering.info.next_value
                        },
                        model: {
                          value: _vm.startingReferenceNumber,
                          callback: function($$v) {
                            _vm.startingReferenceNumber = $$v
                          },
                          expression: "startingReferenceNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpAlert",
                    { attrs: { show: "" } },
                    [
                      _c("ItpIcon", {
                        staticClass: "mr-1",
                        attrs: { name: "fa-info-circle" }
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("gettext")(
                                "Next reference numbers will be generated from the given value. It must be higher than the current value."
                              )
                            ) +
                            "\n        "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _c("ItpButton", {
                attrs: {
                  form: "om71edxw7f",
                  text: _vm._f("pgettext")(
                    "Generate",
                    "DunningLetterCreatePage"
                  ),
                  type: "submit",
                  variant: "primary",
                  spinning: _vm.actions.submit.isRunning
                }
              }),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("gettext")("Cancel"),
                  variant: "default",
                  disabled: _vm.actions.submit.isRunning
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1419464017721962_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }