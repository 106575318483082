var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c("ItpIcon", {
        staticClass: "mr-1",
        attrs: {
          fa: "circle",
          color: _vm.value
            ? _vm.$config.colors.inactiveRed
            : _vm.$config.colors.activeGreen,
          tooltip: _vm.value ? _vm.gettext("Inactive") : _vm.gettext("Active")
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }