var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpPanel",
        { attrs: { collapsible: "" } },
        [
          _c("template", { slot: "caption" }, [
            _c(
              "h5",
              [
                _c("span", [_vm._v("\n          Files\n        ")]),
                _vm._v(" "),
                _c("ItpBadge", {
                  attrs: {
                    text:
                      "(" +
                      ((_vm.item.files && _vm.item.files.length) || 0) +
                      ")",
                    variant: "light"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _vm.item.files && _vm.item.files.length
            ? _c(
                "ul",
                _vm._l(_vm.item.files, function(file) {
                  return _c(
                    "li",
                    { key: file.file_name },
                    [
                      _c("ItpLink", {
                        attrs: {
                          text: file.file_name,
                          href: file.href,
                          target: "_blank",
                          downloadFileName: file.file_name,
                          "use-auth": ""
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }