var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DIV",
    { staticClass: "d-inline-block" },
    [
      _c(
        "ItpBox",
        {
          class: _vm.b_2248226175642056_class,
          attrs: { alignItems: "center" }
        },
        [
          _c("ItpText", {
            staticClass: "px-2",
            attrs: { text: _vm.revisionInfo.revision_number }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "mx-2 text-center",
              style: _vm.b_3343864469950044_style
            },
            [
              _c("UcContractStatusBadge", {
                attrs: { value: _vm.revisionInfo.status }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("ItpText", {
            staticClass: "mx-2",
            attrs: { text: _vm._f("date")(_vm.revisionInfo.revision_date) }
          }),
          _vm._v(" "),
          _vm.revisionInfo.valid_from || _vm.revisionInfo.valid_to
            ? _c("UcValidityPeriod", {
                staticClass: "px-2 border-left",
                attrs: {
                  from: _vm.revisionInfo.valid_from,
                  to: _vm.revisionInfo.valid_to
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.revisionInfo.description
            ? _c("ItpText", {
                staticClass: "px-2 border-left",
                attrs: { text: _vm.revisionInfo.description }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }