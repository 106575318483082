var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpPanel",
        { attrs: { collapsible: _vm.b_2248226175642056_collapsible } },
        [
          _c("template", { slot: "caption" }, [
            _c(
              "h5",
              { staticClass: "ml-3 mb-3" },
              [
                _c("span", [_vm._v("\n          Child jobs\n        ")]),
                _vm._v(" "),
                _c("ItpBadge", {
                  attrs: {
                    text:
                      "(" +
                      ((_vm.parent.jobs && _vm.parent.jobs.length) || 0) +
                      ")",
                    variant: "light"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "list-group" },
            _vm._l(_vm.parent.jobs, function(item) {
              return _c(
                "ItpPanel",
                {
                  key: item.id,
                  staticClass: "list-group-item",
                  attrs: { collapsible: "", initialState: "collapsed" }
                },
                [
                  _c(
                    "template",
                    { slot: "caption" },
                    [_c("JobHeader", { attrs: { item: item } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("JobView", {
                    staticClass: "mt-3",
                    attrs: { "no-display-header": "", item: item }
                  })
                ],
                2
              )
            }),
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }