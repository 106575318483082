var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpBox",
    { attrs: { direction: "column", alignItems: "stretch" } },
    [
      _c("ItpBox", { staticClass: "pb-2" }, [
        _c("strong", [
          _vm._v(
            "\n      " +
              _vm._s(_vm._f("pgettext")("Invoices", "Worksheet")) +
              "\n    "
          )
        ])
      ]),
      _vm._v(" "),
      _vm.loaded
        ? _c("ItpDataTable", {
            staticClass: "h-100",
            attrs: {
              rowKey: "id",
              tableId: "WorksheetInvoiceLineAccountings",
              rowHeader: _vm.b_5962862420439144_rowHeader,
              paging: _vm.b_5962862420439144_paging,
              sortable: _vm.b_5962862420439144_sortable,
              editable: _vm.b_5962862420439144_editable,
              value: _vm.items,
              variant: "details",
              rowTemplate: { worksheet_id: _vm.worksheet.id },
              columns: _vm.b_5962862420439144_columns
            },
            on: {
              save: function($event) {
                return _vm.evh_5962862420439144_save($event, {})
              },
              delete: function($event) {
                return _vm.evh_5962862420439144_delete($event, {})
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }