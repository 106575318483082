var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ItpBadge", {
    attrs: {
      value: _vm.value,
      variantMap: _vm.$config.invoice.invoiceStatusVariants,
      text: _vm._f("pgettext")(_vm.value, "Status", "Sentence"),
      "no-box-shadow": ""
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }