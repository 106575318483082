var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "InvoiceRegisterEntry",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c(
            "UcHeader",
            {
              attrs: {
                slot: "header",
                card: !_vm.modal,
                modal: _vm.modal,
                color: _vm.$config.invoiceRegister.color
              },
              on: {
                close: function($event) {
                  return _vm.evh_2248226175642056_close($event, {})
                }
              },
              slot: "header"
            },
            [
              _c("InvoiceRegisterEntryHeader", {
                attrs: {
                  register: _vm.resource.register || _vm.register,
                  entry: _vm.resource
                },
                on: {
                  reload: function($event) {
                    return _vm.evh_8564662037462133_reload($event, {})
                  },
                  deleted: function($event) {
                    return _vm.evh_8564662037462133_deleted($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "ItpForm",
                {
                  ref: "mainForm",
                  attrs: {
                    id: _vm.formId,
                    name: _vm.formName,
                    readonly: _vm.resource.isReadOnly
                  },
                  on: {
                    submit: function($event) {
                      return _vm.evh_1040167445267876_submit($event, {})
                    }
                  }
                },
                [
                  _c("FieldsetInvoiceRegisterEntry", {
                    attrs: { item: _vm.editor, form: _vm.forms[_vm.formName] },
                    on: {
                      reload: function($event) {
                        return _vm.evh_5534025912102772_reload($event, {})
                      },
                      close: function($event) {
                        return _vm.evh_5534025912102772_close($event, {})
                      },
                      changed: function($event) {
                        return _vm.evh_5534025912102772_changed($event, {})
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: {
                slot: "footer",
                card: !_vm.modal,
                modal: _vm.modal,
                color: _vm.$config.invoiceRegister.color
              },
              slot: "footer"
            },
            [
              !_vm.resource.isNew
                ? _c("ItpButton", {
                    staticClass: "mr-2",
                    attrs: {
                      text: _vm._f("pgettext")(
                        "Create another entry",
                        "InvoiceRegisterEntry/Command"
                      ),
                      disabled:
                        !_vm.forms[_vm.formName] ||
                        !_vm.forms[_vm.formName].submittable,
                      variant: "light",
                      icon: "mdi:forward",
                      spinning: _vm.actions.save.isRunning
                    },
                    on: {
                      click: function($event) {
                        return _vm.evh_1548630417156826_click($event, {})
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: { text: _vm._f("gettext")("Close"), variant: "default" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1419464017721962_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }