var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpBox",
        { attrs: { direction: "column" } },
        [
          _c(
            "ItpBox",
            { attrs: { alignItems: "center" } },
            [
              _c(
                "ItpLink",
                {
                  staticClass: "d-inline-block",
                  attrs: {
                    to: {
                      name: "app.account",
                      params: { id: _vm.payload.id }
                    }
                  }
                },
                [_vm._v("\n        " + _vm._s(_vm.payload.name) + "\n\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }