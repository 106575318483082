var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c(
            "ItpBox",
            { attrs: { spacing: "pr-3 py-1", alignItems: "center" } },
            [
              _c("UcContractStatusBadge", {
                attrs: { value: _vm.contract.status }
              }),
              _vm._v(" "),
              _c(
                "ItpBox",
                { staticClass: "ml-auto" },
                [
                  _vm._l(_vm.states, function(state) {
                    return _vm.canTransitionTo(
                      state.value,
                      _vm.contract.metadata
                    )
                      ? _c("ItpButton", {
                          key: state.value,
                          staticClass: "mx-2 border",
                          attrs: {
                            size: "sm",
                            variant: state.variant,
                            text: state.text
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.evh_3943151158818434_clickStopPrevent(
                                $event,
                                { state: state }
                              )
                            }
                          }
                        })
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  _vm.contract.status === "archived"
                    ? _c("ItpButton", {
                        staticClass: "mx-2 border",
                        attrs: {
                          size: "sm",
                          variant: "light",
                          text: _vm._f("pgettext")(
                            "Unarchive...",
                            "SetContractStatus"
                          )
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.evh_2857579216270778_clickStopPrevent(
                              $event,
                              {}
                            )
                          }
                        }
                      })
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }