var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c("span", { class: ["mr-2", _vm.dotClass] }, [_vm._v("\n    ⬤\n  ")]),
      _vm._v(" "),
      _c(_vm.strong ? "strong" : "span", { tag: "component" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm._f("pgettext")(_vm.value, "WorksheetStatus", "Sentence")
            ) +
            "\n  "
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }