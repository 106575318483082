var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DIV",
    [
      _c("ItpIcon", {
        class: _vm.dotClass,
        attrs: { icon: "icon-park-outline:dot" }
      }),
      _vm._v(" " + _vm._s(_vm.option.name) + "\n\n")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }