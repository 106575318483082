var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c("ItpWidgetCard", [
        _c("table", { attrs: { slot: "content" }, slot: "content" }, [
          _c(
            "tbody",
            [
              _c("tr", [
                _c(
                  "td",
                  [
                    _c("ItpText", {
                      attrs: {
                        text: _vm._f("gettext")("Net"),
                        spacing: "p-2",
                        uppercase: ""
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("ItpText", {
                      attrs: {
                        text: _vm._f("number")(_vm.totals.subtotal),
                        spacing: "p-2"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("tr", [
                _c(
                  "td",
                  [
                    _c("ItpText", {
                      attrs: {
                        text: _vm._f("gettext")("VAT"),
                        spacing: "p-2",
                        small: "",
                        muted: "",
                        uppercase: ""
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _vm._l(_vm.totals.taxes, function(tax) {
                return _c("tr", [
                  _c(
                    "td",
                    [
                      _c("ItpText", {
                        attrs: {
                          text: tax.tax_code,
                          spacing: "p-2",
                          small: "",
                          muted: "",
                          uppercase: ""
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("ItpText", {
                        attrs: {
                          text: _vm._f("number")(tax.amount),
                          spacing: "p-2",
                          muted: "",
                          small: ""
                        }
                      })
                    ],
                    1
                  )
                ])
              }),
              _vm._v(" "),
              _c("tr", [_c("td", { attrs: { colspan: "2" } }, [_c("hr")])]),
              _vm._v(" "),
              _c("tr", [
                _c(
                  "td",
                  [
                    _c("ItpText", {
                      attrs: {
                        text: _vm._f("gettext")("Total"),
                        spacing: "p-2",
                        strong: "",
                        uppercase: ""
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("ItpText", {
                      attrs: {
                        text: _vm._f("number")(_vm.totals.total),
                        spacing: "p-2",
                        strong: ""
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            2
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }