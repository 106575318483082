<template>
<ItpBadge
  no-box-shadow
  :title="abbreviated ? messages[value] : null"
  :class="`bg-${colorCodes.bg} text-${colorCodes.text} rounded-pill px-2`"
  v-if="colorCodes"
>
  <!--ItpIcon icon="oui:dot"></ItpIcon-->
{{ abbreviated ? messages.abbreviated[value] : messages[value] }}

</ItpBadge>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcInvoiceTypeBadge extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
  })
  value!: string;

  @Watch('value')
  onValue(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("value")
  }

  @Prop({
    type: Boolean,
  })
  abbreviated!: boolean;

  @Watch('abbreviated')
  onAbbreviated(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("abbreviated")
  }

  messages!: any;
  ux!: any;
  dataMembers = ['messages', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        messages: {
          abbreviated: {
            normal: this.$fn.pgettext("InvoiceType|Abbrev", "N"),
            storno: this.$fn.pgettext("InvoiceType|Abbrev", "S"),
            correction: this.$fn.pgettext("InvoiceType|Abbrev", "C"),
            proforma: this.$fn.pgettext("InvoiceType|Abbrev", "PF"),
          }
          ,
          normal: this.$fn.pgettext("InvoiceType", "Normal"),
          storno: this.$fn.pgettext("InvoiceType", "Storno"),
          correction: this.$fn.pgettext("InvoiceType", "Correction"),
          proforma: this.$fn.pgettext("InvoiceType", "Pro Forma"),
        }
        ,
        ux: null,
      },
    }
  }

  get colorCodes() {
    if (this.loaded) {

      return this.$config.invoice.types.colorCodes[this.value]
    }
    return null;
  }

  actions!: {
  }

  getActions() {
    return {
    }
  }
}

Vue.component("UcInvoiceTypeBadge", UcInvoiceTypeBadge);

</script>