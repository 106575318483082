var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c("ItpButton", {
        attrs: {
          icon: "fa-list-alt",
          variant: "default",
          id: _vm.triggerButtonID
        }
      }),
      _vm._v(" "),
      _c(
        "b-popover",
        {
          attrs: {
            target: _vm.triggerButtonID,
            triggers: "click blur",
            title: _vm.currentTitle
          }
        },
        [
          _c(
            "ItpFormGroup",
            {
              attrs: {
                name: "currency_to",
                labelColsMd: "4",
                contentColsMd: "8",
                label: _vm._f("gettext")("Currency"),
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormSelect2", {
                attrs: {
                  name: "currency_to",
                  valueField: "code",
                  textField: "code",
                  required: "",
                  options: _vm.currencies
                },
                model: {
                  value: _vm.item.currency_to,
                  callback: function($$v) {
                    _vm.$set(_vm.item, "currency_to", $$v)
                  },
                  expression: "item.currency_to"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ItpFormGroup",
            {
              attrs: {
                labelColsMd: "4",
                contentColsMd: "8",
                name: "value",
                label: _vm._f("gettext")("Amount")
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { type: "number", name: "value" },
                model: {
                  value: _vm.item.value,
                  callback: function($$v) {
                    _vm.$set(_vm.item, "value", $$v)
                  },
                  expression: "item.value"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ItpFormGroup",
            {
              attrs: {
                labelColsMd: "4",
                contentColsMd: "8",
                name: "date",
                label: _vm._f("gettext")("Date")
              }
            },
            [
              _c("ItpFormDatetime", {
                attrs: { name: "date" },
                model: {
                  value: _vm.item.date,
                  callback: function($$v) {
                    _vm.$set(_vm.item, "date", $$v)
                  },
                  expression: "item.date"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ItpFormGroup",
            {
              attrs: {
                labelColsMd: "4",
                contentColsMd: "8",
                name: "bank",
                label: _vm._f("gettext")("Bank")
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { name: "bank" },
                model: {
                  value: _vm.item.bank,
                  callback: function($$v) {
                    _vm.$set(_vm.item, "bank", $$v)
                  },
                  expression: "item.bank"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }