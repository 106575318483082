var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpWidgetCard",
        [
          _c(
            "ItpBox",
            { attrs: { slot: "header", alignItems: "center" }, slot: "header" },
            [
              _c("strong", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("pgettext")("Financial", "Contract")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              !_vm.readonly
                ? _c("ItpButton", {
                    staticClass: "ml-auto",
                    attrs: {
                      text: _vm._f("gettext")("Edit..."),
                      variant: "light",
                      size: "sm"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_8320016629450276_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.loaded
            ? _c(
                "BListGroup",
                [
                  _c(
                    "ItpLabeledListGroupItem",
                    {
                      attrs: {
                        label: _vm._f("pgettext")("Advances", "Contract"),
                        labelCols: _vm.labelCols
                      }
                    },
                    [
                      _c("ItpText", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.item.rental_fee_advances_initial_months
                            ) +
                            " " +
                            _vm._s(
                              _vm._f("pgettext")(
                                "months initial",
                                "ContractAdvances"
                              )
                            ) +
                            "\n"
                        ),
                        _c("br"),
                        _vm._v(
                          "\n" +
                            _vm._s(
                              _vm.item.rental_fee_advances_reserve_months
                            ) +
                            " " +
                            _vm._s(
                              _vm._f("pgettext")(
                                "months reserved",
                                "ContractAdvances"
                              )
                            ) +
                            "\n\n        "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpLabeledListGroupItem",
                    {
                      attrs: {
                        label: _vm._f("pgettext")(
                          "Billing currency",
                          "Contract"
                        ),
                        labelCols: _vm.labelCols
                      }
                    },
                    [
                      _c("ItpText", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.item.billing_currency) +
                            "\n        "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpLabeledListGroupItem",
                    {
                      attrs: {
                        label: _vm._f("pgettext")(
                          "Billing frequency",
                          "Contract"
                        ),
                        labelCols: _vm.labelCols
                      }
                    },
                    [
                      _c("ItpText", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.billingCycleValue) +
                            " " +
                            _vm._s(_vm._f("gettext")(_vm.billingCycleName)) +
                            "\n        "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.item.invoice_notes
                    ? _c(
                        "ItpLabeledListGroupItem",
                        {
                          attrs: {
                            label: _vm._f("pgettext")(
                              "Notes on invoice",
                              "Contract"
                            ),
                            labelCols: _vm.labelCols,
                            helpTooltip: _vm._f("pgettext")(
                              "Text to append to the invoices generated from this contract.",
                              "Contract"
                            )
                          }
                        },
                        [
                          _c("ItpText", [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.item.invoice_notes) +
                                "\n        "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.payment_terms
                    ? _c(
                        "ItpLabeledListGroupItem",
                        {
                          attrs: {
                            label: _vm._f("pgettext")(
                              "Payment terms",
                              "Contract"
                            ),
                            labelCols: _vm.labelCols
                          }
                        },
                        [
                          _c("ItpText", [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm._f("pgettext")(
                                    _vm.item.payment_terms.type,
                                    "ContractPaymentTerms"
                                  )
                                ) +
                                ", " +
                                _vm._s(_vm.item.payment_terms.value) +
                                "\n\n        "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }