var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "Template",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.item
        ? _c(
            "DIV",
            { staticClass: "d-flex flex-column h-100 mr-3 py-3" },
            [
              _c(
                "DIV",
                { staticClass: "w-100 d-flex align-items-center mb-3" },
                [
                  _c("H4", { staticClass: "mr-3 mb-0" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.item.snapshot("name")) +
                        "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c("ItpButton", {
                    attrs: {
                      variant: "primary",
                      spinning: _vm.actions.save.isRunning,
                      icon: "fa-save",
                      text: _vm._f("pgettext")("Save", "Templates")
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_8320016629450276_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("ItpResourceMeta", {
                    staticClass: "ml-auto",
                    attrs: { item: _vm.item }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "DIV",
                { staticClass: "h-100 overflow-hidden" },
                [
                  _c(
                    "splitpanes",
                    { staticClass: "default-theme" },
                    [
                      _c(
                        "pane",
                        { attrs: { "min-size": "30" } },
                        [
                          _c(
                            "CardTabs",
                            [
                              _c(
                                "b-tab",
                                {
                                  staticClass:
                                    "h-100 position-relative overflow-auto",
                                  attrs: {
                                    title: _vm._f("pgettext")(
                                      "General",
                                      "Templates"
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "ItpForm",
                                    {
                                      attrs: {
                                        name: "template",
                                        id: "frmTemplate"
                                      }
                                    },
                                    [
                                      _c(
                                        "ItpFormGroup",
                                        {
                                          attrs: {
                                            name: "name",
                                            label: _vm._f("pgettext")(
                                              "Name",
                                              "Templates"
                                            ),
                                            row: "",
                                            labelColsMd: "4",
                                            contentColsMd: "8"
                                          }
                                        },
                                        [
                                          _c("ItpFormInput", {
                                            attrs: { name: "name", size: "md" },
                                            model: {
                                              value: _vm.item.name,
                                              callback: function($$v) {
                                                _vm.$set(_vm.item, "name", $$v)
                                              },
                                              expression: "item.name"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "ItpFormGroup",
                                        {
                                          attrs: {
                                            name: "notes",
                                            label: _vm._f("pgettext")(
                                              "Notes",
                                              "Templates"
                                            ),
                                            row: "",
                                            labelColsMd: "4",
                                            contentColsMd: "8"
                                          }
                                        },
                                        [
                                          _c("ItpFormTextarea", {
                                            attrs: {
                                              name: "notes",
                                              size: "md"
                                            },
                                            model: {
                                              value: _vm.item.notes,
                                              callback: function($$v) {
                                                _vm.$set(_vm.item, "notes", $$v)
                                              },
                                              expression: "item.notes"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.item.template_type === "mjml"
                                        ? _c("HR")
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.item.template_type === "mjml"
                                        ? _c(
                                            "ItpFormGroup",
                                            {
                                              attrs: {
                                                name: "subject",
                                                label: _vm._f("pgettext")(
                                                  "Subject",
                                                  "email"
                                                ),
                                                row: "",
                                                labelColsMd: "4",
                                                contentColsMd: "8",
                                                description: _vm._f("pgettext")(
                                                  "The subject of the email sent to the client. This is a template definition, interpolate expressions can be used here.",
                                                  "Templates"
                                                )
                                              }
                                            },
                                            [
                                              _c("ItpFormInput", {
                                                attrs: {
                                                  name: "subject",
                                                  size: "md"
                                                },
                                                model: {
                                                  value: _vm.item.subject,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.item,
                                                      "subject",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.subject"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-tab",
                                {
                                  staticClass: "h-100 position-relative",
                                  attrs: {
                                    title: _vm._f("pgettext")(
                                      "Body template",
                                      "Templates"
                                    )
                                  }
                                },
                                [
                                  _c("CodeMirror", {
                                    attrs: {
                                      mode: "html",
                                      options: _vm.b_3418904655244121_options
                                    },
                                    model: {
                                      value: _vm.item.content,
                                      callback: function($$v) {
                                        _vm.$set(_vm.item, "content", $$v)
                                      },
                                      expression: "item.content"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "pane",
                        { attrs: { "min-size": "30" } },
                        [
                          _c(
                            "CardTabs",
                            [
                              _c(
                                "b-tab",
                                {
                                  staticClass: "h-100 position-relative",
                                  attrs: {
                                    title: _vm._f("pgettext")(
                                      "Preview",
                                      "Templates"
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "DIV",
                                    { staticClass: "d-flex flex-column h-100" },
                                    [
                                      _vm.sampleData
                                        ? _c("ItpIFrame", {
                                            staticClass:
                                              "h-100 w-100 border-0 mb-3",
                                            attrs: { html: _vm.previewData }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.sampleData
                                        ? _c(
                                            "DIV",
                                            { staticClass: "h-100 d-flex" },
                                            [
                                              _c(
                                                "b-alert",
                                                {
                                                  staticClass: "m-auto",
                                                  attrs: {
                                                    show: "",
                                                    variant: "primary"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm._f("pgettext")(
                                                          "Generate HTML previews using the data loaded/entered at the Data tab.",
                                                          "Templates"
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.item &&
                                      _vm.item.template_type === "mjml"
                                        ? _c(
                                            "DIV",
                                            { staticClass: "w-100 form-row" },
                                            [
                                              _c(
                                                "DIV",
                                                { staticClass: "col" },
                                                [
                                                  _c("ItpFormInput", {
                                                    attrs: {
                                                      type: "email",
                                                      name: "email"
                                                    },
                                                    model: {
                                                      value: _vm.emailAddress,
                                                      callback: function($$v) {
                                                        _vm.emailAddress = $$v
                                                      },
                                                      expression: "emailAddress"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "DIV",
                                                { staticClass: "col-auto" },
                                                [
                                                  _c("ItpButton", {
                                                    attrs: {
                                                      icon: "fa-envelope",
                                                      text: _vm._f("pgettext")(
                                                        "Send email",
                                                        "Templates"
                                                      ),
                                                      variant: "primary",
                                                      spinning:
                                                        _vm.actions.sendmail
                                                          .isRunning,
                                                      disabled: !_vm.previewData
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.preventDefault()
                                                        $event.stopPropagation()
                                                        return _vm.evh_8399737542760576_clickPreventStop(
                                                          $event,
                                                          {}
                                                        )
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("ItpButton", {
                                    staticClass: "position-absolute m-3",
                                    style: _vm.b_8695782439888834_style,
                                    attrs: {
                                      icon: "fa-file-invoice",
                                      text: _vm._f("pgettext")(
                                        "Render",
                                        "Templates"
                                      ),
                                      variant: "primary",
                                      spinning:
                                        _vm.actions.renderPreview.isRunning,
                                      disabled: !_vm.sampleData
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.evh_8695782439888834_clickPreventStop(
                                          $event,
                                          {}
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-tab",
                                {
                                  staticClass:
                                    "h-100 overflow-hidden d-flex flex-column",
                                  attrs: {
                                    title: _vm._f("pgettext")(
                                      "Data",
                                      "Templates"
                                    )
                                  }
                                },
                                [
                                  _c("ItpFormSelect2", {
                                    staticClass: "mb-3",
                                    attrs: {
                                      name: "reference_number",
                                      dataSource:
                                        _vm.b_2407519033660513_dataSource,
                                      valueField: "id",
                                      textField: "data.reference_number",
                                      placeholder: _vm._f("pgettext")(
                                        "Search data",
                                        "Templates"
                                      )
                                    },
                                    model: {
                                      value: _vm.sampleDataSearchResult,
                                      callback: function($$v) {
                                        _vm.sampleDataSearchResult = $$v
                                      },
                                      expression: "sampleDataSearchResult"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("CodeMirror", {
                                    staticClass: "h-100",
                                    attrs: {
                                      mode: "json",
                                      options: _vm.b_5419303415570863_options
                                    },
                                    model: {
                                      value: _vm.sampleData,
                                      callback: function($$v) {
                                        _vm.sampleData = $$v
                                      },
                                      expression: "sampleData"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }