var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "CreateContract",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "DIV",
                { staticClass: "col" },
                [
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "contract_type",
                        label: _vm._f("gettext")("Contract type"),
                        row: "",
                        labelColsMd: "3",
                        contentColsMd: "4",
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormSelect2", {
                        attrs: {
                          name: "contract_type",
                          options: _vm.contractTypes,
                          valueField: "value",
                          textField: "text",
                          required: ""
                        },
                        on: {
                          optionSelected: function($event) {
                            return _vm.evh_7789867021953041_optionSelected(
                              $event,
                              {}
                            )
                          }
                        },
                        model: {
                          value: _vm.contract_type,
                          callback: function($$v) {
                            _vm.contract_type = $$v
                          },
                          expression: "contract_type"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c(
                "ItpForm",
                {
                  staticClass: "col",
                  attrs: { id: _vm.formId, name: _vm.formName }
                },
                [
                  _c(
                    "b-tabs",
                    {
                      attrs: { vertical: "", pills: "" },
                      model: {
                        value: _vm.currentTab,
                        callback: function($$v) {
                          _vm.currentTab = $$v
                        },
                        expression: "currentTab"
                      }
                    },
                    [
                      _c(
                        "b-tab",
                        {
                          attrs: {
                            title: _vm._f("pgettext")("General", "Contract")
                          }
                        },
                        [
                          _c("FieldsetContractNumbersData", {
                            attrs: {
                              contract: _vm.contract,
                              labelAlign: _vm.labelAlign
                            }
                          }),
                          _vm._v(" "),
                          _c("hr"),
                          _vm._v(" "),
                          _c("FieldsetContractValidityData", {
                            attrs: {
                              contract: _vm.contract,
                              labelAlign: _vm.labelAlign
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tab",
                        { attrs: { title: _vm.companyTranslation } },
                        [
                          _c("FieldsetContractCompanyData", {
                            attrs: {
                              contract: _vm.contract,
                              labelAlign: _vm.labelAlign
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tab",
                        { attrs: { title: _vm.counterpartyTranslation } },
                        [
                          _c("FieldsetContractClientData", {
                            attrs: {
                              contract: _vm.contract,
                              labelAlign: _vm.labelAlign
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tab",
                        {
                          attrs: {
                            title: _vm._f("pgettext")("Rental", "Contract")
                          }
                        },
                        [
                          _c("FieldsetContractRentalData", {
                            attrs: {
                              contract: _vm.contract,
                              labelAlign: _vm.labelAlign,
                              "no-legend": ""
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tab",
                        {
                          attrs: {
                            title: _vm._f("pgettext")("Financial", "Contract")
                          }
                        },
                        [
                          _c("FieldsetContractInvoicing", {
                            attrs: {
                              contract: _vm.contract,
                              labelAlign: _vm.labelAlign
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _c(
                "ItpBox",
                { staticClass: "mr-auto" },
                [
                  _c("ItpButton", {
                    attrs: {
                      text: _vm._f("gettext")("Previous"),
                      disabled: _vm.currentTab == 0
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_2140198529698299_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpBox",
                [
                  _c("ItpButton", {
                    attrs: {
                      text: _vm._f("gettext")("Next"),
                      disabled: _vm.currentTab > 3
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_8790374038998522_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("ItpButton", {
                    staticClass: "ml-3",
                    attrs: {
                      type: "submit",
                      form: _vm.formId,
                      variant: "primary",
                      icon: "fa-save",
                      text: _vm._f("gettext")("Create contract"),
                      spinning: _vm.actions.save.isRunning,
                      disabled:
                        !_vm.forms[_vm.formName] ||
                        !_vm.forms[_vm.formName].submittable
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_309864017738714_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("ItpButton", {
                    staticClass: "ml-3",
                    attrs: {
                      variant: "default",
                      text: _vm._f("gettext")("Close"),
                      disabled: _vm.actions.save.isRunning
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_1589624209624921_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }