var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpWidgetCard",
        [
          _c(
            "ItpBox",
            { attrs: { slot: "header", alignItems: "center" }, slot: "header" },
            [
              _c("strong", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("gettext")("Employees")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              !_vm.parent.isReadOnly
                ? _c("ItpButton", {
                    staticClass: "ml-auto",
                    attrs: {
                      text: _vm._f("gettext")("Add employee..."),
                      variant: "light",
                      size: "sm"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_8320016629450276_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "BListGroup",
            [
              !_vm.items || !_vm.items.length
                ? _c("b-list-group-item", [
                    _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("gettext")(
                              "No employees have been added yet."
                            )
                          ) +
                          "\n        "
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(
                _vm._.orderBy(_vm.items, ["created_at"], ["desc"]),
                function(item) {
                  return _c(
                    "b-list-group-item",
                    { key: item.id, staticClass: "d-flex flex-column" },
                    [
                      _c(
                        "ItpBox",
                        { attrs: { spacing: "pb-1" } },
                        [
                          _c("UcEmployeeCard", {
                            staticClass: "mb-2",
                            attrs: { item: item }
                          }),
                          _vm._v(" "),
                          !item.locked
                            ? _c(
                                "b-dropdown",
                                {
                                  staticClass: "ml-auto mb-auto",
                                  attrs: {
                                    size: "sm",
                                    "toggle-class": "btn-default-borderless",
                                    "no-caret": ""
                                  }
                                },
                                [
                                  _c("ItpIcon", {
                                    staticClass: "mx-1",
                                    attrs: {
                                      slot: "button-content",
                                      fa: "ellipsis-v"
                                    },
                                    slot: "button-content"
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "b-dropdown-item-button",
                                    {
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.evh_294572930540096_clickPreventStop(
                                            $event,
                                            { item: item }
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("ItpText", {
                                        attrs: {
                                          text: _vm._f("pgettext")(
                                            "Edit",
                                            "Command"
                                          )
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  !item.locked
                                    ? _c(
                                        "b-dropdown-item-button",
                                        {
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                              return _vm.evh_3365375699802249_clickPreventStop(
                                                $event,
                                                { item: item }
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("ItpText", {
                                            attrs: {
                                              text: _vm._f("pgettext")(
                                                "Delete",
                                                "Command"
                                              )
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "DIV",
                        { staticClass: "d-flex align-items-end" },
                        [
                          _c("ItpText", { attrs: { muted: "", small: "" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm._f("datetime")(
                                    item.created_at || item.created_at
                                  )
                                ) +
                                "\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "small",
                            { staticClass: "text-muted text-nowrap mx-2" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm._f("username")(
                                      item.updated_by || item.created_by
                                    )
                                  ) +
                                  "\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "DIV",
                            {
                              staticClass:
                                "ml-2 d-flex align-self-end flex-grow-1 justify-content-end"
                            },
                            [
                              item.locked
                                ? _c("ItpIcon", {
                                    staticClass: "text-muted",
                                    attrs: {
                                      title: _vm._f("gettext")("Locked"),
                                      name: "fa-lock"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }