var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "Account",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c(
            "UcHeader",
            {
              attrs: { slot: "header", card: !_vm.modal, modal: _vm.modal },
              on: {
                close: function($event) {
                  return _vm.evh_2248226175642056_close($event, {})
                }
              },
              slot: "header"
            },
            [
              _c("UcAccountHeader", {
                attrs: { account: _vm.item },
                on: {
                  reload: function($event) {
                    return _vm.evh_8564662037462133_reload($event, {})
                  },
                  deleted: function($event) {
                    return _vm.evh_8564662037462133_deleted($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "ItpForm",
                {
                  ref: "mainForm",
                  staticClass: "row",
                  attrs: {
                    id: _vm.formId,
                    name: "account",
                    readonly: _vm.item.locked
                  },
                  on: {
                    submit: function($event) {
                      return _vm.evh_1040167445267876_submit($event, {})
                    }
                  }
                },
                [
                  _c(
                    "ItpCol",
                    [
                      _c("FieldsetAccountGeneral", {
                        attrs: {
                          item: _vm.item,
                          formId: _vm.formId,
                          "no-submit-button": ""
                        },
                        on: {
                          saved: function($event) {
                            return _vm.evh_7789867021953041_saved($event, {})
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _c("ItpButton", {
                attrs: {
                  type: "submit",
                  text: _vm._f("gettext")("Save"),
                  form: _vm.forms.account,
                  spinning: _vm.actions.save.isRunning
                }
              }),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: { text: _vm._f("gettext")("Close"), variant: "default" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1419464017721962_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }