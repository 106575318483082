var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "WasteBinReadings",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _c(
        "VueSlickgrid",
        {
          ref: "grid",
          staticClass: "page--grid",
          attrs: {
            name: "wasteBinReadings",
            headerTitle: _vm._f("gettext")("Waste Bin Readings"),
            items: _vm.$fn.slickDataSource("list_waste_bin_readings", {
              parameters: _vm.dsParams
            }),
            "export-to-csv": "",
            exportToCsvFilename: _vm._f("pgettext")(
              "waste-bin-readings",
              "ExportFilename"
            ),
            "checkbox-row-selection": "",
            columnDefaults: _vm.b_2248226175642056_columnDefaults,
            columns: _vm.b_2248226175642056_columns,
            contextMenu: _vm.b_2248226175642056_contextMenu
          },
          on: {
            selectedRowsChanged: function($event) {
              return _vm.evh_2248226175642056_selectedRowsChanged($event, {})
            },
            "contextmenu:remove": function($event) {
              return _vm.evh_2248226175642056_contextmenuRemove($event, {})
            },
            command: function($event) {
              return _vm.evh_2248226175642056_command($event, {})
            }
          }
        },
        [
          _c(
            "DIV",
            {
              staticClass: "flex-grow-1 d-flex",
              attrs: { slot: "toolbar" },
              slot: "toolbar"
            },
            [
              _c(
                "DIV",
                { staticClass: "mx-2" },
                [
                  _c("ItpFormCheckbox", {
                    attrs: {
                      name: "skip_archived",
                      label: _vm._f("pgettext")(
                        "Include handled readings",
                        "WasteBinReadings"
                      ),
                      inline: ""
                    },
                    model: {
                      value: _vm.dsParams.include_worksheets,
                      callback: function($$v) {
                        _vm.$set(_vm.dsParams, "include_worksheets", $$v)
                      },
                      expression: "dsParams.include_worksheets"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("ItpButton", {
                staticClass: "mx-2",
                attrs: {
                  icon: "fa-cog",
                  text: _vm._f("gettext")("Create Worksheets..."),
                  size: "sm",
                  disabled: !_vm.selectedItems || !_vm.selectedItems.length,
                  variant: "primary"
                },
                on: {
                  click: function($event) {
                    return _vm.evh_8320016629450276_click($event, {})
                  }
                }
              }),
              _vm._v(" "),
              _c("ItpButton", {
                staticClass: "mx-2",
                attrs: {
                  icon: "fa-trash",
                  text: _vm._f("gettext")("Delete"),
                  size: "sm",
                  variant: "danger",
                  disabled: !_vm.selectedItems || !_vm.selectedItems.length,
                  spinning: _vm.actions.deleteItems.isRunning
                },
                on: {
                  click: function($event) {
                    return _vm.evh_6705964765214521_click($event, {})
                  }
                }
              }),
              _vm._v(" "),
              _c("PageHelp", { attrs: { path: "/assets/fixed-assets.html" } })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }