var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpBox",
        { attrs: { direction: "column" } },
        [
          _c(
            "ItpBox",
            { attrs: { spacing: "mb-2" } },
            [
              _c("ItpText", { attrs: { text: _vm.item.account_number } }),
              _vm._v(" "),
              _vm.item.is_default
                ? _c("ItpBadge", {
                    staticClass: "mx-4",
                    attrs: { text: _vm._f("gettext")("Default") }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.item.valid_from || _vm.item.valid_to
            ? _c("UcValidityPeriod", {
                staticClass: "mb-2",
                attrs: { from: _vm.item.valid_from, to: _vm.item.valid_to }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.item.description
            ? _c("ItpText", {
                attrs: { text: _vm.item.description, pre: "", spacing: "mb-2" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.item.bank
            ? _c("ItpText", {
                attrs: {
                  title: _vm._f("pgettext")("Bank", "BankAccount"),
                  text: _vm.item.bank,
                  spacing: "mb-2"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.item.iban
            ? _c("ItpText", {
                attrs: {
                  title: _vm._f("pgettext")("IBAN", "BankAccount"),
                  text: _vm.item.iban,
                  spacing: "mb-2"
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }