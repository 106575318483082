var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpWidgetCard",
        [
          _c(
            "ItpBox",
            { attrs: { slot: "header", alignItems: "center" }, slot: "header" },
            [
              _c("strong", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("pgettext")("General", "Property")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("ItpButton", {
                staticClass: "ml-auto",
                attrs: {
                  text: _vm._f("gettext")("Edit..."),
                  variant: "light",
                  size: "sm"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_8320016629450276_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "BListGroup",
            [
              _vm.property.property_type
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Type", "Property"),
                      content: _vm.property.property_type
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.property.name
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Name", "Property"),
                      content: _vm.property.name
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.property.code
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Code", "Property"),
                      content: _vm.property.code
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.property.description
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Description", "Property"),
                      content: _vm.property.description
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.property.area
                ? _c(
                    "ItpLabeledListGroupItem",
                    {
                      attrs: { label: _vm._f("pgettext")("Area", "Property") }
                    },
                    [
                      _c("ItpText", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.property.area) +
                            " " +
                            _vm._s(_vm.property.unit_of_area) +
                            "\n        "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.property.location_code
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Location", "Property"),
                      content: _vm.property.location_code
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "ItpLabeledListGroupItem",
                {
                  attrs: { label: _vm._f("pgettext")("Validity", "Property") }
                },
                [
                  _c("UcPropertyValidityCard", {
                    attrs: { item: _vm.property }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }