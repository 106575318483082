var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "CreateMeterReadings",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "ItpForm",
                {
                  attrs: { name: "createOptions" },
                  on: {
                    submit: function($event) {
                      return _vm.evh_1040167445267876_submit($event, {})
                    }
                  }
                },
                [
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "notes",
                        label: _vm._f("pgettext")(
                          "Notes",
                          "CreateMeterReadings"
                        )
                      }
                    },
                    [
                      _c("ItpFormTextarea", {
                        attrs: { name: "notes" },
                        model: {
                          value: _vm.notes,
                          callback: function($$v) {
                            _vm.notes = $$v
                          },
                          expression: "notes"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "irentWriteback",
                        description: _vm._f("pgettext")(
                          "Save values to the legacy database, too.",
                          "CreateMeterReadings"
                        ),
                        row: "",
                        "label-cols-md": "0",
                        "content-cols-md": "12"
                      }
                    },
                    [
                      _c("ItpFormCheckbox", {
                        attrs: {
                          name: "irentWriteback",
                          label: _vm._f("pgettext")(
                            "IntegroRent writeback",
                            "CreateMeterReadings"
                          )
                        },
                        model: {
                          value: _vm.irentWriteback,
                          callback: function($$v) {
                            _vm.irentWriteback = $$v
                          },
                          expression: "irentWriteback"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "irentWriteback",
                        description: _vm._f("pgettext")(
                          "Skip writeback to the legacy database if the serial number is not found in the legacy database.",
                          "GenerateMeterReading"
                        ),
                        row: "",
                        "label-cols-md": "0",
                        "content-cols-md": "12"
                      }
                    },
                    [
                      _c("ItpFormCheckbox", {
                        attrs: {
                          name: "irentWritebackSkipMetersNotFound",
                          label: _vm._f("pgettext")(
                            "IntegroRent writeback - skip not found",
                            "CreateMeterReadings"
                          )
                        },
                        model: {
                          value: _vm.irentWritebackSkipMetersNotFound,
                          callback: function($$v) {
                            _vm.irentWritebackSkipMetersNotFound = $$v
                          },
                          expression: "irentWritebackSkipMetersNotFound"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        name: "skipMetersNotFound",
                        description: _vm._f("pgettext")(
                          "Skip meters not found in the database.",
                          "CreateMeterReadings"
                        ),
                        row: "",
                        "label-cols-md": "0",
                        "content-cols-md": "12"
                      }
                    },
                    [
                      _c("ItpFormCheckbox", {
                        attrs: {
                          name: "skipMetersNotFound",
                          label: _vm._f("pgettext")(
                            "Skip not found",
                            "CreateMeterReadings"
                          )
                        },
                        model: {
                          value: _vm.skipMetersNotFound,
                          callback: function($$v) {
                            _vm.skipMetersNotFound = $$v
                          },
                          expression: "skipMetersNotFound"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _c("ItpButton", {
                attrs: {
                  form: _vm.forms.createOptions && _vm.forms.createOptions.id,
                  text: _vm._f("pgettext")(
                    "Create Readings",
                    "MeterMeasurementGenerateButton"
                  ),
                  type: "submit",
                  variant: "primary",
                  spinning: _vm.actions.submit.isRunning
                }
              }),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("pgettext")("Cancel", "CreateMeterReadings"),
                  variant: "default",
                  disabled: _vm.actions.submit.isRunning
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1419464017721962_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }