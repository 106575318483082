var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c("legend", { staticClass: "text-muted" }, [
        _vm._v(
          "\n    " + _vm._s(_vm._f("pgettext")("General", "Meter")) + "\n  "
        )
      ]),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "meter_type",
            label: _vm._f("pgettext")("Meter type", "Meter"),
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormSelect", {
            attrs: { name: "meter_type", options: _vm.meterTypes },
            model: {
              value: _vm.meter.meter_type,
              callback: function($$v) {
                _vm.$set(_vm.meter, "meter_type", $$v)
              },
              expression: "meter.meter_type"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "parent",
            label: _vm._f("pgettext")("Main meter", "Meter"),
            labelColsMd: "4",
            contentColsMd: "8",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormSelect2", {
            attrs: {
              name: "parent",
              clearable: "",
              dataSource: _vm.b_1548630417156826_dataSource,
              initialOptions: _vm.meter.main_meter
                ? [_vm.meter.main_meter]
                : [],
              valueField: "id",
              textField: "serial_number",
              "use-option-template-for-selected-option": "",
              optionTemplate:
                '<UcSuggestMeterOption :option="option"></UcSuggestMeterOption>'
            },
            model: {
              value: _vm.meter.parent,
              callback: function($$v) {
                _vm.$set(_vm.meter, "parent", $$v)
              },
              expression: "meter.parent"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "meter_unit",
            label: _vm._f("pgettext")("Unit and multiplier", "Meter"),
            labelAlign: _vm.labelAlign,
            contentClass: "form-row",
            required: "",
            helpTooltip: _vm._f("pgettext")(
              "The multiplier converts the readings into the given units.",
              "Meter"
            )
          }
        },
        [
          _c(
            "DIV",
            { staticClass: "col-4" },
            [
              _c("ItpFormSelect", {
                attrs: {
                  name: "unit",
                  options: _vm.units,
                  placeholder: 'P_("Meter", "Meter", "unit")'
                },
                model: {
                  value: _vm.meter.unit,
                  callback: function($$v) {
                    _vm.$set(_vm.meter, "unit", $$v)
                  },
                  expression: "meter.unit"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "DIV",
            { staticClass: "col-4" },
            [
              _c("ItpFormInput", {
                attrs: {
                  type: "number",
                  name: "multiplier",
                  placeholder: _vm._f("pgettext")("Multiplier", "Meter"),
                  title: _vm._f("pgettext")("Multiplier", "Meter")
                },
                model: {
                  value: _vm.meter.multiplier,
                  callback: function($$v) {
                    _vm.$set(_vm.meter, "multiplier", $$v)
                  },
                  expression: "meter.multiplier"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "model_name",
            label: _vm._f("pgettext")("Model name", "Meter"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "model_name" },
            model: {
              value: _vm.meter.model_name,
              callback: function($$v) {
                _vm.$set(_vm.meter, "model_name", $$v)
              },
              expression: "meter.model_name"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "smart_device",
            label: _vm._f("pgettext")("Smart Device", "Meter"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormCheckbox", {
            attrs: { name: "smart_device" },
            model: {
              value: _vm.meter.smart_device,
              callback: function($$v) {
                _vm.$set(_vm.meter, "smart_device", $$v)
              },
              expression: "meter.smart_device"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "groups",
            label: _vm._f("pgettext")("Groups", "Meter"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormSelect2", {
            attrs: { name: "groups", multiple: "", options: _vm.meterGroups },
            model: {
              value: _vm.meter.groups,
              callback: function($$v) {
                _vm.$set(_vm.meter, "groups", $$v)
              },
              expression: "meter.groups"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "description",
            label: _vm._f("pgettext")("Site", "Meter"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormSelect2", {
            attrs: {
              name: "site_id",
              clearable: "",
              dataSource: _vm.b_7072234632991584_dataSource,
              initialOptions: _vm.meter.site ? [_vm.meter.site] : [],
              valueField: "id",
              optionTemplate: _vm.b_7072234632991584_optionTemplate,
              "use-option-template-for-selected-option": ""
            },
            model: {
              value: _vm.meter.site_id,
              callback: function($$v) {
                _vm.$set(_vm.meter, "site_id", $$v)
              },
              expression: "meter.site_id"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "description",
            label: _vm._f("pgettext")("Description", "Meter"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormTextarea", {
            attrs: { name: "description" },
            model: {
              value: _vm.meter.description,
              callback: function($$v) {
                _vm.$set(_vm.meter, "description", $$v)
              },
              expression: "meter.description"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("legend", { staticClass: "text-muted" }, [
        _vm._v(
          "\n    " + _vm._s(_vm._f("pgettext")("Numbers", "Meter")) + "\n  "
        )
      ]),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "serial_number",
            label: _vm._f("pgettext")("Serial number", "Meter"),
            labelAlign: _vm.labelAlign,
            helpTooltip: _vm._f("pgettext")(
              "The device's unique serial number.",
              "Meter"
            ),
            required: ""
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "serial_number" },
            model: {
              value: _vm.meter.serial_number,
              callback: function($$v) {
                _vm.$set(_vm.meter, "serial_number", $$v)
              },
              expression: "meter.serial_number"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "deveui",
            label: _vm._f("pgettext")("DevEUI", "Meter"),
            labelAlign: _vm.labelAlign,
            helpTooltip: _vm._f("pgettext")(
              "64-bit globally-unique Extended Unique Identifier (EUI-64) assigned by the manufacturer, or the owner, of the end-device.",
              "Meter"
            )
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "deveui" },
            model: {
              value: _vm.meter.deveui,
              callback: function($$v) {
                _vm.$set(_vm.meter, "deveui", $$v)
              },
              expression: "meter.deveui"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "tag_id",
            label: _vm._f("pgettext")("Tag ID", "Meter"),
            labelAlign: _vm.labelAlign,
            helpTooltip: _vm._f("pgettext")(
              "Locally-unique tag ID to be used within the company.",
              "Meter"
            )
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "tag_id" },
            model: {
              value: _vm.meter.tag_id,
              callback: function($$v) {
                _vm.$set(_vm.meter, "tag_id", $$v)
              },
              expression: "meter.tag_id"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("FieldsetMeterValidityData", {
        attrs: { meter: _vm.meter, labelAlign: _vm.labelAlign }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }