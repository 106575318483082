var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "4",
            name: "name",
            label: _vm._f("pgettext")("Type", "Product"),
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormSelect", {
            attrs: {
              name: "product_type",
              required: "",
              options: _vm.productTypes,
              autofocus: ""
            },
            model: {
              value: _vm.product.product_type,
              callback: function($$v) {
                _vm.$set(_vm.product, "product_type", $$v)
              },
              expression: "product.product_type"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "4",
            name: "number",
            label: _vm._f("pgettext")("Item number", "Product"),
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "number", required: "" },
            model: {
              value: _vm.product.number,
              callback: function($$v) {
                _vm.$set(_vm.product, "number", $$v)
              },
              expression: "product.number"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "name",
            label: _vm._f("pgettext")("Name", "Product"),
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "name", required: "" },
            model: {
              value: _vm.product.name,
              callback: function($$v) {
                _vm.$set(_vm.product, "name", $$v)
              },
              expression: "product.name"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "description",
            label: _vm._f("pgettext")("Description", "Product"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormTextarea", {
            attrs: { name: "description" },
            model: {
              value: _vm.product.description,
              callback: function($$v) {
                _vm.$set(_vm.product, "description", $$v)
              },
              expression: "product.description"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "4",
            name: "unit",
            label: _vm._f("pgettext")("Unit", "Product"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "unit" },
            model: {
              value: _vm.product.unit,
              callback: function($$v) {
                _vm.$set(_vm.product, "unit", $$v)
              },
              expression: "product.unit"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            name: "sales_vat_code",
            label: _vm._f("pgettext")("Sales tax code", "Product"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormSelect", {
            attrs: {
              name: "sales_vat_code",
              options: _vm.taxCodes,
              required: ""
            },
            model: {
              value: _vm.product.sales_vat_code,
              callback: function($$v) {
                _vm.$set(_vm.product, "sales_vat_code", $$v)
              },
              expression: "product.sales_vat_code"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            name: "sales_vat_code",
            label: _vm._f("pgettext")("Purchase tax code", "Product"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormSelect", {
            attrs: {
              name: "purchase_vat_code",
              options: _vm.taxCodes,
              required: ""
            },
            model: {
              value: _vm.product.purchase_vat_code,
              callback: function($$v) {
                _vm.$set(_vm.product, "purchase_vat_code", $$v)
              },
              expression: "product.purchase_vat_code"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            name: "intermediated_service",
            label: _vm._f("pgettext")("Intermediated service", "Product"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormCheckbox", {
            attrs: { name: "intermediated_service" },
            model: {
              value: _vm.product.intermediated_service,
              callback: function($$v) {
                _vm.$set(_vm.product, "intermediated_service", $$v)
              },
              expression: "product.intermediated_service"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "invoice_group_id",
            label: _vm._f("pgettext")("Invoice Group", "Product"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormSelect2", {
            attrs: {
              name: "invoice_group_id",
              multiple: _vm.b_3573211234170528_multiple,
              options: _vm.invoiceGroups,
              valueField: "id",
              textField: "name",
              clearable: ""
            },
            model: {
              value: _vm.product.invoice_group_id,
              callback: function($$v) {
                _vm.$set(_vm.product, "invoice_group_id", $$v)
              },
              expression: "product.invoice_group_id"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "groups",
            label: _vm._f("pgettext")("Groups", "Product"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormSelect2", {
            attrs: { name: "groups", multiple: "", options: _vm.productGroups },
            model: {
              value: _vm.product.groups,
              callback: function($$v) {
                _vm.$set(_vm.product, "groups", $$v)
              },
              expression: "product.groups"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "4",
            name: "customs_tariff_no",
            label: _vm._f("pgettext")("Customs tariff no.", "Product"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "customs_tariff_no" },
            model: {
              value: _vm.product.customs_tariff_no,
              callback: function($$v) {
                _vm.$set(_vm.product, "customs_tariff_no", $$v)
              },
              expression: "product.customs_tariff_no"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "4",
            name: "hu_teszor_code",
            label: _vm._f("pgettext")("TESZOR code (HU)", "Product"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "hu_teszor_code" },
            model: {
              value: _vm.product.hu_teszor_code,
              callback: function($$v) {
                _vm.$set(_vm.product, "hu_teszor_code", $$v)
              },
              expression: "product.hu_teszor_code"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "4",
            name: "eu_ewc_code",
            label: _vm._f("pgettext")("EWC code (EU)", "Product"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "eu_ewc_code" },
            model: {
              value: _vm.product.eu_ewc_code,
              callback: function($$v) {
                _vm.$set(_vm.product, "eu_ewc_code", $$v)
              },
              expression: "product.eu_ewc_code"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }