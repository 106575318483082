var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DIV", [
    _vm.option.code
      ? _c("span", [_vm._v(_vm._s(_vm.option.code) + " - ")])
      : _vm._e(),
    _vm._v(_vm._s(_vm.option.name) + "\n\n")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }