var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpBox",
    { attrs: { direction: "column" } },
    [
      _c(
        "ItpBox",
        { attrs: { alignItems: "center" } },
        [
          _c("ItpIcon", {
            staticClass: "pr-2",
            attrs: {
              title: _vm.$config.meterTypes[_vm.payload.meter_type].description,
              name: _vm.$config.meterTypes[_vm.payload.meter_type].icon
            }
          }),
          _vm._v(" "),
          _c(
            "ItpLink",
            {
              staticClass: "d-inline-block",
              attrs: {
                to: {
                  name: "app.meter",
                  params: { id: _vm.payload.id }
                }
              }
            },
            [
              _vm._v("\n      " + _vm._s(_vm.payload.serial_number) + "\n"),
              _vm.payload.tag_id
                ? _c("span", [
                    _vm._v("\n(" + _vm._s(_vm.payload.tag_id) + ")\n")
                  ])
                : _vm._e()
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpBox",
        { attrs: { alignItems: "center" } },
        [
          _vm.payload.deveui
            ? _c("ItpText", {
                staticClass: "pl-3",
                attrs: {
                  title: _vm._f("gettext")("DevEUI"),
                  text: _vm.payload.deveui
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }