var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "Registers",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _c(
        "DIV",
        { staticClass: "overflow-auto h-100 p-3" },
        [
          _c(
            "ItpBox",
            { attrs: { direction: "column" } },
            [
              _c(
                "ItpBox",
                [
                  _c("ItpButton", {
                    attrs: {
                      text: _vm._f("pgettext")("Add register...", "Registers")
                    },
                    on: {
                      click: function($event) {
                        return _vm.evh_4615685607825208_click($event, {})
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("ItpButton", {
                    attrs: { text: _vm._f("pgettext")("Reload", "Registers") },
                    on: {
                      click: function($event) {
                        return _vm.evh_2095089031469157_click($event, {})
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.loaded
                ? _c("ItpDataTable", {
                    ref: "table",
                    staticClass: "h-100",
                    attrs: {
                      tableId: "Settings.Registers",
                      editable: _vm.b_8320016629450276_editable,
                      "no-create-row": "",
                      "no-delete-row": "",
                      value: _vm._.orderBy(_vm.items, ["key", "description"]),
                      columns: _vm.b_8320016629450276_columns
                    },
                    on: {
                      click: function($event) {
                        return _vm.evh_8320016629450276_click($event, {})
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }