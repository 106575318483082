import * as integroApiSchema from './integro-api.json';
import * as metersSchema from './meters.json';
import * as meterStatsSchema from './meter-stats.json';
import * as wasteBinsSchema from './waste-bins.json';

export const connections = [
  {
    name: 'integro_api',
    kind: 'openapi',
    url: '<%= integro_api_openapi_json_url %>',
    baseUrl: '<%= integro_api_base_url %>',
    schema: integroApiSchema
  },
    {
    name: 'meters',
    kind: 'openapi',
    url: '<%= meters_api_openapi_json_url %>',
    baseUrl: '<%= meters_api_base_url %>',
    schema: metersSchema
  },
  {
    name: 'meter_stats',
    kind: 'openapi',
    url: '<%= meter_stats_api_openapi_json_url %>',
    baseUrl: '<%= meter_stats_api_base_url %>',
    schema: meterStatsSchema
  },
  {
    name: 'waste_bins',
    kind: 'openapi',
    url: '<%= waste_bins_api_openapi_json_url %>',
    baseUrl: '<%= waste_bins_api_base_url %>',
    schema: wasteBinsSchema
  }
];
