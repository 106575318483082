var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "MeterDataExchange",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c("ItpAlert", { attrs: { variant: "info", show: "" } }, [
                _c(
                  "p",
                  [
                    _c("ItpIcon", { attrs: { fa: "info-circle" } }),
                    _vm._v(" "),
                    _c("ItpText", { attrs: { wrap: "" } }, [
                      _vm._v(
                        "\n          A szenzor-mérőóra beállítások adatcseréje Excel táblázat segítségével történik.\nA mindenkor aktuális szenzor események innen tölthetőek le.\n\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("ItpLink", {
                      attrs: {
                        text: _vm._f("pgettext")(
                          "Download events",
                          "MeterDataExchange"
                        ),
                        href: _vm.downloadMeterDataExchangeFileURL,
                        button: "",
                        variant: "light",
                        "use-auth": "",
                        "force-download": ""
                      }
                    }),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("ItpText", [
                      _vm._v(
                        "\n          Új események az alábbi űrlap segítségével tölthetőek fel a rendszerbe.\n\n        "
                      )
                    ])
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c(
                "DIV",
                { staticClass: "container" },
                [
                  _vm.loaded
                    ? _c(
                        "ItpForm",
                        {
                          attrs: { name: _vm.formName, id: _vm.formId },
                          on: {
                            submit: function($event) {
                              return _vm.evh_648747096704084_submit($event, {})
                            }
                          }
                        },
                        [
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                name: "file",
                                label: _vm._f("pgettext")(
                                  "Events file",
                                  "MeterDataExchange"
                                ),
                                labelFor: "file",
                                description: _vm._f("gettext")(
                                  "Only XLSX files are allowed. It must contain at least serial_number and meter_type columns."
                                ),
                                required: ""
                              }
                            },
                            [
                              _c("ItpFormFile", {
                                attrs: {
                                  name: "file",
                                  accept: ".xlsx",
                                  required: "",
                                  validation: _vm.b_3086171180629160_validation
                                },
                                model: {
                                  value: _vm.file,
                                  callback: function($$v) {
                                    _vm.file = $$v
                                  },
                                  expression: "file"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "ItpAlert",
                    {
                      attrs: {
                        show: _vm.resultText && !_vm.actions.submit.isRunning
                      }
                    },
                    [
                      _c("p", [
                        _vm._v(
                          "\n          " + _vm._s(_vm.resultText) + "\n        "
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _c("ItpButton", {
                attrs: {
                  form: _vm.formId,
                  text: _vm._f("pgettext")(
                    "Upload events",
                    "MeterDataExchange"
                  ),
                  type: "submit",
                  variant: "primary",
                  icon: "fa-file-import",
                  spinning: _vm.actions.submit.isRunning,
                  disabled:
                    !_vm.forms[_vm.formName] ||
                    !_vm.forms[_vm.formName].submittable
                }
              }),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("gettext")("Cancel"),
                  variant: "default",
                  disabled: _vm.actions.submit.isRunning
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1419464017721962_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }