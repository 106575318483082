import RentalService from './RentalService.yaml?component';
import RentalServiceCostAllocator from './RentalServiceCostAllocator.yaml?component';
import UcRentalFormServicesTable from './UcRentalFormServicesTable.yaml?component';
import UcRentalServiceCostAllocators from './UcRentalServiceCostAllocators.yaml?component';
import UcRentalServices from './UcRentalServices.yaml?component';

export {
  RentalService,
  RentalServiceCostAllocator,
  UcRentalFormServicesTable,
  UcRentalServiceCostAllocators,
  UcRentalServices
};
