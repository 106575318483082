var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "DIV",
        { staticClass: "input-group" },
        [
          _c("ItpFormInput", {
            attrs: { type: "number", name: "payment_terms_value" },
            model: {
              value: _vm.value.value,
              callback: function($$v) {
                _vm.$set(_vm.value, "value", $$v)
              },
              expression: "value.value"
            }
          }),
          _vm._v(" "),
          _c(
            "DIV",
            { staticClass: "input-group-append" },
            [
              _c("ItpFormSelect", {
                attrs: {
                  name: "payment_terms_type",
                  options: _vm.contractPaymentTermTypes
                },
                model: {
                  value: _vm.value.type,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "type", $$v)
                  },
                  expression: "value.type"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }