var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpWidgetCard",
        [
          _c(
            "ItpBox",
            { attrs: { slot: "header", alignItems: "center" }, slot: "header" },
            [
              _c("strong", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("pgettext")("General", "Client")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              !_vm.readonly
                ? _c("ItpButton", {
                    staticClass: "ml-auto",
                    attrs: {
                      text: _vm._f("gettext")("Edit..."),
                      variant: "light",
                      size: "sm"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_8320016629450276_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "BListGroup",
            [
              _vm.client.name
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Name", "Client"),
                      labelCols: _vm.labelCols,
                      content: _vm.client.name
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.client.code
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Number", "Client"),
                      labelCols: _vm.labelCols,
                      content: _vm.client.code
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.client.client_relation
                ? _c(
                    "ItpLabeledListGroupItem",
                    {
                      attrs: {
                        label: _vm._f("pgettext")("Client type", "Client"),
                        labelCols: _vm.labelCols
                      }
                    },
                    [
                      _c("UcClientTypeBadge", {
                        attrs: { value: _vm.client.client_relation }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.client.emails
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Email", "Client"),
                      labelCols: _vm.labelCols,
                      content: _vm.client.emails
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.client.tax_number
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Tax number", "Client"),
                      labelCols: _vm.labelCols,
                      content: _vm.client.tax_number
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.client.eu_tax_number
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("EU tax number", "Client"),
                      labelCols: _vm.labelCols,
                      content: _vm.client.eu_tax_number
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.client.registration_number
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")(
                        "Registration number",
                        "Client"
                      ),
                      labelCols: _vm.labelCols,
                      content: _vm.client.registration_number
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.client.notes
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Notes", "Client"),
                      labelCols: _vm.labelCols,
                      content: _vm.client.notes
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.client.payment_period_days
                ? _c(
                    "ItpLabeledListGroupItem",
                    {
                      attrs: {
                        label: _vm._f("pgettext")("Payment period", "Client"),
                        labelCols: _vm.labelCols
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.client.payment_period_days) +
                          " " +
                          _vm._s(
                            _vm._f("pgettext")("days", "InputGroupAddon")
                          ) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.client.discount
                ? _c(
                    "ItpLabeledListGroupItem",
                    {
                      attrs: {
                        label: _vm._f("pgettext")("Discount", "Client"),
                        labelCols: _vm.labelCols
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.client.discount) +
                          " %\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.client.is_contractor
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Contractor", "Client"),
                      labelCols: _vm.labelCols,
                      content: _vm._f("gettext")("Yes")
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.client.is_natural_person
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Natural person", "Client"),
                      labelCols: _vm.labelCols,
                      content: _vm._f("gettext")("Yes")
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.client.excluded_from_claims
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")(
                        "Exclude from claims",
                        "Client"
                      ),
                      labelCols: _vm.labelCols,
                      content: _vm._f("gettext")("Yes")
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.client.vat_exempt
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("VAT exempt", "Client"),
                      labelCols: _vm.labelCols,
                      content: _vm._f("gettext")("Yes")
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.client.groups
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Groups", "Client"),
                      labelCols: _vm.labelCols,
                      content: _vm.client.groups
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.client.external_key
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("External key", "Client"),
                      labelCols: _vm.labelCols,
                      content: _vm.client.external_key
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }