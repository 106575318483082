var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "DIV",
        { staticClass: "form-row" },
        [
          _c(
            "ItpFormGroup",
            {
              staticClass: "col-7",
              attrs: {
                name: "address_type",
                label: _vm._f("pgettext")("Address Type", "Address"),
                labelAlign: _vm.labelAlign,
                required: ""
              }
            },
            [
              _c("ItpFormSelect", {
                attrs: {
                  name: "address_type",
                  options: _vm.addressTypes,
                  required: ""
                },
                model: {
                  value: _vm.address.address_type,
                  callback: function($$v) {
                    _vm.$set(_vm.address, "address_type", $$v)
                  },
                  expression: "address.address_type"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ItpBox",
            {
              staticClass: "col-5 mb-4",
              attrs: {
                direction: "column",
                alignItems: "end",
                justifyContent: "end"
              }
            },
            [
              _c("ItpFormCheckbox", {
                staticClass: "font-weight-bold",
                attrs: {
                  name: "is_default",
                  label: _vm._f("gettext")("Default"),
                  inline: "",
                  "is-switch": "",
                  readonly: _vm.address.isReadonly
                },
                model: {
                  value: _vm.address.is_default,
                  callback: function($$v) {
                    _vm.$set(_vm.address, "is_default", $$v)
                  },
                  expression: "address.is_default"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "DIV",
        { staticClass: "form-row" },
        [
          _c(
            "ItpFormGroup",
            {
              staticClass: "col-7",
              attrs: {
                name: "country",
                label: _vm._f("pgettext")("Country", "Address"),
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { name: "country" },
                model: {
                  value: _vm.address.country,
                  callback: function($$v) {
                    _vm.$set(_vm.address, "country", $$v)
                  },
                  expression: "address.country"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ItpFormGroup",
            {
              staticClass: "col-5",
              attrs: {
                name: "region",
                label: _vm._f("pgettext")("Region", "Address"),
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { name: "region" },
                model: {
                  value: _vm.address.region,
                  callback: function($$v) {
                    _vm.$set(_vm.address, "region", $$v)
                  },
                  expression: "address.region"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "DIV",
        { staticClass: "form-row" },
        [
          _c(
            "ItpFormGroup",
            {
              staticClass: "col-3",
              attrs: {
                name: "postal_code",
                label: _vm._f("pgettext")("Postal Code", "Address"),
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { name: "postal_code" },
                model: {
                  value: _vm.address.postal_code,
                  callback: function($$v) {
                    _vm.$set(_vm.address, "postal_code", $$v)
                  },
                  expression: "address.postal_code"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ItpFormGroup",
            {
              staticClass: "col-6",
              attrs: {
                name: "city",
                label: _vm._f("pgettext")("City", "Address"),
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { name: "city" },
                model: {
                  value: _vm.address.city,
                  callback: function($$v) {
                    _vm.$set(_vm.address, "city", $$v)
                  },
                  expression: "address.city"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ItpFormGroup",
            {
              staticClass: "col-3",
              attrs: {
                name: "district",
                label: _vm._f("pgettext")("District", "Address"),
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { name: "district" },
                model: {
                  value: _vm.address.district,
                  callback: function($$v) {
                    _vm.$set(_vm.address, "district", $$v)
                  },
                  expression: "address.district"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "DIV",
        { staticClass: "form-row" },
        [
          _c(
            "ItpFormGroup",
            {
              staticClass: "col-7",
              attrs: {
                name: "public_place",
                label: _vm._f("pgettext")("Public Place", "Address"),
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { name: "public_place" },
                model: {
                  value: _vm.address.public_place,
                  callback: function($$v) {
                    _vm.$set(_vm.address, "public_place", $$v)
                  },
                  expression: "address.public_place"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ItpFormGroup",
            {
              staticClass: "col-5",
              attrs: {
                name: "public_place_type",
                label: _vm._f("pgettext")("Public Place Type", "Address"),
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { name: "public_place_type" },
                model: {
                  value: _vm.address.public_place_type,
                  callback: function($$v) {
                    _vm.$set(_vm.address, "public_place_type", $$v)
                  },
                  expression: "address.public_place_type"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "DIV",
        { staticClass: "form-row" },
        [
          _c(
            "ItpFormGroup",
            {
              staticClass: "col",
              attrs: {
                name: "house_nr",
                label: _vm._f("pgettext")("House Nr", "Address"),
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { name: "house_nr" },
                model: {
                  value: _vm.address.house_nr,
                  callback: function($$v) {
                    _vm.$set(_vm.address, "house_nr", $$v)
                  },
                  expression: "address.house_nr"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ItpFormGroup",
            {
              staticClass: "col",
              attrs: {
                name: "building_nr",
                label: _vm._f("pgettext")("Building Nr", "Address"),
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { name: "building_nr" },
                model: {
                  value: _vm.address.building_nr,
                  callback: function($$v) {
                    _vm.$set(_vm.address, "building_nr", $$v)
                  },
                  expression: "address.building_nr"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ItpFormGroup",
            {
              staticClass: "col",
              attrs: {
                name: "sub_building_nr",
                label: _vm._f("pgettext")("Sub Building Nr", "Address"),
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { name: "sub_building_nr" },
                model: {
                  value: _vm.address.sub_building_nr,
                  callback: function($$v) {
                    _vm.$set(_vm.address, "sub_building_nr", $$v)
                  },
                  expression: "address.sub_building_nr"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ItpFormGroup",
            {
              staticClass: "col",
              attrs: {
                name: "floor_nr",
                label: _vm._f("pgettext")("Floor Nr", "Address"),
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { name: "floor_nr" },
                model: {
                  value: _vm.address.floor_nr,
                  callback: function($$v) {
                    _vm.$set(_vm.address, "floor_nr", $$v)
                  },
                  expression: "address.floor_nr"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ItpFormGroup",
            {
              staticClass: "col",
              attrs: {
                name: "door_nr",
                label: _vm._f("pgettext")("Door Nr", "Address"),
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { name: "door_nr" },
                model: {
                  value: _vm.address.door_nr,
                  callback: function($$v) {
                    _vm.$set(_vm.address, "door_nr", $$v)
                  },
                  expression: "address.door_nr"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "other_details",
            label: _vm._f("pgettext")("Other Details", "Address"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormTextarea", {
            attrs: { name: "other_details" },
            model: {
              value: _vm.address.other_details,
              callback: function($$v) {
                _vm.$set(_vm.address, "other_details", $$v)
              },
              expression: "address.other_details"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }