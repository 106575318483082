var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "account_number",
            label: _vm._f("pgettext")("Account Number", "ClientBankAccount"),
            labelColsMd: "4",
            contentColsMd: "8",
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "account_number", required: "", autofocus: "" },
            model: {
              value: _vm.bankAccount.account_number,
              callback: function($$v) {
                _vm.$set(_vm.bankAccount, "account_number", $$v)
              },
              expression: "bankAccount.account_number"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "description",
            label: _vm._f("pgettext")("Description", "ClientBankAccount"),
            labelColsMd: "4",
            contentColsMd: "8",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "description" },
            model: {
              value: _vm.bankAccount.description,
              callback: function($$v) {
                _vm.$set(_vm.bankAccount, "description", $$v)
              },
              expression: "bankAccount.description"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "iban",
            label: _vm._f("pgettext")("IBAN", "ClientBankAccount"),
            labelColsMd: "4",
            contentColsMd: "8",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "iban" },
            model: {
              value: _vm.bankAccount.iban,
              callback: function($$v) {
                _vm.$set(_vm.bankAccount, "iban", $$v)
              },
              expression: "bankAccount.iban"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "valid_from",
            label: _vm._f("pgettext")("Validity", "ClientBankAccount"),
            labelColsMd: "4",
            contentColsMd: "8",
            labelAlign: _vm.labelAlign,
            contentClass: "form-row"
          }
        },
        [
          _c(
            "DIV",
            { staticClass: "col" },
            [
              _c("ItpFormDatetime", {
                attrs: { name: "valid_from" },
                model: {
                  value: _vm.bankAccount.valid_from,
                  callback: function($$v) {
                    _vm.$set(_vm.bankAccount, "valid_from", $$v)
                  },
                  expression: "bankAccount.valid_from"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "DIV",
            { staticClass: "col" },
            [
              _c("ItpFormDatetime", {
                attrs: { name: "valid_to" },
                model: {
                  value: _vm.bankAccount.valid_to,
                  callback: function($$v) {
                    _vm.$set(_vm.bankAccount, "valid_to", $$v)
                  },
                  expression: "bankAccount.valid_to"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            name: "is_default",
            label: _vm._f("pgettext")("Default", "Client"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormCheckbox", {
            attrs: { name: "is_default" },
            model: {
              value: _vm.bankAccount.is_default,
              callback: function($$v) {
                _vm.$set(_vm.bankAccount, "is_default", $$v)
              },
              expression: "bankAccount.is_default"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }